import { APP_WILL_LOAD, APP_WILL_UNMOUNT } from './actionTypes';
export const appWillLoad = () => {
    return {
        type: APP_WILL_LOAD,
    };
};
export const appWillUnmount = () => {
    return {
        type: APP_WILL_UNMOUNT,
    };
};
