import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../Colors';
import { useGlobalState } from '../../../GlobalStateProvider';
import { Icon } from '../../../common';
import { useAssignmentList, useStatistics } from '../../../server';
import { ErrorContainer } from '../../DSS/DSSL001/DSSL001_Detail_Child';
import { MobileTabLayout } from '../../base/layout';
import { AverageExamAttendance, ChoiceSubject, DSTL001DetailStyled, InfoTitle, SubmissionStatusGraph, SubmissionStatusTable, TaskInfoContainer, UpperAverage, } from './component/DSTL001_Detail_Child';
import { TEST_STATUS } from './constant';
export const DSTL001_Detail = React.memo(({ isTest = false, test_id, props }) => {
    const { t } = useTranslation();
    const { isTablet, isDesktop, isMobile } = useGlobalState();
    const [item, setItem] = React.useState(null);
    const [statisticItem, setStatisticItem] = React.useState(null);
    const [subjectId, setSubjectId] = React.useState(isDesktop ? null : undefined);
    const [isErrorGraph, setIsErrorGraph] = React.useState(false);
    const { data, refetch } = useAssignmentList({ id: test_id });
    const { data: statisticData, refetch: statisticRefetch } = useStatistics({
        test_id,
        subject_item: subjectId,
    });
    useEffect(() => {
        if (test_id) {
            refetch();
        }
    }, [test_id]);
    useEffect(() => {
        if (test_id)
            statisticRefetch();
    }, [test_id, subjectId]);
    useEffect(() => {
        if (!data)
            return;
        if (data.found_count > 0) {
            const item = data.items[0];
            setItem(item);
        }
    }, [data]);
    useEffect(() => {
        if (!statisticData)
            return;
        setStatisticItem(statisticData);
    }, [statisticData]);
    const previousPage = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: undefined,
            id: undefined,
        });
    };
    //과목 선택 DropDown
    const renderChoiceSubject = useMemo(() => (React.createElement(ChoiceSubject, { key: 'choiceSubject', test_id: test_id, setSubjectId: setSubjectId })), [test_id, subjectId]);
    // 과제 상세보기
    const renderTackInfo = useMemo(() => isTablet ? (React.createElement("div", { key: 'taskInfo', className: "task-info-wrap" },
        React.createElement(TaskInfoContainer, { isTeacher: true, isTest: isTest, item: item, component: renderChoiceSubject }))) : (React.createElement(TaskInfoContainer, { key: 'taskInfo', isTeacher: true, isTest: isTest, item: item })), [isTest, item]);
    // 제출 결과
    const renderSubmissionStatusTable = useMemo(() => (React.createElement(SubmissionStatusTable, { key: 'submissionStatus', test_id: test_id, isTest: isTest, subjectId: subjectId })), [isTest, test_id, subjectId]);
    //제출 현황
    const renderSubmissionGraph = useMemo(() => statisticItem && (React.createElement("div", { key: 'submssionGraph', className: "wrong-rate-list" },
        React.createElement(SubmissionStatusGraph, { test_id: test_id, subject_item: subjectId }),
        React.createElement(AverageExamAttendance, { average: statisticItem.average['100%'], question_count: statisticItem.question_count }),
        React.createElement(UpperAverage, { test_id: test_id, subject_item: subjectId, average: statisticItem.average }))), [test_id, subjectId, statisticItem]);
    //오답률 보기
    const renderErrorContainer = useMemo(() => item && (React.createElement(ErrorContainer, { key: 'errorContainer', isTest: isTest, isSubmit: item.status === TEST_STATUS.CLOSED, isClosed: item.status === TEST_STATUS.CLOSED, test_id: test_id, setIsErrorGraph: setIsErrorGraph })), [isTest, item, test_id]);
    const renderInfoTitle = useMemo(() => item && (React.createElement(InfoTitle, { key: 'infoTitle', isTeacher: true, title: item.title, test_id: test_id, isTest: isTest, previousPage: previousPage })), [item?.title, test_id, isTest, previousPage]);
    if (!item)
        return null;
    if (isMobile) {
        return (React.createElement(DSTL001DetailStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        title: isTest ? '테스트 상세보기' : '과제 상세보기',
                        component: [renderTackInfo],
                        styles: { gap: '12px', height: 'unset' },
                    },
                    {
                        id: 2,
                        title: '제출현황 리스트',
                        component: [renderSubmissionStatusTable],
                        styles: { height: 'unset' },
                    },
                    {
                        id: 3,
                        title: '제출 현황 ',
                        component: [renderChoiceSubject, renderSubmissionGraph],
                        styles: {
                            paddingBottom: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        },
                    },
                    {
                        id: 4,
                        title: '오답률 보기',
                        component: renderErrorContainer,
                        styles: {
                            height: isErrorGraph ? 'unset' : '100%',
                        },
                    },
                ], showNav: false, showPageButton: true, showPageTab: true, submitAssignment: previousPage, mobilePage: props ? props.mobilePage : undefined })));
    }
    if (isTablet) {
        return (React.createElement(DSTL001DetailStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        title: isTest ? '테스트 상세보기' : '과제 상세보기',
                        component: [renderTackInfo, renderSubmissionStatusTable],
                        styles: {
                            overflow: 'auto',
                            width: '100%',
                            gap: '24px',
                            display: 'flex',
                            flexDirection: 'column',
                        },
                    },
                    {
                        id: 2,
                        title: '제출 현황',
                        component: [renderSubmissionGraph],
                        styles: {
                            overflow: 'auto',
                            paddingBottom: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        },
                    },
                    {
                        id: 3,
                        title: '오답률 보기',
                        component: renderErrorContainer,
                        styles: {
                            overflow: 'auto',
                            height: isErrorGraph ? 'unset' : '100%',
                        },
                    },
                ], footer: renderInfoTitle })));
    }
    return (React.createElement(DSTL001DetailStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
        renderInfoTitle,
        React.createElement("div", { className: "warp-001" },
            React.createElement("div", { className: "test-info-container" },
                renderTackInfo,
                renderSubmissionStatusTable),
            React.createElement("div", { className: "value-container" },
                renderChoiceSubject,
                statisticItem ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "_001" }, renderSubmissionGraph),
                    renderErrorContainer)) : (React.createElement("div", { className: "fext-field no-rate-wrap" },
                    React.createElement(Icon, { icon: "detail-delete", size: 35, fill: Colors.w_grey }),
                    React.createElement("p", null, t('assignment.noSubmitInfo'))))))));
});
