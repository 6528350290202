import React from 'react';
import styled from 'styled-components';
import { Button } from '../../../../common';
const DupliactionErrorPageStyled = styled.div `
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	background-color: var(--color-tur, #3ab2bf);

	.back_home {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 590px;
	}

	.btn_white {
		z-index: 1;
		border: none;
		padding: 50px;
		background-color: var(--color-white, #ffffff);
		p {
			font-size: var(--b-l-medium-font-size, 16px);
		}
	}
`;
export const DupliactionErrorPage = React.memo(() => {
    return (React.createElement(DupliactionErrorPageStyled, { className: "empty_data" },
        React.createElement(Button, { className: "btn_white lg", text: "\uC911\uBCF5 \uB85C\uADF8\uC778\uC744 \uC9C0\uC6D0\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4. \uB2E4\uC2DC \uB85C\uADF8\uC778 \uD574\uC8FC\uC138\uC694. " })));
});
