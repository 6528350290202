import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from '../../../../common';
import { postAuthTokenGet } from '../../../../server';
import { ClassesDropDown } from '../../classes';
const LoginStyled = styled.div `
	width: 100%;
	height: 100%;
	background: var(--color-tur, #3ab2bf);
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;

	> * {
		box-sizing: border-box;
	}

	.login_box {
		position: relative;
		overflow: auto;
		padding: 20px;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
		border-radius: 10px;
		margin: 0 auto;
		background: var(--color-white, #ffffff);
		display: flex;
		flex-direction: column;
		gap: 24px;
		justify-content: flex-start;
		align-items: center;
		height: 80%;
		justify-content: flex-start;
		width: 90%;
		overflow: auto;

		.login_line {
			display: flex;
			gap: 12px;
			align-items: center;
			justify-content: center;
			width: 100%;

			.login_title {
				width: 95px;
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
				font-size: var(--h4-font-size, 20px);
				line-height: var(--h4-line-height, 32px);
				font-weight: var(--h4-font-weight, 700);
				position: relative;
			}

			input {
				padding: 12px;
				flex: 1;
			}
		}

		.dropdown {
			position: relative;
			width: 100%;
		}
	}
`;
export function setCookie(name, value, days) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
}
export function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
export const Login2 = React.memo(() => {
    const [classes, setClasses] = React.useState('');
    // useEffect(() => {
    // 	localStorage.clear();
    // }, []);
    // const loginMutation = useMutation(postLogin);
    const onLogin = async (auth_name) => {
        // try {
        // 	const response: any = await loginMutation.mutateAsync({
        // 		auth_name: id,
        // 		auth_password: password,
        // 	});
        // 	dispatch(setToken(response.data.token));
        // 	navigate(`/class/${classes}`);
        // } catch (error) {
        // 	alert(error);
        // }
        if (!classes) {
            alert('클래스를 선택하세요.');
            return;
        }
        const response = await postAuthTokenGet({
            partner_id: 'default',
            key: 'Master-Key',
            user_id: auth_name,
        });
        const result = response.result;
        if (result) {
            const token = {
                access_token: result.access_token,
                refresh_token: result.refresh_token,
            };
            token.refresh_token && setCookie('refresh_token', token.refresh_token, 1);
            const popupUrl = `/class/${classes}`;
            const popupName = 'Test';
            const popupFeatures = 'popup=true, width=1920, height=1024, scrollbars=no';
            // Open the popup window
            const popupWindow = window.open(popupUrl, popupName, popupFeatures);
            // Focus on the popup window (optional)
            if (popupWindow) {
                popupWindow.focus();
                popupWindow.onload = function () {
                    // Remove an item from localStorage in the popup window
                    // popupWindow.localStorage.clear();
                };
            }
        }
        // const user = response.result.user;
        // if (user) {
        // 	store.dispatch(setToken(token));
        // 	if (location.pathname.includes(`/class/${classes}`)) return;
        // 	window.location.assign(`/class/${classes}`);
        // 	return;
        // } else {
        // 	alert('잘못된 사용자 정보입니다. 다시 로그인하세요');
        // }
        // dispatch(setToken({ access_token: member.access_token, refresh_token: undefined }));
        // navigate(`/class/${classes}`);
    };
    return (React.createElement(LoginStyled, null,
        React.createElement("div", { className: "login_box" },
            React.createElement(Icon, { icon: "logo", width: 90, height: 40 }),
            React.createElement("div", { className: "login_line" },
                React.createElement("div", { className: "login_title" }, "\uD074\uB798\uC2A4"),
                React.createElement(ClassesDropDown, { setClasses: setClasses })),
            React.createElement("div", { className: "flex row", style: {
                    gap: 24,
                    flexWrap: 'wrap',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                } }, Array.from({ length: 16 }, (_, index) => {
                return (React.createElement(Button, { key: index, className: "btn_white xmd", text: `member_${index}`, onClick: () => onLogin(`member${index}`) }));
            })))));
});
