import React from 'react';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../GlobalStateProvider';
const SubjectListBoxStyled = styled.div `
	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileStyles = css `
	background: ${props => props.$isHome ? 'var(--color-silver, #f3f4f8)' : 'var(--color-white, #ffffff)'};
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	gap: 6px;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	min-width: 100px;
	padding: ${props => (props.$isHome ? '10px' : undefined)};

	.frame-986988 {
		width: 100%;
		background: var(--color-silver, #f3f4f8);
		border-radius: 100px;
		padding: ${props => (props.$isHome ? '10px 0px 10px 0px' : '10px 5px')};
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		max-width: 150px;
	}
	.fixed {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 12px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		position: relative;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.value {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: 'NotoSansKr-Bold', sans-serif;
		font-size: ${props => (props.$isHome ? '22px' : '20px')};
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		width: 64px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
const tabletStyles = css `
	background: ${props => props.$isHome ? 'var(--color-silver, #f3f4f8)' : 'var(--color-white, #ffffff)'};
	border-radius: 12px;
	padding: ${props => props.$isHome && '10px 5px 10px 5px'};
	display: flex;
	flex-direction: column;
	gap: ${props => !props.$isHome && '6px'};
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	min-width: 100px;

	.frame-986988 {
		width: 100%;
		background: var(--color-silver, #f3f4f8);
		border-radius: 100px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
	}
	.fixed {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: var(--b1-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b3-font-size, 14px);
		line-height: ${props => (props.$isHome ? '150%' : 'var(--b1-line-height, 42px)')};
		font-weight: var(--b1-font-weight, 400);
		position: relative;
		height: 40px;
		display: flex;
		padding: 0 20px;
		align-items: center;
		justify-content: center;
	}
	.value {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: var(--h2-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--b3-font-size, 22px);
		line-height: ${props => (props.$isHome ? '150%' : 'var(--b1-line-height, 46px)')};
		font-weight: var(--h2-font-weight, 700);
		position: relative;
		width: 64px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
const desktopStyles = css `
	background: ${props => props.$isHome ? 'var(--color-silver, #f3f4f8)' : 'var(--color-white, #ffffff)'};
	border-radius: 12px;
	padding: ${props => (props.$isHome ? '10px 5px 10px 5px' : '20px 10px 20px 10px')};
	display: flex;
	flex-direction: column;
	gap: ${props => !props.$isHome && '16px'};
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	min-width: 124px;

	.frame-986988 {
		width: 100%;
		background: var(--color-silver, #f3f4f8);
		border-radius: 100px;
		padding: 2px 0px 2px 0px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
	}
	.fixed {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: var(--b1-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: ${props => props.$isHome ? 'var(--b3-font-size, 14px);' : 'var(--b1-font-size, 20px)'};
		line-height: ${props => (props.$isHome ? '150%' : 'var(--b1-line-height, 42px)')};
		font-weight: var(--b1-font-weight, 400);
		position: relative;
		height: 40px;
		display: flex;
		padding: 0 20px;
		align-items: center;
		justify-content: center;
	}
	.value {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: var(--h2-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: ${props => props.$isHome ? 'var(--b3-font-size, 22px);' : 'var(--h2-font-size, 32px)'};
		line-height: ${props => (props.$isHome ? '150%' : 'var(--b1-line-height, 46px)')};
		font-weight: var(--h2-font-weight, 700);
		position: relative;
		width: 64px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
export const SubjectListBox = React.memo(({ subject_item, score, isHome }) => {
    const { isMobile, isTablet } = useGlobalState();
    return (React.createElement(SubjectListBoxStyled, { className: "category", "$isHome": isHome, "$isMobile": isMobile, "$isTablet": isTablet },
        isHome ? (React.createElement("div", { className: "fixed" }, subject_item)) : (React.createElement("div", { className: "frame-986988" },
            React.createElement("div", { className: "fixed" }, subject_item))),
        React.createElement("div", { className: "value" },
            score,
            " ")));
});
