import { ReducerRegistry } from '../../../redux/regist';
import { SET_CLASSES_DATA } from './actionTypes';
const initialState = {
    classes: undefined,
};
ReducerRegistry.register('base/classes', (state = initialState, action) => {
    switch (action.type) {
        case SET_CLASSES_DATA:
            return {
                ...state,
                classes: action.classes,
            };
    }
    return state;
});
