import moment from 'moment';
function getWebsocketURLBase() {
    const urlBase = window.location.origin;
    if (urlBase.startsWith('http:')) {
        return `ws:${urlBase.substring(5)}`;
    }
    else if (urlBase.startsWith('https:')) {
        return `wss:${urlBase.substring(6)}`;
    }
    throw new Error('알 수 없는 프로토콜');
}
const handleMessage = (data) => {
    switch (data.class) {
    }
};
const formatMessage = msg => {
    if (!msg || !msg?.format) {
        return '';
    }
    if (!msg?.params) {
        return msg.format;
    }
    return msg.format.replace(/\{(\w+)\}/g, (match, p1) => {
        return msg.params[p1];
    });
};
export class PlayerWebsoket {
    connection;
    onMessage;
    nextId;
    timeDiff;
    streamingId;
    constructor() {
        this.onMessage = [];
        this.connection = null;
        this.nextId = 0;
        this.timeDiff = 0;
        this.streamingId = null;
        this.joinStreaming = this.joinStreaming.bind(this);
    }
    cleanUp() {
        this.onMessage = [];
        this.nextId = 0;
        this.timeDiff = 0;
        this.streamingId = '';
    }
    disconnect() {
        if (this.connection) {
            this.connection.close();
        }
    }
    connect() {
        const connection = new WebSocket(`${getWebsocketURLBase()}/api/media/v1/websocket`);
        return new Promise((resolve, reject) => {
            this.connection = connection;
            connection.onopen = async () => {
                console.log('웹소켓 연결됨.');
                connection.onclose = () => {
                    this.connection = null;
                };
                connection.onmessage = message => {
                    handleMessage(JSON.parse(message.data));
                    this.onMessage.forEach(handle => handle(message.data));
                };
                resolve(true);
            };
            connection.onerror = err => {
                this.connection = null;
                reject(err);
            };
            connection.onclose = () => {
                this.connection = null;
                resolve(true);
            };
        });
    }
    getTimeDiff() {
        return new Promise(async (resolve, reject) => {
            try {
                const t1 = Date.now();
                const message = await this._request('GetServerTime', null, 5000);
                const t2 = Date.now();
                if (message?.status != 200) {
                    throw new Error(formatMessage(message?.payload?.message));
                }
                const serverTime = Date.parse(message?.payload?.result?.time);
                this.timeDiff = t1 + (t2 - t1) / 2 - serverTime;
                return resolve(this.timeDiff);
            }
            catch (err) {
                reject(formatMessage(err));
            }
        });
    }
    // 스트리밍 참여
    joinStreaming(id) {
        console.log('joinStreaming', id);
        if (!id)
            return Promise.resolve();
        if (this.streamingId === id) {
            return Promise.resolve();
        }
        return new Promise(async (resolve, reject) => {
            try {
                const joinResponse = await this._request('SubscribeStreaming', {
                    streaming_id: id,
                }, 5000);
                if (joinResponse?.status != 200) {
                    throw new Error(joinResponse?.payload?.message);
                }
                this.timeDiff = (await this.getTimeDiff()) || 0;
                this.streamingId = id;
                const { play_position, speed, status, play_time, time, media, pause_time } = joinResponse?.payload?.result;
                const isPaused = moment(play_time).isBefore(moment(pause_time));
                resolve({
                    play_position,
                    speed,
                    status,
                    time,
                    play_time: isPaused ? pause_time : play_time,
                    isPaused,
                    media,
                });
            }
            catch (err) {
                reject(err);
            }
        });
    }
    close() {
        this.connection?.close();
    }
    _request(clazz, payload, timeout = 5000) {
        if (this.connection && this.connection.readyState === WebSocket.OPEN) {
            const requestID = `${Date.now()}-${++this.nextId}`;
            this.connection.send(JSON.stringify({
                class: clazz,
                id: requestID,
                payload,
            }));
            return this._waitFor(data => JSON.parse(data), res => res?.payload?.id === requestID || res?.id === requestID, timeout, clazz);
        }
        console.log('웹소켓 연결 안됨.', payload);
        return Promise.reject('api.refreshError');
    }
    _subscribe(handle) {
        this.onMessage.push(handle);
        return () => {
            const index = this.onMessage.findIndex(element => element === handle);
            if (index >= 0) {
                this.onMessage.splice(index, 1);
            }
        };
    }
    async _waitFor(hook, check, timeout, clazz) {
        return new Promise((resolve, reject) => {
            let timerID;
            let unsubscribe;
            const cleanup = () => {
                clearTimeout(timerID);
                unsubscribe();
            };
            unsubscribe = this._subscribe(data => {
                try {
                    const result = hook(data);
                    if (!check(result))
                        return;
                    cleanup();
                    resolve(result);
                }
                catch (err) {
                    cleanup();
                    reject(err);
                }
            });
            timerID = setTimeout(() => {
                cleanup();
                reject(new Error(`${clazz} timeout`));
            }, timeout);
        });
    }
}
