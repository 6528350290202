import React, { useEffect } from 'react';
import InputElement from './Input';
import Icon from './Icon';
import { Colors } from '../../Colors';
const SearchBar = React.memo(({ className = 'md', filterData = 'name', handlerFilterList, isMember = false, defaultSearchContent, isIconHide = false, isDisable = false, }) => {
    const searhTextRef = React.useRef(null);
    const [isFocused, setIsFocused] = React.useState(false);
    const [textValue, setTextValue] = React.useState('');
    const onSubmit = () => {
        if (!isIconHide) {
            handlerFilterList(textValue === '' ? { [filterData]: undefined } : { [filterData]: textValue });
        }
    };
    useEffect(() => {
        if (isIconHide) {
            handlerFilterList(textValue === '' ? { [filterData]: undefined } : { [filterData]: textValue });
        }
    }, [isIconHide, textValue, filterData]);
    // 검색어 초기화(dropDown 변경 시)
    useEffect(() => {
        setTextValue('');
    }, [isDisable]);
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);
    const getSize = () => {
        if (className.includes('sm')) {
            return 20;
        }
        else if (className.includes('lg')) {
            return 28;
        }
        return 24;
    };
    useEffect(() => {
        if (searhTextRef.current) {
            searhTextRef.current.value = defaultSearchContent || '';
            setTextValue(defaultSearchContent || '');
        }
    }, [defaultSearchContent]);
    return (React.createElement("div", { className: `flex row search-bar ${className} ${isFocused && 'focus'}` },
        React.createElement(InputElement, { ref: searhTextRef, type: "text", placeholder: isMember ? '성명 검색' : '검색어를 입력해주세요', isEnterPrevent: true, onChange: setTextValue, onSubmit: onSubmit, onFocus: handleFocus, onBlur: handleBlur, value: isDisable ? '' : textValue, isDisable: isDisable }),
        !isIconHide && (React.createElement("div", { className: "flex row icon", onClick: onSubmit },
            React.createElement(Icon, { icon: "search", size: getSize(), fill: Colors.tur })))));
});
export default SearchBar;
