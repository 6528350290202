import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { Icon } from '../../../../common';
const DateRangeSelector = ({ defaultStartDate, defaultEndDate, setStartDate, setEndDate }) => {
    const [dateRangeDisplay, setDateRangeDisplay] = useState('날짜를 선택해주세요.');
    const startRef = useRef(null);
    const endRef = useRef(null);
    useEffect(() => {
        if (defaultStartDate) {
            startRef.current.value = defaultStartDate;
            handlerCheckStartDate(defaultStartDate);
        }
        if (defaultEndDate) {
            endRef.current.value = defaultEndDate;
            handlerCheckEndDate(defaultEndDate);
        }
    }, [defaultStartDate, defaultEndDate]);
    const handlerCheckStartDate = newStartDate => {
        const today = moment().startOf('day');
        const newStartMoment = moment(newStartDate, 'YYYY-MM-DD');
        const endDateMoment = endRef.current.value
            ? moment(endRef.current.value, 'YYYY-MM-DD')
            : null;
        if (newStartMoment.isAfter(today)) {
            newStartMoment.set(today.toObject());
        }
        if (endDateMoment && newStartMoment.isAfter(endDateMoment)) {
            setEndDate(newStartDate);
            endRef.current.value = newStartDate;
        }
        const formattedStartDate = newStartMoment.format('YYYY-MM-DD');
        setStartDate(formattedStartDate);
        startRef.current.value = formattedStartDate;
        updateDateRangeDisplay(formattedStartDate, endRef.current.value);
    };
    const handlerCheckEndDate = newEndDate => {
        const today = moment().startOf('day');
        const startDateMoment = startRef.current.value
            ? moment(startRef.current.value, 'YYYY-MM-DD')
            : null;
        let newEndMoment = moment(newEndDate, 'YYYY-MM-DD');
        if (newEndMoment.isAfter(today)) {
            newEndMoment = today.clone();
        }
        if (startDateMoment && newEndMoment.isBefore(startDateMoment)) {
            newEndMoment = startDateMoment.clone();
        }
        const formattedEndDate = newEndMoment.format('YYYY-MM-DD');
        setEndDate(formattedEndDate);
        endRef.current.value = formattedEndDate;
        updateDateRangeDisplay(startRef.current.value, formattedEndDate);
    };
    const updateDateRangeDisplay = (start, end) => {
        if (start && end) {
            const startMoment = moment(start, 'YYYY-MM-DD');
            const endMoment = moment(end, 'YYYY-MM-DD');
            const daysDiff = endMoment.diff(startMoment, 'days');
            if (daysDiff < 0) {
                setDateRangeDisplay('유효하지 않은 날짜 범위입니다.');
            }
            else if (daysDiff === 0) {
                setDateRangeDisplay(`선택된 날짜: ${startMoment.format('YYYY년 MM월 DD일')}`);
            }
            else {
                setDateRangeDisplay(`${startMoment.format('YYYY년 MM월 DD일')} - ${endMoment.format('YYYY년 MM월 DD일')} (${daysDiff + 1}일)`);
            }
        }
        else {
            setDateRangeDisplay('날짜를 선택해주세요.');
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { type: "date", ref: startRef, onChange: e => handlerCheckStartDate(e.target.value), min: "2000-01-01", max: "2100-12-31" }),
        React.createElement(Icon, { icon: "dash", width: 12 }),
        React.createElement("input", { type: "date", ref: endRef, onChange: e => handlerCheckEndDate(e.target.value), min: "2000-01-01", max: "2100-12-31" })));
};
export default DateRangeSelector;
