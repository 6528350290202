import React, { useCallback, useMemo } from 'react';
import { CheckBoxElement } from './CheckBox';
const RenderBody = ({ isMobile = false, selectedId, tableList, styles, showCheckBox, checkedList, disableIdList, showIndex, page, showCount, isInifinite, handlerSelectItem, handlerCheck, renderItem, renderMoreItem, isHome = true, }) => (React.createElement(React.Fragment, null, tableList.map((item, index) => (React.createElement(TableItem, { key: index, isMobile: isMobile, selectedId: selectedId, item: item, index: index, styles: styles, showCheckBox: showCheckBox, checkedList: checkedList, disable: disableIdList.includes(item.id), showIndex: showIndex, page: page, showCount: showCount, isInifinite: isInifinite, renderItem: renderItem, renderMoreItem: renderMoreItem, handlerSelectItem: handlerSelectItem, handlerCheck: handlerCheck, isHome: isHome })))));
const TableItem = ({ isMobile, selectedId, item, index, styles, showCheckBox, disable, checkedList, handlerSelectItem, handlerCheck, showIndex, page, showCount, isInifinite, renderItem, renderMoreItem, }) => {
    const handleClick = useCallback((e) => {
        // Check if the click originated from the checkbox
        const target = e.target;
        // 클릭한 요소가 체크박스 또는 체크박스를 포함하는 요소인지 확인
        // @ts-ignore
        if (target.tagName === 'INPUT' && target.type === 'checkbox') {
            return; // 체크박스를 클릭한 경우, handleClick 함수 실행 중지
        }
        // 부모 요소들 중에서 체크박스를 포함하는지 검사
        if (target.closest('svg')) {
            return; // 체크박스를 포함한 요소를 클릭한 경우, handleClick 함수 실행 중지
        }
        if (handlerSelectItem) {
            handlerSelectItem(item);
        }
    }, [handlerSelectItem, item]);
    const renderComponent = useMemo(() => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: `${isMobile ? 'mobile_item item' : 'item'} ${selectedId === item.id ? 'selected' : ''} ${disable && 'disable'}`, style: {
                ...styles?.bodyWrapper,
                cursor: renderMoreItem || handlerSelectItem ? 'pointer' : 'default',
            }, onClick: handleClick },
            showCheckBox && (React.createElement("div", { className: "body-item", style: {
                    ...styles?.bodyItem,
                    minWidth: 30,
                    maxWidth: isMobile ? '30px' : 'unset',
                } }, !disable ? (React.createElement(CheckBoxElement, { id: item.id, iconSize: 22, checked: checkedList.includes(item.id), handlerCheck: handlerCheck })) : (React.createElement("div", null)))),
            showIndex && (React.createElement("div", { className: "body-item", style: { ...styles?.bodyItem, minWidth: 45, maxWidth: 60 } },
                React.createElement("p", { style: styles?.bodyText }, isInifinite ? index + 1 : page * showCount + index + 1))),
            renderItem(item, styles, index, isMobile)),
        /**  홈화면에서 선택한 아이템에만 하단 border 추가
             -> 요약보기 선택 시 border-radius가 적용되어 하단 밑줄이 없어지는 것 처럼 보임 **/
        selectedId === item.id && React.createElement("div", { className: "home-hr" }))), [
        item,
        checkedList,
        handlerCheck,
        showCheckBox,
        showIndex,
        page,
        showCount,
        renderItem,
        styles,
        isMobile,
        renderMoreItem,
    ]);
    return (React.createElement(React.Fragment, null,
        renderComponent,
        selectedId === item.id && renderMoreItem && renderMoreItem(item, styles)));
};
export default RenderBody;
