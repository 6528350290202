import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../Colors';
import { Icon } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { CONTENTS_TABS } from '../constants';
const ContentTabStyled = styled.div `
	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	border-style: solid;
	border-color: var(--color-grey-c, #e6e8ed);
	border-width: 0px 0px 1px 0px;
	padding: 0px 0px 24px 0px;
	display: flex;
	flex-direction: row;
	gap: 12px;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;

	.content-tab {
		border-radius: 12px;
		border-radius: 12px;
		padding: 12px 16px 12px 16px;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		cursor: pointer;
		flex: 1;

		svg {
			min-width: 20px;
		}
	}

	.on {
		background: var(--pur-l, #dfd6fb);
	}

	.off {
		background: var(--color-silver, #f3f4f8);
	}

	.tab-text {
		text-align: left;
		font-family: 'NotoSansKr-Bold', sans-serif;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;

		word-break: keep-all;
	}
`;
const tabletStyles = css `
	border-style: solid;
	border-color: var(--color-grey-c, #e6e8ed);
	border-width: 0px 1px 0px 0px;
	padding: 0px 24px 0px 0px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;

	.content-tab {
		border-radius: 12px;
		border-radius: 12px;
		padding: 12px 20px 12px 20px;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		width: 170px;
		position: relative;
		cursor: pointer;
	}

	.on {
		background: var(--pur-l, #dfd6fb);
	}

	.off {
		background: var(--color-silver, #f3f4f8);
	}

	.tab-text {
		text-align: left;
		font-family: 'NotoSansKr-Bold', sans-serif;
		font-size: 18px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
	}
`;
const desktopStyles = css `
	border-style: solid;
	border-color: var(--color-grey-c, #e6e8ed);
	border-width: 0px 1px 0px 0px;
	padding: 0px 48px 0px 0px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;

	.content-tab {
		border-radius: 12px;
		padding: 24px 30px 24px 30px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		width: 174px;
		position: relative;
		cursor: pointer;
	}

	.on {
		background: var(--pur-l, #dfd6fb);
	}

	.off {
		background: var(--color-silver, #f3f4f8);
	}

	.tab-text {
		text-align: left;
		font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h3-font-size, 24px);
		line-height: var(--h3-line-height, 36px);
		letter-spacing: var(--h3-letter-spacing, -0.02em);
		font-weight: var(--h3-font-weight, 700);
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
	}
`;
export const ContentTab = React.memo(({ menu, setMenu }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = useGlobalState();
    const handlerContentTab = (menu) => {
        setMenu(menu);
    };
    return (React.createElement(ContentTabStyled, { "$isTablet": isTablet, "$isMobile": isMobile }, CONTENTS_TABS.map(tab => (React.createElement("div", { key: tab.key, className: `content-tab ${menu === tab.key ? 'on' : 'off'}`, onClick: () => handlerContentTab(tab.key), style: {
            background: menu === tab.key
                ? Colors.light_pur
                : 'var(--color-silver, #f3f4f8)',
        } },
        React.createElement(Icon, { icon: tab.icon, fill: menu === tab.key ? Colors.pur : Colors.w_grey, width: isMobile ? 20 : 25, height: isMobile ? 20 : 25 }),
        React.createElement("p", { className: "tab-text", style: {
                color: menu === tab.key ? Colors.dark_pur : Colors.light_grey,
            } }, t(tab.text)))))));
});
