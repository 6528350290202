import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../common';
import { TOGGLE_POPUP } from '../popup';
/**
 * DS-T-L-001: 삭제 안내 팝업 (DS-POPUP-022)
 */
export const DeletePopUpLayout = React.memo(({ deleteContent, isQnA = false, showCancel = true, previosPage, onDelete, onRegist, }) => {
    const { t } = useTranslation();
    const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
    const handlerDelete = () => {
        onDelete && onDelete();
        APP.eventManager.publish(TOGGLE_POPUP);
        APP.eventManager.publish('SUCCESS_DELETE');
    };
    return (React.createElement("div", { className: "flex column center" },
        React.createElement("div", { className: "body1", style: {
                whiteSpace: 'break-spaces',
                textAlign: 'center',
            } }, deleteContent),
        React.createElement("div", { className: "btn-container" },
            React.createElement(Button, { className: "btn_default sm", text: t('common.okay'), onClick: onDelete
                    ? isQnA
                        ? onDelete
                        : handlerDelete
                    : onRegist
                        ? onRegist
                        : previosPage
                            ? previosPage
                            : closePopup }),
            showCancel && (React.createElement(Button, { className: "btn_white sm", text: t('common.cancel'), onClick: closePopup })))));
});
