import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
export const GradeCategoryDropDown = React.memo(({ defaultValue, handlerUpdateValue, }) => {
    const { isMobile } = useGlobalState();
    const { t } = useTranslation();
    const [dropValue, setDropValue] = React.useState({
        id: 'attend',
        title: 'assignment.attendTitle',
    });
    const [dropList, setDropList] = React.useState([
        { id: 'attend', title: 'assignment.attendTitle' },
        { id: 'test', title: 'assignment.testTitle' },
        { id: 'task', title: 'assignment.taskTitle' },
    ]);
    useEffect(() => {
        setDropValue(dropList.find(item => item.id === defaultValue));
    }, [defaultValue]);
    useEffect(() => {
        handlerUpdateValue(dropValue.id);
    }, [dropValue]);
    return (React.createElement("div", { className: "field-global-001" },
        React.createElement("div", { className: "task-label overText2", style: { minWidth: '135px', width: isMobile && '100%' } }, t('assignment.gradeCardChoice')),
        React.createElement("div", { className: "fext-field" },
            React.createElement(DropDown, { className: "dropdown-live", list: dropList, value: dropValue, setDropValue: setDropValue, styles: {
                    dropWrap: {
                        ...(isMobile
                            ? { width: '100%' }
                            : { minWidth: 200, maxWidth: 200 }),
                    },
                } }))));
});
