import { fetchData, objectToQueryString, useCustomQuery } from '../functions';
// (account) 사용자 정보 조회
const getUserInfoData = async (props) => {
    const queryString = objectToQueryString(props); // props를 쿼리 문자열로 변환
    const url = `/api/user/v1/users?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (account) 회원 계정
export const postLogin = async (props) => {
    const url = `/api/user/v1/issue-auth-token`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(props),
    });
};
// (account) 사용자 정보 조회 -useUserInfo
export const useUserInfo = (data) => {
    return useCustomQuery(['user', data], () => getUserInfoData(data));
};
// (account) 사용자 정보 조회 -useUserInfo
export const postAuthTokenGet = async (props) => {
    const { partner_id, key, user_id } = props;
    const url = `/api/mimac/v1/partner/${partner_id}/ifAuthTokenGet`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-API-Key': key,
        },
        body: JSON.stringify({
            userId: user_id,
        }),
    });
};
// (account) 사용자 정보 조회 -useUserInfo
export const postRefreshToken = async (props) => {
    const url = `/api/user/v1/refresh-auth-token`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(props),
    });
};
// (account) 사용자 정보 조회 -useUserInfo
export const postVerifyToken = async (props) => {
    const url = `/api/user/v1/verify-auth-token`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(props),
    });
};
