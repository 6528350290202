// 해당 과목에 대한 점수와 배점 배열 생성
export const createEvalArray = (scores) => {
    const scoresArray = [];
    scores.forEach(score => {
        const range = score.number.split('~');
        const start = parseInt(range[0]);
        const end = parseInt(range[1]) || start;
        for (let i = start; i <= end; i++) {
            scoresArray.push({
                id: i,
                type: score.type,
                score: score.score,
                answer: '',
                examples: 5,
            });
        }
    });
    return scoresArray;
};
// 해당 과목에 대한 점수와 배점 배열 생성
export const createFreeEvalArray = (objectiveNum, subjectiveNum) => {
    const scoresArray = [];
    for (let i = 1; i <= objectiveNum + subjectiveNum; i++) {
        const type = i <= objectiveNum ? 'objective' : 'subjective'; // objectiveNum 만큼 객관식, 나머지는 주관식
        const score = 2; // 기본값 2로 설정
        scoresArray.push({ id: i, type, score, answer: '', examples: 5 });
    }
    return scoresArray;
};
export const getQuestionType = (text) => {
    switch (text) {
        case 'OMR(기본형)':
        case 'OMR (기본형)':
            return 'OMR';
        case 'OMR(자유형)':
        case 'OMR (자유형)':
            return 'OMR-free';
        case '파일첨부형':
            return 'attachment';
        case '설문형':
            return 'survey';
    }
};
