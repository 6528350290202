import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../GlobalStateProvider';
const RegistContentStyled = styled.div `
	background: var(--color-white, #ffffff);
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	width: 100%;

	.fext-field {
		height: 100%;
		overflow: hidden;
	}

	.answer_wrapper {
		background: var(--color-white, #ffffff);
		border-radius: 10px;
		border-style: solid;
		border-color: var(--color-pink, #f46790);
		border-width: 1px;
		padding: 24px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		box-shadow: var(--shadow-bg-box-shadow, 0px 2px 6px 0px rgba(224, 226, 235, 0.4));
		width: 100%;
		height: 100%;
	}

	.box_wrapper {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 1px 0px 0px 0px;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 16px;
		height: 100%;
		padding: 20px 0px;
		overflow: auto;
	}

	.content_wrapper {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 20px 0px;
		background: var(--color-white, #ffffff);
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;

		.answer-content {
			width: 100%;
			height: 100%;
			background: var(--color-white, #ffffff);
			border-radius: 4px;
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px;
			padding: 10px;
			gap: 10px;
		}
	}
	.quill_wrap {
		flex: 1;
		overflow: hidden;
		align-items: start;
	}

	.quill {
		height: inherit;
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 0px;
		background: var(--color-white, #ffffff);
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 10px;
		display: flex;
		gap: 12px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
		max-width: 960px;
		> * {
			width: 100%;
			box-sizing: border-box;
		}
	}

	.disable-text {
		width: 100%;
		height: 100%;
		overflow: auto;
		word-break: break-all;
	}

	.ql-formats {
		svg {
			color: var(--color-pink, #f46790) !important;
		}
	}

	.ql-container {
		overflow: hidden;
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	flex: 1;
	font-size: 13px;

	.quill_wrap {
		min-height: 240px;
	}

	.answer-content {
		width: 100%;
		height: 100%;
		background: var(--color-white, #ffffff);
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		border-width: ${props => (props.$isTeacher && props.$isAnswer ? '0px 0px 1px 0px' : '1px')};
		border-radius: ${props => (props.$isTeacher && props.$isAnswer ? 'unset' : '4px')};
		//border-radius: 4px;
		padding: 10px;
		gap: 10px;
		//border-radius: unset;
	}
`;
const tabletStyles = css `
	flex: 1;
	.answer-content {
		width: 100%;
		height: 100%;
		background: var(--color-white, #ffffff);
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 10px;
		gap: 10px;
		min-height: 300px;
	}
`;
const desktopStyles = css `
	flex: 1;

	.quill_wrap {
		min-height: 350px;
	}

	.answer-content {
		width: 100%;
		height: 100%;
		background: var(--color-white, #ffffff);
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 10px;
		gap: 10px;
	}
`;
export const RegistContent = React.memo(({ isDisbaled = false, hideTitle = false, defaultValue, setValue, contentName, handlerTextLength, isAnswer = false, isRequestion = false, isTeacher = false, containerRef, }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = useGlobalState();
    const quillRef = useRef(null); // Ref to hold the Quill editor instance
    const [editorHtml, setEditorHtml] = useState(defaultValue ? defaultValue : '');
    const [textLength, setTextLength] = useState(0);
    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    [{ font: [] }],
                    [{ size: [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ color: [] }, { background: [] }],
                    [{ align: [] }],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    // ['blockquote', 'code-block'],
                    ['blockquote'],
                    ['image'],
                ],
                handlers: {
                    'letter-spacing': function (value) {
                        if (value) {
                            this.quill.format('letter-spacing', value);
                        }
                    },
                },
            },
        };
    }, []);
    useEffect(() => {
        quillRef.current?.editor.root.setAttribute('spellcheck', 'false');
    }, []);
    useEffect(() => {
        if (defaultValue !== editorHtml) {
            setEditorHtml(defaultValue || '');
        }
    }, [defaultValue]);
    useEffect(() => {
        handlerTextLength && handlerTextLength(textLength);
    }, [textLength]);
    useEffect(() => {
        setValue && setValue(editorHtml);
    }, [editorHtml]);
    const handleChange = (content, delta, source, editor) => {
        const length = editor.getLength();
        setEditorHtml(content);
        setTextLength(length);
    };
    if (isDisbaled) {
        return (React.createElement(RegistContentStyled, { "$isTablet": isTablet, "$isMobile": isMobile, "$isAnswer": isAnswer, "$isRequestion": isRequestion, "$isTeacher": isTeacher },
            React.createElement("div", { className: "field-global-001 quill_wrap" },
                !hideTitle && (React.createElement("div", { className: "task-label  overText2" },
                    React.createElement("p", null, contentName))),
                React.createElement("div", { className: "fext-field", style: { minHeight: isTablet && '265px' } },
                    React.createElement("div", { className: "disable-text ql-editor", dangerouslySetInnerHTML: { __html: editorHtml } })))));
    }
    // useEffect(() => {
    // 	const editor = quillRef.current.getEditor();
    // 	const container = containerRef.current;
    // 	if (container) {
    // 		const handlePaste = () => {
    // 			const scrollTop = container.clientHeight;
    // 			setTimeout(() => {
    // 				// 붙여넣기 후 새로 생성된 내용의 높이를 포함하여 스크롤 위치를 복원
    // 				container.scrollTop = editor.root.clientHeight;
    // 			}, 0);
    // 		};
    // 		editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
    // 			handlePaste();
    // 			return delta;
    // 		});
    // 	}
    // }, [containerRef]);
    useEffect(() => {
        const editor = quillRef.current.getEditor();
        const container = containerRef.current;
        if (container) {
            const handlePaste = () => {
                // 붙여넣기 작업이 완료된 후 커서 위치를 확인합니다.
                setTimeout(() => {
                    const selection = editor.getSelection();
                    if (selection) {
                        const bounds = editor.getBounds(selection.index);
                        // 붙여넣기 후 커서 위치로 이동
                        container.scrollTop = bounds.bottom;
                    }
                }, 0);
            };
            // 붙여넣기 이벤트 핸들러 등록
            editor.on('text-change', () => {
                // 붙여넣기 작업 완료 후 호출할 함수
                handlePaste();
            });
            // 클립보드 매처 등록 (선택적)
            editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
                return delta;
            });
        }
        // Cleanup on component unmount
        return () => {
            if (quillRef.current) {
                quillRef.current.getEditor().off('text-change');
            }
        };
    }, [containerRef]);
    return (React.createElement(RegistContentStyled, { "$isTablet": isTablet, "$isMobile": isMobile, "$isAnswer": isAnswer, "$isRequestion": isRequestion, "$isTeacher": isTeacher },
        React.createElement("div", { className: "field-global-001 quill_wrap" },
            !hideTitle &&
                (isMobile ? (React.createElement(React.Fragment, null)) : (React.createElement("div", { className: "task-label content-label overText2" },
                    React.createElement("p", null, contentName)))),
            React.createElement("div", { className: "fext-field" },
                React.createElement(ReactQuill, { className: "answer-content", ref: quillRef, theme: "snow", modules: modules, onChange: handleChange, value: editorHtml, placeholder: t('supportRoom.contentPlaceHolder') })))));
});
