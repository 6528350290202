/**
 * 기본 메뉴 리스트 (강사)
 */
export const DEFAULT_MENU_T_LIST = [
    'home',
    'learningManagement',
    'learningSupportRoom',
    'liveRoom',
];
/**
 * 기본 메뉴 리스트 (학생)
 */
export const DEFAULT_MENU_S_LIST = ['home', 'myRoom', 'learningSupportRoom', 'liveRoom'];
/**
 * 기본 메인 메뉴 기본값 (강사)
 */
export const DEFAULT_MAIN_T_ITEM = 'home';
/**
 * 기본 메인 메뉴 기본값 (학생)
 */
export const DEFAULT_MAIN_S_ITEM = 'home';
