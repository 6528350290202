import React, { useMemo } from 'react';
import Icon from './Icon';
import { ButtonOption } from '../option';
// @ts-ignore
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
export const Button = React.memo(({ id, className, text, option, tablIndex, styles, onClick, ellipsis }) => {
    const { t } = useTranslation();
    const buttonOption = useMemo(() => _.merge({}, ButtonOption, option), [option]);
    return (React.createElement("button", { id: id, className: `button ${className} ${onClick ? 'cursor-pointer' : 'cursor-default'}`, onClick: onClick, tabIndex: tablIndex, style: styles?.buttonWrap },
        buttonOption.buttonBeforeIcon.show && (React.createElement(Icon, { className: buttonOption.buttonBeforeIcon.className, icon: buttonOption.buttonBeforeIcon.name, size: buttonOption.buttonBeforeIcon.size, width: buttonOption.buttonBeforeIcon.width, height: buttonOption.buttonBeforeIcon.height, fill: buttonOption.buttonBeforeIcon.color, stroke: buttonOption.buttonBeforeIcon.stroke })),
        text && React.createElement("p", { className: "overText", style: ellipsis &&
                {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                } }, t(text)),
        buttonOption.buttonAfterIcon.show && (React.createElement(Icon, { className: buttonOption.buttonAfterIcon.className, icon: buttonOption.buttonAfterIcon.name, size: buttonOption.buttonAfterIcon.size, width: buttonOption.buttonBeforeIcon.width, height: buttonOption.buttonBeforeIcon.height, fill: buttonOption.buttonAfterIcon.color, stroke: buttonOption.buttonBeforeIcon.stroke }))));
});
