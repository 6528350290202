import React from 'react';
import { TOGGLE_POPUP } from '../../base/popup';
import { RegistButton } from '../RegistButton';
import { DSTP001_Regist } from './DSTP001_Regist';
import { AssignmentTable } from './component/DSTL001_List_Child';
import { useGlobalState } from '../../../GlobalStateProvider';
export const DSTL001_List = React.memo(({ isTeacher, isTest }) => {
    const { isDesktop, isMobile } = useGlobalState();
    const handlerButtonClick = () => {
        isMobile
            ? APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'regist',
                id: undefined,
            })
            : APP.eventManager.publish(TOGGLE_POPUP, {
                component: DSTP001_Regist,
                componentProps: { isTest, isTeacher: isTeacher },
                width: isDesktop ? 800 : 600,
                height: isDesktop ? 800 : 'unset',
                title: isTest ? '테스트 등록' : '과제 등록',
            });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(AssignmentTable, { isTeacher: isTeacher, isTest: isTest }),
        isTeacher && React.createElement(RegistButton, { handlerButtonClick: handlerButtonClick })));
});
