import { getAccessToken } from '../../base/account';
/**
 * 파일 용량을 사람이 읽기 쉬운 형식으로 변환
 * @param size - 바이트 단위의 파일 용량
 * @returns 사람이 읽기 쉬운 형식의 문자열 (예: "17.03 MB")
 */
export function formatFileSize(size) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
        size /= 1024;
        unitIndex++;
    }
    return `${size.toFixed(2)} ${units[unitIndex]}`;
}
export async function fetchVideoUrl(replayId, mediaId) {
    const getToken = () => getAccessToken(APP.store.getState);
    const url = `/api/mimac/v1/replays/${replayId}/source?media_id=${mediaId}`;
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
    if (!response.ok) {
        throw new Error('비디오 스트림을 가져오는 데 실패했습니다.');
    }
    return response.body;
}
export async function fetchGetStreamingAuth(id, media_id) {
    try {
        const getToken = () => getAccessToken(APP.store.getState);
        const url = `/api/mimac/v1/replays/${id}/ready`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({ media_id }),
        });
        if (!response.ok) {
            throw new Error('스트리밍 토큰을 가져오는데 실패했습니다.');
        }
        const data = await response.json();
        return data;
    }
    catch (err) {
        throw new Error(err.message || 'An unknown error occurred');
    }
}
export async function getStreaming({ streamingId }) {
    if (!streamingId) {
        throw new Error(' streamingId가 없습니다.');
    }
    try {
        const url = `/api/media/v1/streamings?id=${streamingId}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('스트리밍 토큰을 가져오는데 실패했습니다.');
        }
        const data = await response.json();
        return data;
    }
    catch (err) {
        throw new Error(err.message || 'An unknown error occurred');
    }
}
export async function getPlayerMediaId({ streamingId }) {
    if (!streamingId) {
        throw new Error('streamingAuth 또는 streamingId가 없습니다.');
    }
    try {
        const url = `/api/media/v1/streamings/${streamingId}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('스트리밍 토큰을 가져오는데 실패했습니다.');
        }
        const data = await response.json();
        return data;
    }
    catch (err) {
        throw new Error(err.message || 'An unknown error occurred');
    }
}
export async function playStreaming({ streamingAuth, streamingId, position, speed, }) {
    try {
        const url = `/api/media/v1/streamings/${streamingId}/play`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ position, speed, auth_token: streamingAuth }),
        });
        if (!response.ok) {
            throw new Error('스트리밍 토큰을 가져오는데 실패했습니다.');
        }
        const data = await response.json();
        return data;
    }
    catch (err) {
        throw new Error(err.message || 'An unknown error occurred');
    }
}
export async function stopStreaming({ streamingAuth, streamingId, }) {
    if (!streamingAuth || !streamingId) {
        throw new Error('streamingAuth 또는 streamingId가 없습니다.');
    }
    try {
        const url = `/api/media/v1/streamings/${streamingId}/pause`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ auth_token: streamingAuth }),
        });
        if (!response.ok) {
            throw new Error('스트리밍 토큰을 가져오는데 실패했습니다.');
        }
        const data = await response.json();
        return data;
    }
    catch (err) {
        throw new Error(err.message || 'An unknown error occurred');
    }
}
export function getFitSize(canvasStyle, pdfLength, isWidthFit) {
    let fitStyle = getScreenFitStyle(canvasStyle.width, canvasStyle.height, pdfLength.width, pdfLength.height, isWidthFit);
    if (isWidthFit === undefined) {
        if (canvasStyle.height < fitStyle.height || canvasStyle.width < fitStyle.width) {
            fitStyle = getScreenFitStyle(canvasStyle.width, canvasStyle.height, pdfLength.width, pdfLength.height, true);
            isWidthFit = true;
        }
    }
    return {
        width: Math.floor(fitStyle.width) < 0 ? pdfLength.width : Math.floor(fitStyle.width),
        height: Math.floor(fitStyle.height) < 0 ? pdfLength.height : Math.floor(fitStyle.height),
        fitWCanvas: isWidthFit,
    };
}
function getScreenFitStyle(width, height, imageWidth, imageHeight, isWidthFit) {
    if (isWidthFit) {
        // 폭맞춤
        return { width, height: imageHeight * (width / imageWidth) || height };
    }
    else {
        // 쪽맞춤
        return { width: imageWidth * (height / imageHeight) || width, height };
    }
}
