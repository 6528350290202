import { quitEnquiriesList } from '../../../server';
import { getAccessToken } from '../../base/account';
// (Action) Enter 회의실 입장
export const goToConference = async (id, text) => {
    let popup;
    try {
        const token = getAccessToken(APP.store.getState);
        const form = document.createElement('form');
        popup = window.open('', 'popupPage', 'directories=no,titlebar=no,toolbar=no,status=no,menubar=no,location=yes,width=1920,height=964,scrollbars=yes');
        if (!popup) {
            throw new Error('Popup was blocked');
        }
        form.setAttribute('target', 'popupPage');
        form.setAttribute('method', 'POST');
        form.setAttribute('action', `/api/mimac/v1/conferences/${id}/enter`);
        form.setAttribute('charset', 'UTF-8');
        const tokenElement = document.createElement('input');
        tokenElement.setAttribute('type', 'hidden');
        tokenElement.setAttribute('name', 'token');
        tokenElement.setAttribute('value', token);
        form.appendChild(tokenElement);
        document.body.appendChild(form);
        document.body.appendChild(form);
        form.submit();
        popup.focus();
    }
    catch (error) {
        if (text === 'live') {
            await quitEnquiriesList(id);
        }
        alert('팝업이 차단되어있습니다. 팝업 허용 후 다시 시도해주세요.');
        popup && popup.close();
    }
};
