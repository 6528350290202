import React, { forwardRef } from 'react';
export const FileInput = forwardRef((props, ref) => {
    const { id, className, children, onChange, setFile, ...rest } = props;
    const handleChange = (event) => {
        const file = event.target.files?.[0];
        setFile && setFile(file);
        if (file && rest?.accept) {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            const fileType = file.type;
            switch (rest.accept) {
                case 'image/*':
                    if (!['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'].includes(fileExtension)) {
                        alert('이미지 파일만 업로드 가능합니다.');
                        event.target.value = ''; // Clear the input
                        return;
                    }
                    break;
                case 'application/pdf':
                    if (fileExtension !== 'pdf') {
                        alert('PDF 파일만 업로드 가능합니다.');
                        event.target.value = ''; // Clear the input
                        return;
                    }
                    break;
                case 'video/*':
                    if (!['mp4', 'avi', 'mkv', 'mov', 'webm'].includes(fileExtension)) {
                        alert('비디오 파일만 업로드 가능합니다.');
                        event.target.value = ''; // Clear the input
                        return;
                    }
                    break;
                case 'image/*,application/pdf,video/*':
                    if (![
                        'jpg',
                        'jpeg',
                        'png',
                        'gif',
                        'bmp',
                        'webp',
                        'pdf',
                        'mp4',
                        'avi',
                        'mkv',
                        'mov',
                        'webm',
                    ].includes(fileExtension)) {
                        alert('이미지, 비디오 또는 PDF 파일만 업로드 가능합니다.');
                        event.target.value = ''; // Clear the input
                        return;
                    }
                    break;
                case 'excel':
                    if (!['xlsx', 'xls', 'csv'].includes(fileExtension)) {
                        alert('엑셀 파일만 업로드 가능합니다.');
                        event.target.value = ''; // Clear the input
                        return;
                    }
                    break;
                default:
                    if (![
                        'jpg',
                        'jpeg',
                        'png',
                        'gif',
                        'bmp',
                        'webp',
                        'pdf',
                        'xlsx',
                        'xls',
                        'csv',
                        'ppt',
                        'pptx',
                        'doc',
                        'docx',
                        'txt',
                    ].includes(fileExtension) &&
                        ![
                            'application/vnd.ms-excel',
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.ms-powerpoint',
                            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                            'application/msword',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                            'application/pdf',
                            'text/plain',
                        ].includes(fileType)) {
                        alert('허용된 파일 형식만 업로드 가능합니다.');
                        event.target.value = ''; // Clear the input
                        return;
                    }
            }
        }
        if (onChange) {
            onChange(event);
        }
    };
    return (React.createElement("label", { className: className, htmlFor: id, style: { cursor: 'pointer' } },
        React.createElement("input", { id: id, type: "file", style: { display: 'none' }, ref: ref, onChange: handleChange, ...rest }),
        children));
});
