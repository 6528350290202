import { thunk } from 'redux-thunk';
import { MiddlewareRegistry, ReducerRegistry, StateListenerRegistry } from '../regist';
import { compose, legacy_createStore } from 'redux';
const createAppStore = () => {
    // Create combined reducer from all reducers in ReducerRegistry.
    const reducer = ReducerRegistry.combineReducers();
    const middleware = MiddlewareRegistry.applyMiddleware(thunk);
    const composeEnhancers = compose;
    const store = legacy_createStore(reducer, composeEnhancers(middleware));
    StateListenerRegistry.subscribe(store);
    if (typeof APP !== 'undefined') {
        APP.store = store;
    }
    return store;
};
export default createAppStore;
