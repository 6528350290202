import React from 'react';
import styled, { css } from 'styled-components';
import { Colors } from '../../../Colors';
import { Button, Icon } from '../../../common';
import { useGlobalState } from '../../../GlobalStateProvider';
const InfoTitleStyled = styled.div `
	&.test-info-title,
	&.test-info-title * {
		box-sizing: border-box;
	}
	&.test-info-title {
		background: var(--color-silver, #f3f4f8);
		border-radius: 12px;
		padding: 10px 12px 10px 24px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		min-width: ${props => (props.$isMobile ? 'unset' : '560px')};
		position: relative;
	}
	.title-container {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.text-1 {
		text-align: left;
		vertical-align: top;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);

		font-weight: var(--h4-font-weight, 700);
		color: var(--color-black, #101010);
	}

	.btn-container {
		padding: 0;
		gap: 12px;
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.text-1 {
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 32px);
	}
	margin-bottom: 12px;
`;
const tabletStyles = css `
	.text-1 {
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 32px);
	}
`;
const desktopStyles = css `
	.text-1 {
		font-size: var(--h4-font-size, 20px);
		line-height: var(--h4-line-height, 32px);
	}
`;
export const DetailTitleInfo = React.memo(({ name, title, previousPage, children, }) => {
    const { isMobile, isTablet } = useGlobalState();
    return (React.createElement(InfoTitleStyled, { "$isMobile": isMobile, "$isTablet": isTablet, className: "test-info-title" },
        React.createElement("div", { className: "title-container" },
            React.createElement("p", { className: "text-1" }, title),
            React.createElement(Icon, { className: "arrow-right", icon: "arrow", size: 22, fill: Colors.w_grey }),
            React.createElement("p", { className: "text-1" }, name),
            React.createElement(Button, { option: {
                    buttonBeforeIcon: {
                        show: true,
                        name: 'detail-delete',
                        size: 22,
                        color: Colors.w_grey,
                    },
                }, onClick: previousPage })),
        children));
});
