import React, { useEffect, useMemo, useState } from 'react';
import { Icon } from '../element';
import styled from 'styled-components';
const ImageTagStyled = styled.div `
	&.no-img {
		background: var(--color-silver, #f3f4f8);
		border-radius: 4px;
		flex-shrink: 0;
		width: 100px;
		height: 100px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
const getFileExtension = fileName => {
    const parts = fileName.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : '';
};
const ImageTag = React.memo(({ file }) => {
    const [hasError, setHasError] = useState(false);
    const handleError = () => {
        setHasError(true);
    };
    useEffect(() => {
        setHasError(false);
    }, [file]);
    const ext = useMemo(() => getFileExtension(file)?.toLowerCase(), [file]);
    return !hasError && ['jpg', 'jpeg', 'png', 'gif'].includes(ext) ? (React.createElement("img", { className: "img", src: file, alt: "", onError: handleError })) : (React.createElement(ImageTagStyled, { className: "no-img" },
        React.createElement(Icon, { icon: `no_${['pdf', 'zip'].includes(ext) ? getFileExtension(file) : 'image'}`, width: 50, height: 58 })));
});
export default ImageTag;
