import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Button } from '../../../common';
import { postAttachment, postFeedback, useAnswerList, useAssignmentList, useFeedbackList, useGradesList, useQuestionList, useSurveyFrequency } from '../../../server';
import { TOGGLE_POPUP } from '../../base/popup';
import { ErrataTable, NameTagContainer } from './component/DSTL001_View_Child';
import { useGlobalState } from '../../../GlobalStateProvider';
import { useMutation } from 'react-query';
import { SurveyLayout } from '../../base/layout/Survey';
import { SurveyResultLayout } from '../../base/layout/Survey/SurveyResultLayout';
import { AttachFileAnswer } from '../../DSS/DSSL001/DSSL001_Detail_Child/AttachFileAnswer';
const DSTL001ViewStyled = styled.div `
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: ${props => (props.$isMobile ? 'hidden' : props.$isDetail ? 'unset' : 'auto')};
	gap: ${props => (props.$isMobile ? '24px' : 'unset')};

	&.hidetitle {
		height: 100%;
		flex: 1;
	}

	input:disabled {
		background: var(--color-white, #ffffff);
	}

	.field-short {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
		padding: 0px 0px 20px 0px;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.errataTable_wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		height: 100%;

		.score {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 0px 0px;

			padding: 20px 0px 20px 0px;
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			${props => props.$isMobile
    ? css `
							.label {
								color: var(--color-grey-d, #4d4d4d);
								text-align: right;
								font-family: 'NotoSansKr-Bold', sans-serif;
								font-size: 16px;
								line-height: 150%;
								letter-spacing: -0.02em;
								font-weight: 700;
								position: relative;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
							.value {
								color: #f46790;
								text-align: right;
								font-family: 'NotoSansKr-Bold', sans-serif;
								font-size: 20px;
								line-height: 150%;
								letter-spacing: -0.02em;
								font-weight: 700;
								position: relative;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
					  `
    : css `
							height: 100px;

							.label {
								color: var(--color-grey-d, #4d4d4d);
								text-align: right;
								font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
								font-size: var(--h3-font-size, 24px);
								line-height: var(--h3-line-height, 36px);
								font-weight: var(--h3-font-weight, 700);
								position: relative;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
							.value {
								color: #f46790;
								text-align: right;
								font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
								font-size: var(--h3-font-size, 24px);
								line-height: var(--h3-line-height, 36px);
								font-weight: var(--h3-font-weight, 700);
								position: relative;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
					  `}

			.fixed {
				color: #f46790;
				text-align: right;
				font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
				font-size: var(--h3-font-size, 24px);
				line-height: var(--h3-line-height, 36px);
				font-weight: var(--h3-font-weight, 700);
				position: relative;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}

	&.hidetitle {
		.errataTable_wrap {
			margin-top: 12px;
		}
	}

	.btn_default {
		margin-top: 36px;
	}

	.no_submit_wrap {
		width: 100%;
		justify-content: center;
		align-items: center;
		display: flex;
		color: var(--color-grey-l, #83868a);
		text-align: left;
		font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b3-font-size, 16px);
		line-height: var(--b3-line-height, 32px);
		font-weight: var(--b3-font-weight, 400);
		position: relative;
		height: ${props => (props.$isTablet ? '100%' : undefined)};
	}

	.name-tag-info {
		gap: 12px;
		display: flex;
		flex-direction: column;
	}

	.att_submit_view {
		display: flex;
		flex-direction: column;
		gap: 12px;
		flex: 1;

	}

	.feedback-submit-button {
		display: flex;
		justify-content: flex-end;
	}

	.feedback-file {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.submit-false {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.survey_option_detail {
        display: none;

        .radio_wrap {
            label {
                >span {
                    margin-left: 6px;
                    font-size: 12px;
                    line-height: 1.41;
                    letter-spacing: -.01em;
                }
            }

            .mark {
                background: var(--color-grey-c);
                border-color: var(--color-grey-c);
            }
        }

        .chk_wrap {
            label {
                >span {
                    margin-left: 6px;
                    font-size: 12px;
                    line-height: 1.41;
                    letter-spacing: -.01em;
                }
            }
        }
    }

    .survey_option_detail.active {
        display: block;
    }

    .survey_option_detail[survey-option="1"].active {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 5px 20px;
    }

    .survey_option_detail[survey-option="4"].active {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 5px 20px;
    }

	.survey-submit-view {
		height: calc(100% - 70px);
    	overflow: auto;
		gap: 12px;
		display: grid;
		padding: 10px;
	}

	.survey-table {
    	width: 100%;
    	border-collapse: collapse;
  	}

	.survey-table th, .survey-table td {
		font-weight: 100;
    	padding: 10px;
		border-style: solid;
    	border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
  	}

	.survey-data-header {
		background: var(--color-silver, #f3f4f8);
    	border-style: solid;
    	border-color: var(--color-grey-w, #b6bac0);
    	border-width: 1px 0px 0px 0px;
    	position: relative;
    	width: 100%;	
	}

	.survey-data-header th {
 	 	background: var(--color-silver, #f3f4f8);
    	border-style: solid;
    	border-color: var(--color-grey-w, #b6bac0);
    	border-width: 1px 0px 0px 0px;
	}

	.count-column {
    	width: 50px;
    	text-align: center;
  	}

	.survey-result {
		padding: 20px 20px 20px 20px;
		border-radius: 0px 0px 8px 8px;
		border-style: solid;
    	border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 1.5px 1.5px 1.5px;
	}


`;
const count = 20;
export const DSTL001_Submit_View = React.memo(({ hideTitle = false, test_id, member, subjectId = '', isTeacher, isDetail = false, }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = useGlobalState();
    const [testInfo, setTestInfo] = useState();
    const [score, setScore] = useState(0); // 총점 조회
    const [questionId, setQuestionId] = useState('');
    const [isOMR, setIsOMR] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submittedFileList, setSubmittedFileList] = useState([]);
    const [attachFileList, setAttachFileList] = useState([]);
    const [feedbackFileList, setFeedbackFileList] = useState([]);
    const [feedbackComplete, setFeedbackComplete] = useState(false);
    const [surveyQuestions, setSurveyQuestions] = useState([]);
    const [surveyData, setSurveyData] = useState([]);
    const [memberIndex, setMemberIndex] = useState('');
    const postFeedbackMutation = useMutation(postFeedback);
    const { member_id, member_name } = useMemo(() => {
        return { member_id: member?.id || '', member_name: member?.name };
    }, [member]);
    // 과제 정보 조회
    const { data, refetch } = useAssignmentList({ id: test_id });
    // 성적 정보 조회
    const { data: gradesData, refetch: gradesRefetch } = useGradesList({
        test_id,
        member_id,
        ...(member_id !== '' ? { subject_item: subjectId } : {}),
        orderby: ["IF(subject_item='공통',0,1)", 'subject_item'],
    });
    const { data: fileData, refetch: fileRefetch } = useAnswerList({
        question_id: questionId,
        from: member_id,
        orderby: "value",
    });
    const { data: surveyAnswerData, refetch: surveyAnswerRefetch } = useAnswerList({
        test_id: test_id,
        from: member_id,
        orderby: "question_id",
    });
    const { data: questionData, refetch: questionRefetch } = useQuestionList({
        test_id,
        orderby: "number",
    });
    const { data: feedbackData, refetch: feedbackRefetch } = useFeedbackList({
        test_id,
        to: member_id,
    });
    const { data: frequencyData, refetch: frequencyRefetch } = useSurveyFrequency({
        test_id
    });
    const refetchList = throttle(() => {
        if (!test_id)
            return;
        refetch();
    }, 1000, { leading: true, trailing: false });
    const refetchGradesList = throttle(() => {
        if (!test_id)
            return;
        gradesRefetch();
    }, 1000, { leading: true, trailing: false });
    const refetchFileList = throttle(() => {
        if (!questionId)
            return;
        fileRefetch();
    }, 1000, { leading: true, trailing: false });
    const refetchQuestionList = throttle(() => {
        if (!test_id)
            return;
        questionRefetch();
    }, 1000, { leading: true, trailing: false });
    const refetchFeedbackList = throttle(() => {
        if (!test_id)
            return;
        feedbackRefetch();
    }, 1000, { leading: true, trailing: false });
    const refetchSurveyAnswer = throttle(() => {
        if (!test_id)
            return;
        surveyAnswerRefetch();
    }, 1000, { leading: true, trailing: false });
    const refetchFrequency = throttle(() => {
        if (!test_id)
            return;
        frequencyRefetch();
    }, 1000, { leading: true, trailing: false });
    useEffect(() => {
        if (!hideTitle)
            refetchList();
    }, [hideTitle, test_id, member]);
    useEffect(() => {
        refetchGradesList();
        refetchQuestionList();
        refetchFeedbackList();
        refetchSurveyAnswer();
        refetchFrequency();
    }, [test_id, member_id]);
    // useEffect(() => {
    // if (!member) return;
    // console.log("member_id", member_id);
    // console.log("surveyAnswerData", surveyAnswerData);
    // if (member_id) {
    // 	setAttachFileList([]);
    // }
    // }, [member_id, surveyAnswerData]);
    // useEffect(() => {
    // 	if (!frequencyData) return;
    // 	const isEmpty = Object.keys(frequencyData).length === 0;
    // 	console.log("frequencyData", frequencyData);
    // 	console.log("frequencyData.length", frequencyData.length);
    // 	console.log("frequencyData[0]", frequencyData[0]);
    // 	console.log("frequencyData null?", frequencyData[0] == null);
    // 	console.log("isEmpty", isEmpty);
    // }, [frequencyData]);
    useEffect(() => {
        if (!data)
            return;
        const { total_count, items } = data;
        if (total_count >= 1) {
            setTestInfo(items[0]);
        }
        if (items[0].type === 'OMR (기본형)' || items[0].type === 'OMR (자유형)') {
            setIsOMR(true);
        }
    }, [data]);
    useEffect(() => {
        if (!feedbackData) {
            setFeedbackComplete(false);
            setFeedbackFileList([]);
            return;
        }
        const { total_count, items } = feedbackData;
        if (total_count == 0) {
            setFeedbackComplete(false);
            setFeedbackFileList([]);
        }
        if (total_count >= 1) {
            setFeedbackComplete(true);
            setFeedbackFileList(items.map(item => item.value));
        }
    }, [feedbackData, member]);
    useEffect(() => {
        if (!isOMR)
            return;
        if (!gradesData)
            return;
        const { total_count, items } = gradesData;
        if (total_count >= 1) {
            const score = !member_id
                ? items.reduce((acc, cur) => {
                    if (cur.subject_item !== '') {
                        return acc + cur.score;
                    }
                    return acc;
                }, 0)
                : items[0].score;
            setScore(score);
        }
        else {
            setScore(0);
        }
    }, [gradesData]);
    useEffect(() => {
        if (isOMR)
            return;
        if (!questionData)
            return;
        const { total_count, items } = questionData;
        if (total_count >= 1) {
            setQuestionId(items[0].id);
        }
    }, [questionData]);
    useEffect(() => {
        if (!questionId || !member)
            return;
        refetchFileList();
    }, [questionId, member]);
    useEffect(() => {
        if (!test_id || !member)
            return;
        refetchSurveyAnswer();
    }, [test_id, member]);
    useEffect(() => {
        if (!fileData || !member) {
            return;
        }
        const { total_count, items } = fileData;
        if (total_count == 0) {
            setSubmitted(false);
            setSubmittedFileList([]);
        }
        if (total_count >= 1) {
            setSubmitted(true);
            setSubmittedFileList(items.map(item => item.value));
        }
    }, [fileData, member]);
    useEffect(() => {
        if (!questionData || !testInfo)
            return;
        if (questionData && testInfo.type === '설문형') {
            // console.log("data1", questionData);
            const { items } = questionData;
            const { survey_questions } = data.items[0];
            // console.log("data2", survey_questions);
            const combinedArray = questionData.items.map(i => {
                const question = survey_questions[i.id];
                // console.log("question", question);
                return {
                    id: i.id,
                    type: i.type,
                    title: question ? question.title : null,
                    data: i.type == 'choice' || i.type == 'checkbox'
                        ? question.options.map((option, optionIndex) => ({
                            id: `${i.id}-${optionIndex}`,
                            title: option,
                            checked: false
                        }))
                        : '',
                };
            });
            // Sort the final array by the 'number' field
            combinedArray.sort((a, b) => a.number - b.number);
            // console.log("combinedArray", combinedArray);
            setSurveyQuestions(combinedArray);
        }
    }, [questionData, testInfo]);
    useEffect(() => {
        if (!surveyQuestions)
            return;
        if (surveyAnswerData) {
            if (surveyAnswerData.total_count === 0) {
                setSurveyData([]);
                setSubmitted(false);
                return;
            }
            const { items } = surveyAnswerData;
            const newSurveyData = surveyQuestions.map(survey => {
                const answers = items.filter((a) => a.question_id === survey.id); // Filter answers for the current survey question
                if (answers.length > 0) {
                    if (survey.type === 'choice') {
                        const newSurvey = { ...survey };
                        newSurvey.data = survey.data.map(option => {
                            const selected = answers.some(answer => answer.value.includes(option.title));
                            return { ...option, checked: selected };
                        });
                        return newSurvey;
                    }
                    else if (survey.type === 'checkbox') {
                        const newSurvey = { ...survey };
                        // For checkboxes, multiple answers can be checked
                        newSurvey.data = survey.data.map(option => {
                            const selected = answers.some(answer => answer.value === option.title);
                            return { ...option, checked: selected };
                        });
                        return newSurvey;
                    }
                    else {
                        // For other types, we just assign the single answer value
                        return { ...survey, data: answers[0].value };
                    }
                }
                return survey; // If no answer is found, return the survey unchanged
            });
            // console.log("newSurveyData",newSurveyData);
            setSurveyData(newSurveyData);
            setSubmitted(true);
            setMemberIndex(member_id);
        }
    }, [surveyQuestions, surveyAnswerData]);
    // useEffect(() => {
    // 	if (!surveyData) return;
    // 	console.log("surveyData1", surveyData);
    // }, [surveyData]);
    // useEffect(() => {
    // 	console.log("memberIndex", memberIndex);
    // }, [memberIndex]);
    const hidePopup = () => {
        APP.eventManager.publish(TOGGLE_POPUP);
    };
    const test_type = useMemo(() => testInfo
        ? testInfo.type +
            `${testInfo.type === 'OMR' && testInfo.subject !== ''
                ? ' - ' + t(`assignment.${testInfo.subject}`)
                : ''}`
        : '', [testInfo]);
    const onSubmit = async () => {
        try {
            let values = [];
            if (attachFileList.length === 0) {
                alert('답변 파일을 업로드해주세요.');
                return;
            }
            for (let i = 0; i < attachFileList.length; i++) {
                const file = attachFileList[i];
                const filepath = `${questionId}/feedback/${i + 1}`;
                const value = `/api/mimac/file/${filepath}/${file.name}`;
                await postAttachment(file, filepath);
                values.push(value);
            }
            await postFeedbackMutation.mutateAsync({
                id: test_id,
                props: {
                    feedbacks: [{
                            question_id: questionId,
                            values: values,
                        }],
                    member_id: member_id
                },
            });
            alert('피드백이 전송되었습니다.');
            APP.eventManager.publish(TOGGLE_POPUP);
        }
        catch (error) {
            alert(error);
        }
    };
    const renderSubmitButton = useMemo(() => {
        if (test_type === '파일첨부형' && !feedbackComplete && submitted) {
            return (React.createElement("div", { className: 'feedback-submit-button' },
                React.createElement(Button, { className: "btn_default xmd", text: "common.submit", onClick: onSubmit })));
        }
    }, [test_type, feedbackComplete, submitted, onSubmit]);
    const renderEmptySurvey = () => {
        return (React.createElement("div", { className: 'submit-false' },
            React.createElement("p", null, "\uC81C\uCD9C\uB41C \uC124\uBB38\uC774 \uC5C6\uC2B5\uB2C8\uB2E4.")));
    };
    if (!testInfo)
        return (React.createElement(DSTL001ViewStyled, { "$isDetail": isDetail, "$isTablet": isTablet, "$isMobile": isMobile }, t('assignment.noAssignment')));
    return (React.createElement(DSTL001ViewStyled, { "$isDetail": isDetail, "$isTablet": isTablet, "$isMobile": isMobile, className: `${hideTitle ? 'hidetitle' : ''}` },
        hideTitle ? (React.createElement("div", { className: "score-title" }, hideTitle && !member_id
            ? t('assignment.scoreStatus2')
            : t('assignment.scoreStatus', { score, member_name }))) : (React.createElement(NameTagContainer, { test_type: test_type, member_name: member_name })),
        isOMR && (hideTitle && !member_id ? (React.createElement("div", { className: "no_submit_wrap" }, t('assignment.noSubmit'))) : (React.createElement("div", { className: "errataTable_wrap" },
            !hideTitle && (React.createElement("div", { className: "score" },
                React.createElement("div", { className: "label" }, t('assignment.allScore')),
                React.createElement("div", { className: "value" }, t('assignment.scoreValue', { score })))),
            React.createElement(ErrataTable, { subject_name: testInfo.subject, isDetail: !!member_id, test_id: test_id, member_id: member_id, isTeacher: isTeacher }),
            !hideTitle && !isMobile && !isTeacher && (React.createElement(Button, { className: "btn_default xmd", text: "common.complete", onClick: hidePopup }))))),
        test_type === '파일첨부형' &&
            (submitted ? (React.createElement("div", { className: 'att_submit_view' },
                React.createElement("div", { className: 'feedback-file' },
                    React.createElement(AttachFileAnswer, { isTeacher: isTeacher, showRegist: false, isRegist: false, attachFileList: submittedFileList }))))
                :
                    React.createElement("div", { className: 'submit-false' },
                        React.createElement("p", null, "\uC81C\uCD9C\uB41C \uD30C\uC77C\uC774 \uC5C6\uC2B5\uB2C8\uB2E4."))),
        test_type === '설문형' &&
            (submitted ? (React.createElement("div", { className: 'survey-submit-view' },
                React.createElement(SurveyLayout, { key: memberIndex, surveyData: surveyData, isSubmit: true, isRegist: false }))) : (member_id ? (renderEmptySurvey()) : ((Object.keys(frequencyData).length === 0 ?
                renderEmptySurvey()
                :
                    React.createElement("div", { className: 'survey-submit-view' },
                        React.createElement(SurveyResultLayout, { surveyData: surveyQuestions, isSubmit: true, isRegist: false, frequencies: frequencyData }))))))));
});
