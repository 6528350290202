import { downloadContent } from '../../../../server';
export class DownloadQueue {
    queue;
    isProcessing;
    constructor() {
        this.queue = [];
        this.isProcessing = false;
    }
    enqueue(item) {
        this.queue.push(item);
        this.processQueue();
    }
    async processQueue() {
        if (this.isProcessing || this.queue.length === 0)
            return;
        this.isProcessing = true;
        const item = this.queue.shift();
        try {
            APP.eventManager.publish('SHOW_LOADING', true);
            await downloadContent(item.id);
        }
        catch (error) {
            console.error('Download failed:', error);
        }
        finally {
            this.isProcessing = false;
            this.processQueue(); // Process the next item in the queue
            APP.eventManager.publish('SHOW_LOADING', false);
        }
    }
}
