import React, { useRef } from 'react';
import styled from 'styled-components';
import { Colors } from '../../../../../Colors';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { Button } from '../../../../../common';
import { useReplyList } from '../../../../../server';
import { RegistFileTable } from '../DSTT007_Detail_Child';
import { Player } from './Player';
const StreamingContainerStyled = styled.div `
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	justify-content: flex-start;
	align-items: flex-start;

	.top_menubar {
		width: 100%;
		flex-wrap: wrap;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px 20px;

		.button {
			gap: 20px;
			color: #fff;
		}

		.right_txt {
			display: flex;
			gap: 10px;
			font-size: 20px;

			.time {
				color: ${Colors.white};
			}
		}
	}

	.middle_list {
		width: 100%;
		height: 70%;
		position: absolute;
		top: 50px;
		z-index: 5;
		background-color: ${Colors.white};
	}
	.fullscreen {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.video-wrap {
		flex: 1;
		position: relative;
		width: 100%;
		background-size: object-fit;
		background-repeat: no-repeat;
		display: flex;
		flex-direction: column;
		background-color: ${Colors.black};
		height: 100%;
		overflow: hidden;
		justify-content: center;
		align-items: flex-end;

		${props => props.$isMobile && ` justify-content: center; align-items: center;`}

		video {
			object-fit: contain;
			background-size: contain;
			background-repeat: no-repeat;
			width: 100%;
			position: absolute;
			z-index: 2;
			height: 100%;
		}

		video::after {
			content: attr(data-watermark);
			position: absolute;
			top: 10px;
			left: 10px;
			background: rgba(0, 0, 0, 0.5);
			color: white;
			padding: 4px 8px;
			font-size: 14px;
			border-radius: 4px;
			pointer-events: none;
			user-select: none;
			z-index: 2; /* ensure watermark is on top */
		}
	}

	.loading {
		position: absolute;
		font-size: 20px;
		color: ${Colors.white};

		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
	}

	.progress-bar-container {
		width: 100%;
		height: 10px; // 5px에서 10px로 증가
		background-color: rgba(255, 255, 255, 0.3);
		border-radius: 5px; // 2.5px에서 5px로 증가
		cursor: pointer;
		position: relative;
	}

	.full-progress-bar {
		height: 100%;
		background-color: #8bacff;
		border-radius: 5px; // 2.5px에서 5px로 증가
		margin: 0;
	}

	.progress-bar {
		width: 20px; // 12px에서 20px로 증가
		height: 20px; // 12px에서 20px로 증가
		background-color: #8bacff;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		cursor: pointer;
	}

	.custom-controls {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 4;
	}

	.controls-container {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
		flex-direction: column;
		padding: 10px;
		transition: opacity 0.3s ease-in-out;
	}

	.controls-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-top: 10px;
		> * {
			margin: 0;
		}
	}

	.left_wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 20px; // 16px에서 20px로 증가

		.button {
			background: none;
			border: none;
			color: white;
			cursor: pointer;
			transition: transform 0.1s ease-in-out;
			outline: none;
			display: flex;
			align-items: center;

			&:hover {
				transform: scale(1.15); // 1.1에서 1.15로 증가
			}

			&:active {
				transform: scale(0.85); // 0.9에서 0.85로 감소
			}
		}
	}

	canvas {
		z-index: 3;
	}

	.quality-selector {
		z-index: 5;
		select {
			background: rgba(0, 0, 0, 0.5);
			color: white;
			border: 1px solid rgba(255, 255, 255, 0.5);
			border-radius: 4px;
			padding: 6px 10px;
			font-size: 14px;
			margin-right: 15px;
			cursor: pointer;
			transition: all 0.3s ease;

			&:hover,
			&:focus {
				background: rgba(0, 0, 0, 0.7);
				outline: none;
			}

			option {
				background: #222;
			}
		}
	}

	.time-display {
		color: white;
		font-size: 15px; // 14px에서 16px로 증가
		line-height: 25px; // 23px에서 25px로 증가
		width: max-content;
		display: flex;
		align-items: center;
	}

	.volume-control {
		display: flex;
		align-items: center;

		&:hover & {
			min-width: 70px; // 60px에서 70px로 증가
			width: 140px; // 120px에서 140px로 증가
			opacity: 1;
			margin-left: 15px; // 12px에서 15px로 증가
		}
	}

	.volume-slider {
		width: 0;
		opacity: 0;
		transition: width 0.3s ease-in-out, opacity 0.3s ease-in-out;

		.volume-control:hover & {
			min-width: 70px; // 60px에서 70px로 증가
			width: 140px; // 120px에서 140px로 증가
			opacity: 1;
			margin-left: 15px; // 12px에서 15px로 증가
		}

		-webkit-appearance: none;
		background: transparent;
		cursor: pointer;

		&::-webkit-slider-runnable-track {
			width: 100%;
			height: 6px; // 4px에서 6px로 증가
			cursor: pointer;
			background: #fff;
			border-radius: 3px; // 2px에서 3px로 증가
		}

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			width: 18px; // 12px에서 18px로 증가
			height: 18px; // 12px에서 18px로 증가
			border-radius: 50%;
			background: #fff;
			cursor: pointer;
			margin-top: -6px; // -4px에서 -6px로 조정
		}

		&:focus {
			outline: none;
		}
	}

	.screen-full-btn {
		color: rgb(255, 255, 255) !important;
		background: transparent !important;
		border: none !important;
		font-size: 16px !important;
		cursor: pointer;
		margin-right: 5px;
	}
`;
export const StreamingContainer = React.memo(() => {
    const { isMobile, isDesktop } = useGlobalState();
    const [streaming_id, setStreaming_id] = React.useState();
    const [item, setItem] = React.useState();
    const videoRef = useRef();
    const [show, setShow] = React.useState(false);
    const [preview, setPreview] = React.useState();
    const [playData, setPlayData] = React.useState();
    const { data, refetch } = useReplyList({
        id: streaming_id,
    });
    React.useEffect(() => {
        const uuid = location.pathname.split('/').pop();
        if (uuid) {
            setStreaming_id(uuid);
        }
    }, []);
    React.useEffect(() => {
        if (!streaming_id)
            return;
        refetch();
    }, [streaming_id]);
    React.useEffect(() => {
        if (data) {
            const { items } = data;
            if (items && items.length > 0) {
                setItem(items[0]);
                return;
            }
        }
        setItem(undefined);
    }, [data]);
    const medias = React.useMemo(() => item?.medias, [item?.medias]);
    return (React.createElement(StreamingContainerStyled
    // ref={windowRef}
    , { "$isMobile": isMobile, "$isDesktop": isDesktop, id: "streaming-container" },
        React.createElement("div", { className: "top_menubar", id: "top_menubar", style: { background: show ? Colors.white : Colors.w_grey } },
            React.createElement(Button, { option: {
                    buttonBeforeIcon: {
                        className: show ? 'arrow-up' : 'arrow-down',
                        name: 'arrow',
                        size: 30,
                        color: !show ? Colors.white : Colors.w_grey,
                        show: true,
                    },
                }, text: preview ? preview.name : '파일 목록 보기', onClick: () => setShow(!show) }),
            React.createElement("div", { className: "right_txt" },
                React.createElement("p", { className: "time" }, playData),
                React.createElement("p", { onClick: () => APP.eventManager.publish('STOP_STREAMING'), style: { color: 'red', cursor: 'pointer' } }, "\uC885\uB8CC")),
            React.createElement("div", { className: "middle_list", style: { display: `${show ? 'block' : 'none'}` } },
                React.createElement(RegistFileTable, { onlyShowList: true, isRegist: false, medias: medias, previewId: preview?.id, setPreviewId: setPreview }))),
        React.createElement("div", { className: "video-wrap", ref: videoRef }, preview?.id && React.createElement(Player, { id: item.id, previewId: preview?.id }))));
});
