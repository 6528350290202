import React, { useMemo } from 'react';
import { useGlobalState } from '../../GlobalStateProvider';
import { MenuBar } from '../../common';
import { MyPageDropDown } from '../base/account';
import { DEFAULT_MENU_S_LIST, DEFAULT_MENU_T_LIST } from './constant';
/**
 * 네비게이션 바 컨테이너
 * @param menu 메뉴
 * @param setMenu 메뉴 변경 함수
 *
 * @returns 네비게이션 바 컨테이너
 *
 * @example
 * 로고, 메뉴,  마이페이지 버튼이 있는 네비게이션 바 컨테이너
 */
export const NavbarContainter = React.memo(({ isFooter = false, isTeacher, menu, setMenu, }) => {
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const list = useMemo(() => {
        return [
            ...(isTeacher ? DEFAULT_MENU_T_LIST : DEFAULT_MENU_S_LIST),
            ...(!isDesktop ? ['logout'] : []),
        ];
    }, [isTeacher, isMobile, isDesktop, isTablet]);
    return (React.createElement(React.Fragment, null,
        ((!isFooter && isDesktop) || (isFooter && (isMobile || isTablet))) && (React.createElement(MenuBar, { className: `menu ${isTablet ? 'tablet' : ''}`, isRow: true, isClickDisabled: false, list: list, value: menu, onItemClick: setMenu, option: {
                titleIcon: {
                    show: isMobile || isTablet ? true : false,
                    size: 24,
                },
            } })),
        !isFooter && React.createElement(MyPageDropDown, null)));
});
