import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
// 스피너 애니메이션 정의
const spin = keyframes `
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
// 로딩 오버레이 스타일 정의
const LoadingOverlay = styled.div `
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;

	.background {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #000;
		opacity: 0.5;
	}
`;
// 스피너 스타일 정의
const Spinner = styled.div `
	border: 30px solid ${props => `var(--color-tur, #3AB2BF)`};
	border-top: 30px solid ${props => `var(--color-dark_tur, #479DA6)`};
	border-radius: 50%;
	width: 120px;
	height: 120px;
	animation: ${spin} 2s linear infinite;
	position: absolute;
	z-index: 4;
`;
const LoadingPage = ({ defaultLoading = false }) => {
    const [isShow, setIsShow] = React.useState(true);
    const handlerLoading = (isLoading) => {
        setIsShow(isLoading);
    };
    useEffect(() => {
        setIsShow(defaultLoading);
    }, [defaultLoading]);
    useEffect(() => {
        APP.eventManager.subscribe('SHOW_LOADING', handlerLoading);
        return () => {
            APP.eventManager.unsubscribe('SHOW_LOADING', handlerLoading);
        };
    }, []);
    return (React.createElement(React.Fragment, null, isShow && (React.createElement(LoadingOverlay, null,
        React.createElement("div", { className: "background" }),
        React.createElement(Spinner, null)))));
};
export default LoadingPage;
