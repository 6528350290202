import { toState } from '../../../redux/regist';
import { deleteCookie } from '../account';
import { DeletePopUpLayout } from '../layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../popup';
export const getIsLoad = (statful) => {
    const state = toState(statful);
    return state['base/app'].isLoad;
};
export function closeWindow(isDuplicate = false) {
    //const navigate = useNavigate();
    try {
        // localStorage와 쿠키 삭제
        localStorage.clear();
        deleteCookie('refresh_token');
        // isDuplicate가 true일 경우 사용자 확인
        if (!isDuplicate) {
            self.close();
            window.close();
        }
        else {
            const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
            const onDelete = () => {
                if (window.opener) {
                    self.close();
                    window.close();
                }
                else {
                    closePopup();
                }
                // error 페이지로 이동
                // @ts-ignore
                location.href = '/error';
                // APP.eventManager.publish('updateMenu', 'error');
            };
            APP.eventManager.publish(TOGGLE_POPUP, {
                component: DeletePopUpLayout,
                componentProps: {
                    deleteContent: '중복 로그인을 지원하지 않습니다. \n사용 중인 계정을 로그아웃 합니다. 다시 접속해 주세요',
                    showCancel: false,
                    onDelete: onDelete,
                    isQnA: true,
                },
                width: 639,
                title: '중복 로그인 알림',
                isFit: true,
                isClose: false,
            });
        }
    }
    catch (e) {
        console.log('Failed to close the window:', e);
    }
}
