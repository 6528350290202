import styled, { css } from 'styled-components';
export const LiveReservationStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 28px;
	width: 100%;
	padding-top: 28px;
	height: 100%;
	flex: 1;
	overflow: auto;

	.container-001 {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		padding: 0px 0px 28px 0px;
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.container-002 {
		display: flex;
		flex-direction: row;
		gap: 36px;
		position: relative;

		width: 100%;
	}

	.title {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 24px);
		font-weight: var(--h5-font-weight, 700);
		position: relative;
		width: 64px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.setting_box {
		background: var(--color-white, #ffffff);
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 10px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: auto;
		min-height: 50px;
		.btn-file {
			background: var(--color-silver, #f3f4f8);
			border-radius: 100px;
			padding: 4px 16px 4px 16px;
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			max-width: 120px;

			.name {
				color: var(--font-dgrey, #4d4d4d);
				text-align: left;
				font-family: var(--b5-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b5-font-size, 12px);
				line-height: var(--b5-line-height, 22px);
				letter-spacing: var(--b5-letter-spacing, -0.02em);
				font-weight: var(--b5-font-weight, 400);
			}
		}
	}

	.test-setting-list {
		padding-top: 0px;
		padding-bottom: 14px;
		min-width: ${props => (props.$isMobile ? 'unset' : '320px')};
		flex: 1;
	}

	.test-data-add {
		padding-top: 0px;
	}

	.setting-list {
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		flex: 1;
	}

	${props => (props.$isMobile ? mobileSytles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileSytles = css `
	.container-002 {
		flex: 1;
	}
`;
const tabletStyles = css `
	.container-002 {
		flex: 1;
	}
`;
const desktopStyles = css ``;
