import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../Colors';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { Button, Table } from '../../../../common';
import { postEnquiriesList, postoutEnquiriesList, useEnquiriesList, } from '../../../../server';
import { goToConference } from '../../../DST/DSTT001/funtions';
import { getIsManagerAndUserId, getIsTeacher, getUserId } from '../../account';
import { TOGGLE_POPUP } from '../../popup';
import loadingFinal from './../../../../assest/loading-final.json';
import LottieAnimation from './LottieAnimation';
const LiveEnquiriesStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 20px;
	.title {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h4-font-size, 20px);
		line-height: var(--h4-line-height, 32px);
		letter-spacing: var(--h4-letter-spacing, -0.02em);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
		align-self: stretch;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.popup-list-009 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		height: unset;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}
		}

		.item {
			.body-item {
				overflow: hidden;
			}
		}
	}

	&.waiting {
		.container,
		.container * {
			box-sizing: border-box;
		}
		.container {
			padding: 20px 0px 20px 0px;
			display: flex;
			flex-direction: column;
			gap: 18px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			overflow: hidden;
		}
		.txt {
			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
			font-size: var(--h3-font-size, 24px);
			line-height: var(--h3-line-height, 36px);
			letter-spacing: var(--h3-letter-spacing, -0.02em);
			font-weight: var(--h3-font-weight, 700);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.loading-final-1-1 {
			flex-shrink: 0;
			width: 150px;
			height: 150px;
			position: relative;
			object-fit: cover;
		}
		.txt2 {
			text-align: center;
			font-family: var(--b2-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b2-font-size, 18px);
			line-height: var(--b2-line-height, 36px);
			letter-spacing: var(--b2-letter-spacing, -0.02em);
			font-weight: var(--b2-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.txt-2-span {
			color: var(--color-black, #101010);
			font-family: var(--b2-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b2-font-size, 18px);
			line-height: var(--b2-line-height, 36px);
			letter-spacing: var(--b2-letter-spacing, -0.02em);
			font-weight: var(--b2-font-weight, 400);
		}
		.txt-2-span2 {
			color: var(--color-pink, #f46790);
			font-family: var(--b2-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b2-font-size, 18px);
			line-height: var(--b2-line-height, 36px);
			letter-spacing: var(--b2-letter-spacing, -0.02em);
			font-weight: var(--b2-font-weight, 400);
		}
		.txt-2-span3 {
			color: var(--color-black, #101010);
			font-family: var(--b2-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b2-font-size, 18px);
			line-height: var(--b2-line-height, 36px);
			letter-spacing: var(--b2-letter-spacing, -0.02em);
			font-weight: var(--b2-font-weight, 400);
		}
	}

	.empty {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: space-around;
		width: 100%;
		height: 100%;

		.text {
			color: var(--color-grey, #666666);
			text-align: left;
			font-family: 'NotoSansKr-Regular', sans-serif;
			font-size: 18px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.popup-list-009 {
		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 10px 0px 10px 0px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 12px);
			line-height: 150%;
			letter-spacing: var(--b4-letter-spacing, -0.02em);
			font-weight: var(--b4-font-weight, 400);
			position: relative;

			&.underline {
				cursor: pointer;
				text-decoration: underline;
			}
		}

		.bold {
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 700;
		}

		.live-mobile-wrap {
			display: flex;
			width: 100%;

			.live-contents-wrap {
				width: 100%;
			}

			.live-status {
				min-width: 45px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.underline {
				cursor: pointer;
				text-decoration: underline;
				font-size: var(--b4-font-size, 14px);
				color: var(--bule, #3c5295);
			}
		}
	}
`;
const desktopStyles = css `
	.popup-list-009 {
		.item {
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			span {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);
				position: relative;

				&.underline {
					cursor: pointer;
					text-decoration: underline;
				}
			}
		}
	}
`;
export const LiveEnquiries = React.memo(({ liveRoom }) => {
    const { isMobile } = useGlobalState();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const manager_loacl_id = useSelector((state) => getIsManagerAndUserId(state));
    const localId = useSelector((state) => getUserId(state));
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState(0);
    const [waitingRoomId, setWaitingRoomId] = useState('');
    const [entering, setEntering] = useState(false);
    const registEnquiryMutation = useMutation(postEnquiriesList);
    const rejectedEnquiryMutation = useMutation(postoutEnquiriesList);
    const { data, refetch } = useEnquiriesList({
        conference_id: liveRoom.map(i => i.id),
        ...(manager_loacl_id ? { host: manager_loacl_id } : {}),
        status: 'held',
        with_more_info: true,
    });
    const refetchList = throttle(() => {
        refetch();
    }, 1000, { trailing: false, leading: true });
    useEffect(() => {
        if (liveRoom && liveRoom.length > 0) {
            refetchList();
            return;
        }
        setTestData([]);
        setTotalCount(0);
    }, [liveRoom]);
    const { headTitleList, tableReadDataKeys } = useMemo(() => {
        const headTitleList = [
            { title: 'seperate', space: 1, sort: false },
            { title: 'contentTitle', space: 3, sort: false },
            { title: 'charge', space: 1, sort: false },
            { title: 'waiting', space: 1, sort: false },
            { title: 'etc', space: 1, sort: false },
        ];
        const tableReadDataKeys = [
            { title: 'type', space: 1, sort: false },
            { title: 'title', space: 3, sort: false },
            { title: 'host_name', space: 1, sort: false },
            { title: 'waiting', space: 1, sort: false },
            { title: 'etc', space: 1, sort: false },
        ];
        return { headTitleList, tableReadDataKeys };
    }, []);
    useEffect(() => {
        APP.eventManager.subscribe('RERENDER_LIVE_ENQUIRIES', refetchList);
        return () => {
            APP.eventManager.unsubscribe('RERENDER_LIVE_ENQUIRIES', refetchList);
        };
    }, []);
    useEffect(() => {
        if (data) {
            const { total_count, items } = data;
            if (total_count > 0) {
                setTestData(items);
                setTotalCount(total_count);
                const waitingRoom = items.find(i => i.id === waitingRoomId);
                const isEnterMember = items.find(i => i?.members_id.includes(localId));
                if (!waitingRoom) {
                    if (isEnterMember)
                        return;
                    setWaitingRoomId(undefined);
                }
                if (items && items.length > 0) {
                    const waiting = items.find(i => i.id === waitingRoomId);
                    if (waiting && !waiting?.host_occupied) {
                        queueOutList(waitingRoomId, !entering);
                        setWaitingRoomId(undefined);
                    }
                }
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    const queueOutList = async (waitingRoomId, entering) => {
        if (entering)
            return;
        if (waitingRoomId) {
            try {
                await rejectedEnquiryMutation.mutateAsync(waitingRoomId);
            }
            catch (error) {
                alert(error);
            }
            finally {
                setWaitingRoomId(undefined);
            }
        }
    };
    const closePopup = () => {
        APP.eventManager.publish(TOGGLE_POPUP);
    };
    // 학생만 동작
    useEffect(() => {
        // 학생인 경우 -> 대기열 수락
        const acceptedUser = async (data) => {
            const { class_id, enquiry_id } = data;
            if (enquiry_id === waitingRoomId) {
                await goToConference(enquiry_id, 'live');
                setTimeout(() => {
                    setEntering(true);
                    closePopup();
                }, 2000);
            }
        };
        !isTeacher && APP.eventManager.subscribe('QUEUE_ACCEPTED', acceptedUser);
        return () => {
            !isTeacher && APP.eventManager.unsubscribe('QUEUE_ACCEPTED', acceptedUser);
        };
    }, [isTeacher, waitingRoomId]);
    // 대기가 해제되었을때 대기열에서 제외
    useEffect(() => {
        setEntering(false);
        return () => {
            if (!entering)
                queueOutList(waitingRoomId, !entering);
        };
    }, [waitingRoomId, entering]);
    useEffect(() => {
        const handleBeforeUnload = event => {
            queueOutList(waitingRoomId, false);
            // 필요하다면 사용자에게 경고 메시지를 표시할 수 있습니다
            event.preventDefault();
            event.returnValue = '';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [waitingRoomId, entering]);
    const registRoom = async (id) => {
        try {
            await registEnquiryMutation.mutateAsync({ id });
            setWaitingRoomId(id);
        }
        catch (error) {
            alert(error);
        }
        finally {
            refetchList();
        }
    };
    const disableRoom = async (id) => {
        try {
            await rejectedEnquiryMutation.mutateAsync(id);
            setWaitingRoomId(undefined);
        }
        catch (error) {
            alert(error);
        }
        finally {
            refetchList();
        }
    };
    const isOnline = useMemo(() => testData.find(item => isTeacher
        ? item.host_occupied
        : // @ts-ignore
            item.members &&
                item.members.find(i => i.member_id === localId && i.status === 'occupied'))
        ? true
        : false, [testData, isTeacher, localId]);
    // const renderTitle = useMemo(() => {
    // 	const title = isTeacher ? null : liveRoom[0]?.title;
    // 	return isTeacher ? null : <div className="title">[{title}]</div>;
    // }, [isTeacher]);
    const renderEmpty = useMemo(() => {
        if (testData.length === 0) {
            return (React.createElement("div", { className: "empty_data" },
                React.createElement(Button, { className: "text", text: "\uC9C4\uD589\uC911\uC778 1:1 Live \uBB38\uC758 \uBC29\uC774 \uC5C6\uC2B5\uB2C8\uB2E4" }),
                React.createElement(Button, { className: `btn_default ${isMobile ? 'sm' : 'xmd'}`, text: "common.okay", onClick: closePopup })));
        }
    }, [testData]);
    const renderContent = (item, headItem, index, styles) => {
        const live_data = liveRoom.find(i => i.id === item.parent_id);
        return (headItem.title === 'host_name' || headItem.title === 'etc') && isMobile ? null : (React.createElement("div", { key: index, className: "body-item", style: {
                flex: !isMobile && headItem.space,
                ...styles?.bodyItem,
            } }, headItem.title === 'etc' ? (!isTeacher && !item.host_occupied ? null : item.waiting &&
            item.waiting.length > 0 &&
            item.waiting.find(i => i.id === localId) ? (React.createElement("span", { className: "underline", onClick: isTeacher ? null : () => disableRoom(item.id) }, "\uB300\uAE30 \uD574\uC81C")) : (React.createElement("span", { className: "underline", onClick: isTeacher
                ? item.host_occupied
                    ? null
                    : () => goToConference(item.id, 'live')
                : isOnline
                    ? null
                    : () => registRoom(item.id) }, isTeacher
            ? item.host_occupied
                ? '입장중'
                : '입장'
            : isOnline
                ? '입장중'
                : '대기'))) : headItem.title === 'waiting' ? (isMobile ? (React.createElement("div", { style: { gap: '4px', display: 'flex' } },
            React.createElement("span", { className: "overText2", style: { color: Colors.light_grey } }, item['host_name']),
            React.createElement("span", { className: "overText" },
                item.waiting?.length,
                "\uBA85"))) : (React.createElement("span", { className: "overText2" },
            item.waiting?.length,
            " \uBA85"))) : headItem.title === 'type' ? (React.createElement("span", { className: "overText2" }, item.host_occupied ? '대기중' : '미대기중')) : headItem.title === 'title' ? (React.createElement("span", { className: "overText2 bold" },
            `${live_data?.title} - `,
            " ",
            item[headItem.title])) : (React.createElement("span", { className: "overText2" }, item[headItem.title]))));
    };
    const renderItem = (item, styles) => {
        return (React.createElement(React.Fragment, null, isMobile ? (React.createElement("div", { className: "live-mobile-wrap" },
            React.createElement("div", { className: "live-contents-wrap" }, tableReadDataKeys.map((headItem, index) => renderContent(item, headItem, index))),
            React.createElement("div", { className: "live-status" }, !isTeacher && !item.host_occupied ? null : item.waiting &&
                item.waiting.length > 0 &&
                item.waiting.find(i => i.id === localId) ? (React.createElement("span", { className: "underline", onClick: isTeacher ? null : () => disableRoom(item.id) }, "\uB300\uAE30 \uD574\uC81C")) : (React.createElement("span", { className: "underline", onClick: isOnline
                    ? null
                    : isTeacher
                        ? () => goToConference(item.id, 'live')
                        : () => registRoom(item.id) }, isOnline ? '입장중' : isTeacher ? '입장' : '대기'))))) : (tableReadDataKeys.map((headItem, index) => renderContent(item, headItem, index)))));
    };
    if (waitingRoomId) {
        const items = testData.find(i => i.id === waitingRoomId);
        if (!items)
            return null;
        return (React.createElement(LiveEnquiriesStyled, { "$isMobile": isMobile, className: "waiting" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "txt" }, "\uC7A0\uC2DC\uB9CC \uAE30\uB2E4\uB824 \uC8FC\uC2ED\uC2DC\uC624"),
                React.createElement(LottieAnimation, { animationData: loadingFinal, width: 150, height: 150 }),
                React.createElement("div", { className: "txt2" },
                    React.createElement("span", null,
                        React.createElement("span", { className: "txt-2-span" }, "\uD604\uC7AC \uB300\uAE30\uC778\uC6D0"),
                        React.createElement("span", { className: "txt-2-span2" },
                            ' ',
                            items.waiting.length,
                            "\uBA85\uC73C\uB85C \uB300\uAE30\uC2DC\uAC04 \uCD5C\uC18C",
                            ' ',
                            items.waiting.length * 10,
                            "\uBD84 \uC774\uC0C1"),
                        React.createElement("span", { className: "txt-2-span3" },
                            "\uC18C\uC694\uB429\uB2C8\uB2E4",
                            React.createElement("br", null),
                            "\uCDE8\uC18C \uC2DC \uB300\uAE30\uC778\uC6D0\uC5D0\uC11C \uC81C\uC678\uB418\uBA70, \uB2E4\uC2DC \uB300\uAE30\uB97C \uD574\uC57C \uD569\uB2C8\uB2E4"))),
                React.createElement(Button, { className: "btn_default xmd", text: "\uB300\uAE30 \uCDE8\uC18C", onClick: () => disableRoom(waitingRoomId) }))));
    }
    return (React.createElement(LiveEnquiriesStyled, { "$isMobile": isMobile },
        React.createElement(Table, { className: "popup-list-009", isInifinite: true, page: page, data: testData, totalCount: totalCount, headTitleList: headTitleList, 
            // renderTitle={renderTitle}
            renderEmpty: renderEmpty, renderItem: renderItem, forceShowheader: testData.length === 0 || isMobile ? false : true })));
});
