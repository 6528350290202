import React, { useEffect, useMemo, useState } from 'react';
import { Colors } from '../../../Colors';
import { Icon } from '../../../common';
import { TOGGLE_POPUP } from './actionTypes';
const DEFAULT_POPUP = {
    className: undefined,
    component: undefined,
    componentProps: undefined,
    title: undefined,
    width: 0,
    height: 0,
    isFit: false,
    isClose: true,
};
const PopupContainer = React.memo(() => {
    const popupRef = React.useRef(null);
    const [popup, setPopup] = useState(DEFAULT_POPUP);
    // const outsideClick = (event: MouseEvent) => {
    // 	if (!popup) return;
    // 	if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
    // 		hidePopup();
    // 	}
    // };
    const handlerTogglePopup = (data) => {
        if (!data)
            hidePopup();
        else
            setPopup(data);
    };
    const hidePopup = () => setPopup(DEFAULT_POPUP);
    const component = useMemo(() => {
        return popup.component
            ? React.createElement(popup.component, popup.componentProps)
            : undefined;
    }, [popup]);
    const title = useMemo(() => popup.title, [popup.title]);
    useEffect(() => {
        APP.eventManager.subscribe(TOGGLE_POPUP, handlerTogglePopup);
    }, []);
    // useEffect(() => {
    // 	window.addEventListener('click', outsideClick);
    // 	return () => {
    // 		window.removeEventListener('click', outsideClick);
    // 	};
    // }, []);
    if (!component)
        return null;
    return (React.createElement("div", { className: `popup ${popup.className}` },
        React.createElement("div", { className: "popup-wrapper", ref: popupRef, style: {
                width: `${popup?.width}px`,
                height: `${popup?.height ? `${popup?.height}px` : popup?.isFit ? 'auto' : '90%'}`,
                // minHeight: `${popup?.isFit ? 'auto' : '80%'}`,
                maxHeight: `${popup?.isFit ? 'auto' : '90%'}`,
            } },
            React.createElement("div", { className: "popup-header" },
                React.createElement("p", { className: "headline5" }, title),
                popup?.isClose !== false && (React.createElement("div", { onClick: hidePopup, style: { cursor: 'pointer' } },
                    React.createElement(Icon, { icon: "close", size: 24, fill: Colors.white })))),
            React.createElement("div", { className: "popup-body" }, component))));
});
export default PopupContainer;
