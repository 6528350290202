import { ReducerRegistry } from '../../../redux/regist';
import { SET_IS_TEACHER, SET_MANAGER, SET_TOKEN, SET_USER_INFO } from './actionTypes';
const initialState = {
    token: {
        access_token: undefined,
        refresh_token: undefined,
    },
    user: undefined,
    isTeacher: false,
    manager: [],
    isAdmin: false,
};
ReducerRegistry.register('base/account', (state = initialState, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return {
                ...state,
                token: action.token,
            };
        case SET_USER_INFO:
            return {
                ...state,
                user: action.user,
                isTeacher: action.isTeacher,
            };
        case SET_IS_TEACHER:
            return {
                ...state,
                isTeacher: action.isTeacher,
            };
        case SET_MANAGER:
            return {
                ...state,
                manager: action.manager,
            };
    }
    return state;
});
