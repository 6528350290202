import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
const LottieAnimation = ({ animationData, width, height }) => {
    const containerRef = useRef(null);
    const animationRef = useRef(null);
    useEffect(() => {
        if (containerRef.current) {
            animationRef.current = lottie.loadAnimation({
                container: containerRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                },
            });
            return () => {
                if (animationRef.current) {
                    animationRef.current.destroy();
                }
            };
        }
    }, [animationData]);
    return React.createElement("div", { ref: containerRef, style: { width, height } });
};
export default LottieAnimation;
