import React from 'react';
import styled from 'styled-components';
import { Button, Icon } from '../../../../common';
const MobilePageTabStyled = styled.div `
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	.cancel-wrap {
		display: flex;
		gap: 4px;
		padding-bottom: 12px;

		.exit-tab {
			gap: 4px;
			.overText {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: 'NotoSansKr-Bold', sans-serif;
				font-size: 16px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 700;
				position: relative;
			}
		}
	}

	.hr {
		margin-bottom: 14px;
	}

	.page-tab {
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}

	.tab-item {
		height: unset;
	}

	.page-tab-button {
		padding-bottom: 4px;
	}
`;
export const MobilePageTab = React.memo(({ activeTabIndex, tabList, previousPage, content, button, }) => {
    return (React.createElement(MobilePageTabStyled, null,
        React.createElement("div", { className: "cancel-wrap" },
            React.createElement(Button, { className: "exit-tab", option: {
                    buttonBeforeIcon: {
                        show: true,
                        name: 'cancel-mobile',
                        size: 24,
                    },
                }, text: tabList[activeTabIndex].title, onClick: previousPage })),
        React.createElement("div", { className: "hr" }),
        React.createElement("div", { className: "scroll-content" },
            content,
            React.createElement("div", { className: "page-tab-button" },
                React.createElement("div", { className: "hr", style: { marginTop: '14px' } }),
                React.createElement("div", { className: "page-tab" }, Array.from({ length: tabList.length }, (_, index) => (React.createElement(Icon, { key: index, icon: `page_${activeTabIndex === index ? 'on' : 'off'}`, width: 40 })))),
                button))));
});
