import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../Colors';
import { Button, FileInput } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import styled, { css } from 'styled-components';
const AttachFileAnswerStyled = styled.div `
	width: 100%;
	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.attach-file-box-render {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: flex-start;
	}

	.attach-file-answer {
		width: 100%;
		display: grid;
		gap: 12px;
	}
	
	.attach-file-column {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
	}

	.attach-file-box {
		border-radius: 4px;
    	padding: 10px;
    	display: inline-flex;
    	flex-wrap: wrap;
    	gap: 10px;
    	border: 1px solid #b6bac0;
		height: 50px;
	}

	.mobile-button-font {
		padding: 12px 10px 12px 10px;
		border-radius: 6px;
		p {
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
		}
	}
`;
const desktopStyles = css `
	.attach-file-box-render {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
	}

	.attach-file-answer {
		width: 100%;
		display: grid;
		gap: 12px;
	}
	
	.attach-file-column {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
	}

	.attach-file-box {
		border-radius: 4px;
    	padding: 10px;
    	display: inline-flex;
    	flex-wrap: wrap;
    	gap: 10px;
    	border: 1px solid #b6bac0;
		height: 50px;
	}

	.mobile-button-font {
		padding: 12px 10px 12px 10px;
		border-radius: 6px;
		p {
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
		}
	}
`;
// 첨부 파일 컴포넌트
export const AttachFileAnswer = React.memo(({ attachFileList, setAttachFileList, isRegist, showRegist = false, isAnswer = false, isTeacher, isRequestion, isLive = false, }) => {
    const { t } = useTranslation();
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const fileRef = React.useRef(null);
    const [count, setCount] = React.useState(0);
    //이미지, pdf, 엑셀, 워드, PPT, 텍스트 파일
    const acceptedFileTypes = 'image/*,application/pdf,.xlsx, .xls, .csv, .ppt, .pptx, .pdf, .doc, .docx, .txt, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain';
    const downloadFile = (url) => {
        const formattedUrl = url.replace(/\s/g, '%20');
        const a = document.createElement('a');
        a.href = formattedUrl; // 다운로드 URL 설정
        a.download = url.split('/').pop() || 'download'; // 다운로드 파일명 설정
        document.body.appendChild(a); // a 요소를 문서에 추가
        a.click();
        document.body.removeChild(a); // a 요소를 문서에서 제거
    };
    // (onChange) 첨부 파일 추가
    const onChangeFile = (e, index) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            const updatedList = [...attachFileList];
            updatedList[index] = file;
            setAttachFileList(updatedList);
        }
        if (fileRef.current)
            fileRef.current.value = '';
    };
    const addNewBox = () => {
        setAttachFileList([...attachFileList, null]);
    };
    const deleteBox = (index) => {
        const updatedList = [...attachFileList];
        updatedList.splice(index, 1); // Remove the file box
        setAttachFileList(updatedList);
    };
    const deleteAttachFile = (index) => {
        const updatedList = [...attachFileList];
        updatedList.splice(index, 1); // Remove the file box
        setAttachFileList(updatedList);
    };
    //드래그 앤 드롭 파일 막기
    const handleDrop = e => {
        e.preventDefault();
        const dt = e.dataTransfer;
        // 드롭된 항목이 폴더인지 파일인지 확인
        for (let i = 0; i < dt.items.length; i++) {
            const item = dt.items[i];
            if (item.kind === 'file') {
                const file = item.getAsFile();
                // 파일 확장자 확인 (예시: mp4, mov 확장자만 허용)
                if (file.type.startsWith('video/') &&
                    (file.type.includes('mp4') || file.type.includes('quicktime'))) {
                    // 파일 처리 로직 추가
                }
                else {
                    alert('폴더를 등록할 수 없습니다.');
                    return;
                }
            }
            else {
                alert('폴더를 등록할 수 없습니다.');
                return;
            }
        }
    };
    const handleDragOver = e => {
        e.preventDefault();
    };
    const renderAttachFileButton = useMemo(() => (React.createElement(FileInput, { ref: fileRef, id: `dst_attach_file_${count}`, onChange: (e) => onChangeFile(e, count), accept: acceptedFileTypes },
        React.createElement(Button, { className: `btn_tur ${isDesktop || isAnswer ? 'sm' : 'mobile-button-font'}`, text: isMobile
                ? '업로드'
                : isAnswer
                    ? 'supportRoom.regist'
                    : 'common.regist', styles: {
                buttonWrap: isAnswer
                    ? {
                        width: '60px',
                        height: '30px',
                    }
                    : isLive && isMobile
                        ? { width: '100%' }
                        : {
                            height: isTeacher ? undefined : '44px',
                            width: isMobile ? '60px' : '80px',
                        },
            } }))), [isDesktop, isTeacher, isAnswer, attachFileList]);
    const renderAddButton = (index) => (React.createElement(Button, { key: count, className: `btn_white ${isMobile ? 'mobile-button-font' : 'xmd'}`, text: "common.add", styles: {
            buttonWrap: {
                maxWidth: isMobile ? 60 : 80,
                maxHeight: isMobile && 44,
                height: isMobile && 44,
            },
        }, onClick: () => {
            setCount(index + 1);
            addNewBox();
        } }));
    const renderDeleteButton = (index) => (React.createElement(Button, { className: `btn_pink ${isMobile ? 'mobile-button-font' : 'xmd'}`, text: "conference.delete", styles: {
            buttonWrap: {
                maxWidth: isMobile ? 60 : 80,
                maxHeight: isMobile && 44,
                height: isMobile && 44,
                boxShadow: 'unset',
            },
        }, onClick: () => {
            if (attachFileList.length === 1) {
                setAttachFileList([]);
                setCount(0);
                return;
            }
            deleteAttachFile(index);
            deleteBox(index);
            setCount(count - 1);
        } }));
    const renderAttachFile = (file, index) => (React.createElement(React.Fragment, null,
        React.createElement("div", { onDrop: handleDrop, onDragOver: handleDragOver, className: "attach-file-box", style: {
                width: '100%',
                minHeight: isAnswer || (isTeacher && isTablet)
                    ? 50
                    : isTeacher && !isMobile
                        ? 100
                        : undefined,
                // maxHeight: isTeacher && !isTablet ? 100 : undefined,
                alignContent: 'center',
                alignItems: 'center',
            } },
            React.createElement("div", { style: {
                    width: isAnswer ? '85%' : '100%',
                    display: 'inline-flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    gap: '10px',
                } }, file ? (React.createElement(Button, { key: index, className: `btn_sliver md_round center body5 cursor attach_item ${isMobile ? 'mobile-xxs' : ''}`, text: typeof file === 'string'
                    ? file.split('/')[file.split('/').length - 1]
                    : file.name, styles: {
                    buttonWrap: {
                        height: 'fit-content',
                        maxWidth: isMobile ? 180 : 220,
                    },
                }, option: {
                    buttonBeforeIcon: {
                        name: 'file',
                        color: Colors.dark_tur,
                        size: isMobile ? 12 : 20,
                        show: true,
                    },
                    buttonAfterIcon: {
                        name: !showRegist &&
                            !isAnswer &&
                            typeof file === 'string'
                            ? 'file-down'
                            : '',
                        color: Colors.accent,
                        size: !showRegist &&
                            !isAnswer &&
                            typeof file === 'string'
                            ? 16
                            : 12,
                        show: showRegist || isAnswer
                            ? true
                            : typeof file === 'string',
                    },
                }, onClick: !showRegist && typeof file === 'string'
                    ? () => downloadFile(file)
                    : () => { } })) : (React.createElement("p", { className: "body5", style: { color: Colors.light_grey, width: '100%' } }, isTeacher && isRegist
                ? t('assignment.attachFilePlaceholer')
                : t('assignment.noAttachFilePlaceholer'))))),
        showRegist && !file && renderAttachFileButton,
        showRegist && attachFileList.length >= 1 && (attachFileList[index] != null || index !== 0) && renderDeleteButton(index),
        showRegist && index == count && attachFileList.length >= 1 && attachFileList[index] != null && renderAddButton(index)));
    // const renderAttachFileMobile = useMemo(
    // 	() => (
    // 		<>
    // 			{isMobile && showRegist && renderAttachFileButton}
    // 			<div
    // 				onDrop={handleDrop}
    // 				onDragOver={handleDragOver}
    // 				className="box"
    // 				style={{
    // 					width: '100%',
    // 					minHeight:
    // 						isAnswer || (isTeacher && isTablet)
    // 							? 50
    // 							: isTeacher && !isMobile
    // 							? 100
    // 							: undefined,
    // 					// maxHeight: isTeacher && !isTablet ? 100 : undefined,
    // 				}}
    // 			>
    // 				<div
    // 					style={{
    // 						width: isAnswer ? '85%' : '100%',
    // 						display: 'inline-flex',
    // 						flexWrap: 'wrap',
    // 						flexDirection: 'row',
    // 						gap: '10px',
    // 					}}
    // 				>
    // 					{attachFileList.length > 0 ? (
    // 						attachFileList.map((item: any, index: number) => (
    // 							<Button
    // 								key={index}
    // 								className={`btn_sliver md_round center body5 cursor attach_item ${
    // 									isMobile ? 'mobile-xxs' : ''
    // 								}`}
    // 								text={
    // 									typeof item === 'string'
    // 										? item.split('/')[item.split('/').length - 1]
    // 										: item.name
    // 								}
    // 								styles={{
    // 									buttonWrap: {
    // 										height: 'fit-content',
    // 										maxWidth: isMobile ? 180 : 220,
    // 									},
    // 								}}
    // 								option={{
    // 									buttonBeforeIcon: {
    // 										name: 'file',
    // 										color: Colors.dark_tur,
    // 										size: isMobile ? 12 : 20,
    // 										show: true,
    // 									},
    // 									buttonAfterIcon: {
    // 										name:
    // 											!showRegist &&
    // 											!isAnswer &&
    // 											typeof item === 'string'
    // 												? 'file-down'
    // 												: 'cancel',
    // 										color: Colors.accent,
    // 										size:
    // 											!showRegist &&
    // 											!isAnswer &&
    // 											typeof item === 'string'
    // 												? 16
    // 												: 12,
    // 										show:
    // 											showRegist || isAnswer
    // 												? true
    // 												: typeof item === 'string',
    // 									},
    // 								}}
    // 								onClick={
    // 									showRegist || isAnswer
    // 										? () => deleteAttachFile(item)
    // 										: typeof item === 'string'
    // 										? () => downloadFile(item)
    // 										: () => {}
    // 								}
    // 							/>
    // 						))
    // 					) : (
    // 						<p
    // 							className="body5"
    // 							style={{ color: Colors.light_grey, width: '85%' }}
    // 						>
    // 							{isTeacher && isRegist
    // 								? t('assignment.attachFilePlaceholer')
    // 								: t('assignment.noAttachFilePlaceholer')}
    // 						</p>
    // 					)}
    // 				</div>
    // 				{isAnswer && renderAttachFileButton}
    // 			</div>
    // 			{!isMobile && showRegist && renderAttachFileButton}
    // 		</>
    // 	),
    // 	[showRegist, isAnswer, attachFileList, isTeacher, isRegist]
    // );
    return (React.createElement(AttachFileAnswerStyled, { "$isDesktop": isDesktop, "$isMobile": isMobile },
        React.createElement("div", { className: "field-global-001", style: isRequestion ? { gap: '48px' } : { alignItems: 'flex-start' } },
            React.createElement("div", { className: 'attach-file-answer' },
                React.createElement("div", null,
                    React.createElement("div", { className: "task-label" }, t('assignment.attachFile'))),
                React.createElement("div", { className: 'attach-file-column' }, showRegist ? [...Array(count + 1)].map((_, i) => (React.createElement("div", { key: i, className: 'attach-file-box-render' },
                    !isMobile &&
                        React.createElement(Button, { key: i, className: `btn_light_grey ${isMobile ? 'mobile-button-font' : 'xmd'}`, text: `No.${i + 1}`, styles: {
                                buttonWrap: {
                                    maxWidth: isMobile ? 60 : 80,
                                    maxHeight: isMobile && 44,
                                    boxShadow: 'unset',
                                },
                            } }),
                    renderAttachFile(attachFileList[i], i)))) :
                    [...Array(attachFileList.length)].map((_, i) => (React.createElement("div", { key: i, className: 'attach-file-box-render' },
                        React.createElement(Button, { key: i, className: `btn_light_grey ${isMobile ? 'mobile-button-font' : 'xmd'}`, text: `No.${i + 1}`, styles: {
                                buttonWrap: {
                                    maxWidth: isMobile ? 60 : 80,
                                    maxHeight: isMobile && 44,
                                    boxShadow: 'unset',
                                },
                            }, ellipsis: true }),
                        renderAttachFile(attachFileList[i], i)))))))));
});
