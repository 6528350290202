import { MiddlewareRegistry } from '../../redux/regist';
import { DEFAULT_MAIN_S_ITEM, DEFAULT_MAIN_T_ITEM } from '../DST';
import { getIsTeacher } from '../base/account';
import { APP_WILL_LOAD } from '../base/app/actionTypes';
import { updateMenu } from './action';
import { CHANGE_MENU_FROM_NAME, CHANGE_MENU_PROPS, SET_MENU } from './actionType';
import { getDepths, getMenuData } from './function';
MiddlewareRegistry.register(store => next => (action) => {
    const { dispatch } = store;
    const isTeacher = getIsTeacher(store.getState);
    const prevDepths = getDepths(store.getState);
    let depths = action?.name || 'home';
    switch (action.type) {
        case APP_WILL_LOAD:
            const default_menu = localStorage.getItem(isTeacher ? 'dst_menu' : 'dss_menu');
            depths = default_menu
                ? default_menu
                : isTeacher
                    ? DEFAULT_MAIN_T_ITEM
                    : DEFAULT_MAIN_S_ITEM;
        case CHANGE_MENU_FROM_NAME:
            if (prevDepths === action.name)
                break;
            localStorage.removeItem(`${prevDepths}_menu`);
            localStorage.removeItem(`${prevDepths}_id`);
            localStorage.removeItem(`${prevDepths}_props`);
            const storedMenu = localStorage.getItem(`${depths}_menu`);
            const storedId = localStorage.getItem(`${depths}_id`);
            const storedProps = localStorage.getItem(`${depths}_props`);
            let parsingMenu = {
                menu: depths === 'learningSupportRoom'
                    ? 'notice'
                    : depths === 'learningManagement' || depths === 'myRoom'
                        ? 'assignment'
                        : depths === 'liveRoom'
                            ? 'reservation'
                            : depths,
                detail: 'list',
            };
            let parsingId = undefined;
            let parsingProps = undefined;
            try {
                if (storedMenu) {
                    parsingMenu = JSON.parse(storedMenu);
                }
                if (storedId) {
                    parsingId = storedId;
                }
                if (storedProps) {
                    parsingProps = JSON.parse(storedProps);
                }
            }
            catch (e) {
            }
            finally {
                parsingMenu = action.isMain ? { ...parsingMenu, detail: 'list' } : parsingMenu;
                parsingId = action.isMain ? undefined : parsingId;
                parsingId = parsingId === 'undefined' ? undefined : parsingId;
                parsingProps = parsingProps === 'undefined' ? undefined : parsingProps;
                dispatch(updateMenu(depths, parsingMenu, parsingId, parsingProps));
            }
            break;
        case SET_MENU:
            const { menu, id, props } = action;
            localStorage.setItem(isTeacher ? 'dst_menu' : 'dss_menu', action.depths);
            if (action.depths === 'home')
                break;
            localStorage.setItem(`${action.depths}_menu`, JSON.stringify(menu));
            localStorage.setItem(`${action.depths}_id`, id);
            localStorage.setItem(`${action.depths}_props`, JSON.stringify(props));
            break;
        case CHANGE_MENU_PROPS:
            const nowDepths = getMenuData(store.getState).depths;
            localStorage.setItem(`${nowDepths}_props`, JSON.stringify(action.props));
            break;
    }
    return next(action);
});
