import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { DropDown, InputElement } from '../../../../../common';
const ConferenceInfoStyled = styled.div `
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	width: 100%;

	input:disabled {
		background: var(--color-white, #fff);
	}

	.live-class-wrapper {
		background: var(--color-white, #ffffff);
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 10px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.btn-file {
		background: var(--color-silver, #f3f4f8);
		border-radius: 100px;
		padding: 4px 16px 4px 16px;
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.overText {
			width: 100%;
		}
	}

	.conference-info {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		padding: 0px 0px 28px 0px;
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.class-date {
		display: flex;
		width: 100%;
	}
	${props => (props.$isMobile ? mobileStyels : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileStyels = css `
	gap: 12px;

	.class-date {
		gap: 12px;
		flex-direction: column;
	}

	.dropdown-live {
		.dropdown-field {
			padding: 12px 10px;
		}
		.placeholder {
			font-size: 12px;
			line-height: 150%;
		}
	}
`;
const tabletStyles = css `
	gap: 24px;

	.class-date {
		gap: 24px;
	}
`;
const desktopStyles = css `
	gap: 30px;

	.class-date {
		gap: 24px;
	}
`;
//강의모드 선택
// "lesson", "duplex", "vod"
const liveModeList = [
    { id: 'select', title: 'common.select' },
    { id: 'lesson', title: 'conference.lesson' },
    // { id: 'duplex', title: 'conference.duplex' },
    { id: 'vod', title: 'conference.vod' },
    { id: 'mock_test', title: 'conference.mock_test' },
];
export const ConferenceInfo = React.memo(({ defaultItem, item, setItem, isDetail, isModify = false, }) => {
    const { t } = useTranslation();
    const [dropValue, setDropValue] = React.useState({
        id: 'select',
        title: 'common.select',
    });
    const [load, setLoad] = useState(false);
    const [title, setTitle] = useState(item?.title || '');
    const [startDate, setStartDate] = useState(item?.date || moment().format('YYYY-MM-DD'));
    const [startTime, setStartTime] = useState(moment().format('HH:mm'));
    const [duration, setDuration] = useState(item?.duration || '60');
    const [entryDate, setEntryDate] = useState(item?.entryDate || '10');
    useEffect(() => {
        if (!defaultItem)
            return;
        const { title, conferenceMode, date, duration, entryDate } = defaultItem;
        setTitle(title);
        setDropValue(liveModeList.find(i => i.id === conferenceMode) || liveModeList[0]);
        setStartDate(moment(date).format('YYYY-MM-DD'));
        date
            ? setStartTime(moment(date).format('HH:mm'))
            : setStartTime(moment().add(5, 'minutes').format('HH:mm'));
        setDuration(duration);
        setEntryDate(entryDate);
        setLoad(true);
    }, []);
    useEffect(() => {
        if (!load)
            return;
        const formattedDate = moment(`${startDate}T${startTime}:00`).format('YYYY-MM-DDTHH:mm:ssZ');
        const item = {
            ...defaultItem,
            title,
            conferenceMode: dropValue.id,
            date: formattedDate,
            duration,
            entryDate,
        };
        setItem(item);
    }, [title, dropValue, startDate, startTime, duration, entryDate]);
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    // 회의 제목 가져오기
    const renderTitle = useMemo(() => (React.createElement("div", { className: "field-global-001", style: { minWidth: `${isDesktop ? 'calc(100% - 240px)' : ''}` } },
        React.createElement("div", { className: "task-label", style: { minWidth: isDesktop && isDetail && '109px' } }, t('conference.title')),
        React.createElement("div", { className: "fext-field" },
            React.createElement(InputElement, { className: "showText", type: "text", value: title, isDisable: isDetail, onChange: setTitle })))), [title, isDetail, isDesktop]);
    // 수업 모드 dropdonw
    const renderClassMode = useMemo(() => (React.createElement("div", { className: "field-global-001" },
        React.createElement("div", { className: "task-label" }, t('conference.liveMode')),
        React.createElement("div", { className: "fext-field" },
            React.createElement(DropDown, { className: "dropdown-live", value: dropValue, list: liveModeList, isDisable: isDetail || isModify, option: {
                    downIcon: {
                        show: isDetail ? false : true,
                    },
                }, styles: {
                    titleWrap: { cursor: isDetail || isModify ? 'default' : 'pointer' },
                }, setDropValue: setDropValue })))), [dropValue, liveModeList, isDetail]);
    // 수업 일자
    const renderClassDate = useMemo(() => isMobile ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "field-global-001", style: {
                minWidth: isDesktop ? '50%' : '',
            } },
            React.createElement("div", { className: "task-label", style: { minWidth: isDesktop && isDetail && '109px' } }, t('conference.classDate')),
            React.createElement("div", { className: "text-line" },
                React.createElement(InputElement, { type: "date", value: startDate || '', isDisable: isDetail, onChange: setStartDate }))),
        React.createElement("div", { className: "field-global-001", style: {
                minWidth: isDesktop ? '50%' : '',
            } },
            React.createElement("div", { className: "task-label", style: { minWidth: isDesktop && isDetail && '109px' } }, t('conference.startTime')),
            React.createElement("div", { className: "text-line" },
                React.createElement(InputElement, { type: "time", value: startTime || '', isDisable: isDetail, onChange: setStartTime }))))) : (React.createElement("div", { className: "field-global-001", style: {
            minWidth: isDesktop ? '50%' : '',
        } },
        React.createElement("div", { className: "task-label", style: { minWidth: isDesktop && isDetail && '109px' } }, t('conference.classDate')),
        React.createElement("div", { className: "class-date" },
            React.createElement("div", { className: "text-line" },
                React.createElement(InputElement, { type: "date", value: startDate || '', isDisable: isDetail, onChange: setStartDate })),
            React.createElement("div", { className: "text-line" },
                React.createElement(InputElement, { type: "time", value: startTime || '', isDisable: isDetail, onChange: setStartTime }))))), [isDesktop, startDate, startTime, isDetail]);
    // 수업 진행 시간
    const renderDutaion = useMemo(() => (React.createElement("div", { className: "field-global-001" },
        React.createElement("div", { className: "task-label" }, t('conference.progressTime')),
        React.createElement("div", { className: "text-line" },
            React.createElement(InputElement, { value: duration, className: "time", type: "text", isDisable: isDetail, onChange: setDuration }),
            t('assignment.minute')))), [duration, isDetail]);
    // 사전 입장 등록 시간
    const renderEnterTime = useMemo(() => (React.createElement("div", { className: "field-global-001" },
        React.createElement("div", { className: "task-label" }, t('conference.enterTime')),
        React.createElement("div", { className: "text-line" },
            React.createElement(InputElement, { value: entryDate, className: "time", type: "text", isDisable: isDetail, onChange: setEntryDate }),
            t('conference.beforeMinute')))), [entryDate, isDetail]);
    if (isMobile || isTablet) {
        return (React.createElement(ConferenceInfoStyled, { "$isMobile": isMobile, "$isTablet": isTablet, className: "title-info" },
            renderTitle,
            renderClassMode,
            renderClassDate,
            renderDutaion,
            renderEnterTime));
    }
    return (React.createElement(ConferenceInfoStyled, { "$isMobile": isMobile, "$isTablet": isTablet, className: "title-info" },
        React.createElement("div", { className: "field-short", style: { maxWidth: `${isDesktop ? '80%' : ''}` } },
            renderTitle,
            renderClassMode),
        React.createElement("div", { className: "field-short", style: { maxWidth: `${isDesktop ? '80%' : ''}` } },
            renderClassDate,
            renderDutaion,
            renderEnterTime)));
});
