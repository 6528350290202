export const DATE_CHECK_LIST = [
    { id: 'now', title: 'sendSMS.now', checked: true },
    { id: 'reservation', title: 'sendSMS.reservation', checked: false },
];
export const NUMBER_CHECK_LIST = [
    { id: 'fix', title: 'sendSMS.fix', checked: true },
    { id: 'direct', title: 'sendSMS.direct', checked: false },
];
export const SMS_STATUS = {
    CREATED: 'created',
    SENT: 'sent',
    FAILED: 'failed',
};
// export const SMS_STATUS = [
// 	{ id: 'create', title: 'sendSMS.create' },
// 	{ id: 'send', title: 'sendSMS.send' },
// 	{ id: 'failed', title: 'sendSMS.failed' },
// ];
