import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../../GlobalStateProvider';
export const AverageBox = React.memo(({ title, renderItem, isHome = false, }) => {
    const { t } = useTranslation();
    const { isMobile, isDesktop, isTablet } = useGlobalState();
    return (React.createElement(AverageBoxStyled, { key: title, "$isMobile": isMobile, "$isDesktop": isDesktop, "$isTablet": isTablet, "$isHome": isHome, className: `average-container ${isHome ? 'home' : ''}` },
        React.createElement("div", { className: "dst001_detail_title" },
            t(title),
            " "),
        renderItem));
});
const AverageBoxStyled = styled.div `
	${props => props.$isMobile
    ? css `
					&.average-container {
						display: flex;
						flex-direction: column;
						gap: 12px;
						align-items: center;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
					}
					.box-wrap {
						background: var(--color-silver, #f3f4f8);
						border-radius: 10px;
						padding: ${props.$isHome ? '0px' : '12px 10px 12px 10px'};
						display: flex;
						flex-direction: column;
						gap: 12px;
						align-items: center;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
						width: 100%;
						display: flex;
					}
					.box-wrap-item {
						width: 100%;
						background: var(--color-white, #ffffff);
						border-radius: 8px;
						padding: ${props.$isHome ? '10px 18px 10px 18px' : '12px 20px 12px 20px'};
						display: flex;
						flex-direction: row;
						gap: ${props.$isHome ? '0px' : '6px'};
						align-items: flex-start;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
						min-height: 50px;
					}
			  `
    : css `
					&.average-container {
						display: flex;
						flex-direction: column;
						gap: 12px;
						align-items: center;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
					}
					.box-wrap {
						background: var(--color-silver, #f3f4f8);
						border-radius: 10px;
						padding: 12px 10px 12px 10px;
						display: flex;
						flex-direction: column;
						gap: 12px;
						align-items: flex-start;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
						min-width: 200px;
						height: 90%;
					}
					.box-wrap-item {
						flex: 1;
						width: 100%;
						background: var(--color-white, #ffffff);
						border-radius: 8px;
						padding: 10px 18px 10px 18px;
						display: flex;
						flex-direction: column;
						gap: 4px;
						align-items: flex-start;
						justify-content: center;
						flex-shrink: 0;
						position: relative;
					}
			  `}
`;
