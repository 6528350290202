import React, { useEffect } from 'react';
import { Button } from '../../../../common';
// @ts-ignore
import Back from '../../../../assest/icon/backhome.png';
const DSLayoutHeader = React.memo(({ title, isHome, children, isScrolled = false, setMenu, }) => {
    useEffect(() => {
        window.addEventListener('DOMContentLoaded', function () {
            console.log('load');
            var element = document.getElementById('element');
            element.classList.add('animate'); // 페이지 로드시 클래스 추가
        });
    }, []);
    return (React.createElement("div", { className: `navbar ${isHome ? (isScrolled ? 'home scroll' : 'home') : ''}` },
        isHome && (React.createElement(React.Fragment, null,
            React.createElement("img", { className: "back_home", src: Back }),
            React.createElement("div", { id: "element", className: isScrolled ? 'overlay-on' : 'overlay-off' }))),
        React.createElement(Button, { className: `btn-logo ${isHome ? 'white' : undefined}`, option: {
                buttonBeforeIcon: {
                    show: true,
                    width: 107,
                    height: isHome ? 30 : 40,
                    name: isHome && !isScrolled ? 'logo_white' : 'logo',
                },
            }, onClick: () => setMenu('home'), text: title }),
        React.createElement("div", { className: "container", style: { flex: 2 } }, children)));
});
export default DSLayoutHeader;
