import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Colors } from '../../../../Colors';
import { Button, FileInput } from '../../../../common';
import { postChunk, postContentsList } from '../../../../server';
import { getClassesId } from '../../../base/classes';
function isVideoFile(file) {
    return file.type.startsWith('video/');
}
export const AttachContentButton = React.memo(({ menu, isReserve = false, setUpdateCheckList, }) => {
    const { t } = useTranslation();
    const fileRef = React.useRef(null);
    const contentsMutation = useMutation(postContentsList);
    const chunkMutation = useMutation(postChunk);
    const acceptedFileTypes = useMemo(() => menu === 'all'
        ? 'image/*,application/pdf,video/*'
        : menu === 'document'
            ? 'application/pdf'
            : menu === 'background'
                ? 'image/*'
                : 'video/*', [menu]);
    const uploadVideoChunks = async (file, chunkSizeMB, uuid) => {
        const chunkSize = chunkSizeMB * 1024 * 1024;
        const fileSize = file.size;
        let offset = 0;
        const uploadChunk = async (chunk, index) => {
            const formData = new FormData();
            formData.append('type', 'video');
            formData.append('id', uuid);
            formData.append('file', chunk);
            try {
                const response = await chunkMutation.mutateAsync({ id: uuid, data: formData });
            }
            catch (error) {
                console.error(`Error uploading chunk ${index}:`, error);
            }
        };
        const readNextChunk = () => {
            const fileReader = new FileReader();
            const slice = file.slice(offset, offset + chunkSize);
            fileReader.onload = async (event) => {
                const chunk = event.target?.result;
                if (chunk) {
                    await uploadChunk(new Blob([chunk]), offset / chunkSize);
                    offset += chunkSize;
                    if (offset < fileSize) {
                        readNextChunk();
                    }
                    else {
                        // 빈 파일로 최종 요청
                        await uploadChunk(new Blob([]), offset / chunkSize);
                        setTimeout(() => {
                            APP.eventManager.publish('SHOW_LOADING', false);
                            APP.eventManager.publish('REFRESH_CONTENTS');
                        }, 1000);
                    }
                }
            };
            fileReader.readAsArrayBuffer(slice);
        };
        readNextChunk();
    };
    const uploadDocument = async (file, uuid) => {
        const formData = new FormData();
        if (menu === 'document') {
            formData.append('type', 'document');
            formData.append('id', uuid);
        }
        formData.append('file', file);
        try {
            const response = await chunkMutation.mutateAsync({
                id: uuid,
                data: formData,
            });
            if (response)
                APP.eventManager.publish('REFRESH_CONTENTS');
        }
        catch (error) {
            console.error('Error uploading document:', error);
            alert(error);
        }
        finally {
            setTimeout(() => {
                APP.eventManager.publish('SHOW_LOADING', false);
            }, 1000);
        }
    };
    const onChangeFile = async (e) => {
        APP.eventManager.publish('SHOW_LOADING', true);
        try {
            const selectedFile = e.target.files?.[0];
            if (!selectedFile) {
                throw new Error('No file selected');
            }
            const response = await contentsMutation.mutateAsync({
                class_id: getClassesId(),
                type: menu === 'document'
                    ? 'document'
                    : menu === 'video'
                        ? 'video'
                        : isVideoFile(selectedFile)
                            ? 'video'
                            : menu === 'background'
                                ? 'background'
                                : 'document',
                name: selectedFile.name,
                size: selectedFile.size,
            });
            if (response.result.id) {
                if (isVideoFile(selectedFile)) {
                    await uploadVideoChunks(selectedFile, 10, response.result.id); // 10MB씩 나눔
                }
                else {
                    await uploadDocument(selectedFile, response.result.id); // 문서 업로드
                }
                setUpdateCheckList &&
                    // @ts-ignore
                    setUpdateCheckList(prev => prev ? [...prev, response.result.id] : [response.result.id]);
            }
            else {
                console.error('UUID error');
            }
        }
        catch (error) {
            console.error('Failed to post :', error);
            alert(error);
            APP.eventManager.publish('SHOW_LOADING', false);
        }
        finally {
            if (fileRef.current) {
                fileRef.current.value = '';
            }
        }
    };
    return (React.createElement(FileInput, { ref: fileRef, id: "attach_file", className: "regist_btn", onChange: onChangeFile, accept: acceptedFileTypes }, isReserve ? (React.createElement(Button, { className: "btn_default sm", text: t('conference.dataAdd') })) : (React.createElement(Button, { className: "btn_pink round", option: {
            buttonBeforeIcon: {
                show: true,
                name: 'plus',
                size: 20,
                color: Colors.white,
            },
        } }))));
});
