import { toState } from '../../../redux/regist';
export const getAccessToken = (stateful) => {
    const state = toState(stateful);
    return state['base/account'].token.access_token;
};
export const getUserAuthName = (stateful) => {
    const user = getUserInfo(stateful);
    return user?.auth_name;
};
export const getUserId = (stateful) => {
    const user = getUserInfo(stateful);
    return user?.id;
};
export const getUserInfo = (stateful) => {
    const state = toState(stateful);
    return state['base/account'].user;
};
export const getIsTeacher = (stateful) => {
    const state = toState(stateful);
    return state['base/account'].isTeacher;
};
export const getManager = (stateful) => {
    const state = toState(stateful);
    return state['base/account'].manager;
};
export const getIsManagerAndUserId = (stateful) => {
    const manager = getManager(stateful);
    const localId = getUserId(stateful);
    return manager.includes(localId) ? localId : null;
};
export const getIsManager = (stateful) => {
    const user = getUserInfo(stateful);
    return user?.role_name === 'admin';
};
