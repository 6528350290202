import React, { useEffect, useState } from 'react';
import { Button } from '../../../../../common';
const FullScreenBtn = ({ className, video, autoPlay = true, handlerFullScreen, handlerExitFullScreen, }) => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const enterFullscreen = () => {
        try {
            // 전체 화면 요청
            if (handlerFullScreen) {
                handlerFullScreen();
            }
        }
        catch (error) {
            const streamingContainer = document.getElementById('streaming-container');
            if (streamingContainer && streamingContainer.classList.contains('top_menubar')) {
                streamingContainer.classList.remove('top_menubar');
            }
            setErrorOpen(true);
        }
    };
    const exitFullscreen = () => {
        try {
            if (handlerExitFullScreen) {
                handlerExitFullScreen();
            }
        }
        catch (error) {
            const streamingContainer = document.getElementById('streaming-container');
            if (streamingContainer && streamingContainer.classList.contains('top_menubar')) {
                streamingContainer.classList.add('top_menubar');
            }
        }
    };
    const toggleFullscreen = () => {
        if (isFullscreen) {
            exitFullscreen();
        }
        else {
            enterFullscreen();
        }
        setIsFullscreen(!isFullscreen);
    };
    useEffect(() => {
        const handleFullscreenChange = () => {
            // @ts-ignore
            if (!document.fullscreenElement || !document.webkitFullscreenElement) {
                setIsFullscreen(false);
            }
        };
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
        };
    }, []);
    return (React.createElement(Button, { id: "fullscreenBtn", className: className, onClick: toggleFullscreen, option: {
            buttonBeforeIcon: {
                show: true,
                name: isFullscreen ? 'fullScreen_on' : 'fullScreen_off',
                size: 25,
                color: '#fff',
                stroke: '#fff',
                className: 'handlerIcon',
            },
        } })
    // <button id="fullscreenBtn" className={` ${className}`} onClick={toggleFullscreen}>
    // 	{isFullscreen ? '전체보기 종료' : '전체보기'}
    // </button>
    );
};
export default FullScreenBtn;
