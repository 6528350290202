import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { DSTL001_Submit_View } from './DSTL001_Submit_View';
import { SubmissionStatusTable } from './component/DSTL001_Detail_Child';
import { useGlobalState } from '../../../GlobalStateProvider';
import { useTranslation } from 'react-i18next';
import { MobileTabLayout } from '../../base/layout';
const DSTL001SubmitDetailViewStyled = styled.div `
	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	display: flex;
	flex-direction: column;
	gap: 0px;
	align-items: flex-end;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	width: 100%;
	gap: 36px;
	flex: 1;
	overflow: hidden;

	.divider {
		background: var(--color-silver, #f3f4f8);
		align-self: stretch;
		flex-shrink: 0;
		width: 1px;
		position: relative;
	}

	.wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-end;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;
		flex: 1;
		overflow: hidden;
	}

	.submission-list {
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		flex: 1;
		height: 100%;
	}

	.score-title {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h4-font-size, 20px);
		line-height: var(--h4-line-height, 32px);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.score-list {
		flex: 2;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-end;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}
`;
const tabletStyles = css `
	display: flex;
	flex-direction: column;
	gap: 0px;
	align-items: flex-end;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	width: 100%;
	gap: 36px;
	flex: 1;
	overflow: hidden;

	.divider {
		background: var(--color-silver, #f3f4f8);
		align-self: stretch;
		flex-shrink: 0;
		width: 1px;
		position: relative;
	}

	.wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-end;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;
		flex: 1;
		overflow: auto;
	}

	.submission-list {
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		flex: 1;
		height: 100%;
		overflow: hidden;
	}

	.score-title {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h4-font-size, 20px);
		line-height: var(--h4-line-height, 32px);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.score-list {
		flex: 2;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-end;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}
`;
const desktopStyles = css `
	display: flex;
	flex-direction: column;
	gap: 0px;
	align-items: flex-end;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	width: 100%;
	gap: 36px;
	flex: 1;
	overflow: hidden;

	.wrap {
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-end;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;
		flex: 1;
		overflow: auto;
	}

	.submission-list {
		box-sizing: border-box;
		border-radius: 10px;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 1px;
		padding: 24px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		flex: 1;
		height: 100%;
		overflow: hidden;
	}

	.score-title {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h4-font-size, 20px);
		line-height: var(--h4-line-height, 32px);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.score-list {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-end;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}
`;
export const DSTL001_Submit_Detail = React.memo(({ isTest, test_id, isTeacher }) => {
    const { isTablet, isMobile } = useGlobalState();
    const [isDetail, setIsDetail] = useState();
    const { t } = useTranslation();
    const [member, setMember] = useState(undefined);
    const renderSubmissionList = useMemo(() => (React.createElement("div", { className: "submission-list", key: 'submitList' },
        React.createElement(SubmissionStatusTable, { isTest: isTest, test_id: test_id, isDetail: true, isPopUp: true, handlerUpdateMember: setMember }))), [isTest, test_id, isDetail]);
    const renderSubmitView = useMemo(() => (React.createElement("div", { className: "score-list", key: 'submitView' },
        React.createElement(DSTL001_Submit_View, { hideTitle: false, test_id: test_id, member: member, isTeacher: isTeacher, isDetail: true }))), [test_id, member, isTeacher]);
    if (isMobile) {
        return (React.createElement(DSTL001SubmitDetailViewStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: member
                    ? [
                        {
                            id: 6,
                            component: [renderSubmitView],
                            isDetail: true,
                        },
                    ]
                    : [
                        {
                            id: 7,
                            component: [renderSubmissionList],
                            isDetail: false,
                        },
                    ], showPageButton: false, isSubmit: true, previousPageList: {
                    title: t('assignment.goBack'),
                    menuDetail: 'detail',
                    setMember: setMember,
                    props: { mobilePage: 1 },
                }, test_id: test_id })));
    }
    return (React.createElement(DSTL001SubmitDetailViewStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
        React.createElement("div", { className: "wrap" },
            renderSubmissionList,
            isTablet && React.createElement("div", { className: "divider" }),
            renderSubmitView)));
});
