import { getClassesId } from '../../features/base/classes';
import { fetchData, objectToQueryString, useCustomQuery } from '../functions';
import { getAccessToken } from '../../features/base/account';
const getToken = () => getAccessToken(APP.store.getState);
export const getNoticeList = async (queryString) => {
    const class_id = getClassesId();
    const url = `/api/mimac/v1/posts?${queryString}&class_id=${class_id}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (게시글 등록) postsRegist
export const registPosts = async (data) => {
    const url = `/api/mimac/v1/posts`;
    // console.log(getToken());
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
    });
};
// (게시글 수정) postsModify
export const postUpdatePosts = async (data) => {
    const url = `/api/mimac/v1/posts/${data.id}/update`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data.props),
    });
};
// (게시글 삭제) postsModify
export const postDeletePosts = async (id) => {
    const url = `/api/mimac/v1/posts/${id}/delete`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
export const useNoticeList = (data) => {
    const queryString = objectToQueryString(data); // props를 쿼리 문자열로 변환
    return useCustomQuery([`notice`, data], () => getNoticeList(queryString));
};
