import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { getIsTeacher, getUserInfo } from '../../account';
import { ClassesDropDown } from '../../classes';
import { getClasses } from '../../classes/functions';
import { useConferenceList } from '../../../../server';
import moment from 'moment';
import { Button } from '../../../../common';
import { goToConference } from '../../../DST/DSTT001/funtions';
import { LiveEnquiries } from './LiveEnquiries';
import { TOGGLE_POPUP } from '../../popup';
import { REFRESH_POSTS_LIST } from '../../../DST/DSTSL001/constant';
import { REFRESH_ASSIGNMENT_LIST } from '../../../DST/DSTL001/constant';
const ProfileStyled = styled.div `
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	align-self: stretch;
	position: relative;
	width: 80%;

	.btn-classroom-in {
		&.on {
			background: linear-gradient(
				94.13deg,
				rgba(70, 148, 157, 1) 0%,
				rgba(37, 132, 143, 1) 100%
			);
		}
		&.disabled {
			background: var(--color-w_grey, #b6bac0);
		}
	}

	.btn-classroom-personal {
		&.on {
			background: linear-gradient(
				91.14deg,
				rgba(243, 244, 248, 1) 0%,
				rgba(218, 221, 237, 1) 100%
			);
			p {
				color: var(--color-black, #000000);
			}
		}
		&.disabled {
			background: var(--color-w_grey, #83868a);
			p {
				color: var(--color-white, #ffffff);
			}
		}
	}

	.disabled {
		cursor: default;
	}

	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileStyles = css `
	padding: 24px 0 0;
	gap: 24px;

	.left {
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}

	.profile {
		display: flex;
		flex-direction: column;
		gap: 4px;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}
	.name-info {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}
	.name {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: 'NotoSansKr-SemiBold', sans-serif;
		font-size: 26px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 600;
		position: relative;
	}
	.id {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: 'NotoSansKr-SemiBold', sans-serif;
		font-size: 26px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 600;
		position: relative;
	}
	.alram-today {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}
	.btn-today {
		background: #2c6273;
		border-radius: 100px;
		padding: 12px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		width: 69px;
		height: 34px;
	}
	.today {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h3-font-size, 16px);
		line-height: 150%;
		font-weight: var(--h3-font-weight, 700);
		position: relative;
	}
	.txt_live {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h3-font-size, 16px);
		line-height: 150%;
		font-weight: var(--h3-font-weight, 700);
		position: relative;
	}
	.class-info {
		background: rgba(16, 16, 16, 0.3);
		border-radius: 16px;
		padding: 20px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		box-shadow: 0px 0px 12px 0px rgba(32, 32, 32, 0.25);
	}

	.subject {
		display: flex;
		flex-direction: column;
		gap: 4px;
		align-items: flex-start;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.title {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 700;
			position: relative;
			align-self: stretch;
		}
		.txt2 {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: 'NotoSansKr-Regular', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
			align-self: stretch;
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		.btn-classroom-personal {
			/* background: linear-gradient(
				91.14deg,
				rgba(243, 244, 248, 1) 0%,
				rgba(218, 221, 237, 1) 100%
			); */
			border-radius: 14px;
			padding: 16px 68px 16px 68px;
			display: flex;
			flex-direction: row;
			gap: 9px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			width: 100%;

			p {
				font-size: 16px;
			}

			&.on {
				p {
					color: var(--color-grey, #666666);
				}
			}
		}

		.btn-classroom-in {
			border-radius: 16px;
			padding: 16px 10px 16px 10px;
			display: flex;
			flex-direction: row;
			gap: 9px;
			align-items: flex-start;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			width: 100%;

			p {
				color: var(--color-white, #ffffff);
			}
		}

		.overText {
			text-align: center;
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-size: 20px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 700;
			position: relative;
		}
	}
`;
const tabletStyles = css `
	padding: 60px 0 0;

	.left {
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		min-width: 53%;
		max-width: 53%;
	}

	.profile {
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}
	.name-info {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}
	.name {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: 'NotoSansKr-SemiBold', sans-serif;
		font-size: 30px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 600;
		position: relative;
	}
	.id {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: 'NotoSansKr-SemiBold', sans-serif;
		font-size: 30px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 600;
		position: relative;
	}
	.alram-today {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}
	.btn-today {
		background: #2c6273;
		border-radius: 100px;
		padding: 6px 12px 6px 12px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
	}
	.today {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: 'NotoSansKr-Bold', sans-serif;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
	}
	.txt_live {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: 'NotoSansKr-Bold', sans-serif;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		width: 100%;
	}
	.class-info {
		background: rgba(16, 16, 16, 0.3);
		border-radius: 16px;
		padding: 20px 30px 20px 30px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		box-shadow: 0px 0px 12px 0px rgba(32, 32, 32, 0.25);
	}
	.subject {
		display: flex;
		flex-direction: row;
		gap: 19px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.title {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-size: 16px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 700;
			position: relative;
			width: 66px;
		}
		.txt2 {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: 'NotoSansKr-Regular', sans-serif;
			font-size: 16px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
			flex: 1;
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		min-width: 33%;

		.btn-classroom-personal {
			/* background: linear-gradient(
				91.14deg,
				rgba(243, 244, 248, 1) 0%,
				rgba(218, 221, 237, 1) 100%
			); */
			border-radius: 14px;
			padding: 16px 68px 16px 68px;
			display: flex;
			flex-direction: row;
			gap: 9px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			width: 100%;
		}
		.txt2 {
			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
			font-size: var(--h3-font-size, 24px);
			line-height: var(--h3-line-height, 36px);
			font-weight: var(--h3-font-weight, 700);
			position: relative;
		}

		.btn-classroom-in {
			border-radius: 16px;
			padding: 32px 10px 32px 10px;
			display: flex;
			flex-direction: row;
			gap: 9px;
			align-items: flex-start;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			width: 100%;

			p {
				color: var(--color-white, #ffffff);
				text-align: center;
				font-family: 'NotoSansKr-Bold', sans-serif;
				font-size: 24px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 700;
				position: relative;
			}
		}
	}
`;
const desktopStyles = css `
	padding: 60px 0 0;
	.left {
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		min-width: 60%;
	}

	.profile {
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}
	.name-info {
		display: flex;
		flex-direction: row;
		gap: 9px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}
	.name {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: 'NotoSansKr-SemiBold', sans-serif;
		font-size: 50px;
		line-height: 78px;
		font-weight: 600;
		position: relative;
	}
	.id {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: 'NotoSansKr-SemiBold', sans-serif;
		font-size: 50px;
		line-height: 78px;
		font-weight: 600;
		position: relative;
	}
	.alram-today {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		max-width: 620px;
	}
	.btn-today {
		background: #2c6273;
		border-radius: 100px;
		padding: 10px 20px 10px 20px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
	}
	.today {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h3-font-size, 24px);
		line-height: var(--h3-line-height, 36px);
		font-weight: var(--h3-font-weight, 700);
		position: relative;
	}
	.txt_live {
		color: var(--color-white, #ffffff);
		text-align: left;
		font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h3-font-size, 24px);
		line-height: var(--h3-line-height, 36px);
		font-weight: var(--h3-font-weight, 700);
		position: relative;
	}
	.class-info {
		background: rgba(16, 16, 16, 0.3);
		border-radius: 16px;
		padding: 28px 32px 28px 32px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		box-shadow: 0px 0px 12px 0px rgba(32, 32, 32, 0.25);
		max-width: 620px;
	}
	.subject {
		display: flex;
		flex-direction: row;
		gap: 19px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.title {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-size: var(--h4-font-size, 20px);
			line-height: 42px;
			font-weight: 700;
			position: relative;
			min-width: 60px;
		}
		.txt2 {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: 'NotoSansKr-Regular', sans-serif;
			font-size: var(--h4-font-size, 20px);
			line-height: 42px;
			font-weight: 400;
			position: relative;
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.btn-classroom-personal {
			/* background: linear-gradient(
				91.14deg,
				rgba(243, 244, 248, 1) 0%,
				rgba(218, 221, 237, 1) 100%
			); */
			border-radius: 14px;
			padding: 16px 68px 16px 68px;
			display: flex;
			flex-direction: row;
			gap: 9px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			width: 100%;
		}
		.txt2 {
			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
			font-size: var(--h3-font-size, 24px);
			line-height: var(--h3-line-height, 36px);
			font-weight: var(--h3-font-weight, 700);
			position: relative;
		}

		.btn-classroom-in {
			border-radius: 16px;
			padding: 46px 68px 46px 68px;
			display: flex;
			flex-direction: row;
			gap: 9px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			width: 100%;

			p {
				color: var(--color-white, #ffffff);
				text-align: center;
				font-family: 'NotoSansKr-Bold', sans-serif;
				font-size: 40px;
				line-height: 46px;
				font-weight: 700;
				position: relative;
			}
		}
	}
`;
const hideName = (name) => {
    const hiddenPart = name.slice(3); // 문자열의 세 번째 인덱스부터 끝까지 추출
    const maskedPart = '*'.repeat(hiddenPart.length); // 추출한 부분의 길이만큼 '*' 문자를 반복
    const maskedString = name.slice(0, 3) + maskedPart; // 첫 세 글자와 가려진 부분을 결합하여 최종 결과 생성
    return maskedString;
};
export const Profile = React.memo(() => {
    const navigate = useNavigate();
    const { classesId, isMobile, isTablet } = useGlobalState();
    const user = useSelector((state) => getUserInfo(state));
    const classes_data = useSelector((state) => getClasses(state));
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const [classes, setClasses] = useState(classesId);
    const [liveRoomName, setLiveRoomName] = useState();
    const [isLiveRoom, setIsLiveRoom] = useState();
    const [isOpenEnquiries, setIsOpenEnquiries] = useState(false);
    const [liveRoomId, setLiveRoomId] = useState();
    const [indviualLive, setIndviualLive] = useState([]);
    const [openLive, setOpenLive] = useState([]);
    const [isOpnenLive, setIsOpnenLive] = useState(false);
    // 현재 시간
    const startOfDay = useMemo(() => moment().startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'), []);
    // 오늘의 끝 시간 (23:59:59)
    const endOfDay = useMemo(() => moment().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'), []);
    const { data, refetch } = useConferenceList({
        start_before: endOfDay,
        start_after: startOfDay,
        orderby: ['startline DESC'],
    });
    useEffect(() => {
        APP.eventManager.publish(REFRESH_POSTS_LIST);
        APP.eventManager.publish('REFETCH_QNA_LIST');
        APP.eventManager.publish(REFRESH_ASSIGNMENT_LIST);
        APP.eventManager.publish('REFRESH_REPLY_LIST');
    }, [classesId]);
    useEffect(() => {
        refetch();
    }, [classesId, endOfDay, startOfDay]);
    useEffect(() => {
        if (data) {
            const { total_count, items } = data;
            const now = moment();
            if (total_count > 0) {
                //setIsLiveRoom(moment().isSame(items.start_time, 'day'));
                //1:1 질의응답 활성화
                setIsOpenEnquiries(items[total_count - 1].status === 'closed' ? true : false);
                const closed_items = items.filter(i => ['closed'].includes(i.status));
                setIndviualLive(closed_items);
                const filter_items = items.filter(i => ['held', 'booked'].includes(i.status));
                setOpenLive(filter_items);
                if (filter_items && filter_items.length > 0) {
                    // 현재 시간
                    // `start_time`이 현재 시간과 가장 가까운 값을 찾기
                    const closestData = filter_items.reduce((closest, current) => {
                        const currentDiff = Math.abs(moment(current.start_time)
                            .subtract(current.pre_entering_duration, 'minutes')
                            .diff(now));
                        const closestDiff = Math.abs(moment(closest.start_time)
                            .subtract(closest.pre_entering_duration, 'minutes')
                            .diff(now));
                        return currentDiff < closestDiff ? current : closest;
                    });
                    // 시간 차이를 밀리초 단위로 계산
                    const diffMilliseconds = moment(closestData.start_time).diff(now, 'minutes');
                    const isOpen = diffMilliseconds < closestData.pre_entering_duration &&
                        closestData.status !== 'closed'
                        ? true
                        : false;
                    setLiveRoomId(closestData.id);
                    setIsOpnenLive(isOpen);
                    setLiveRoomName(closestData.title);
                }
                return;
            }
        }
        setLiveRoomId(undefined);
        setLiveRoomName(undefined);
    }, [data]);
    const { userName, userId } = useMemo(() => {
        if (!user) {
            return {
                userName: '',
                userId: '',
            };
        }
        else {
            return {
                userName: user.nickname,
                userId: hideName(user.auth_name),
            };
        }
    }, [user]);
    const classesTitle = useMemo(() => {
        return !classes_data
            ? ''
            : classes_data?.title +
                (classes_data?.subject ? '(' + classes_data?.subject + ')' : '');
    }, [classes_data]);
    const openEnquiries = () => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            className: `${indviualLive.length === 0 ? 'home-live-empty' : 'home-live'} ${isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'}`,
            component: LiveEnquiries,
            componentProps: {
                liveRoom: indviualLive,
            },
            title: '1:1 Live 문의',
            height: 'auto',
        });
    };
    useEffect(() => {
        if (!classes || classesId === classes || classes === 'select')
            return;
        navigate(`/class/${classes}`);
    }, [classes]);
    if (!user || !classes_data)
        return null;
    return (React.createElement(ProfileStyled, { "$isMobile": isMobile, "$isTablet": isTablet },
        React.createElement("div", { className: "left" },
            React.createElement("div", { className: "profile" },
                React.createElement("div", { className: "name-info" },
                    React.createElement("div", { className: "name" }, userName),
                    React.createElement("div", { className: "id" },
                        "(",
                        userId,
                        ")")),
                liveRoomId && (React.createElement("div", { className: "alram-today" },
                    React.createElement("div", { className: "btn-today" },
                        React.createElement("div", { className: "today" }, "TODAY")),
                    React.createElement("div", { className: "txt_live overText2" }, liveRoomName)))),
            React.createElement("div", { className: "class-info" },
                React.createElement(ClassesDropDown, { userId: user.id, userTenantId: user.tenant_id, defaultClasses: classesId || 'select', setClasses: setClasses }),
                React.createElement("div", { className: "subject" },
                    React.createElement("div", { className: "title" }, "\uAC15\uC88C\uBA85"),
                    React.createElement("div", { className: "txt2 overText2" }, classesTitle)))),
        React.createElement("div", { className: "right" },
            React.createElement(Button, { className: `btn-classroom-in ${(isTeacher || isOpnenLive) && liveRoomId ? 'on' : 'disabled'}`, text: "Live \uAC15\uC758\uC2E4 \uC785\uC7A5", onClick: () => (isTeacher || isOpnenLive) && liveRoomId && goToConference(liveRoomId) }),
            React.createElement(Button, { className: `btn-classroom-personal ${indviualLive.length > 0 ? 'on' : 'disabled'}`, text: "1:1 \uB77C\uC774\uBE0C \uC9C8\uC758\uC751\uB2F5", onClick: () => indviualLive.length > 0 && openEnquiries() }))));
});
