import * as React from 'react';
import { ResponsivePie } from '@nivo/pie';
const PieChart = ({ data }) => {
    const handle = {
        padClick: (data) => {
            console.log(data);
        },
        legendClick: (data) => {
            console.log(data);
        },
    };
    return (React.createElement("div", { style: { width: '100%', height: '200px', margin: '0 auto' } },
        React.createElement(ResponsivePie
        /**
         * chart에 사용될 데이터
         */
        , { 
            /**
             * chart에 사용될 데이터
             */
            data: data, 
            /**
             * chart margin
             */
            margin: { top: 20, right: 0, bottom: 40, left: 0 }, 
            /**
             * chart 중간 빈공간 반지름
             */
            innerRadius: 0.5, 
            /**
             * chart 색상
             */
            colors: { scheme: 'nivo' }, 
            /**
             * pad border 두께 설정
             */
            borderWidth: 1, 
            /**
             * link label skip할 기준 각도
             */
            arcLinkLabelsSkipAngle: 0, 
            /**
             * link label 색상
             */
            arcLinkLabelsTextColor: "#000000", 
            /**
             * link label 연결되는 선 두께
             */
            arcLinkLabelsThickness: 2, 
            /**
             * link label 연결되는 선 색상
             */
            arcLinkLabelsColor: { from: 'color' }, 
            /**
             * label (pad에 표현되는 글씨) skip할 기준 각도
             */
            arcLabelsSkipAngle: 10, theme: {
                /**
                 * label style (pad에 표현되는 글씨)
                 */
                labels: {
                    text: {
                        fontSize: 14,
                        fill: '#000000',
                    },
                },
                /**
                 * legend style (default로 하단에 있는 색상별 key 표시)
                 */
                legends: {
                    text: {
                        fontSize: 12,
                        fill: '#000000',
                    },
                },
            }, 
            /**
             * pad 클릭 이벤트
             */
            onClick: handle.padClick, 
            /**
             * legend 설정 (default로 하단에 있는 색상별 key 표시)
             */
            legends: [] })));
};
export default PieChart;
