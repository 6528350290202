import { default as React } from 'react';
import styled from 'styled-components';
import { ReservationTable } from '../../DST/DSTT001/component/DSTT001_List_Child';
export const LiveRoomListStyled = styled.div `
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 40px;
	height: 100%;
	overflow: auto;
	.live-class-reservation {
		min-height: auto;
		width: 100%;
		height: 100%;
		gap: 25px;
		flex: 1;
		overflow: hidden;
		min-height: 467px;

		.task-board-container {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 40px;
			flex: 1;
			overflow: hidden;
			height: 100%;
		}

		.table {
			width: 100%;
			height: 100%;
			background: #fff;
			padding: 40px;
			border-radius: 23px;
			overflow: hidden;
			flex: 1;
			overflow: hidden;
			height: 100%;
		}
	}
	.live-class-review {
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		min-height: 200px;
		width: 100%;
	}
`;
export const DSST001_List = React.memo(() => {
    return (React.createElement(LiveRoomListStyled, null,
        React.createElement("div", { className: "live-class-reservation" },
            React.createElement(ReservationTable, { isTeacher: false, isClosed: false }))));
});
