import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../Colors';
import Icon from './Icon';
import InputElement from './Input';
// CheckBoxElement 컴포넌트를 메모화하여 동일한 속성으로 렌더링 재사용
export const CheckBoxElement = React.memo(({ style, isShowCheckBox = false, id, className, iconSize = 25, isRadioButton = false, title, checked, option, handlerCheck, isDisable, }) => {
    // 언어 번역을 위한 훅 사용
    const { t } = useTranslation();
    // 체크 상태 토글 함수
    const toggleChecked = e => {
        e.stopPropagation(); // Stop propagation to prevent bubbling
        handlerCheck(id, !checked);
    };
    const handleClick = e => {
        e.stopPropagation(); // Stop propagation to prevent bubbling
    };
    // 컴포넌트 렌더링
    return (React.createElement("label", { className: `checkbox-item ${className}`, style: style },
        React.createElement("input", { type: "checkbox", disabled: isDisable, checked: checked, onChange: toggleChecked, onClick: handleClick }),
        React.createElement(Icon, { icon: checked
                ? isRadioButton && !isShowCheckBox
                    ? 'radio_checked'
                    : 'checked'
                : isRadioButton && !isShowCheckBox
                    ? 'radio_unchecked'
                    : 'unchecked', size: iconSize, fill: Colors.transparent }),
        !option?.input?.show && title && React.createElement("span", null,
            t(title),
            " "),
        option?.input?.show && (React.createElement(InputElement, { className: option.input?.className, type: "text", placeholder: option?.input?.placeholder, defaultValue: title, onChange: option?.input?.onChange
                ? value => option?.input?.onChange(id, value)
                : undefined, isDisable: isDisable }))));
});
export const CheckBox = forwardRef(({ className, isRadioButton = false, isShowCheckBox = false, iconSize = 25, defaultCheckId = null, list, option, handlerCheck, isDisable, }, ref) => {
    const [selectedId, setSelectedId] = useState(null);
    const localRef = useRef(null);
    const handleCheck = (id, checked) => {
        if (isRadioButton) {
            if (checked) {
                setSelectedId(id);
                handlerCheck(id, true);
            }
        }
        else {
            handlerCheck(id, checked);
            if (checked) {
                setSelectedId(id);
            }
            else {
                setSelectedId(null);
            }
        }
    };
    useImperativeHandle(ref, () => ({
        reset: () => {
            setSelectedId(null);
        },
        ...localRef.current, // HTMLDivElement 속성을 포함
    }));
    useEffect(() => {
        if (selectedId !== defaultCheckId)
            setSelectedId(defaultCheckId);
    }, [defaultCheckId]);
    useEffect(() => {
        if (isRadioButton) {
            const checkedItem = list.find(item => item.checked);
            if (checkedItem && checkedItem.id !== selectedId) {
                setSelectedId(checkedItem.id);
            }
        }
    }, [list, isRadioButton]);
    useImperativeHandle(ref, () => ({
        reset: () => {
            setSelectedId(null);
        },
        ...localRef.current, // HTMLDivElement 속성을 포함
    }));
    return (React.createElement("div", { ref: localRef, className: `checkbox ${className}` }, list.map(item => (React.createElement(CheckBoxElement, { key: item.id, iconSize: iconSize, option: option, isRadioButton: isRadioButton, isShowCheckBox: isShowCheckBox, ...item, checked: isRadioButton ? selectedId === item.id : item.checked, handlerCheck: handleCheck, isDisable: isDisable })))));
});
