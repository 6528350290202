import throttle from 'lodash/throttle';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Colors } from '../../../Colors';
import { useGlobalState } from '../../../GlobalStateProvider';
import { Icon, SearchBar, Table } from '../../../common';
import { useNoticeList } from '../../../server/posts';
import { DSTSL001_Detail } from '../../DST/DSTSL001/DSTSL001_Detail';
import { DeleteResourceItem } from '../../DST/DSTSL001/DSTSL001_List_Child/DeleteResourceItem';
import { REFRESH_POSTS_LIST } from '../../DST/DSTSL001/constant';
import { changeMemuProps, updateMenu } from '../../menu/action';
const OpenTableStyled = styled.div `
	.item {
		.pink {
			color: var(--color-pink, #f46790);
			text-align: center;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
		}

		.purple {
			color: var(--pur-d, #8268d9);
			text-align: center;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
		}
	}

	.resource-content {
		width: 100%;
	}

	.notice-list-001 {
		width: 100%;
	}

	${props => (props.$isDesktop ? desktopStyles : props.$isTablet ? tabletStyles : mobileStyles)}
`;
const mobileStyles = css `
	display: flex;
	width: 100%;
	flex-direction: column;
	/* height: 100%; */
	&.home {
		height: 100%;
	}

	.notice-list-001 {
		display: flex;
		flex-direction: column;
		gap: ${props => (props.$isTeacher ? '24px' : '0px')};
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;

		.table-header {
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b3-font-size, 16px);
				line-height: var(--b3-line-height, 32px);
				font-weight: var(--b3-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: center;
			align-self: stretch;
			flex: 1;
			position: relative;
			box-sizing: border-box;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: ${props => (props.$isTeacher ? '1px 0px 0px 0px' : '0px 0px 1px 0px')};
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			width: 100%;

			.file {
				text-align: left;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;

				p {
					min-width: auto;
					font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
					font-size: var(--h5-font-size, 12px);
					line-height: 150%;
					font-weight: var(--h5-font-weight, 400);
				}
			}

			font-size: var(--b3-font-size, 12px);
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
		}
	}

	.home-list-003 {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 1px 0px 0px 0px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 14px);
			line-height: 150%;
			letter-spacing: var(--b3-letter-spacing, -0.02em);
			font-weight: var(--b3-font-weight, 400);
		}
	}

	.notice-info-wrap {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.view-wrap {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	.resource-content {
		width: 100%;
	}
`;
const tabletStyles = css `
	display: flex;
	width: 100%;
	flex-direction: column;

	&.home {
		height: 100%;
	}

	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: ${props => (props.$isTeacher ? 'space-between' : 'flex-end')};
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.empty_data {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.home-list-003 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b3-font-size, 14px);
				line-height: 150%;
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			flex: 1;
			position: relative;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 14px);
			line-height: 150%;
			letter-spacing: var(--b3-letter-spacing, -0.02em);
			font-weight: var(--b3-font-weight, 400);
		}

		.body-item {
			overflow: hidden;
		}
	}

	.notice-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
		height: unset;

		.body-item {
			overflow: hidden;
		}

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 12px 20px 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: center;
				font-family: 'NotoSansKr-Regular', sans-serif;
				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			align-self: stretch;
			flex: 1;
			position: relative;
			box-sizing: border-box;
			height: unset;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 20px;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			width: 100%;

			color: var(--color-black, #101010);
			font-family: 'NotoSansKr-Regular', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;

			.file {
				text-align: left;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;

				p {
					min-width: auto;
					color: var(--color-grey-w, #b6bac0);
					font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
					font-size: var(--h5-font-size, 14px);
					line-height: 150%;
					font-weight: var(--h5-font-weight, 700);
				}
			}
		}
	}

	.resource-content {
		width: 100%;
	}
`;
const desktopStyles = css `
	display: flex;
	width: 100%;
	flex-direction: column;
	height: 100%;

	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: ${props => (props.$isTeacher ? 'space-between' : 'flex-end')};
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.home-list-003 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 6px 28px 6px 28px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b3-font-size, 16px);
				line-height: var(--b3-line-height, 32px);
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			flex: 1;
			position: relative;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 10px 28px 10px 28px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			letter-spacing: var(--b3-letter-spacing, -0.02em);
			font-weight: var(--b3-font-weight, 400);
		}

		.body-item {
			overflow: hidden;
		}
	}

	.notice-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
		height: unset;

		.body-item {
			overflow: hidden;
		}

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 6px 28px 6px 28px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			flex: 1;
			position: relative;
			/* overflow: auto; */
			box-sizing: border-box;
			align-self: stretch;
			height: unset;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 20px 28px 20px 28px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.file {
				text-align: left;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;

				p {
					min-width: auto;
					color: var(--color-grey-w, #b6bac0);
					font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
					font-size: var(--h5-font-size, 16px);
					line-height: var(--h5-line-height, 24px);
					font-weight: var(--h5-font-weight, 700);
				}
			}

			color: var(--color-black, #101010);
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
		}
	}

	.body-item {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
`;
const addUniqueNumbers = (arr, startNumber = 0, important_count = 0) => {
    let index = 0;
    return arr.map(item => {
        if (item.importance)
            return { ...item, number: -1 };
        else {
            index += 1;
            return {
                ...item,
                number: startNumber + index,
            };
        }
    });
};
export const OpenTable = React.memo(({ isResource = false, isTeacher = false, isHome = false, defaultOpen, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const [selectedId, setSelectedId] = useState();
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [count, setCount] = useState(isHome ? 5 : 10);
    const [checkedList, setCheckedList] = useState([]);
    const [searchOption, setSearchOption] = useState({});
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = isMobile && isTeacher
            ? []
            : [
                ...(isHome
                    ? [
                        { title: 'no', space: 1, maxWidth: 45 },
                        { title: 'content', space: 2, sort: false },
                        { title: 'writeData', space: 1 },
                    ]
                    : [
                        { title: 'no', space: 1, maxWidth: 45 },
                        { title: 'content', space: 4, sort: false },
                        ...(isMobile
                            ? []
                            : [
                                { title: 'attachFile', space: 1 },
                                { title: 'views', space: 1 },
                                ...(isTeacher
                                    ? [{ title: 'author_name', space: 1 }]
                                    : []),
                            ]),
                        { title: 'creation_time', space: 1 },
                    ]),
            ];
        const tableReadDataKeys = [
            ...(isHome
                ? [
                    { title: 'no', space: 1, maxWidth: 45 },
                    { title: 'title', space: 2, sort: false },
                    { title: 'creation_time', space: 1 },
                ]
                : [
                    { title: 'no', space: 1, maxWidth: 45 },
                    { title: 'title', space: 4 },
                    ...(isMobile
                        ? []
                        : [
                            { title: 'files', space: 1 },
                            { title: 'views', space: 1 },
                            ...(isTeacher ? [{ title: 'author_name', space: 1 }] : []),
                        ]),
                    { title: 'creation_time', space: 1 },
                ]),
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, [isHome]);
    const { data, refetch } = useNoticeList({
        ...(searchOption ? { ...searchOption } : {}),
        type: isResource ? 'study_data' : 'notice',
        offset: page === 0 ? 0 : page * count,
        limit: count,
        orderby: ['importance DESC', 'creation_time DESC'],
    });
    // (리스트 갱신
    const refetchList = throttle(() => {
        refetch();
    }, 1000, { leading: true, trailing: false });
    // 이벤트 등록 - 리스트 갱신
    useEffect(() => {
        APP.eventManager.subscribe(REFRESH_POSTS_LIST, refetchList);
        return () => {
            APP.eventManager.unsubscribe(REFRESH_POSTS_LIST, refetchList);
        };
    }, []);
    useEffect(() => {
        return () => {
            setSearchOption({});
        };
    }, [isResource]);
    useEffect(() => {
        if (selectedId !== defaultOpen)
            setSelectedId(defaultOpen);
    }, [defaultOpen]);
    useEffect(() => {
        if (data) {
            const { total_count, found_count, items, important_count } = data;
            if (found_count === 0 && page > 0) {
                setPage(Math.max(page - 1, 0));
                return;
            }
            const numbersItem = addUniqueNumbers(items, page === 0 ? 0 : page * count - important_count);
            setTotalCount(isHome ? Math.min(total_count, 5) : total_count);
            setTestData(numbersItem);
            return;
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    useEffect(() => {
        if (isHome) {
            setCount(5);
        }
        else {
            setCount(10);
        }
    }, [isHome, isResource]);
    useEffect(() => {
        if (count <= 0)
            return;
        refetchList();
    }, [count, page, searchOption, isResource, selectedId]);
    const handlerMoreData = (page) => {
        if (isHome) {
            return;
        }
        setPage(page);
    };
    // 상단 레이아웃 검색 조건을 위한 action
    const handlerFilterList = (filter) => {
        setSearchOption(filter);
    };
    useEffect(() => {
        if (page !== 0)
            setPage(0);
    }, [searchOption]);
    const renderTitle = useMemo(() => {
        return (React.createElement("div", { className: "header", style: {
                alignSelf: !isTeacher && 'flex-end',
                width: '100%',
            } },
            isTeacher && !isMobile && (React.createElement(DeleteResourceItem, { isOneDel: false, menu: isResource ? 'resource' : 'notice', deleteList: checkedList })),
            React.createElement(SearchBar, { className: `${isDesktop ? 'search-bar-big' : 'search-bar-mobile'}`, filterData: "title", defaultSearchContent: searchOption['title'], handlerFilterList: handlerFilterList })));
    }, [isTeacher, isResource, checkedList, isDesktop, searchOption]);
    const renderMoreItem = (item, styles) => {
        return (React.createElement("div", { className: "resource-content" },
            React.createElement(DSTSL001_Detail, { item: item, isResource: isResource, isTeacher: isTeacher, selectedId: selectedId })));
    };
    const handlerSelectItem = (item) => {
        if (!item)
            return;
        if (isHome) {
            dispatch(updateMenu('learningSupportRoom', { menu: isResource ? 'resource' : 'notice', detail: 'list' }, undefined, {
                defaultOpen: item.id,
            }));
            return;
        }
        const newId = selectedId === item.id ? undefined : item.id;
        setSelectedId(newId);
        dispatch(changeMemuProps({ defaultOpen: newId }));
    };
    const renderItem = (item, styles) => {
        if (!item)
            return;
        const isSelcted = item.id === selectedId;
        //detail 페이지가 열릴 경우 폰트 색상 변경
        const isHighlighted = selectedId === item?.id;
        const fontStyle = {
            color: isHighlighted ? Colors.dark_pur : Colors.black,
            fontWeight: !isMobile && isHighlighted ? 700 : 400,
        };
        const fontStyleMobileTitle = {
            color: isHighlighted ? Colors.dark_pur : Colors.black,
            fontWeight: 600,
            fontSize: '14px',
        };
        const fontStyleMobileGrey = {
            color: isHighlighted ? Colors.dark_pur : Colors.light_grey,
            fontSize: '12px',
        };
        const fillStyle = isHighlighted ? Colors.dark_pur : Colors.light_grey;
        return tableReadDataKeys.map((headItem, index) => (React.createElement("div", { key: index, className: "body-item", style: {
                flex: !isMobile && headItem.space,
                cursor: 'pointer',
                ...styles?.bodyItem,
                maxWidth: headItem?.maxWidth,
                width: isMobile && headItem.title === 'title' ? '100%' : 'unset', //학습지원실 제목 잘리게 하기 위해
            } }, headItem.title === 'no' ? (React.createElement("p", { className: "overText2", style: {
                color: isSelcted && Colors.pur,
                fontWeight: isSelcted && 700,
            } }, item.importance
            ? t('supportRoom.mustRead')
            : !isMobile && item.number)) : headItem.title === 'files' ? (item.files && item.files.length > 0 ? (React.createElement("div", { className: "file", style: { justifyContent: 'unset' } },
            React.createElement(Icon, { icon: "file", size: isTablet ? 14 : 20, fill: isSelcted ? Colors.pur : Colors.w_grey }),
            React.createElement("p", { className: "overText2", style: { color: isSelcted && Colors.pur } },
                "(",
                item.files.length,
                ")"))) : (React.createElement("p", null, " -"))) : headItem.title === 'creation_time' ? (isMobile && !isHome ? (React.createElement("div", { className: "notice-info-wrap", style: fontStyleMobileGrey },
            item.files && item.files.length > 0 && (React.createElement("div", { className: "file" },
                React.createElement(Icon, { icon: "file", size: isTablet ? 14 : isMobile ? 12 : 20, fill: isSelcted ? Colors.pur : Colors.w_grey }),
                React.createElement("p", { className: "overText2" },
                    "(",
                    item.files.length,
                    ")"))),
            React.createElement("div", { className: "view-wrap" },
                React.createElement(Icon, { icon: "view", size: 13, fill: fillStyle }),
                React.createElement("div", null, item['views'])),
            isTeacher && (React.createElement("div", { style: fontStyle }, item['author_name'])),
            React.createElement("p", { className: "overText2" }, moment(item[headItem.title]).format('YYYY-MM-DD')))) : (React.createElement("p", { className: "overText2", style: isMobile && isHome ? fontStyleMobileGrey : fontStyle }, moment(item[headItem.title]).format('YYYY-MM-DD')))) : (React.createElement("p", { className: `
								${headItem.title === 'answerState' &&
                item[headItem.title] === '답변완료'
                ? 'pink'
                : item[headItem.title] === '답변대기'
                    ? 'purple'
                    : ''} ${headItem.title} overText2
								`, style: isMobile ? fontStyleMobileTitle : fontStyle }, headItem.title === 'creation_time'
            ? moment(item[headItem.title]).format('YYYY-MM-DD')
            : item[headItem.title])))), [isResource]);
    };
    const renderEmpty = useMemo(() => totalCount <= 0 && (React.createElement("div", { className: "empty_data", style: { color: Colors.light_grey } }, t(`supportRoom.${isResource ? 'resource' : 'notice'}Empty`))), [isResource, totalCount]);
    const renderMobileDeleteButton = useMemo(() => {
        return (React.createElement(React.Fragment, null,
            React.createElement(DeleteResourceItem, { isOneDel: false, menu: isResource ? 'resource' : 'notice', deleteList: checkedList, styles: { position: 'absolute', top: 60, right: 0, zIndex: 2 } }),
            React.createElement("p", { style: {
                    position: 'absolute',
                    top: 70,
                    left: 27,
                    fontSize: '12px',
                    fontWeight: 400,
                } }, t('assignment.allSelect'))));
    }, [checkedList]);
    return (React.createElement(OpenTableStyled, { "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile, "$isHome": isHome, "$isTeacher": isTeacher, className: `task-board-container open-table ${isHome && 'home'}` },
        isTeacher && isMobile && !isHome && renderMobileDeleteButton,
        React.createElement(Table, { className: isHome ? 'home-list-003 ' : 'notice-list-001', showCheckBox: isTeacher ? true : false, isInifinite: isHome ? true : false, selectedId: selectedId, page: page, data: testData, totalCount: totalCount, headTitleList: headTitleList, renderTitle: !isHome && renderTitle, renderItem: renderItem, renderEmpty: renderEmpty, renderMoreItem: !isHome && renderMoreItem, handlerMoreData: handlerMoreData, handlerCheckList: setCheckedList, handlerSelectItem: handlerSelectItem, forceShowheader: isMobile && !isTeacher ? false : true })));
});
