import { ReducerRegistry, StateListenerRegistry } from '../../../redux/regist';
import { getAccessToken } from '../account';
import { APP_WILL_LOAD, APP_WILL_UNMOUNT } from './actionTypes';
import { closeWindow } from './function';
import { MimacWebsocket } from './websocket';
const initialState = {
    isLoad: false,
};
ReducerRegistry.register('base/app', (state = initialState, action) => {
    switch (action.type) {
        case APP_WILL_LOAD:
            return {
                ...state,
                isLoad: true,
            };
        case APP_WILL_UNMOUNT:
            return {
                ...state,
                isLoad: false,
            };
    }
    return state;
});
let previousAccessToken = null;
let websocket = null;
StateListenerRegistry.register((state) => getAccessToken(state), async (access_token, store) => {
    if (!access_token)
        return;
    if (location.pathname.includes('player'))
        return;
    if (access_token !== previousAccessToken) {
        if (websocket) {
            // Close the existing WebSocket connection
            websocket.connection.close();
            websocket = null;
        }
        try {
            websocket = new MimacWebsocket();
            await websocket.connect(access_token);
            previousAccessToken = access_token; // Update the previous token after successful connection
        }
        catch (error) {
            alert('로그인 오류입니다. 다시 로그인하세요. ' + error);
            console.error('Error in initRefreshToken:', error);
            closeWindow();
        }
    }
}, {
    deepEquals: true,
});
