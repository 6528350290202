// 국어 점수와 배점
export const koreanScores = [{ number: '1~34', type: 'objective', score: 2 }];
export const selectKoreanScores = [
    { number: '35~45', type: 'objective', score: 2 },
];
// 수학 점수와 배점
export const mathScores = [
    { number: '1~2', type: 'objective', score: 2 },
    { number: '3~8', type: 'objective', score: 3 },
    { number: '9~15', type: 'objective', score: 4 },
    { number: '16~19', type: 'subjective', score: 3 },
    { number: '20~22', type: 'subjective', score: 4 },
];
export const selectMathScores = [
    { number: '23', type: 'objective', score: 2 },
    { number: '24~27', type: 'objective', score: 3 },
    { number: '28', type: 'objective', score: 4 },
    { number: '29~30', type: 'subjective', score: 4 },
];
// 영어 점수와 배점
export const englishScores = [{ number: '1~17', type: 'objective', score: 2 }];
export const selectEnglishScores = [
    { number: '18~45', type: 'objective', score: 2 },
];
export const selectHistorytScore = [
    { number: '1~20', type: 'objective', score: 2 },
];
export const selectChatacterScore = [
    { number: '1~30', type: 'objective', score: 2 },
];
// Event Handler
export const REFRESH_ASSIGNMENT_LIST = 'REFRESH_ASSIGNMENT_LIST';
export const TEST_STATUS = {
    CREATED: 'created',
    CLOSED: 'closed',
    BOOKED: 'booked',
    OPEN: 'open',
};
