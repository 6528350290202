import React, { useEffect, useMemo } from 'react';
import { DropDown } from '../../../../common';
import { CONTENTS_DROP_LIST, CONTENTS_DROP_DEFAULT } from '../constants';
export const ContentDropDown = React.memo(({ typeList, setFilterMenu, }) => {
    const [choiceTab, setChoiceTab] = React.useState(CONTENTS_DROP_DEFAULT);
    const handlerDropDown = value => {
        setChoiceTab(value);
    };
    useEffect(() => {
        if (choiceTab.id === 'all') {
            setFilterMenu(typeList);
        }
        else {
            setFilterMenu([choiceTab.id]);
        }
    }, [choiceTab]);
    const list = useMemo(() => CONTENTS_DROP_LIST.filter(item => item.id !== 'all' ? typeList.includes(item.id) : item), [typeList]);
    return (React.createElement(DropDown, { className: "dropdown-live", list: list, value: choiceTab, setDropValue: handlerDropDown }));
});
