import { throttle } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useMemberInfo } from '../../../../../server';
import { getUserId } from '../../../../base/account';
import { getClassesStudnet } from '../../../../base/classes';
import { Button, SearchBar, Table } from '../../../../../common';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { TOGGLE_POPUP } from '../../../../base/popup';
const WatchMemberStyled = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 24px;

	.popup-list-008 {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
		height: unset;
	}

	.table-header {
		background: var(--color-silver, #f3f4f8);
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px 0px 0px 0px;
		padding: 10px 16px 10px 16px;
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		p {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			letter-spacing: var(--b4-letter-spacing, -0.02em);
			font-weight: var(--b4-font-weight, 400);
			position: relative;
		}
	}

	.item {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
		padding: 12px 16px 12px 16px;
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		span {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			letter-spacing: var(--b4-letter-spacing, -0.02em);
			font-weight: var(--b4-font-weight, 400);
		}
	}

	${props => props.$isMobile &&
    css `
			.table-item-wrap {
				.body-item {
					width: unset;
				}
			}
		`}
`;
export const WatchMember = React.memo(({ members, setMembers, }) => {
    const { t } = useTranslation();
    const { isDesktop, isMobile } = useGlobalState();
    const localID = useSelector((state) => getUserId(state));
    const { classesId } = useGlobalState();
    const memberList = useSelector((state) => getClassesStudnet(state), shallowEqual);
    const [page, setPage] = useState(0);
    const [previousPage, setPreviousPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [checkList, setCheckList] = useState([]);
    const [selectedChecklistMembers, setSelectedChecklistMembers] = useState({});
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [choiceMember, setChoiceMember] = useState({});
    const [searchOption, setSearchOption] = useState({});
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        const headTitleList = [
            { title: 'name', space: 2, sort: false },
            { title: 'participant', space: 1, sort: false },
        ];
        const tableReadDataKeys = [
            { title: 'nickname', space: 2, sort: false },
            { title: 'level_name', space: 1, sort: false },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, []);
    // 참가자 목록 가져오기
    const { data, refetch } = useMemberInfo({
        class_id: classesId,
        status: 'created',
        ...searchOption,
        offset: page === 0 ? 0 : page * 10,
        limit: 10,
    });
    const refetchList = throttle(refetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        refetchList();
    }, [page]);
    useEffect(() => {
        if (data) {
            const { items, total_count } = data;
            if (total_count > 0) {
                setTestData(items);
                setTotalCount(total_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    //참여자 선택 시 페이지 이동을 해도 선택된 참여자가 유지되도록
    useEffect(() => {
        setPreviousPage(page);
        if (checkList.length > 0 && previousPage === page) {
            setSelectedChecklistMembers(prevState => ({
                ...prevState,
                [page]: checkList,
            }));
        }
    }, [checkList, page]);
    useEffect(() => {
        setSelectedMembers(testData.filter(member => selectedChecklistMembers[page]?.includes(member.id)));
    }, [selectedChecklistMembers, page, testData]);
    useEffect(() => {
        const newSelected = selectedMembers.map(member => ({
            id: member.id,
            name: member.nickname,
        }));
        setChoiceMember(prevData => ({ ...prevData, [page]: newSelected }));
    }, [selectedMembers, page]);
    const closePopup = () => {
        APP.eventManager.publish(TOGGLE_POPUP);
    };
    const onSubmit = () => {
        const selectedMembers = Object.keys(choiceMember)
            .map(index => choiceMember[index])
            .flat();
        setMembers(selectedMembers);
        closePopup();
    };
    const renderTitle = useMemo(() => {
        return (React.createElement("div", { className: "frame-986964" },
            React.createElement("div", { className: "label" },
                "\uCC38\uAC00\uC790 ",
                checkList.length),
            React.createElement(SearchBar, { className: `${isDesktop ? 'search-bar-big' : 'search-bar-tablet'}`, handlerFilterList: setSearchOption })));
    }, [checkList.length, setSearchOption]);
    const renderItem = (item, styles) => {
        return tableReadDataKeys.map(headItem => (React.createElement("div", { key: headItem.title, className: "body-item", style: { flex: headItem.space, ...styles?.bodyItem } },
            React.createElement("span", null, item[headItem.title]))));
    };
    // const defaultCheckList = useMemo(
    // 	() => members && members.map(member => member.id),
    // 	[members]
    // );
    // list 페이지 업데이트 시
    const handlerMoreData = (page) => {
        setPage(page);
    };
    return (React.createElement(WatchMemberStyled, { "$isMobile": isMobile },
        React.createElement(Table, { className: "popup-list-008", showCheckBox: true, defaultCheckList: selectedChecklistMembers[page] ? selectedChecklistMembers[page] : [], forceShowheader: true, isInifinite: false, data: testData, totalCount: totalCount, page: page, sortDefault: "id", headTitleList: headTitleList, renderItem: renderItem, handlerCheckList: setCheckList, handlerMoreData: handlerMoreData }),
        React.createElement("div", { className: "btn-container" },
            React.createElement(Button, { className: `btn_white ${isMobile ? ' sm' : 'xmd'}`, text: "common.cancel", onClick: closePopup }),
            React.createElement(Button, { className: `btn_default ${isMobile ? ' sm' : 'xmd'}`, text: "common.okay", onClick: onSubmit }))));
});
