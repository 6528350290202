import throttle from 'lodash/throttle';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../../common';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useGlobalState } from '../../../GlobalStateProvider';
import { FileAttach } from '../../../common/option';
import { postDeletePosts, useNoticeList } from '../../../server';
import { DeletePopUpLayout } from '../../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../../base/popup';
import { DSTSL001_Regist } from './DSTSL001_Regist';
import { RegistContent } from './DSTSL001_Regist_Child';
import { REFRESH_POSTS_LIST } from './constant';
const commonStyles = css `
	background: var(--color-silver, #f3f4f8);
	border-radius: 0px 0px 12px 12px;
	width: 100%;

	.attach-files {
		width: 100%;
	}

	.teacher-info {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		padding: 0px 0px 8px 0px;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-end;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		letter-spacing: var(--b4-letter-spacing, -0.02em);
		font-weight: var(--b4-font-weight, 400);
		color: var(--color-grey, #666666);

		.name {
			display: flex;
			flex-direction: row;
			gap: 12px;
		}
	}

	.btn-container {
		padding-top: 0px;
		position: absolute;
		right: 28px;
	}

	.underline {
		text-decoration: underline;
	}

	.green {
		color: var(--tur-d, #479da6);
	}

	.pink {
		color: var(--color-accent, #e7424c);
	}

	.open-contents {
		background: var(--color-white, #ffffff);
		border-radius: 10px;
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: flex-start;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		box-shadow: var(--shadow-bg-box-shadow, 0px 2px 6px 0px rgba(224, 226, 235, 0.4));
		overflow: auto;

		color: var(--natural-color-n-0, #2f2f2f);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
	}

	.notice-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		color: var(--natural-color-n-0, #2f2f2f);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;

		.img_wrap {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.img {
				border-radius: 4px;
				flex-shrink: 0;
				width: 100px;
				height: 100px;
				position: relative;
				object-fit: cover;
				background: #000;
			}
		}
	}

	svg {
		min-width: 16px;
	}

	.btn-container {
		padding: 0;
	}
`;
const mobileStyles = css `
	padding: 10px;

	.open-contents {
		padding: 16px 20px;
	}

	.teacher-info {
		border-width: 0px 0px 0.5px 0px;
		.active-bar {
			.button p {
				min-width: fit-content;
				font-size: 12px;
			}
		}
	}

	.notice-content {
		.img_wrap {
			gap: 8px;
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		}
		.img_pair {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}
`;
const tabletStyles = css `
	padding: 20px;

	.open-contents {
		padding: 24px 28px;
		overflow: hidden;
	}

	.notice-content {
		overflow: auto;
		.img_wrap {
			gap: 12px;
		}
	}

	.teacher-info {
		border-width: 0px 0px 1px 0px;
		.active-bar {
			.button p {
				min-width: fit-content;
			}
		}
	}
`;
const desktopStyles = css `
	padding: 30px;

	.open-contents {
		padding: 24px 28px;
		overflow: hidden;
	}

	.notice-content {
		overflow: auto;
	}

	.teacher-info {
		border-width: 0px 0px 1px 0px;
		.active-bar {
			.button p {
				min-width: fit-content;
			}
		}
	}
`;
const DSTL001DetailStyled = styled.div `
	${commonStyles}
	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
export const DSTSL001_Detail = ({ item, isResource = false, isTeacher, selectedId, }) => {
    const { t } = useTranslation();
    const [testData, setTestData] = React.useState(null);
    const deletePostMutation = useMutation(postDeletePosts);
    //조회수 증가
    const { data, refetch } = useNoticeList({
        id: selectedId,
        with_content: true,
    });
    useEffect(() => {
        selectedId && refetch();
    }, [selectedId]);
    useEffect(() => {
        if (data) {
            const { items } = data;
            if (items && items.length > 0) {
                setTestData(items[0]);
            }
            //APP.eventManager.publish(REFRESH_POSTS_LIST);
        }
    }, [data]);
    // (리스트 갱신
    const refetchList = throttle(() => {
        refetch();
    }, 1000, { leading: true, trailing: false });
    useEffect(() => {
        APP.eventManager.subscribe('REFRESH_POSTS_DETAIL_LIST', refetchList);
        return () => {
            APP.eventManager.unsubscribe('REFRESH_POSTS_DETAIL_LIST', refetchList);
        };
    }, []);
    // //이미지 파일 종류
    // const imageFiles = ['jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg', 'webp', 'jfif'];
    //게시글 삭제
    const handleDelete = () => {
        const onDelete = async () => {
            const id = testData.id;
            try {
                await deletePostMutation.mutateAsync(id);
                APP.eventManager.publish(REFRESH_POSTS_LIST);
            }
            catch (error) {
                console.error('Failed to post deletePost:', error);
                alert(error);
            }
        };
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DeletePopUpLayout,
            componentProps: {
                deleteContent: t('assignment.delteInto', { count: 1 }),
                onDelete,
            },
            width: 639,
            title: t(`supportRoom.${isResource ? 'resource' : 'notice'}Delete`),
            isFit: true,
        });
    };
    //게시글 수정 버튼 클릭 시 팝업창으로 이동
    const handleEdit = () => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DSTSL001_Regist,
            componentProps: {
                item: testData,
                isModify: true,
                isTeacher: isTeacher,
                isResource: isResource,
                isOpen: true,
            },
            width: 800,
            height: 884,
            title: isResource ? t('supportRoom.resourceEdit') : t('supportRoom.noticeEdit'),
        });
    };
    const { isMobile, isTablet } = useGlobalState();
    return (React.createElement(DSTL001DetailStyled, { "$isMobile": isMobile, "$isTablet": isTablet },
        React.createElement("div", { className: "open-contents" },
            React.createElement("div", { className: "notice-content" },
                isTeacher && (React.createElement("div", { className: "teacher-info " },
                    React.createElement("div", { className: "name" },
                        React.createElement("div", { className: "active-bar " },
                            React.createElement(Button, { className: "underline green", text: 'common.modify', onClick: handleEdit })),
                        React.createElement("div", { className: "active-bar " },
                            React.createElement(Button, { className: "underline pink", text: 'common.delete', onClick: handleDelete }))))),
                React.createElement(RegistContent, { isDisbaled: true, hideTitle: true, contentName: t('supportRoom.content'), defaultValue: testData?.content, isTeacher: isTeacher }),
                React.createElement("div", { className: "attach-files" },
                    React.createElement(FileAttach, { id: "file_attache", fileList: testData?.files, isOnlyFile: true, isRegist: false }))))));
};
