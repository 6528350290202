import React from 'react';
import { RegistButton } from '../RegistButton';
import { QnAListTable } from './DTST004_List_Child/QnAListTable';
export const DSTS004_List = React.memo(({ defaultOpen, isTeacher }) => {
    const handlerButtonClick = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'regist',
            id: undefined,
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(QnAListTable, { defaultOpen: defaultOpen, isTeacher: isTeacher }),
        !isTeacher && React.createElement(RegistButton, { handlerButtonClick: handlerButtonClick })));
});
