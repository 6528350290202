import React from 'react';
import { ReplyRoomTable } from './component';
import { RegistButton } from '../RegistButton';
export const DSTT007_List = React.memo(({ isTeacher = false }) => {
    const handlerButtonClick = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'regist',
            id: undefined,
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ReplyRoomTable, { isHome: false }),
        isTeacher && React.createElement(RegistButton, { handlerButtonClick: handlerButtonClick })));
});
