import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { SearchBar } from '../../../../../common';
import { DeleteListBtn } from './DeleteListBtn';
import { FilterType } from './FilterType';
import { StateDropdown } from './StateDropdown';
const Container001Styled = styled.div `
	${props => (props.$isDesktop ? desktopStyles : props.$isTablet ? tabletStyles : mobileStyles)}
`;
const mobileStyles = css `
	&.header {
		display: flex;
		flex-direction: row;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-end;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.active-bar {
			display: flex;
			flex-direction: column;
			gap: 12px;
			align-items: flex-start;
			justify-content: center;
			flex: 1;
			position: relative;
		}

		.drop-wrap {
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}
	}
`;
const tabletStyles = css `
	&.header {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.active-bar {
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-end;
			flex: 1;
			position: relative;
		}
	}
`;
const desktopStyles = css `
	&.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		flex-wrap: wrap;

		.active-bar {
			display: flex;
			flex-direction: row;
			gap: 14px;
			align-items: center;
			justify-content: flex-end;
			flex-shrink: 0;
			position: relative;
			flex-wrap: wrap;
			flex: 1;
		}
	}
`;
export const AssignmentTitle = React.memo(({ isTest = false, isTeacher = false, checkList = [], searchOption, handlerFilterList = (filter) => {
    // console.log(filter);
}, }) => {
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const [type, setType] = React.useState('');
    const [checkItem, setCheckItem] = React.useState('');
    const [initLoad, setInitLoad] = React.useState(true);
    useEffect(() => {
        return () => {
            setType('');
            setCheckItem('');
        };
    }, [isTest]);
    useEffect(() => {
        try {
            const key = Object.keys(searchOption)[0];
            if (key === type) {
                setInitLoad(true);
                return;
            }
            setType(key);
            setCheckItem(searchOption[key] || undefined);
        }
        catch {
            setType('');
            setCheckItem(undefined);
        }
        setInitLoad(true);
        return () => {
            setInitLoad(false);
        };
    }, [searchOption]);
    if (!initLoad)
        return null;
    return (React.createElement(Container001Styled, { className: "header", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        isTeacher && !isMobile ? (React.createElement(DeleteListBtn, { isTest: isTest, isOneDel: false, deleteList: checkList })) : (React.createElement("div", null)),
        React.createElement("div", { className: "active-bar", style: { gap: !isMobile && 14 } },
            isMobile ? (React.createElement("div", { className: "drop-wrap" },
                React.createElement(StateDropdown, { handlerFilterList: handlerFilterList, isState: false, isTest: isTest, isTeacher: isTeacher, type: type === 'status' ? 'title' : type, checkItem: type, setType: setType }),
                type === 'status' && (React.createElement(StateDropdown, { handlerFilterList: handlerFilterList, isState: true, type: type, checkItem: checkItem, isTest: isTest, setType: setType })))) : (React.createElement(React.Fragment, null,
                React.createElement(FilterType, { handlerFilterList: handlerFilterList, isTest: isTest, type: type, setType: setType, searchCheckItem: checkItem, isTeacher: isTeacher }),
                React.createElement(StateDropdown, { handlerFilterList: handlerFilterList, isState: true, isTest: isTest, type: type, checkItem: checkItem, setType: setType }))),
            React.createElement(SearchBar, { className: `${isDesktop ? 'search-bar-big' : 'search-bar-tablet'}`, handlerFilterList: handlerFilterList, filterData: type === 'title' ? 'title' : 'author', isDisable: type === 'status' ? true : false, defaultSearchContent: type === 'status' ? '' : checkItem }))));
});
