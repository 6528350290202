import dashjs from 'dashjs';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getUserInfo } from '../../../../base/account';
import { fetchGetStreamingAuth, getStreaming, playStreaming, stopStreaming } from '../../functions';
import { PlayerWebsoket } from '../../playerWebsoket';
import CustomControls from './CustomControls';
// @ts-ignore
const PlayerStyled = styled.div `
	flex: 1 1 0%;
	position: relative;
	width: 100%;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	background-color: rgb(16, 16, 16);
	height: 100%;
	overflow: hidden;
	justify-content: center;
	align-items: flex-end;
`;
function getStreamingIdFromUrl(url) {
    // URL에서 매개변수를 추출하기 위한 함수
    function getParameterByName(name, url) {
        if (!url)
            url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
        if (!results)
            return null;
        if (!results[2])
            return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    // 주어진 URL에서 streaming_id 매개변수의 값을 가져옵니다.
    let streamingId = getParameterByName('streaming_id', url);
    return streamingId;
}
let player = null;
let websocket = null;
let retryCount = 0;
let timeIntervalId = null;
function getRandomIntervalInMs() {
    // 1분에서 2분 사이의 밀리초 범위
    const minIntervalMs = 1 * 60 * 1000;
    const maxIntervalMs = 2 * 60 * 1000;
    // minIntervalMs와 maxIntervalMs 사이의 랜덤한 밀리초를 생성
    const randomMs = Math.floor(Math.random() * (maxIntervalMs - minIntervalMs + 1)) + minIntervalMs;
    return randomMs;
}
const desiredResolutions = [1080, 720, 360, 180];
export const Player = React.memo(({ id, previewId }) => {
    const handle = useFullScreenHandle();
    const prevIdRef = useRef();
    const prevAuthRef = useRef();
    const videoRef = useRef();
    const waterMarkRef = useRef(null); // Correct type
    const [loading, setLoading] = React.useState(true);
    const [streamingId, setStreamingId] = React.useState();
    const [streamingAuth, setStreamingAuth] = React.useState();
    const [mediaUrl, setMediaUrl] = React.useState();
    const [duration, setDuration] = React.useState(0);
    const [currentTime, setCurrentTime] = React.useState(0);
    const [volume, setVolume] = React.useState(1);
    const [playing, setPlaying] = React.useState(false);
    const [playRate, setPlayRate] = React.useState(0);
    const [availableQualities, setAvailableQualities] = React.useState([]);
    const [selectedQuality, setSelectedQuality] = React.useState('auto');
    // 버퍼링이 완료되었는지 여부를 추적하는 상태
    const maxRetries = 3;
    const user = useSelector((state) => getUserInfo(state));
    const nickname = useMemo(() => {
        if (!user) {
            return `대성마이맥`;
        }
        else {
            return `${user.nickname}(${user.auth_name})`;
        }
    }, [user]);
    // handler 플레이어 시작
    const handlerPlayVideo = async () => {
        try {
            const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
            if (videoPlayerElement) {
                await videoPlayerElement.play();
            }
            else {
                player && player.play();
            }
            setPlaying(true);
        }
        catch (err) {
            if (err.name === 'NotAllowedError') {
                console.warn('User interaction required to play the video.');
            }
        }
    };
    // handler 플레이어 정지
    const handlerPauseVideo = async () => {
        try {
            const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
            if (videoPlayerElement) {
                videoPlayerElement.pause();
            }
            else {
                player && player.pause();
            }
            setPlaying(false);
        }
        catch (err) { }
    };
    // updateQualityOptions 함수 수정
    const updateQualityOptions = useCallback(() => {
        if (!player) {
            console.warn('Player instance is not available.');
            return;
        }
        const bitrateInfos = player.getBitrateInfoListFor('video');
        if (!bitrateInfos || bitrateInfos.length === 0) {
            console.warn('No bitrate information available.');
            return;
        }
        const qualities = bitrateInfos
            .map((info, index) => ({
            bitrate: Math.round(info.bitrate / 1000),
            height: info.height,
            width: info.width,
            qualityIndex: index,
        }))
            .filter(quality => desiredResolutions.includes(quality.height))
            .sort((a, b) => b.height - a.height);
        if (qualities.length === 0) {
            console.warn('No matching qualities found for the desired resolutions.');
        }
        setAvailableQualities(qualities);
    }, [player]);
    const handleQualityChange = (quality) => {
        if (!player) {
            console.warn('Player instance is not available.');
            return;
        }
        setSelectedQuality(quality);
        if (quality === 'auto') {
            player.updateSettings({
                streaming: {
                    abr: {
                        autoSwitchBitrate: {
                            video: true,
                        },
                    },
                },
            });
        }
        else {
            player.updateSettings({
                streaming: {
                    abr: {
                        autoSwitchBitrate: {
                            video: false,
                        },
                    },
                },
            });
            player.setQualityFor('video', quality);
        }
        // Clear buffer and reinitialize the stream
        // setTimeout(() => {
        // 	player.attachSource(player.getSource());
        // }, 100);
    };
    // Add this function to your component
    const forceQualityUpdate = () => {
        if (player && selectedQuality !== 'auto') {
            player.setQualityFor('video', selectedQuality);
            player.attachSource(player.getSource());
        }
    };
    // 플레이어에 참여하기 (웹소켓 연결)
    const joinStreaming = () => {
        if (websocket) {
            websocket
                .joinStreaming(streamingId)
                .then(
            // @ts-ignore
            async (response) => {
                if (!response)
                    return;
                setMediaUrl(`/api/media/v1/medias/${previewId}/manifest.mpd`);
            })
                .catch(err => {
                alert('스트리밍 오류입니다. 다시 시도해주세요. - 스트리밍 입장 실패');
            });
        }
    };
    // 스트리밍 데이터 로드
    const initLoadData = useCallback(() => {
        if (!streamingId)
            return;
        if (player) {
            updateQualityOptions();
            forceQualityUpdate();
            getStreaming({ streamingId }).then((response) => {
                if (!response)
                    return;
                const items = response.result.items;
                if (items && items.length > 0) {
                    const item = items[0];
                    onloadedmetadata(item, player ? player.duration() : 0);
                }
            });
        }
    }, [streamingId]);
    // 비디오 메타데이터 로드
    const onloadedmetadata = async (response, duration) => {
        if (response) {
            const { play_position, speed, status, time, play_time, pause_time } = response;
            if (status === 'stopped') {
                // 재생 시간이 종료 시간보다 작은 경우 처리
                if (moment(play_time).isBefore(moment(pause_time))) {
                    const play_duration = moment
                        .duration(moment(pause_time).diff(moment(play_time)))
                        .asMilliseconds() + play_position;
                    setPlayRate(play_duration / 1000);
                }
                else {
                    setPlayRate(play_position / 1000);
                }
                handlerPauseVideo();
                setLoading(false);
                return;
            }
            const play_duration = moment
                .duration(moment(time).diff(moment(play_time)))
                .asMilliseconds();
            const surveryPosition = play_duration + play_position;
            const localPosition = surveryPosition - websocket.timeDiff;
            if (duration < localPosition / 1000) {
                player && player.seek(0);
                setPlayRate(0);
                handlerPauseVideo();
                setLoading(false);
                return;
            }
            setPlayRate(localPosition / 1000);
            handlerPlayVideo();
            setLoading(false);
        }
    };
    const handlerStop = () => {
        playing &&
            streamingId &&
            streamingAuth &&
            stopStreaming({
                streamingAuth,
                streamingId,
            })
                .catch(err => { })
                .finally(() => window.close());
    };
    React.useEffect(() => {
        APP.eventManager.subscribe('STOP_STREAMING', handlerStop);
        return () => {
            APP.eventManager.unsubscribe('STOP_STREAMING', handlerStop);
        };
    }, [handlerStop]);
    React.useEffect(() => {
        timeIntervalId =
            nickname &&
                setInterval(() => {
                    const videoPlayerElement = document.getElementById('playerFullElement') ||
                        waterMarkRef.current;
                    const { offsetWidth: width, offsetHeight: height } = videoPlayerElement;
                    // Create and style the canvas element
                    const canvasElement = document.createElement('canvas');
                    canvasElement.style.position = 'absolute';
                    canvasElement.style.top = '0';
                    canvasElement.style.left = '0';
                    canvasElement.style.width = '100%';
                    canvasElement.style.height = '100%';
                    canvasElement.width = width;
                    canvasElement.height = height;
                    const ctx = canvasElement.getContext('2d');
                    if (ctx) {
                        //
                        const watermarkText = nickname || '대성마이맥';
                        ctx.font = '25px Arial';
                        ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
                        // Calculate text width and height
                        const textWidth = ctx.measureText(watermarkText).width * 2;
                        const textHeight = 25;
                        // Determine random position for the watermark
                        const x = Math.max(Math.min(Math.random() * (width - textWidth), width - textWidth - 25), 25);
                        const y = Math.max(Math.min(Math.random() * (height - textHeight), height - textHeight - 25), 25);
                        // Draw the watermark
                        ctx.fillText(watermarkText, x, y);
                        const devicePixelRatio = window.devicePixelRatio || 1;
                        ctx.scale(devicePixelRatio, devicePixelRatio);
                        ctx.setTransform(1, 0, 0, 1, 0, 0);
                        if (videoPlayerElement) {
                            videoPlayerElement.appendChild(canvasElement);
                        }
                    }
                    // Remove the canvas after 1 second
                    setTimeout(() => {
                        if (videoPlayerElement && canvasElement) {
                            videoPlayerElement.removeChild(canvasElement);
                        }
                    }, 1000);
                }, getRandomIntervalInMs());
        return () => {
            timeIntervalId && clearInterval(timeIntervalId);
        };
    }, [nickname]);
    React.useEffect(() => {
        return () => {
            if (player) {
                player.reset();
                player.destroy();
                player = null;
            }
            if (websocket) {
                websocket.cleanUp();
                websocket.disconnect();
                websocket = null;
            }
            setLoading(false);
            setMediaUrl(undefined);
        };
    }, []);
    // we
    React.useEffect(() => {
        if (!previewId)
            return;
        let auth = undefined;
        let streaming_id = undefined;
        setLoading(true);
        fetchGetStreamingAuth(id, previewId).then((response) => {
            if (response.result) {
                streaming_id = getStreamingIdFromUrl(response.result.streaming_url);
                auth = response.result.streaming_auth;
                setStreamingAuth(auth);
                setStreamingId(streaming_id);
            }
        });
        return () => {
            setStreamingAuth(undefined);
            setStreamingId(undefined);
            setMediaUrl(undefined);
        };
    }, [previewId]);
    useEffect(() => {
        if (streamingId && streamingAuth) {
            if (websocket)
                return;
            const playerWebsocket = new PlayerWebsoket();
            playerWebsocket
                .connect()
                .then(() => {
                websocket = playerWebsocket;
                joinStreaming();
            })
                .catch(err => {
                alert('스트리밍 오류입니다. 다시 시도해주세요. - 웹소켓 연결 실패');
                setPlaying(false);
            });
        }
        return () => {
            playing &&
                streamingId &&
                streamingAuth &&
                stopStreaming({
                    streamingAuth,
                    streamingId,
                }).catch(err => { });
            prevIdRef.current = streamingId;
            prevAuthRef.current = streamingAuth;
        };
    }, [streamingId, streamingAuth, playing]);
    // media url 변경된 경우 플레이어 초기화
    React.useEffect(() => {
        let handlerTimeUpdate; // 재시도 타이머를 관리할 변수
        // 에러가 있을때 재시도
        const handlerError = err => {
            if (retryCount < maxRetries) {
                const retryDelay = Math.pow(2, retryCount) * 1000; // 지수적 백오프
                handlerTimeUpdate = setTimeout(() => {
                    retryCount++;
                    player && player.attachSource(mediaUrl); // 재시도 시 소스 다시 첨부
                }, retryDelay);
            }
            else {
                // alert(err);
                console.log(err);
                // alert('지원되지 않는 기기입니다.');
            }
        };
        const playBackEnded = () => {
            const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
            if (videoPlayerElement) {
                setPlayRate(0);
                handlerPauseVideo();
            }
        };
        // 플레이어 초기화
        if (mediaUrl && streamingId && streamingAuth) {
            const dashPlayer = dashjs.MediaPlayer().create();
            const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
            if (videoPlayerElement) {
                dashPlayer.initialize(videoPlayerElement, undefined, false);
                dashPlayer.on(dashjs.MediaPlayer.events.STREAM_INITIALIZED, initLoadData);
                dashPlayer.on(dashjs.MediaPlayer.events.ERROR, handlerError); // 에러 핸들러 등록
                dashPlayer.on(dashjs.MediaPlayer.events.PLAYBACK_ENDED, playBackEnded); // 재생 종료 핸들러 등록
                dashPlayer.on(dashjs.MediaPlayer.events.QUALITY_CHANGE_RENDERED, updateQualityOptions); // 재생 종료 핸들러 등록
                dashPlayer.updateSettings({
                    streaming: {
                        buffer: {
                            initialBufferLevel: 4,
                            fastSwitchEnabled: true,
                            bufferPruningInterval: 4, // 1초마다 버퍼 정리
                        },
                        abr: {
                            autoSwitchBitrate: {
                                video: true,
                            },
                        },
                    },
                });
                dashPlayer.attachSource(mediaUrl); // `posix:${125444}`); // 미디어 소스 첨부
                setDuration(dashPlayer.duration());
                player = dashPlayer;
            }
        }
        return () => {
            if (player) {
                player.off(dashjs.MediaPlayer.events.STREAM_INITIALIZED, initLoadData);
                player.off(dashjs.MediaPlayer.events.ERROR, handlerError); // 에러 핸들러 해제
                player.off(dashjs.MediaPlayer.events.PLAYBACK_ENDED, playBackEnded); // 재생 종료 핸들러 해제
                player.off(dashjs.MediaPlayer.events.QUALITY_CHANGE_RENDERED, updateQualityOptions); // 재생 종료 핸들러 등록
                player.destroy(); // 플레이어 파괴
                if (handlerTimeUpdate)
                    clearTimeout(handlerTimeUpdate); // 타이머 클리어
            }
        };
    }, [mediaUrl]);
    useEffect(() => {
        if (player) {
            player.seek(playRate);
        }
    }, [playRate]);
    useEffect(() => {
        if (player) {
            player.setVolume(volume);
        }
        if (videoRef.current) {
            videoRef.current.muted = volume > 0 ? false : true;
        }
    }, [volume]);
    // 플레이어 시간 변경
    const onTimeChange = async (currentTime) => {
        const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
        if (videoPlayerElement) {
            videoPlayerElement.currentTime = currentTime;
            if (player) {
                const isPlaying = playing;
                await onPlay();
                if (!isPlaying)
                    await onStop();
                // await onPlay();
            }
        }
    };
    // 플레이어 재생
    const onPlay = async () => {
        if (!streamingId || !streamingAuth) {
            alert('스트리밍 정보가 없습니다.');
            window.close();
            return;
        }
        const videoPlayerElement = document.getElementById('videoPlayer') || videoRef.current;
        handlerPlayVideo();
        setPlaying(true);
        let position = 0;
        let speed = 1;
        if (videoPlayerElement) {
            position = Math.floor(videoPlayerElement.currentTime * 1000);
            speed = videoPlayerElement.playbackRate * 1000;
        }
        try {
            await playStreaming({
                streamingAuth,
                streamingId,
                position,
                speed,
            });
        }
        catch (err) {
            alert('스트리밍 재생에 실패했습니다.');
        }
    };
    // 플레이어 정지
    const onStop = async () => {
        if (!streamingId || !streamingAuth)
            return;
        handlerPauseVideo();
        setPlaying(false);
        try {
            await stopStreaming({
                streamingAuth,
                streamingId,
            });
        }
        catch (err) {
            alert('스트리밍 중지에 실패했습니다.');
            setPlaying(true);
        }
    };
    const beforeUnload = e => {
        prevAuthRef.current &&
            prevIdRef.current &&
            playing &&
            stopStreaming({
                streamingAuth: prevAuthRef.current,
                streamingId: prevIdRef.current,
            }).catch(err => { });
    };
    useEffect(() => {
        window.addEventListener('beforeunload', beforeUnload);
        return () => window.removeEventListener('beforeunload', beforeUnload);
    }, [beforeUnload]);
    return (React.createElement(FullScreen, { className: "fullscreen", handle: handle },
        React.createElement(PlayerStyled, { id: "playerFullElement", ref: waterMarkRef },
            React.createElement("video", { ref: videoRef, id: "videoPlayer", 
                // @ts-ignore
                onTimeUpdate: e => setCurrentTime(e.target.currentTime), 
                // @ts-ignore
                onLoadedMetadata: e => setDuration(e.target.duration), playsInline: true, controls: false, muted: volume === 0, disablePictureInPicture: true, preload: "metadata", style: { visibility: loading ? 'hidden' : 'visible' } }),
            React.createElement(CustomControls, { videoRef: waterMarkRef, playing: playing, onPlay: onPlay, onPause: onStop, duration: duration, currentTime: currentTime, onSeek: onTimeChange, volume: volume, onVolumeChange: setVolume, handlerFullScreen: handle.enter, handlerExitFullScreen: handle.exit, availableQualities: availableQualities, selectedQuality: selectedQuality, handleQualityChange: handleQualityChange }),
            React.createElement("div", { className: "loading" }, "\uB85C\uB529\uC911..."))));
});
