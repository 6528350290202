import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckBoxElement } from './CheckBox';
import Icon from './Icon';
export const TableHeader = React.memo(({ allChecked, titleFontClass, styles, showCheckBox, showIndex, headTitleList, sort, desc, handlerUpdateSort, handlerCheck, tableOption, }) => {
    const { t } = useTranslation();
    const renderHeadItem = useMemo(() => {
        return headTitleList.map((item, index) => {
            const isSorted = item.sort && item.title === sort;
            const rotation = isSorted
                ? desc
                    ? 'rotate(90deg)'
                    : 'rotate(270deg)'
                : 'rotate(90deg)';
            return (React.createElement("div", { key: item.title + index, className: "table-header-item", style: {
                    ...styles?.headItem,
                    ...item?.styles,
                    cursor: item.sort ? 'pointer' : 'default',
                    flex: item.space,
                    maxWidth: item?.maxWidth ? item.maxWidth : 'auto',
                    minWidth: item?.minWidth ? item.minWidth : 'fit-content',
                    flexDirection: item.children ? 'column' : 'row',
                    ...(item.maxWidth && { maxWidth: item.maxWidth }),
                }, onClick: item.sort ? () => handlerUpdateSort(item.title) : undefined },
                React.createElement("div", { className: "text" },
                    React.createElement("p", { className: "overText2", style: styles?.headText }, t(`table.${item.title}`)),
                    item.sort && (React.createElement(Icon, { style: {
                            transform: rotation,
                        }, icon: "arrow", size: tableOption.sortIcon.size, stroke: tableOption.sortIcon.color }))),
                item.children && (React.createElement("div", { className: "table-header-child", style: { width: '100%' } }, item.children.map(i => (React.createElement("p", { key: i, style: { flex: 1 } }, i)))))));
        });
    }, [headTitleList, sort, desc]);
    return (React.createElement("div", { className: `table-header ${titleFontClass}`, style: styles?.headWrapper },
        showCheckBox && (React.createElement("div", { className: "table-header-item", style: { ...styles?.headItem, minWidth: 30 } },
            React.createElement(CheckBoxElement, { id: "all", iconSize: 22, checked: allChecked, handlerCheck: handlerCheck }))),
        showIndex && (React.createElement("div", { className: "table-header-item", style: { ...styles?.headItem, minWidth: 45, maxWidth: 60 } },
            React.createElement("p", { style: styles?.headText },
                " ",
                t('table.no')))),
        renderHeadItem));
});
