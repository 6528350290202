import { toState } from '../../redux/regist';
export const getMenuData = (statful) => {
    const state = toState(statful);
    return {
        depths: state['base/menu'].depths,
        menu: state['base/menu'].menu,
        id: state['base/menu'].id,
        props: state['base/menu'].props,
    };
};
export const getDepths = (statful) => {
    const state = toState(statful);
    return state['base/menu'].depths;
};
