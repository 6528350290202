import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../../GlobalStateProvider';
import { NavbarContainter } from '../../DST';
import { MenuBox } from '../../menu/MenuBox';
import { changeMenuFromName } from '../../menu/action';
import { getDepths, getMenuData } from '../../menu/function';
import { getIsTeacher, getUserAuthName } from '../account';
import { appWillLoad, closeWindow } from '../app';
import { getClassesId, getClassesTitle } from '../classes';
import { PopupContainer } from '../popup';
import { DSBody, DSLayoutHeader } from './DSLayoutComponent';
import { HomeLayout } from './HomeLayout';
let websocket = null;
export const MainClasses = React.memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const stateClasses = getClassesId();
    const load = useSelector((state) => state['base/app'].isLoad);
    const classesTitle = useSelector((state) => getClassesTitle(state));
    const user = useSelector((state) => getUserAuthName(state));
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const depths = useSelector((state) => getDepths(state));
    const menu = useSelector((state) => getMenuData(state), shallowEqual);
    const [isScrolled, setIsScrolled] = useState(false);
    const handlerUpdateMenu = (menu, isMain = false) => {
        if (menu === 'logout') {
            closeWindow();
            navigate('/login');
            return;
        }
        else if (menu === 'error') {
            navigate('/error');
            if (websocket) {
                websocket.connection.close();
                websocket = null;
            }
            return;
        }
        dispatch(changeMenuFromName(menu, isMain));
    };
    useEffect(() => {
        APP.eventManager.subscribe('updateMenu', handlerUpdateMenu);
        return () => {
            APP.eventManager.unsubscribe('updateMenu', handlerUpdateMenu);
        };
    }, []);
    useEffect(() => {
        const throttleOut = throttle(() => {
            // @ts-ignore
            if (!user)
                location.href = '/error';
        }, 1000, { leading: false, trailing: true });
        throttleOut();
    }, [user]);
    useEffect(() => {
        if (!load)
            dispatch(appWillLoad());
        return () => {
            dispatch(appWillLoad());
        };
    }, [stateClasses]);
    const { name, menuList } = useMemo(() => {
        if (depths === 'home') {
            return {
                name: 'home',
                menuList: ['home'],
                options: {},
            };
        }
        else if (depths === 'learningManagement') {
            return {
                name: 'assignment',
                menuList: ['assignment', 'test', 'attendanceStatus', 'sendSMS'],
            };
        }
        else if (depths === 'myRoom') {
            return {
                name: 'assignment',
                menuList: ['assignment', 'test', 'gradeCard'], //, 'sendSMS'],
            };
        }
        else if (depths === 'liveRoom') {
            return {
                name: 'reservation',
                menuList: ['reservation', 'previous-conference', 'reply'],
            };
        }
        else if (depths === 'learningSupportRoom') {
            return {
                name: 'learningSupportRoom',
                menuList: isTeacher
                    ? ['notice', 'qna', 'resource', 'content']
                    : ['notice', 'qna', 'resource'],
            };
        }
        return {
            name: 'home',
            menuList: ['home'],
        };
    }, [depths, isTeacher]);
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const isWhite = useMemo(() => !isTeacher && depths === 'liveRoom', [isTeacher, depths]);
    if (!user)
        return null;
    return (React.createElement("div", { className: `ds-layout
			${isWhite ? 'box-white' : ''}
			${isTablet ? 'tablet' : isMobile ? 'mobile' : 'desktop'}` },
        ((!isDesktop && depths === 'home') || isDesktop) && (React.createElement(DSLayoutHeader, { title: isDesktop && classesTitle, isHome: depths === 'home', isScrolled: isScrolled, setMenu: handlerUpdateMenu }, isDesktop && (React.createElement(NavbarContainter, { isTeacher: isTeacher, menu: depths, setMenu: menu => handlerUpdateMenu(menu, true) })))),
        depths === 'home' ? (React.createElement(HomeLayout, { isTeacher: isTeacher, setIsScrolled: setIsScrolled })) : (React.createElement(DSBody, { title: isTablet ||
                (isMobile && menu.menu.detail !== 'detail') ||
                ((menu.menu.menu === 'assignment' || menu.menu.menu === 'reservation') &&
                    menu.menu.detail === 'regist')
                ? classesTitle
                : undefined, isWhite: isWhite, name: name, menuList: menuList, hideBody: true },
            React.createElement(MenuBox, { isTeacher: isTeacher, name: name, menuList: menuList, hideMenu: isWhite }))),
        (isMobile || isTablet) && (React.createElement("div", { className: "botom-nav" },
            React.createElement(NavbarContainter, { isFooter: true, isTeacher: isTeacher, menu: depths, setMenu: menu => {
                    if (menu === 'logout') {
                        closeWindow();
                        navigate('/login');
                        return;
                    }
                    handlerUpdateMenu(menu, true);
                } }))),
        React.createElement(PopupContainer, null)));
});
