import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../GlobalStateProvider';
const ErrorRateGraphStyled = styled.div `
	flex-shrink: 0;
	position: relative;
	margin-top: 45px;
	margin-bottom: ${props => (props.$isMobile ? '30px' : 'unset')};

	.bar-graph-wrap {
		display: flex;
		height: 100%;
		width: 100%;

		.frame-001 {
			display: flex;
			flex-direction: column-reverse;
			height: 100%;
			align-items: flex-start;
			justify-content: space-between;
			width: 20px;
		}
	}

	.bar-graph {
		display: flex;

		flex: 1;
	}

	&.vertical {
		.bar-graph {
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-evenly;
		}

		.bar {
			border-radius: 8px;
			height: 36px;
			margin-left: 42px;
			display: flex;
			flex-direction: row-reverse;
		}

		.bottom_label {
			position: absolute;
			left: -42px;
		}

		.label {
			background: var(--natural-color-n-100, #ffffff);
			border-radius: 6px;
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px;
			padding: 6px 4px 6px 4px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			margin: 5px;
			left: 50px;
			box-shadow: 0px 2px 4px 0px rgba(240, 240, 240, 1);
		}
	}

	&.horizontal {
		.bar-graph {
			align-items: flex-end;
			justify-content: space-evenly;
		}

		.label {
			background: var(--natural-color-n-100, #ffffff);
			border-radius: 5px;
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px;
			padding: 0px 4px 0px 4px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			margin-top: -31px;
			box-shadow: 0px 2px 4px 0px rgba(240, 240, 240, 1);
		}

		.bar {
			border-radius: 8px 8px 0px 0px;
			width: 36px;
		}

		.bottom_label {
			position: absolute;
			bottom: -40px;
			transform: translate(-50%, -50%);
			left: 50%;
		}
	}

	.bar {
		position: relative;
		box-shadow: 4px 0px 10px 0px rgba(247, 197, 209, 0.5);
	}

	.label {
		align-items: center;
		justify-content: center;
		position: relative;
		color: var(--tur-p, #2d39aa);
		text-align: center;
		font-family: var(--b6-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b6-font-size, 10px);
		line-height: var(--b6-line-height, 20px);
		font-weight: var(--b6-font-weight, 400);
	}

	.txt {
		color: var(--color-grey, #666666);
		text-align: center;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: ${props => (props.$isMobile ? '12px' : 'var(--b4-font-size, 14px)')};
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		width: 38px;
	}
`;
const color_list = ['#f46790', '#f38fac', '#f1b4c6', '#f4cdd8', '#fbe0e7'];
export const ErrorRateGraph = React.memo(({ isVertical, hardest, width, height, }) => {
    const [data, setData] = React.useState([]);
    const { isMobile } = useGlobalState();
    useEffect(() => {
        const best5 = hardest.slice(0, 5);
        let list = [];
        best5.map(item => {
            list.push({
                label: item.number.toString() + '번',
                value: item.incorrect_rate.toFixed(1) + '%',
                height: isVertical ? '36px' : `${(height * item.incorrect_rate) / 100}px`,
                width: isVertical ? `${(width * item.incorrect_rate) / 100}px` : '36px',
            });
        });
        setData(list);
    }, [hardest]);
    return (React.createElement(ErrorRateGraphStyled, { "$isMobile": isMobile, className: `${isVertical ? 'vertical' : 'horizontal'}`, style: { minWidth: isMobile ? 'unset' : `${width}px`, height: `${height}px` } },
        React.createElement(BarGraph, { isVertical: isVertical, color_list: color_list, data: data })));
});
const BarGraph = ({ isVertical, color_list, data, }) => {
    const { isMobile } = useGlobalState();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "bar-graph-wrap" },
            !isVertical && (React.createElement("div", { className: "frame-001" },
                React.createElement("span", { className: "txt" }, " 0"),
                React.createElement("span", { className: "txt" }, " 50"),
                React.createElement("span", { className: "txt" }, " 100"))),
            React.createElement("div", { className: "bar-graph", style: { flexDirection: `${isVertical ? 'column' : 'row'}` } }, data.map((item, index) => (React.createElement("div", { className: "bar", key: index, style: {
                    height: `${item.height}`,
                    width: `${item.width}`,
                    background: `${color_list[index]}`,
                } },
                React.createElement("span", { className: "label" }, item.value),
                React.createElement("div", { className: "txt bottom_label" }, item.label))))))));
};
