import { useQuery } from 'react-query';
//  API 호출 함수 정의
export const fetchData = async (url, options) => {
    const response = await fetch(url, options);
    if (!response.ok) {
        const result = await response.json();
        // throw new Error(result.message.format);
        try {
            const name = result?.message?.format || result?.format || '서버 오류입니다.';
            return Promise.reject(name);
        }
        catch (err) {
            return Promise.reject('서버 오류입니다.');
        }
    }
    return response.json();
};
// object convert string
export function objectToQueryString(obj) {
    if (obj === undefined || obj === null) {
        return '';
    }
    return Object.keys(obj)
        .map(key => {
        const value = obj[key];
        if (value === undefined)
            return;
        if (Array.isArray(value)) {
            return value
                .map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
                .join('&');
        }
        else {
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        }
    })
        .join('&');
}
export const useCustomQuery = (key, getData) => {
    return useQuery(key, getData, {
        keepPreviousData: true,
        select: (data) => data.result,
        enabled: false,
        cacheTime: 300000,
        staleTime: 60000,
        refetchInterval: 5000,
        refetchOnWindowFocus: false,
        retry: 3,
        suspense: true,
        onError: (error) => {
            console.error(error);
        },
    });
};
