import { closeWindow } from './function';
function getWebsocketURLBase() {
    const urlBase = window.location.origin;
    if (urlBase.startsWith('http:')) {
        return `ws:${urlBase.substring(5)}`;
    }
    else if (urlBase.startsWith('https:')) {
        return `wss:${urlBase.substring(6)}`;
    }
    throw new Error('알 수 없는 프로토콜');
}
const handleMessage = (data) => {
    switch (data.class) {
        case 'Member.LoggedOut':
            closeWindow(true);
            break;
        case 'Conference.StatusChanged':
            APP.eventManager.publish('CONFERENCE_STATUS_CHANGED');
            break;
        case 'Enquiry.MembersChanged':
            APP.eventManager.publish('MEMBERS_CHANGED', data.payload.result);
        case 'Enquiry.QueueChanged':
        case 'Enquiry.GotReady':
        case 'Enquiry.MembersChanged':
            // const change_result: {
            // 	class_id: string;
            // 	enquiry_id: string; // 1:1 라이브 ID
            // 	enquiry_queue: string[]; // 사용자 대기열
            // } = data.payload.result;
            APP.eventManager.publish('RERENDER_LIVE_ENQUIRIES');
            break;
        case 'Enquiry.QueueAccepted':
            const accepted_result = data.payload.result;
            APP.eventManager.publish('QUEUE_ACCEPTED', accepted_result);
            break;
        case 'Contents.StatusChanged':
            const content_status = data.payload.result;
            APP.eventManager.publish('CONTENT_STATUS_ACCEPT', content_status);
            break;
    }
};
export class MimacWebsocket {
    connection;
    onMessage;
    nextId;
    constructor() {
        this.onMessage = [];
        this.connection = null;
        this.nextId = 0;
    }
    connect(access_token) {
        const connection = new WebSocket(`${getWebsocketURLBase()}/api/mimac/v1/websocket`);
        return new Promise((resolve, reject) => {
            this.connection = connection;
            connection.onopen = async () => {
                connection.onclose = () => {
                    this.connection = null;
                };
                connection.onmessage = message => {
                    handleMessage(JSON.parse(message.data));
                    this.onMessage.forEach(handle => handle(message.data));
                };
                try {
                    const joinResponse = await this._request('SetAuth', {
                        access_token,
                    }, 5000);
                    if (joinResponse.status === 409) {
                        reject(joinResponse.payload.message.format);
                        return;
                    }
                    resolve(joinResponse);
                }
                catch (err) {
                    reject(err);
                }
            };
            connection.onerror = err => {
                this.connection = null;
                reject(err);
            };
            connection.onclose = () => {
                this.connection = null;
                resolve(true);
            };
        });
    }
    close() {
        this.connection?.close();
    }
    _request(clazz, payload, timeout = 5000) {
        if (this.connection && this.connection.readyState === WebSocket.OPEN) {
            const requestID = `${Date.now()}-${++this.nextId}`;
            this.connection.send(JSON.stringify({
                class: clazz,
                id: requestID,
                payload,
            }));
            return this._waitFor(data => JSON.parse(data), res => res?.payload?.id === requestID || res?.id === requestID, timeout, clazz);
        }
        console.log('웹소켓 연결 안됨.', payload);
        return Promise.reject('api.refreshError');
    }
    _subscribe(handle) {
        this.onMessage.push(handle);
        return () => {
            const index = this.onMessage.findIndex(element => element === handle);
            if (index >= 0) {
                this.onMessage.splice(index, 1);
            }
        };
    }
    async _waitFor(hook, check, timeout, clazz) {
        return new Promise((resolve, reject) => {
            let timerID;
            let unsubscribe;
            const cleanup = () => {
                clearTimeout(timerID);
                unsubscribe();
            };
            unsubscribe = this._subscribe(data => {
                try {
                    const result = hook(data);
                    if (!check(result))
                        return;
                    cleanup();
                    resolve(result);
                }
                catch (err) {
                    cleanup();
                    reject(err);
                }
            });
            timerID = setTimeout(() => {
                cleanup();
                reject(new Error(`${clazz} timeout`));
            }, timeout);
        });
    }
}
