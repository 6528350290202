import { getAccessToken } from '../../features/base/account';
import { getClassesId } from '../../features/base/classes';
import { fetchData, objectToQueryString, useCustomQuery } from '../functions';
const getToken = () => getAccessToken(APP.store.getState);
const getContentsList = async (queryString) => {
    const class_id = getClassesId();
    const url = `/api/mimac/v1/contents?${queryString}&class_id=${class_id}`; // API 엔드포인트 생성
    return fetchData(url);
};
//UUID를 받아오는 API
export const postContentsList = async (data) => {
    const url = `/api/mimac/v1/contents`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
    });
};
//동영상일 경우 chunk값을 서버로 전달
export const postChunk = async (props) => {
    const url = `/api/mimac/v1/contents/${props.id}/upload-chunk`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: props.data,
    });
};
// export const postChunk = async (props: { id: string; data: ChunkProps }) => {
// 	const url = `/api/mimac/v1/contents/${props.id}/upload-chunk`;
// 	return fetchData(url, {
// 		method: 'POST',
// 		headers: {
// 			'Content-Type': 'application/json',
// 			Authorization: `Bearer ${getToken()}`,
// 		},
// 		body: JSON.stringify(props.data),
// 	});
// };
//문서일 경우
// export const postDocument = async (props: { id: string; data: FormData }) => {
// 	const url = `/api/mimac/v1/contents/${props.id}/upload-document`;
// 	return fetchData(url, {
// 		method: 'POST',
// 		headers: {
// 			// 'Content-Type': 'application/json',
// 			Authorization: `Bearer ${getToken()}`,
// 		},
// 		body: props.data,
// 	});
// };
export const deleteContentsList = async (id) => {
    const url = `/api/mimac/v1/contents/${id}/delete`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
export const useContents = (data) => {
    const queryString = objectToQueryString(data); // props를 쿼리 문자열로 변환
    return useCustomQuery([`contents_${data}`, data], () => getContentsList(queryString));
};
export const downloadContent = async (id) => {
    const url = `/api/mimac/v1/contents/${id}/download`; // Full URL to match the cURL example
    try {
        // // Fetch the download URL directly to let the browser handle it
        // const response = await fetch(url, {
        // 	method: 'GET',
        // 	headers: {
        // 		Accept: 'application/json', // Match the headers from cURL
        // 	},
        // });
        // if (!response.ok) {
        // 	const errorData = await response.json();
        // 	throw new Error(errorData.message || 'Failed to fetch content');
        // }
        // Redirect the browser to the download URL to trigger the Chrome download manager
        window.location.href = url;
    }
    catch (error) {
        console.error('Error downloading content:', error);
    }
};
