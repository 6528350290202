import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../../../Colors';
import { useGlobalState } from '../../../GlobalStateProvider';
import { Button } from '../../../common';
import { MobilePageTab } from './MobileLayout_Child/MobilePageTab';
const MobileTabLayoutStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: flex-start;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	width: 100%;
	height: 100%;

	.hr {
		background: var(--color-silver, #f3f4f8);
		flex-shrink: 0;
		width: 100%;
		height: 1px;
		position: relative;
	}

	.tab-navigation {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		//flex: 1;
		position: relative;
		width: 100%;

		.tab-left {
			color: var(--color-grey-w, #b6bac0);
			text-align: left;
			font-family: 'NotoSansKr-Regular', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
			display: flex;
			flex-direction: row;
			gap: 4px;

			p {
				min-width: auto;
			}
		}

		.exit-regist {
			.overText {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: 'NotoSansKr-Bold', sans-serif;
				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 700;
				position: relative;
			}
		}
	}
	.tab-item {
		width: 100%;
		/* height: 100%; */
		overflow: unset;
		flex: 1;
	}
	.assignment-page {
		justify-content: center;

		.button {
			width: 100%;
		}
	}

	.scroll-content {
		overflow: auto;
		display: flex;
		flex-direction: column;
		gap: ${props => (props.$isMobile ? 'unset' : '14px')};
		justify-content: flex-start;
	}

	.mobile-btn-wrap {
		.hr {
			margin-top: 14px;
		}
	}
`;
export const MobileTabLayout = React.memo(({ tabList, footer, previousPageList, submitAssignment, mobilePage, isSubmit, test_id, showPageButton = false, showNav = true, showPageTab = false, handlerPreviousPage, }) => {
    const { isMobile } = useGlobalState();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    useEffect(() => {
        mobilePage && setActiveTabIndex(mobilePage);
    }, [mobilePage]);
    const registNext = (index) => {
        submitAssignment(index);
    };
    const previousPage = () => {
        handlerPreviousPage
            ? handlerPreviousPage()
            : isMobile && tabList[activeTabIndex]?.isDetail
                ? previousPageList.setMember(undefined)
                : APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                    menuDetail: previousPageList ? previousPageList.menuDetail : undefined,
                    id: test_id ? test_id : undefined,
                    mobilePage: previousPageList?.props?.mobilePage,
                    props: previousPageList?.props ? previousPageList.props : undefined,
                });
    };
    const renderButton = useMemo(() => showPageButton && (React.createElement("div", { className: "mobile-btn-wrap" },
        !showPageTab && React.createElement("div", { className: "hr" }),
        React.createElement("div", { className: "btn-container assignment-page", style: { width: '100%', paddingTop: '12px' } },
            activeTabIndex === 0 ? (React.createElement(Button, { text: "common.cancel", className: "btn_white mobile-xs", onClick: previousPage })) : (React.createElement(Button, { text: "assignment.previous", className: "btn_white mobile-xs", onClick: () => setActiveTabIndex(activeTabIndex - 1) })),
            tabList.length === activeTabIndex + 1 ? (React.createElement(Button, { text: "common.okay", className: "btn_default mobile-xs", onClick: () => submitAssignment(activeTabIndex) })) : (React.createElement(Button, { text: "assignment.next", className: "btn_default mobile-xs", onClick: () => isSubmit
                    ? registNext(activeTabIndex)
                    : setActiveTabIndex(activeTabIndex + 1) }))))), [showPageButton, previousPageList, activeTabIndex, submitAssignment]);
    const renderPageContent = useMemo(() => showPageTab ? (React.createElement(React.Fragment, null,
        footer,
        React.createElement("div", { key: isSubmit ? test_id : tabList[activeTabIndex].id, className: "tab-item", style: tabList[activeTabIndex]?.styles }, tabList[activeTabIndex] && tabList[activeTabIndex].component),
        !showPageTab && renderButton)) : (React.createElement("div", { className: "scroll-content" },
        footer,
        React.createElement("div", { key: isSubmit ? test_id : tabList[activeTabIndex].id, className: "tab-item", style: tabList[activeTabIndex]?.styles }, tabList[activeTabIndex] && tabList[activeTabIndex].component),
        !showPageTab && renderButton)), [activeTabIndex, tabList, isSubmit, test_id, footer]);
    return (React.createElement(MobileTabLayoutStyled, { "$isMobile": isMobile, className: "mobile-tab-layout", style: { gap: showPageButton && '12px' } },
        showNav ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "tab-navigation" }, previousPageList ? (React.createElement(Button, { className: "exit-regist", text: previousPageList.title, onClick: previousPage, option: {
                    buttonBeforeIcon: {
                        show: true,
                        name: 'arrow',
                        size: 24,
                        color: Colors.w_grey,
                    },
                } })) : (React.createElement(React.Fragment, null,
                tabList[activeTabIndex - 1] ? (React.createElement(Button, { className: "tab-left", text: tabList[activeTabIndex - 1].title, onClick: () => setActiveTabIndex(activeTabIndex - 1), option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: 'arrow',
                            size: 18,
                            color: Colors.w_grey,
                        },
                    } })) : (React.createElement("div", null)),
                tabList[activeTabIndex + 1] && (React.createElement(Button, { className: "tab-left", text: tabList[activeTabIndex + 1].title, onClick: () => setActiveTabIndex(activeTabIndex + 1), option: {
                        buttonAfterIcon: {
                            show: true,
                            className: 'arrow-right',
                            name: 'arrow',
                            size: 18,
                            color: Colors.w_grey,
                        },
                    } }))))),
            React.createElement("div", { className: "hr" }))) : showPageTab ? (React.createElement(MobilePageTab, { activeTabIndex: activeTabIndex, tabList: tabList, previousPage: previousPage, content: renderPageContent, button: renderButton })) : (React.createElement(React.Fragment, null)),
        !showPageTab && renderPageContent));
});
