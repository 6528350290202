import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { Table } from '../../../../../common';
import { useWatchHistoryList } from '../../../../../server';
import moment from 'moment';
const WatchHistoryTableStyled = styled.div `
	min-height: 284px;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	gap: 12px;

	.live-list-005 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		overflow: hidden;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}
		}

		.item {
			display: flex;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.body-item {
				span {
					color: var(--color-black, #101010);
					text-align: left;
					font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);

					letter-spacing: var(--b4-letter-spacing, -0.02em);
					font-weight: var(--b4-font-weight, 400);
					position: relative;
				}
			}
		}
	}

	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.live-list-005 {
		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 0px 0px;
			padding: 12px 0px 12px 0px;
			align-items: flex-start;
		}

		.scroll-content {
			overflow: unset;
		}

		.empty_data {
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px 0px 0px 0px;
			padding-top: 16px;
		}

		.mobile_item {
			flex-direction: column;
			.body-item {
				span {
					font-size: var(--b4-font-size, 12px);
					line-height: 150%;
				}
			}
		}
	}

	.watch-history-wrap {
		display: flex;
		flex-direction: column;
		.bold {
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-weight: 700 !important;
			font-size: 14px;
		}
	}

	.wtach-date-wrap {
		display: flex;
		gap: 10px;
	}
	.watch-time-wrap {
		display: flex;
		gap: 4px;
		.overText {
			color: var(--color-grey-l, #83868a) !important;
		}
	}
`;
const desktopStyles = css `
	.live-list-005 {
		.item {
			padding: 8px 16px 8px 16px;
			flex-direction: row;
			align-items: center;
			gap: 20px;

			.body-item {
				span {
					font-size: var(--b4-font-size, 14px);
					line-height: var(--b4-line-height, 28px);
				}
			}
		}
	}
`;
export const WatchHistoryTable = React.memo(({ id }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [testData, setTestData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [totalCount, setTotalCount] = React.useState(100);
    const [checkedList, setCheckedList] = React.useState([]);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        const headTitleList = [
            { title: 'nameId', space: 2, sort: false },
            { title: 'isWatching', space: 1, sort: false },
            { title: 'watchtime', space: 1, sort: false },
            { title: 'recentWatchDate', space: 1, sort: false },
        ];
        const tableReadDataKeys = [
            { title: 'user_name', space: 2 },
            { title: 'isWatching', space: 1 },
            { title: 'total_streaming_play_duration', space: 1 },
            { title: 'last_streaming_play_time', space: 1 },
        ];
        return { headTitleList, tableReadDataKeys };
    }, []);
    const { data, refetch } = useWatchHistoryList({
        id: id,
        // media_id,
        offset: page * 10,
        limit: 10,
    });
    useEffect(() => {
        refetch();
    }, [id, page]);
    useEffect(() => {
        if (data) {
            const { items, total_count } = data;
            if (total_count > 0) {
                setTestData(items);
                setTotalCount(total_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    const handlerMoreData = (page) => {
        setPage(page);
    };
    const handlerSelectItem = (item) => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'detail',
            id: item.id,
        });
    };
    // Title 가져오기
    const renderTitle = useMemo(() => {
        return (React.createElement("div", { className: "task-label" }, t('conference.watchHistory', { count: totalCount })));
    }, [totalCount]);
    const renderEmpty = useMemo(() => totalCount === 0 && React.createElement("div", { className: "empty_data" }, "\uC2DC\uCCAD\uAE30\uB85D\uC774 \uC5C6\uC2B5\uB2C8\uB2E4"), [totalCount]);
    const msToTime = (duration) => {
        const milliseconds = (duration % 1000) / 100, seconds = Math.floor((duration / 1000) % 60), minutes = Math.floor((duration / (1000 * 60)) % 60), hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        const hoursStr = hours < 10 ? '0' + hours : hours;
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;
        const secondsStr = seconds < 10 ? '0' + seconds : seconds;
        return hoursStr + ':' + minutesStr + ':' + secondsStr;
    };
    // Item 가져오기
    const renderItem = (item, styles) => {
        return tableReadDataKeys.map((headItem, index) => isMobile &&
            (headItem.title === 'user_name' ||
                headItem.title === 'last_streaming_play_time') ? null : (React.createElement("div", { key: index, className: `body-item `, style: {
                flex: isMobile ? 'unset' : headItem.space,
                cursor: 'pointer',
            } }, headItem.title === 'isWatching' ? (isMobile ? (React.createElement("div", { className: "watch-history-wrap" },
            React.createElement("span", { className: "overText" }, item['user_name']),
            React.createElement("span", { className: "overText bold" }, item['total_streaming_play_duration'] ? '시청' : '미시청'))) : (React.createElement("span", { className: "overText" }, item['total_streaming_play_duration'] ? '시청' : '미시청'))) : headItem.title === 'total_streaming_play_duration' ? (isMobile ? (React.createElement("div", { className: "wtach-date-wrap" },
            React.createElement("div", { className: "watch-time-wrap" },
                React.createElement("span", { className: "overText" }, "\uC2DC\uCCAD\uC2DC\uAC04 : "),
                React.createElement("span", { className: "overText" }, item[headItem.title]
                    ? moment(item[headItem.title]).format('YYYY-MM-DD, HH:mm')
                    : '-')),
            React.createElement("div", { className: "watch-time-wrap" },
                React.createElement("span", { className: "overText" }, "\uCD5C\uADFC\uC2DC\uCCAD\uC77C\uC790 : "),
                React.createElement("span", { className: "overText" }, msToTime(item[headItem.title]))))) : (React.createElement("span", { className: "overText" }, msToTime(item[headItem.title])))) : headItem.title === 'last_streaming_play_time' ? (React.createElement("span", { className: "overText" }, item[headItem.title]
            ? moment(item[headItem.title]).format('YYYY-MM-DD, HH:mm')
            : '-')) : (React.createElement("span", { className: "overText" }, item[headItem.title])))));
    };
    return (React.createElement("div", { className: "field-global-001 date-wrap" },
        React.createElement("div", { className: "fext-field", style: { gap: 14 } },
            React.createElement(WatchHistoryTableStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop },
                React.createElement(Table, { className: "live-list-005", 
                    // pagingCount={3}
                    showIndex: isMobile ? false : true, showCheckBox: false, isInifinite: false, headTitleList: headTitleList, data: testData, page: page, totalCount: totalCount, renderTitle: renderTitle, renderEmpty: renderEmpty, renderItem: renderItem, handlerCheckList: setCheckedList, handlerMoreData: handlerMoreData, 
                    // handlerSelectItem={handlerSelectItem}
                    forceShowheader: isMobile ? false : true })))));
});
