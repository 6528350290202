import styled, { css } from 'styled-components';
export const DSTP001Styled = styled.div `
	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.mobile-file-wrap {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 5px;
	}

	.checkbox-item {
		&.scoreBlank {
			span {
				font-size: 12px;
			}
		}
	}

	.box {
		min-height: 92px;
	}

	.button {
		z-index: 1;
	}

	.task-info-container-wrapper {
		overflow: auto;
		background: var(--color-white, #ffffff);
		border-radius: 0px 0px 8px 8px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		flex: 1;
		height: 100%;
	}

	.eval-table-wrap {
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 0px 0px 1px 0px;
		padding: 10px 0px 24px 0px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		width: 100%;
		position: relative;

		.popup-list-002-wrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: flex-end;
			justify-content: flex-start;
			gap: 12px;
		}

		.popup-list-002,
		.popup-list-002 * {
			box-sizing: border-box;
		}
		.popup-list-002 {
			width: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-end;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.table-header {
				width: 100%;
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: flex-start;
				justify-content: flex-start;
				flex-shrink: 0;
				position: relative;
			}

			.table-header-item {
				background: var(--color-silver, #f3f4f8);
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px 0px 0px 0px;
				padding: 10px 16px 10px 16px;
				display: flex;
				flex-direction: row;
				gap: 20px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				height: 100%;

				p {
					color: var(--color-black, #101010);
					text-align: center;
					font-family: 'NotoSansKr-Regular', sans-serif;
					font-size: 12px;
					line-height: 150%;
					letter-spacing: -0.02em;
					font-weight: 400;
					position: relative;
				}
			}

			.scroll-content {
				display: flex;
				flex-direction: column;
				gap: 0px;
				align-items: center;
				justify-content: flex-start;
				flex-shrink: 0;
				width: 100%;
				position: relative;

				.item {
					display: flex;
					flex-direction: row;
					gap: 4px;
					align-items: flex-start;
					justify-content: flex-start;
					flex-shrink: 0;
					position: relative;
					width: 100%;

					.body-item {
						padding: 6px 4px 6px 4px;
						display: flex;
						flex-direction: row;
						gap: 20px;
						align-items: center;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
					}

					input {
						background: #ffffff;
						border-radius: 4px;
						border-style: solid;
						border-color: var(--color-grey-w, #b6bac0);
						border-width: 1px;
						padding: 4px 10px 4px 10px;
						display: flex;
						flex-direction: row;
						gap: 6px;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
						position: relative;
						color: var(--color-grey-l, #83868a);
						text-align: left;
						font-family: var(--b5-font-family, 'NotoSansKr-Regular', sans-serif);
						font-size: var(--b5-font-size, 10px);
						line-height: var(--b5-line-height, 22px);
						font-weight: var(--b5-font-weight, 400);
						position: relative;
					}
				}
			}
		}
	}

	.field-global-001 {
		.mobile-date-wrap {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	.attach-file-wrap {
		width: 100%;
		gap: 6px;
		display: flex;
		flex-direction: column;

		button {
			.overText {
				font-size: 12px;
			}
		}
	}

	.assignment-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
	}

	.subject-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
	}

	.eval-info-wrap {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 16px;
	}
`;
const tabletStyles = css `
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.scoreBlank,
	.assignment-checkbox-txt {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 12px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		span {
			color: var(--color-black, #101010);
			text-align: center;
			font-family: 'NotoSansKr-Regular', sans-serif;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.box {
		min-height: 92px;
	}

	.task-info-container-wrapper {
		overflow: auto;
		background: var(--color-white, #ffffff);
		border-radius: 0px 0px 8px 8px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		flex: 1;
		height: 100%;
	}

	.task-info-container {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
		padding: 0px 0px 20px 0px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		width: 100%;
		position: relative;
		min-height: 370px;

		.popup-list-002-wrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: flex-end;
			justify-content: flex-start;
		}

		.popup-list-002,
		.popup-list-002 * {
			box-sizing: border-box;
		}
		.popup-list-002 {
			width: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-end;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.table-header {
				width: 100%;
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: flex-start;
				justify-content: flex-start;
				flex-shrink: 0;
				position: relative;
			}

			.table-header-item {
				background: var(--color-silver, #f3f4f8);
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px 0px 0px 0px;
				padding: 10px 16px 10px 16px;
				display: flex;
				flex-direction: row;
				gap: 20px;
				align-items: flex-start;
				justify-content: center;
				flex-shrink: 0;
				position: relative;

				p {
					color: var(--color-black, #101010);
					text-align: center;
					font-family: 'NotoSansKr-Regular', sans-serif;
					font-size: 10px;
					line-height: 150%;
					letter-spacing: -0.02em;
					font-weight: 400;
					position: relative;
				}
			}

			.scroll-content {
				display: flex;
				flex-direction: column;
				gap: 0px;
				align-items: center;
				justify-content: flex-start;
				flex-shrink: 0;
				width: 100%;
				position: relative;

				.sel_eval_item {
					color: var(--color-grey-l, #83868a);
					text-align: center;
					font-family: 'NotoSansKr-Regular', sans-serif;
					font-size: 10px;
					line-height: 150%;
					letter-spacing: -0.02em;
					font-weight: 400;
				}

				.item {
					display: flex;
					flex-direction: row;
					gap: 4px;
					align-items: flex-start;
					justify-content: flex-start;
					flex-shrink: 0;
					position: relative;
					width: 100%;

					.body-item {
						padding: 8px 16px 8px 16px;
						display: flex;
						flex-direction: row;
						gap: 20px;
						align-items: center;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
					}

					input {
						background: #ffffff;
						border-radius: 4px;
						border-style: solid;
						border-color: var(--color-grey-w, #b6bac0);
						border-width: 1px;
						padding: 4px 10px 4px 10px;
						display: flex;
						flex-direction: row;
						gap: 6px;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
						position: relative;
						color: var(--color-grey-l, #83868a);
						text-align: left;
						font-family: var(--b5-font-family, 'NotoSansKr-Regular', sans-serif);
						font-size: var(--b5-font-size, 12px);
						line-height: var(--b5-line-height, 22px);
						font-weight: var(--b5-font-weight, 400);
						position: relative;
						width: 87px;
					}
				}
			}
		}
	}

	.assignment-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
	}

	.subject-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
	}
`;
const desktopStyles = css `
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.box {
		min-height: 92px;
	}

	.task-info-container-wrapper {
		overflow: auto;
		background: var(--color-white, #ffffff);
		border-radius: 0px 0px 8px 8px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		flex: 1;
		height: 100%;
	}

	.task-info-container {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
		padding: 0px 0px 20px 0px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		width: 100%;
		position: relative;

		.popup-list-002-wrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: flex-end;
			justify-content: flex-start;
		}

		.popup-list-002,
		.popup-list-002 * {
			box-sizing: border-box;
		}
		.popup-list-002 {
			width: 100%;
			flex: 1;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-end;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.table-header {
				width: 100%;
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: flex-start;
				justify-content: flex-start;
				flex-shrink: 0;
				position: relative;
			}

			.table-header-item {
				background: var(--color-silver, #f3f4f8);
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px 0px 0px 0px;
				padding: 10px 16px 10px 16px;
				display: flex;
				flex-direction: row;
				gap: 20px;
				align-items: flex-start;
				justify-content: center;
				flex-shrink: 0;
				position: relative;

				p {
					color: var(--color-black, #101010);
					text-align: center;
					font-family: var(--b5-font-family, 'NotoSansKr-Regular', sans-serif);
					font-size: var(--b5-font-size, 12px);
					line-height: var(--b5-line-height, 22px);
					font-weight: var(--b5-font-weight, 400);
					position: relative;
				}
			}

			.scroll-content {
				display: flex;
				flex-direction: column;
				gap: 0px;
				align-items: center;
				justify-content: flex-start;
				flex-shrink: 0;
				width: 100%;
				position: relative;

				.item {
					display: flex;
					flex-direction: row;
					gap: 4px;
					align-items: flex-start;
					justify-content: flex-start;
					flex-shrink: 0;
					position: relative;
					width: 100%;

					.body-item {
						padding: 8px 16px 8px 16px;
						display: flex;
						flex-direction: row;
						gap: 20px;
						align-items: center;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
					}

					input {
						background: #ffffff;
						border-radius: 4px;
						border-style: solid;
						border-color: var(--color-grey-w, #b6bac0);
						border-width: 1px;
						padding: 4px 10px 4px 10px;
						display: flex;
						flex-direction: row;
						gap: 6px;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
						position: relative;
						color: var(--color-grey-l, #83868a);
						text-align: left;
						font-family: var(--b5-font-family, 'NotoSansKr-Regular', sans-serif);
						font-size: var(--b5-font-size, 12px);
						line-height: var(--b5-line-height, 22px);
						font-weight: var(--b5-font-weight, 400);
						position: relative;
						width: 87px;
					}
				}
			}
		}
	}

	.assignment-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 20px;
		width: 100%;
	}

	.subject-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 24px;
		width: 100%;
	}
`;
