import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Colors } from '../../../../Colors';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { Icon } from '../../../../common';
import { MenuBox } from '../../../menu/MenuBox';
import { getIsTeacher } from '../../account';
export const DSBody = React.memo(({ title, name, menuList, isWhite = false, hideBody = false, children, }) => {
    const { isDesktop, isMobile } = useGlobalState();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const renderIcon = useMemo(() => (React.createElement(Icon, { icon: "message", size: 32, fill: isWhite
            ? Colors.white
            : name === 'reservation'
                ? Colors.dark_blue
                : name === 'assignment'
                    ? Colors.tur
                    : Colors.pur })), [name]);
    return (React.createElement("div", { className: "body" },
        title && (React.createElement("div", { className: "title-live" }, isDesktop || (name === 'reservation' && !isTeacher) ? (React.createElement(React.Fragment, null,
            renderIcon,
            React.createElement("p", { className: "title-name" }, title))) : (React.createElement(React.Fragment, null,
            React.createElement(MenuBox, { isTeacher: isTeacher, name: name, isWhite: isWhite, menuList: menuList, hideBody: hideBody }),
            React.createElement("p", { className: "title-name" }, title))))),
        React.createElement("div", { className: `container2 ${isMobile && !isTeacher ? name : ''}` }, children)));
});
// const default_questionType = { id: 'select', title: 'common.select' };
// export const DSBodyTitle = React.memo(
// 	({ title, isWhite = false }: { title: string; isWhite?: boolean }) => {
// 		const [questionType, setQuestionType] = useState<DropdownItemProps>(default_questionType); // 출제 유형
// 		//나의 강의실 메뉴
// 		const questionTypeList: DropdownItemProps[] = [
// 			{ id: 'assignment', title: 'menu.assignment' },
// 			{ id: 'test', title: 'menu.test' },
// 			{ id: 'gradeCard', title: 'menu.gradeCard' },
// 		];
// 		const { isDesktop } = useGlobalState();
// 		return (
// 			<div className="title-live" style={{ padding: !isDesktop && ' 12px 20px 12px 20px' }}>
// 				{!isDesktop && (
// 					<DropDown
// 						className="dropdown-tablet"
// 						value={questionType}
// 						styles={{
// 							titleWrap: {
// 								display: 'flex',
// 							},
// 						}}
// 						option={{
// 							downIcon: {
// 								name: 'menu_open',
// 							},
// 							itemIcon: {
// 								show: true,
// 								size: 35,
// 							},
// 						}}
// 						list={questionTypeList}
// 						setDropValue={setQuestionType}
// 						isHeaderTitle={true}
// 					/>
// 				)}
// 				<Icon icon="message" size={32} fill={isWhite ? Colors.white : Colors.light_tur} />
// 				<p className="title-name">{title}</p>
// 			</div>
// 		);
// 	}
// );
export const DSSBodyTitle = React.memo(({ title }) => {
    return (React.createElement("div", { className: "title", style: {
            background: 'var(--bule-d, #5168aa)',
            borderRadius: '50px',
            padding: '24px 52px 24px 52px',
        } },
        React.createElement(Icon, { icon: "message", size: 32, fill: Colors.white }),
        React.createElement("p", { className: "title-name", style: { color: 'var(--color-white, #ffffff)' } }, title)));
});
export const DSBodyLeftLayout = React.memo(({ children }) => {
    return React.createElement(React.Fragment, null, children);
});
export const DSBodyRightLayout = React.memo(({ children }) => {
    return React.createElement("div", { className: "right-wrap" }, children);
});
