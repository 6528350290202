import React from 'react';
import { Icon } from '../../../../common';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../GlobalStateProvider';
const SubmitResultBoxStyled = styled.div `
	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	background: var(--color-white, #ffffff);
	border-radius: 8px;
	border-style: ${props => !props.$isHome && 'solid'};
	border-color: var(--color-hr, #e6f0f1);
	border-width: 4px;
	padding: ${props => (props.$isHome ? '0px 12px 0px 12px' : '20px 10px 20px 10px')};
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: center;
	justify-content: flex-start;
	flex: 1;
	position: relative;

	.txt {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}
	.fixed {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: 'NotoSansKr-Bold', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		align-self: stretch;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.score {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}
	.value {
		color: var(--color-pink, #f46790);
		text-align: right;
		font-family: 'NotoSansKr-Bold', sans-serif;
		font-size: 24px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.value2 {
		color: var(--color-grey-w, #b6bac0);
		text-align: right;
		font-family: 'NotoSansKr-Bold', sans-serif;
		font-size: 18px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
`;
const desktopStyles = css `
	background: var(--color-white, #ffffff);
	border-radius: 8px;
	border-style: ${props => !props.$isHome && 'solid'};
	border-color: ${props => !props.$isHome && 'var(--color-hr, #e6f0f1)'};
	border-width: 4px;
	padding: ${props => !props.$isHome && '28px 8px 28px 8px'};
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;

	.txt {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}
	.fixed {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h4-font-size, 20px);
		line-height: var(--h4-line-height, 32px);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
		width: 140px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.score {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
	}
	.value {
		color: var(--color-pink, #f46790);
		text-align: right;
		font-family: var(--h2-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h2-font-size, 32px);
		line-height: var(--h2-line-height, 46px);
		font-weight: var(--h2-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.value2 {
		color: var(--color-grey-w, #b6bac0);
		text-align: right;
		font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h3-font-size, 24px);
		line-height: var(--h3-line-height, 36px);
		font-weight: var(--h3-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
`;
export const SubmitResultBox = React.memo(({ iconOptions, title, score, total, isHome, }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    return (React.createElement(SubmitResultBoxStyled, { className: "answer", "$isHome": isHome, "$isMobile": isMobile },
        !isHome && React.createElement(Icon, { ...iconOptions }),
        React.createElement("div", { className: "txt" },
            React.createElement("div", { className: "fixed" }, t(title)),
            React.createElement("div", { className: "score" },
                React.createElement("div", { className: "value" }, score || '-'),
                React.createElement("div", { className: "value2" },
                    "/ ",
                    total)))));
});
