import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { Button, CheckBoxElement } from '../../../../common';
import { FileAttach } from '../../../../common/option';
import { deleteQna, modifyQna } from '../../../../server';
import { getIsTeacher, getUserId } from '../../../base/account';
import { DeletePopUpLayout } from '../../../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../../../base/popup';
import { QNA_STATUS, QNA_TYPE } from '../constant';
const QnAContentsStyled = styled.div `
	display: flex;

	gap: 20px;
	align-items: center;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;
	flex: 1;

	.qna-open-contents {
		background: var(--color-white, #ffffff);
		border-radius: 10px;

		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		box-shadow: var(--shadow-bg-box-shadow, 0px 2px 6px 0px rgba(224, 226, 235, 0.4));
		overflow: hidden;
	}

	.qna-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
		height: 100%;

		.content {
			width: 100%;
			word-wrap: break-word;
			//max-height: 280px;
			overflow: auto;
		}

		.file-item-wrap,
		.empty_file {
			padding-bottom: 20px;
		}
	}

	.teacher-info {
		display: flex;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		letter-spacing: var(--b4-letter-spacing, -0.02em);
		font-weight: var(--b4-font-weight, 400);
		color: var(--color-grey, #666666);

		.modify-wrap {
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
		}

		.active-bar {
			.button p {
				min-width: fit-content;
			}
		}
	}

	.btn-container {
		padding-top: 0px;
		position: absolute;
		right: 28px;
	}

	.underline {
		text-decoration: underline;
	}

	.green {
		color: var(--tur-d, #479da6);
	}

	.pink {
		color: var(--color-accent, #e7424c);
	}

	.border-pink {
		border-style: solid;
		border-color: var(--color-pink, #f46790);
		border-width: 1px;
	}

	.attach {
		padding: 16px 0px 0px 0px;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;
		position: relative;
	}

	.must-read {
		display: flex;
		justify-content: center;
	}

	.title-button-wrap {
		display: flex;
		${props => props.$isMobile
    ? css `
						gap: 8px;
						span {
							font-size: 12px;
						}
				  `
    : css `
						flex-direction: column;
						gap: 12px;
				  `}
	}

	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileStyles = css `
	flex-direction: column;
	.qna-open-contents {
		padding: 20px 10px 20px 10px;
		gap: 6px;
		display: flex;
		flex-direction: column;
	}
	.qna-content {
		gap: 12px;
	}
	.ql-editor {
		font-size: 14px;
	}
	.modify-button {
		flex-direction: row;
		gap: 6px;
		align-items: center;
	}
	.requestion-button {
		flex-direction: ${props => (props.$isTeacher ? 'row' : 'column')};
		gap: 6px;
		align-items: flex-start;
	}
	.teacher-info {
		.name {
			display: flex;
			flex-direction: column;

			p {
				font-size: 12px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
			}
		}
	}
	.file-container {
		.file-item-wrap {
			padding: 12px 0px 12px 0px;
		}
	}
	.empty_file {
		padding: 12px 0px 12px 0px;
	}
	.modify-wrap {
		button {
			p {
				font-size: 12px;
			}
		}
	}
	.answer-info-wrap {
		gap: 8px;
		display: flex;
		flex-direction: column;
	}
`;
const tabletStyles = css `
	flex-direction: row;
	.qna-open-contents {
		padding: 24px 28px 24px 28px;
	}
	.qna-content {
		padding: 20px 0;
		gap: 20px;
	}
	.teacher-info {
		padding: 0px 0px 8px 0px;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		.name {
			display: flex;
			flex-direction: row;
			gap: 12px;
		}
	}
`;
const desktopStyles = css `
	flex-direction: row;
	.qna-open-contents {
		padding: 24px 28px 24px 28px;
	}
	.qna-content {
		padding: 20px 0;
		gap: 20px;
	}
	.teacher-info {
		padding: 0px 0px 8px 0px;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		.name {
			display: flex;
			flex-direction: row;
			gap: 12px;
		}
	}
`;
export const QnAContents = React.memo(({ data, number, totalCount, isQuestion = false, isPopUP = false, questionData, isSearchAnswer, importanceData, }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = useGlobalState();
    const localId = useSelector((state) => getUserId(state));
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const modifyQnaMutation = useMutation(modifyQna);
    const { root_id, id, author_id, status, type, importance } = useMemo(() => data, [data]);
    const deleteQnaMutation = useMutation(deleteQna);
    const onDelete = async () => {
        try {
            APP.eventManager.publish('SHOW_LOADING', true);
            await deleteQnaMutation.mutateAsync(id);
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'list',
                id: undefined,
            });
            APP.eventManager.publish('REFETCH_QNA_LIST');
        }
        catch (error) {
            alert(error);
        }
        finally {
            APP.eventManager.publish('SHOW_LOADING', false);
        }
    };
    const onAllDelete = async () => {
        try {
            APP.eventManager.publish('SHOW_LOADING', true);
            await deleteQnaMutation.mutateAsync(questionData.id);
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'list',
                id: undefined,
            });
            APP.eventManager.publish('REFETCH_QNA_LIST');
        }
        catch (error) {
            alert(error);
        }
        finally {
            APP.eventManager.publish('SHOW_LOADING', false);
        }
    };
    const handleDelete = (type) => {
        isTeacher && type === 'question'
            ? APP.eventManager.publish(TOGGLE_POPUP, {
                component: DeletePopUpLayout,
                componentProps: {
                    deleteContent: t('supportRoom.questionAndAnswerDelete', { count: 1 }),
                    onDelete: onAllDelete,
                },
                width: 639,
                title: t(`supportRoom.${type}Delete`),
                isFit: true,
            })
            : APP.eventManager.publish(TOGGLE_POPUP, {
                component: DeletePopUpLayout,
                componentProps: {
                    deleteContent: t('assignment.delteInto', { count: 1 }),
                    onDelete,
                },
                width: 639,
                title: t(`supportRoom.${type}Delete`),
                isFit: true,
            });
    };
    //질문, 답변 수정
    const handleEditPage = async (edit_id) => {
        {
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: !isQuestion
                    ? 'answer_modify'
                    : status === QNA_STATUS.CLOSED
                        ? 're_regist'
                        : 'regist',
                id: isTeacher ? root_id : edit_id,
                props: {
                    answer_number: Math.max(0, number - 1),
                    isReQuestion: root_id === id,
                },
            });
        }
    };
    const handleImportance = async (id, checked) => {
        if (data) {
            if (importanceData && importanceData.length >= 5)
                if (importanceData.filter(item => item.id === questionData.id).length === 0) {
                    alert('필독은 5개까지만 선택 가능합니다.');
                    return;
                }
            const importanceModify = {
                importance: importance ? false : true,
            };
            try {
                const responseData = await modifyQnaMutation.mutateAsync({
                    id: data.id,
                    props: importanceModify,
                });
            }
            catch (error) {
                console.error('Failed to post modifyPost:', error);
                alert(error);
            }
            finally {
                APP.eventManager.publish('REFETCH_QNA_LIST');
            }
        }
    };
    const renderImportanceCheckBox = useMemo(() => isTeacher &&
        !isPopUP &&
        !data.private &&
        type === QNA_TYPE.ANSWER && (React.createElement(CheckBoxElement, { id: "importance", title: "\uD544\uB3C5", checked: data?.importance, handlerCheck: handleImportance, className: "must-read", iconSize: 18 })), [data.private, isTeacher, isPopUP, type, importance, data.importance, importanceData]);
    const renderTitleButton = useMemo(() => (React.createElement("div", { className: "title-button-wrap", key: 'titleButton' },
        isMobile && renderImportanceCheckBox,
        React.createElement(Button, { className: `${isQuestion ? 'btn_pur' : 'btn_pink'} ${isMobile ? 'mobile-xs' : ''}`, styles: {
                buttonWrap: {
                    maxWidth: isMobile ? (isQuestion ? 42 : 64) : 80,
                    borderRadius: '50px',
                    height: 30,
                    boxShadow: 'unset',
                    marginBottom: !isTeacher && !isQuestion && '4px',
                },
            }, text: isQuestion ? 'supportRoom.question' : `supportRoom.answer_complete` }),
        React.createElement("div", { className: "active-bar " }, !isMobile && renderImportanceCheckBox))), [isTeacher, isQuestion, isMobile, importance]);
    const renderAnswerInfo = useMemo(() => (React.createElement("div", { key: 'answerInfo', className: isMobile && isTeacher ? 'answer-info-wrap' : '' },
        isMobile && renderTitleButton,
        (!isMobile || (isMobile && !isQuestion)) && (React.createElement("div", { className: "name" },
            isTeacher && (React.createElement("p", null, t(isQuestion
                ? 'supportRoom.question_name'
                : 'supportRoom.answer_name', { name: data.author_name }))),
            React.createElement("p", null, t(isQuestion
                ? 'supportRoom.question_date'
                : 'supportRoom.answer_date', {
                date: moment(data.creation_time).format('YYYY-MM-DD, HH:mm'),
            })))))), [renderTitleButton, isQuestion, isMobile, data]);
    // 강사, 관리자 컨트롤
    const renderTeacherControl = useMemo(() => {
        const lastQuestion = isSearchAnswer
            ? totalCount === number + 1
            : totalCount === number + 2; //마지막 질문일때만(답변자 검색하는 경우에는 +1)
        return (React.createElement("div", { className: `teacher-info ${isQuestion ? 'modify-button' : 'requestion-button'}`, style: {
                justifyContent: isPopUP && !isMobile && 'flex-end',
            } },
            renderAnswerInfo,
            (isTeacher || author_id === localId) && (React.createElement("div", { className: "modify-wrap" }, isPopUP
                ? null
                : ((!isTeacher &&
                    type === QNA_TYPE.QUESTION &&
                    status === QNA_STATUS.OPEN) ||
                    (isTeacher && lastQuestion)) && (React.createElement(React.Fragment, null,
                    (isTeacher
                        ? type === QNA_TYPE.ANSWER
                        : type === QNA_TYPE.QUESTION) && (React.createElement("div", { className: "active-bar " },
                        React.createElement(Button, { className: "underline green", text: 'common.modify', onClick: () => handleEditPage(id) }))),
                    React.createElement("div", { className: "active-bar " },
                        React.createElement(Button, { className: "pink", text: 'common.delete', onClick: () => handleDelete(type) })))))),
            !isMobile &&
                localId === author_id &&
                status === QNA_STATUS.CLOSED &&
                !isPopUP &&
                totalCount % 2 === 0 && (React.createElement(Button, { className: "btn_line_pink mobile-sm", text: t('supportRoom.requestion'), onClick: () => handleEditPage(root_id), styles: { buttonWrap: { padding: '10px 16px' } } }))));
    }, [
        isPopUP,
        isQuestion,
        type,
        isTeacher,
        root_id,
        id,
        QNA_STATUS,
        status,
        data.author_name,
        data.creation_time,
        data.private,
        handleEditPage,
        handleDelete,
        totalCount,
        number,
        isSearchAnswer,
        importance,
    ]);
    return (React.createElement(QnAContentsStyled, { "$isTablet": isTablet, "$isMobile": isMobile, "$isTeacher": isTeacher },
        !isMobile && (React.createElement("div", { className: isQuestion ? 'qna-open-contents' : 'qna-open-contents border-pink', style: { display: 'flex', alignItems: 'center' } }, renderTitleButton)),
        React.createElement("div", { className: isQuestion ? 'qna-open-contents' : 'qna-open-contents border-pink', style: { flex: 1 } },
            renderTeacherControl,
            React.createElement("div", { className: "qna-content ql-snow" },
                React.createElement("div", { className: "content ql-editor", dangerouslySetInnerHTML: { __html: data.content } }),
                React.createElement(FileAttach, { id: "test", fileList: data.files, isRegist: false, isOnlyFile: true })),
            isMobile &&
                localId === author_id &&
                status === QNA_STATUS.CLOSED &&
                !isPopUP &&
                totalCount % 2 === 0 && (React.createElement(Button, { className: "btn_line_pink mobile-sm", text: t('supportRoom.requestion'), onClick: () => handleEditPage(root_id), styles: { buttonWrap: { padding: '10px 20px', width: '100%' } } })))));
});
