import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Button, Table } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { AttandancesTitle } from './AttandancesTitle';
import { Colors } from '../../../../Colors';
import { useConferenceAttendance } from '../../../../server';
import moment from 'moment';
const AttandancesTableStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: flex-end;
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.attend-list-003 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		overflow: hidden;
		width: 100%;
		min-width: ${props => (props.$isDesktop ? '700px' : props.$isTablet ? '650px' : 'unset')};

		/* .scroll-content {
			display: flex;
		} */

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: ${props => (props.$isDesktop ? '10px 28px 10px 28px' : '12px 20px 12px 20px')};
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				${props => props.$isDesktop
    ? `font-size: var(--b3-font-size, 16px);
                    line-height: var(--b3-line-height, 32px);
                    letter-spacing: var(--b3-letter-spacing, -0.02em);`
    : props.$isTablet
        ? `font-size: 14px;
                        line-height: 150%;
                        letter-spacing: -0.02em;`
        : `font-size: 12px;
                        line-height: 150%;
                        letter-spacing: -0.02em;`};

				font-weight: var(--b3-font-weight, 400);
				position: relative;
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: ${props => (props.$isDesktop ? '20px 28px 20px 28px' : '12px 20px 12px 20px')};
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			span {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				${props => props.$isDesktop
    ? `font-size: var(--b3-font-size, 16px);
                    line-height: var(--b3-line-height, 32px);
                    letter-spacing: var(--b3-letter-spacing, -0.02em);`
    : `font-size: 14px;
                        line-height: 150%;
                        letter-spacing: -0.02em;`};
				font-weight: var(--b3-font-weight, 400);
			}

			.green {
				color: var(--tur-l, #458c94);
			}

			.grey {
				color: var(--color-grey-l, #83868a);
			}

			.big {
				span {
					text-align: left;
					font-family: 'NotoSansKr-SemiBold', sans-serif;
					font-size: 14px;
				}
			}

			.bold {
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 600;
			}
		}

		.mobile_item {
			cursor: pointer;
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.one_line {
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: center;
				justify-content: flex-start;
				flex-shrink: 0;
				position: relative;
			}
		}
	}
`;
const renderTextContent = (headItem, item, totalCount) => {
    switch (headItem.title) {
        case 'nameAndStatus':
            return (React.createElement("div", { className: "one_line big overText2" },
                React.createElement("span", { className: "green" }, item.done ? (item.late ? '지각' : '출석') : '미출석'),
                React.createElement("span", { className: "bold" },
                    item.name,
                    "(",
                    item.id,
                    ")")));
        case 'enterAndLeave':
            return (React.createElement("div", { className: "one_line overText2" },
                React.createElement("span", null,
                    "\uC785\uC7A5 \uC2DC\uAC04:",
                    ' ',
                    item.enter_time ? moment(item.enter_time).format('HH:mm:ss') : '-'),
                React.createElement("span", { className: "one_line overText2" },
                    "\uD1F4\uC7A5 \uC2DC\uAC04:",
                    ' ',
                    item.leave_time ? moment(item.leave_time).format('HH:mm:ss') : '-')));
        case 'timeAndEtc':
            return (React.createElement("div", { className: "one_line overText2" },
                React.createElement("span", null,
                    "\uCC38\uC5EC \uC2DC\uAC04 : ",
                    item.attend_time,
                    "\uBD84")));
        case 'attend_time':
            return React.createElement("span", { className: "overText2" },
                item.attend_time,
                "\uBD84");
        case 'done':
            return (React.createElement("span", { className: "overText2" }, item.done ? (item.late ? '지각' : '출석') : '미출석'));
        case 'name':
            return React.createElement("span", { className: "overText2" }, item.name);
        case 'enter_time':
            return (React.createElement("span", { className: "overText2" }, item.enter_time ? moment(item.enter_time).format('HH:mm:ss') : '-'));
        case 'leave_time':
            return (React.createElement("span", { className: "overText2" }, item.leave_time ? moment(item.leave_time).format('HH:mm:ss') : '-'));
    }
    return React.createElement("span", { className: "overText2" }, item[headItem.title]);
};
export const AttandancesTable = React.memo(({ id }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [testData, setTestData] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [totalCount, setTotalCount] = React.useState(100);
    const [count, setCount] = React.useState(10);
    const [searchOption, setSearchOption] = React.useState({});
    const { data, refetch } = useConferenceAttendance({
        id,
        ...searchOption,
        offset: page === 0 ? 0 : page * count,
        limit: count,
    });
    useEffect(() => {
        if (searchOption || id)
            refetch();
    }, [id, page, searchOption]);
    useEffect(() => {
        if (data) {
            const { total_count, found_count, items } = data;
            if (total_count > 0) {
                if (total_count > 0 && found_count === 0) {
                    setPage(Math.max(page - 1, 0));
                    return;
                }
                setTestData(items);
                setTotalCount(total_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
        setPage(0);
    }, [data]);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        const headTitleList = isMobile
            ? []
            : [
                { title: 'name', space: 2, sort: false },
                { title: 'id', space: 2, sort: false },
                { title: 'participantTime', space: 1, sort: false },
                { title: 'attendanceStatus', space: 1, sort: false },
                { title: 'eneter_time', space: 2, sort: false },
                { title: 'exit_time', space: 2, sort: false },
                // { title: 'etc', space: 1, sort: false },
            ];
        const tableReadDataKeys = isMobile
            ? [
                { title: 'nameAndStatus', space: 3 },
                { title: 'enterAndLeave', space: 3 },
                { title: 'timeAndEtc', space: 3 },
            ]
            : [
                { title: 'name', space: 2 },
                { title: 'id', space: 2, sort: false },
                { title: 'attend_time', space: 1 },
                { title: 'done', space: 1 },
                { title: 'enter_time', space: 2, sort: false },
                { title: 'leave_time', space: 2, sort: false },
                // { title: 'etc', space: 1 },
            ];
        return { headTitleList, tableReadDataKeys };
    }, [isMobile]);
    const handlerMoreData = (page) => {
        setPage(page);
    };
    // 상단 레이아웃 검색 조건을 위한 action
    const handlerFilterList = (filter) => {
        const key = Object.keys(filter);
        if (filter[key[0]] === null && filter[key[1]] === null)
            setSearchOption({});
        else if (searchOption[key[0]] !== filter[key[0]] || searchOption[key[1]] !== filter[key[1]])
            setSearchOption({ ...searchOption, ...filter });
    };
    const renderTitle = useMemo(() => React.createElement(AttandancesTitle, { defaultStatus: "all", handlerFilterList: handlerFilterList }), [handlerFilterList]);
    const renderEmpty = useMemo(() => {
        if (testData.length === 0) {
            return (React.createElement(Button, { className: "empty_data", option: {
                    buttonBeforeIcon: {
                        show: true,
                        name: 'none',
                        size: 36,
                        color: Colors.grey,
                    },
                }, text: "\uC120\uD0DD\uB41C \uC218\uC5C5 \uBC0F \uCC38\uC5EC\uC790 \uC815\uBCF4\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4" }));
        }
    }, [testData]);
    const renderItem = (item, styles, index, isMobile) => {
        return tableReadDataKeys.map((headItem, index) => (React.createElement("div", { key: headItem.title + index, style: {
                flex: headItem.space,
                ...styles?.bodyItem,
                overflow: 'hidden',
            } }, renderTextContent(headItem, item, totalCount))));
    };
    return (React.createElement(AttandancesTableStyled, { "$isDesktop": isDesktop, "$isTablet": isTablet },
        React.createElement(Table, { className: "attend-list-003", isInifinite: false, data: testData, totalCount: totalCount, page: page, pagingCount: isTablet ? 3 : 5, headTitleList: headTitleList, renderTitle: renderTitle, renderEmpty: renderEmpty, renderItem: renderItem, handlerMoreData: handlerMoreData, forceShowheader: testData.length === 0 || isMobile ? false : true })));
});
