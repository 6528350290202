import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
const InfinityScrollStyled = styled.div `
	.more {
		color: transparent;
	}
`;
function useIntersectionObserver(callback) {
    const observer = useRef(new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting)
                callback();
        });
    }
    // { threshold: 0.2 }
    ));
    const observe = element => {
        if (!element)
            return;
        observer.current && observer.current.observe(element);
    };
    const unobserve = element => {
        if (!element)
            return;
        observer.current && observer.current.unobserve(element);
    };
    return [observe, unobserve];
}
const InfinityScroll = React.memo(({ page, data, totalCount, handlerMoreData }) => {
    const target = React.useRef(null);
    const [observe, unobserve] = useIntersectionObserver(() => handlerMoreData && handlerMoreData(page + 1));
    const [more, setMore] = useState('More');
    useEffect(() => {
        if (page === 0)
            observe(target.current);
        const N = data.length;
        if (0 === N || totalCount <= N) {
            unobserve(target.current);
        }
        if (totalCount <= N) {
            setMore(null);
        }
    }, [data]);
    // return (
    // 	<InfinityScrollStyled>
    // 		<div ref={target} className="more">
    // 			More...
    // 		</div>
    // 	</InfinityScrollStyled>
    // );
    return React.createElement("div", { ref: target }, " ");
});
export default InfinityScroll;
