import _ from 'lodash';
import throttle from 'lodash/throttle';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useGlobalState, useHistoryStore } from '../../../../GlobalStateProvider';
import { Button, CheckBox, CheckBoxElement, InputElement } from '../../../../common';
import { postModifySendSMS, postRegistSendSMS, useSendSMSList, } from '../../../../server/sms';
import { DATE_CHECK_LIST, NUMBER_CHECK_LIST } from '../constant';
import { useMutation } from 'react-query';
import { TOGGLE_POPUP } from '../../../base/popup';
import { DeletePopUpLayout } from '../../../base/layout/DeletePopUpLayout';
const SendSMSContentStyled = styled.div `
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	position: relative;

	${props => props.$isMobile
    ? `
				gap: 12px;
				flex: 1;
			  `
    : `
				background: var(--color-white, #ffffff);
				border-radius: 10px;
				border-style: solid;
				border-color: var(--color-grey-c, #e6e8ed);
				border-width: 1px;

				${props.$isDesktop
        ? `
							min-width: 450px;
							gap: 14px;
							padding: 26px;
						`
        : `
							min-width: 320px;
							gap: 12px;
							padding: 20px;
						`}
			  `}

	span,
	.overText {
		position: relative;
		text-align: left;

		${props => props.$isDesktop
    ? `
					font-size: var(--b3-font-size, 16px);
					line-height: var(--b3-line-height, 32px);
					letter-spacing: var(--b3-letter-spacing, -0.02em);
					font-weight: var(--b3-font-weight, 400);
				  `
    : `
					font-size: 14px;
					line-height: 150%;
					letter-spacing: -0.02em;
					font-weight: 400;
				  `}
	}

	.sms-wrap {
		flex-direction: column;
		flex: 1;
		gap: ${props => (props.$isDesktop ? '24px' : '12px')};
	}

	.send-wrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: ${props => (props.$isDesktop ? '14px' : '12px')};
	}

	textarea {
		&.focus {
			outline: 2px solid transparent;
			outline-offset: 2px;
			box-shadow: 0 0 5px $tur; // 흐림 효과 추가
		}

		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		width: 100%;
		min-height: 200px;
		resize: none;
		overflow: hidden;
		padding: 9px;

		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: 150%;
		font-weight: var(--b4-font-weight, 400);
	}

	.xmd {
		p {
			font-size: 14px;
			line-height: 20px;
			display: flex;
			justify-content: center;
		}
	}

	.btn_line_light_tur {
		color: var(--color-black, #101010);

		${props => props.$isDesktop
    ? `
					position: absolute;
					right: 24px;
					z-index: 1;
					p {
						font-size: var(--b4-font-size, 14px);
						line-height: var(--b4-line-height, 28px);
						letter-spacing: var(--b4-letter-spacing, -0.02em);
					}
					svg {
						color: var(--color-tur, #3ab2bf);
					}
				  `
    : `
					p {
						font-size: 12px;
						line-height: 150%;
						letter-spacing: -0.02em;
					}

					${props.$isTablet
        ? `
								padding: 4px 10px;
								position: absolute;
								right: 24px;
								z-index: 1;

								svg {
									color: var(--color-tur, #3ab2bf);
								}
							`
        : `
								padding: 6px 10px;
							`}
				  `}
	}

	.message-text {
		min-height: ${props => (props.$isDesktop ? '35px' : props.$isTablet ? '25px' : '20px')};
	}

	.send-type {
		span {
			font-size: ${props => (props.$isDesktop ? '14px' : '12px')};
		}
	}

	.input_send {
	}

	${props => props.$isMobile &&
    `
			.mobile-full {
				border-radius: 6px;
				p {
					justify-content: center;
				}
			}

			.btn-container {
				width: 100%;
			}
		`}
`;
export const SendSMSContent = React.memo(({ setIsOpen, editId, setEditId, openMemberList, receiverMemberList, setOpenMemberList, setReceiverMemberList, setMobilePage, }) => {
    const { t } = useTranslation();
    const historyStore = useHistoryStore();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [isImmediately, setIsImmediately] = React.useState(true); //발송일자 checkBox
    const [isFixNumber, setIsFixNumber] = React.useState(true); //발신번호 checkBox
    const [startDate, setStartDate] = useState(''); // 발송일
    const [startTime, setStartTime] = useState(''); // 발송시간
    const [directNumber, setDirectNumber] = useState(''); // 발신번호
    const [message, setMessage] = useState(''); // 발송 메시지
    const [isCancel, setIsCancel] = useState(false); //수정->취소 눌렀을 때만 새로고침
    const localValue = localStorage.getItem('learningManagement_props'); //로컬 스토리지에 저장된 데이터
    // 현재 시간
    const now = useMemo(() => moment(), []);
    const registSendSMSMutation = useMutation(postRegistSendSMS);
    const modifySendSMSMutation = useMutation(postModifySendSMS);
    const { data, refetch } = useSendSMSList({
        id: editId,
    });
    const refetchList = throttle(() => refetch(), 1000, { leading: true, trailing: false });
    useEffect(() => {
        //모바일 수신자 -> 발송 페이지 이동시 데이터 유지
        if (isMobile) {
            const recentPage = historyStore.getRecentPageHistory('mobileSend');
            //메시지 발송 처음 들어갈 경우 초기화
            if (localValue !== 'undefined' && !editId) {
                const parsedValue = JSON.parse(localValue).historyItem?.props;
                if (parsedValue.mobilePage === 'list') {
                    cleanup();
                    return;
                }
            }
            if (recentPage && recentPage.props) {
                setMessage(recentPage.props.message);
                setIsImmediately(recentPage.props.isImmediately);
                if (!recentPage.props.isImmediately) {
                    setStartDate(recentPage.props.startDate);
                    setStartTime(recentPage.props.startTime);
                }
                setIsFixNumber(recentPage.props.isFixNumber);
                if (!recentPage.props.isFixNumber) {
                    setDirectNumber(recentPage.props.directNumber);
                }
            }
        }
    }, [isMobile, editId]);
    // 예약 클릭 시, 시간
    // useEffect(() => {
    // 	if ((startDate === '' || startTime === '') && !isMobile) {
    // 		const now = moment();
    // 		setStartDate(now.format('YYYY-MM-DD'));
    // 		setStartTime(now.add(2, 'minutes').format('HH:mm'));
    // 	}
    // }, [isImmediately]);
    const cleanup = () => {
        setIsImmediately(true);
        setStartDate(moment().format('YYYY-MM-DD'));
        setStartTime(moment().format('HH:mm'));
        setIsFixNumber(true);
        setDirectNumber('');
        setMessage('');
        setReceiverMemberList([]);
        setIsOpen(false);
        setOpenMemberList(false);
        APP.eventManager.publish('MODIFY_SMS', undefined);
    };
    useEffect(() => {
        if (isCancel) {
            refetchList();
            setIsCancel(false);
        }
        if (!isMobile) {
            cleanup();
        }
    }, [isMobile, isCancel]);
    useEffect(() => {
        const parsedValue = isMobile && JSON.parse(localValue).historyItem?.props;
        if (isMobile ? data && parsedValue?.mobilePage === 'list' : data) {
            const { items } = data;
            if (items && items.length >= 1) {
                const item = items[0];
                setIsImmediately(item.send_time === '' ? true : false);
                setStartDate(moment(item.send_time).format('YYYY-MM-DD'));
                setStartTime(moment(item.send_time).format('HH:mm'));
                setIsFixNumber(item.phone_number.includes('*') ? true : false);
                setDirectNumber(item.phone_number.includes('*') ? '' : item.phone_number);
                setMessage(item.content);
                const newReceiverMemberList = !_.isEqual(receiverMemberList, item.receivers) &&
                    receiverMemberList.length !== 0
                    ? receiverMemberList
                    : item.receivers;
                // @ts-ignore
                setReceiverMemberList(isMobile ? newReceiverMemberList : item.receivers);
                return;
            }
        }
        !isMobile && cleanup();
    }, [data]);
    const handlerCancel = () => {
        if (setIsOpen) {
            cleanup();
            setEditId('');
            setIsCancel(true);
            if (isMobile) {
                setMobilePage('list');
                setIsOpen(false);
            }
        }
    };
    const validatePhoneNumber = phoneNumber => {
        // 한국 전화번호 형식 검증: 010-1234-5678, 01012345678, 011-123-4567, 016-123-4567 등
        const phoneRegex = /^(01[016789]-?\d{3,4}-?\d{4}|070-\d{3,4}-\d{4}|0[2-9]{1}\d{1,2}-?\d{3,4}-?\d{4})$/;
        return phoneRegex.test(phoneNumber);
    };
    const handleChange = value => {
        if (/^[\d]*$/.test(value)) {
            setDirectNumber(value);
        }
        else if (/^[\d-]*$/.test(value)) {
            alert('‘-’를 제외하고 전화번호를 입력해주세요.');
        }
        else {
            alert('숫자만 입력해주세요.');
        }
    };
    const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
    // (onClick) 보내기
    const handlerSend = async () => {
        if (receiverMemberList.length === 0) {
            return alert(t('sendSMS.selectMember'));
        }
        if (!isFixNumber && !validatePhoneNumber(directNumber)) {
            return alert(t('sendSMS.retypeNumber'));
        }
        if (message.trim().length === 0) {
            return alert(t('sendSMS.retypeMessage'));
        }
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DeletePopUpLayout,
            componentProps: {
                deleteContent: editId ? t('sendSMS.editSMSPopUp') : t('sendSMS.submitSMSPopUp'),
                onRegist: hanlerSubmmit,
            },
            width: 639,
            title: editId ? t('sendSMS.editSMS') : t('sendSMS.submitSMS'),
            isFit: true,
        });
    };
    const hanlerSubmmit = async () => {
        if (editId) {
            try {
                APP.eventManager.publish('SHOW_LOADING', true);
                await modifySendSMSMutation.mutateAsync({
                    id: editId,
                    props: {
                        content: message,
                        phone_number: isFixNumber ? '' : directNumber,
                        receivers: receiverMemberList.map(item => item.id),
                        send_time: isImmediately
                            ? null
                            : moment(`${startDate} ${startTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                    },
                });
                APP.eventManager.publish('UPDATE_SEND_SMS');
                setEditId('');
                refetchList();
                cleanup();
                if (isMobile) {
                    setMobilePage('list');
                    setIsOpen(false);
                }
            }
            catch (error) {
                console.error('Failed to post modifySendSMS:', error);
                alert(error);
            }
            finally {
                APP.eventManager.publish('SHOW_LOADING', false);
                closePopup();
            }
        }
        else {
            try {
                APP.eventManager.publish('SHOW_LOADING', true);
                await registSendSMSMutation.mutateAsync({
                    content: message,
                    phone_number: isFixNumber ? '' : directNumber,
                    receivers: receiverMemberList.map(item => item.id),
                    send_time: isImmediately
                        ? null
                        : moment(`${startDate} ${startTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                });
                APP.eventManager.publish('UPDATE_SEND_SMS');
                setReceiverMemberList([]);
                cleanup();
                if (isMobile) {
                    setMobilePage('list');
                    setIsOpen(false);
                }
            }
            catch (error) {
                console.error('Failed to post registSendSMS:', error);
                alert(error);
            }
            finally {
                APP.eventManager.publish('SHOW_LOADING', false);
                closePopup();
            }
        }
    };
    const handlerOpenMemberList = () => {
        setOpenMemberList(!openMemberList);
        if (isMobile) {
            const historyItem = {
                props: {
                    isImmediately,
                    startDate,
                    startTime,
                    isFixNumber,
                    directNumber,
                    message,
                },
                pageName: 'mobileSend',
            };
            historyStore.pushHistory(historyItem);
        }
    };
    return (React.createElement(SendSMSContentStyled, { className: "send-sms-content", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        React.createElement(Button, { className: "btn_line_light_tur md_round", text: `수신자 ( ${receiverMemberList?.length > 0
                ? receiverMemberList.length.toString().padStart(2, '0')
                : '00'} ) 명`, option: {
                buttonAfterIcon: {
                    show: true,
                    className: openMemberList ? 'arrow-left' : 'arrow-right',
                    name: 'twoarrow',
                    size: 16,
                },
            }, onClick: () => handlerOpenMemberList() }),
        React.createElement("div", { className: "field-global-001 sms-wrap" },
            React.createElement("div", { className: "field-short" },
                React.createElement("p", { className: "overText" }, t('sendSMS.sendType')),
                React.createElement(CheckBoxElement, { id: "sendType", className: "send-type", title: t('sendSMS.message'), checked: true, handlerCheck: () => { } })),
            React.createElement("div", { className: "send-wrap" },
                React.createElement("div", { className: "field-short" },
                    React.createElement("p", { className: "overText" }, t('sendSMS.sendDate')),
                    React.createElement(CheckBox, { isRadioButton: true, isShowCheckBox: true, list: DATE_CHECK_LIST, defaultCheckId: isImmediately ? 'now' : 'reservation', handlerCheck: (id, checked) => {
                            setIsImmediately(id === 'now');
                        } })),
                !isImmediately && (React.createElement("div", { className: "field-short" },
                    React.createElement("div", { className: `fext-field ${isMobile ? 'mobile-date-wrap' : ''}` },
                        React.createElement(InputElement, { type: "date", className: "input_send", value: startDate, onChange: setStartDate, min: now.format('YYYY-MM-DD') })),
                    React.createElement("div", { className: `fext-field ${isMobile ? 'mobile-date-wrap' : ''}` },
                        React.createElement(InputElement, { type: "time", className: "input_send", value: startTime, onChange: setStartTime }))))),
            React.createElement("div", { className: "send-wrap" },
                React.createElement("div", { className: "field-short" },
                    React.createElement("p", { className: "overText" }, t('sendSMS.sendNumber')),
                    React.createElement(CheckBox, { isRadioButton: true, isShowCheckBox: true, list: NUMBER_CHECK_LIST, defaultCheckId: isFixNumber ? 'fix' : 'direct', handlerCheck: (id, checked) => {
                            setIsFixNumber(id === 'fix');
                        } })),
                !isFixNumber && (React.createElement("div", { className: "fext-field" },
                    React.createElement(InputElement, { type: "text", placeholder: "\uBC88\uD638\uC785\uB825", value: directNumber, onChange: handleChange })))),
            React.createElement("div", { className: "send-wrap", style: { flex: '1' } },
                React.createElement("p", { className: "overText message-text", style: { width: '100%' } }, t('sendSMS.messageContent')),
                React.createElement(InputElement, { type: "text", textarea: true, isAutoHeight: true, value: message, onChange: setMessage, placeholder: t('sendSMS.messageContent'), style: { minHeight: '95%' } }))),
        editId ? (React.createElement("div", { className: "btn-container" },
            React.createElement(Button, { className: `btn_line_pink ${isMobile ? 'mobile-full' : 'xmd'}`, text: t('common.cancel'), onClick: handlerCancel }),
            React.createElement(Button, { className: `btn_default ${isMobile ? 'mobile-full' : 'xmd'}`, text: t('common.modify'), onClick: handlerSend }))) : (React.createElement(Button, { className: `btn_default ${isMobile ? 'mobile-full' : 'xmd'}`, text: "\uBCF4\uB0B4\uAE30", onClick: handlerSend, styles: { buttonWrap: { marginTop: '12px' } } }))));
});
