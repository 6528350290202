import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { Button, SearchBar } from '../../../../common';
import { objectToQueryString } from '../../../../server/functions';
import { getAccessToken } from '../../../base/account';
import { getClassesId } from '../../../base/classes';
import DateRangeSelector from './DateRangeSelector';
import { QnAFilterType } from './QnAFilterType';
import { QnAStateDropdown } from './QnAStateDropdown';
const QnAListHeaderStyled = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;

	gap: ${props => (props.$isDesktop ? '14px' : '12px')};

	.active-bar {
		display: flex;
		flex-direction: row;
		gap: 14px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		flex-wrap: wrap;
		flex: 1;
	}

	.btn_light_grey {
		padding: 8px 10px;
	}

	button {
		p {
			font-size: var(--b5-font-size, 12px);
			line-height: var(--b5-line-height, 22px);
			letter-spacing: var(--b5-letter-spacing, -0.02em);
		}
	}

	.info-wrap {
		display: flex;
		${props => props.$isMobile
    ? css `
						gap: 12px;
						flex-direction: column;
				  `
    : css `
						gap: 12px;
						width: unset;
				  `}
	}

	.fext-field {
		input {
			border-color: var(--color-grey-w, #e6e8ed) !important;
		}
	}

	.qna-check {
		.checkbox-item {
			svg {
				width: 22px;
				height: 22px;
			}
			span {
				font-size: var(--b5-font-size, 12px);
				line-height: var(--b5-line-height, 22px);
			}
		}
	}

	.btn_line_pink {
		p {
			font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
			font-weight: var(--h5-font-weight, 700);
			${props => props.$isDesktop &&
    `
					font-size: var(--h5-font-size, 16px);
					line-height: var(--h5-line-height, 24px);
					letter-spacing: var(--h5-letter-spacing, -0.02em);
				`}
		}
	}

	.question-info {
		display: flex;
		flex-direction: row;
		gap: 8px;

		.grey {
			color: var(--color-grey-l, #83868a);
		}

		.pur {
			color: var(--pur-d, #8268d9);
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-weight: 700;
		}

		.pink {
			color: var(--color-pink, #f46790);
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-weight: 700;
		}

		p {
			${props => props.$isMobile
    ? css `
							font-size: 10px;
							line-height: 150%;
							letter-spacing: -0.02em;
							font-weight: 400;
					  `
    : css `
							font-size: var(--b4-font-size, 14px);
							line-height: var(--b4-line-height, 28px);
							letter-spacing: var(--b4-letter-spacing, -0.02em);
							font-weight: var(--b4-font-weight, 400);
					  `}
		}
	}

	${props => props.$isDesktop &&
    css `
			.search-bar-qna {
				input {
					font-size: var(--b4-font-size, 14px) !important;
					line-height: var(--b4-line-height, 28px);
					letter-spacing: var(--b4-letter-spacing, -0.02em);
					font-weight: var(--b4-font-weight, 400);
				}
			}
		`}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : '')}
`;
const mobileStyles = css `
	.drop-wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}

	.question-count-btn {
		border-radius: 50px;
		padding: 6px 10px 6px 10px;
		p {
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-size: 10px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 700;
		}
	}

	.btn-container {
		width: 100%;
		.mobile-full {
			p {
				justify-content: center;
			}
		}
	}
`;
const tabletStyles = css `
	.drop-wrap {
		display: flex;
		gap: 6px;
	}
	.qna-check {
		gap: 6px;
		label {
			span {
				font-size: 12px;
			}
		}
	}
`;
export const QnAListInfo = React.memo(({ isTeacher, setStartDate, startDate, setEndDate, endDate, totalCount, openCount, closedCount, searchOption, handlerSearch, handlerFilterList, }) => {
    const access_token = useSelector((state) => getAccessToken(state));
    const { isTablet, isMobile, isDesktop } = useGlobalState();
    const { t } = useTranslation();
    const classesId = getClassesId();
    const [type, setType] = React.useState('');
    const [checkItem, setCheckItem] = React.useState('');
    const [initLoad, setInitLoad] = React.useState(true);
    useEffect(() => {
        try {
            const key = Object.keys(searchOption)[0];
            if (key === type) {
                setInitLoad(true);
                return;
            }
            setType(key);
            setCheckItem(searchOption[key] || undefined);
        }
        catch {
            setType('');
            setCheckItem(undefined);
        }
        setInitLoad(true);
        return () => {
            setInitLoad(false);
        };
    }, [searchOption]);
    const renderQuestionCount = useMemo(() => {
        return (React.createElement(Button, { className: `btn_line_pink ${isMobile ? 'question-count-btn' : ''}`, styles: isMobile
                ? {}
                : {
                    buttonWrap: {
                        minWidth: 260,
                        borderRadius: '50px',
                        padding: '10px 24px',
                        boxShadow: 'unset',
                    },
                }, text: t('supportRoom.questionCount', { count: openCount ? openCount : 0 }) }));
    }, [openCount]);
    const renderQuestionInfo = useMemo(() => {
        return (React.createElement("div", { className: "info-wrap", style: { flexDirection: 'row' } },
            React.createElement("div", { className: "question-info" },
                React.createElement("p", { className: "grey" }, t('supportRoom.allQuestions')),
                React.createElement("p", null, totalCount)),
            React.createElement("div", { className: "question-info" },
                React.createElement("p", { className: "grey" }, t('supportRoom.waitingAnswer')),
                React.createElement("p", { className: "pur" }, openCount)),
            React.createElement("div", { className: "question-info" },
                React.createElement("p", { className: "grey" }, t('supportRoom.allAnswers')),
                React.createElement("p", { className: "pink" }, closedCount))));
    }, [totalCount, openCount, closedCount]);
    //다운로드 버튼 클릭 시 동작
    const handlerDownload = async () => {
        const parseString = objectToQueryString({ download_tz: '+09:00', ...searchOption });
        const url = `/api/mimac/v1/qnas?${parseString}&class_id=${classesId}`;
        await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${access_token}`, // 필요한 경우 헤더에 토큰 추가
            },
        })
            .then(async (res) => {
            if (res.status === 200) {
                const contentDisposition = res.headers.get('Content-Disposition');
                let filename = 'download.xlsx'; // 기본 파일명 설정
                if (contentDisposition) {
                    const filenameRegex = /filename(?:\*=([^;\n]*)|[^;\n]*=["']?([^"\';\n]*)["']?)/;
                    const match = filenameRegex.exec(contentDisposition);
                    if (match) {
                        const encodedFilename = match[1] || match[2];
                        if (encodedFilename) {
                            try {
                                filename = decodeURIComponent(encodedFilename.replace(/['"]/g, ''));
                            }
                            catch (e) {
                                console.error('Filename decoding error:', e);
                            }
                        }
                    }
                }
                const blob = await res.blob();
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename; // 동적으로 파일명 설정
                document.body.appendChild(a); // a 요소를 문서에 추가
                a.click();
                document.body.removeChild(a); // a 요소를 문서에서 제거
                URL.revokeObjectURL(url); // 메모리 해제
            }
            else if (res.status === 404) {
                throw new Error('검색된 Q&A가 없습니다.');
            }
            else {
                throw new Error('파일을 다운로드할 수 없습니다.');
            }
        })
            .catch(error => {
            console.error('Error fetching file:', error);
            alert(error);
        });
    };
    const renderSeachOption = useMemo(() => (React.createElement(React.Fragment, null,
        React.createElement(QnAFilterType, { isTeacher: isTeacher, type: type, searchCheckItem: checkItem, setType: setType, handlerFilterList: handlerFilterList }),
        React.createElement(QnAStateDropdown, { type: type, searchCheckItem: checkItem, handlerFilterList: handlerFilterList }))), [isTeacher, type, checkItem, handlerFilterList]);
    if (!initLoad)
        return null;
    return (React.createElement(QnAListHeaderStyled, { "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        React.createElement("div", { className: "active-bar" },
            !isDesktop ? (React.createElement("div", { className: "drop-wrap" }, renderSeachOption)) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "field-global-001 info-wrap" },
                    isDesktop && (React.createElement(Button, { className: "btn_light_grey xs", text: "\uC720\uD615", styles: {
                            buttonWrap: {
                                width: '66px',
                            },
                        } })),
                    renderSeachOption))),
            React.createElement("div", { className: "field-global-001 info-wrap" },
                isDesktop && (React.createElement(Button, { className: "btn_light_grey xs", text: t('supportRoom.dateSearch'), styles: {
                        buttonWrap: {
                            width: '66px',
                        },
                    } })),
                React.createElement("div", { className: "fext-field", style: { width: !isMobile && 'unset' } },
                    React.createElement(DateRangeSelector, { defaultStartDate: startDate, defaultEndDate: endDate, setStartDate: setStartDate, setEndDate: setEndDate }))),
            React.createElement("div", { className: "field-global-001 info-wrap" },
                React.createElement(SearchBar, { className: `${isDesktop ? 'search-bar-qna' : 'search-bar-mobile'}`, filterData: type, defaultSearchContent: checkItem, isDisable: ['status', 'category'].includes(type) ? true : false, isIconHide: true, handlerFilterList: value => {
                        if (!type)
                            return;
                        handlerFilterList(value);
                    } }),
                React.createElement("div", { className: "btn-container" },
                    React.createElement(Button, { className: `btn_light_pur ${isMobile ? 'mobile-full' : 'xmd'}`, text: t('supportRoom.search'), styles: {
                            buttonWrap: {
                                maxWidth: !isMobile && '80px',
                                height: '40px',
                            },
                        }, onClick: () => handlerSearch() }),
                    isTeacher && (React.createElement(Button, { className: `btn_line_pur ${isMobile ? 'mobile-full' : 'xmd'}`, text: t('common.download'), styles: {
                            buttonWrap: {
                                maxWidth: !isMobile && '80px',
                                height: '40px',
                            },
                        }, onClick: handlerDownload }))))),
        isTeacher && (React.createElement("div", { style: { display: !isMobile && 'flex' } },
            React.createElement("div", { className: "field-global-001" }, isDesktop ? (renderQuestionCount) : (React.createElement("div", { className: "question-count", style: { width: '100%' } }, renderQuestionCount))),
            React.createElement("div", { className: "field-global-001", style: { justifyContent: isMobile ? 'flex-start' : 'flex-end' } }, renderQuestionInfo)))));
});
