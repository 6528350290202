import COUNTRIES_RESOURCES from 'i18n-iso-countries/langs/en.json';
// @ts-ignore
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// @ts-ignore
import I18nextXHRBackend from 'i18next-xhr-backend';
import 'intl-pluralrules';
import _ from 'lodash';
import { I18NEXT_INITIALIZED, LANGUAGE_CHANGED } from './actionTypes';
import LANGUAGES_RESOURCES from './resource/languages.json';
import MAIN_RESOURCES from './resource/main.json';
import TRANSLATION_LANGUAGES_RESOURCES from './resource/translation-languages.json';
// @ts-ignores
import languageDetector from './languageDetector';
/**
 * Override certain country names.
 */
const COUNTRIES_RESOURCES_OVERRIDES = {
    countries: {
        ko: 'Korean',
    },
};
/**
 * Merged country names.
 */
const COUNTRIES = _.merge({}, COUNTRIES_RESOURCES, COUNTRIES_RESOURCES_OVERRIDES);
/**
 * The available/supported languages.
 *
 * @public
 * @type {Array<string>}
 */
export const LANGUAGES = Object.keys(LANGUAGES_RESOURCES);
/**
 * The available/supported translation languages.
 *
 * @public
 * @type {Array<string>}
 */
export const TRANSLATION_LANGUAGES = Object.keys(TRANSLATION_LANGUAGES_RESOURCES);
/**
 * The available/supported translation languages head. (Languages displayed on the top ).
 *
 * @public
 * @type {Array<string>}
 */
export const TRANSLATION_LANGUAGES_HEAD = ['ko'];
/**
 * The default language.
 *
 * English is the default language.
 *
 * @public
 * @type {string} The default language.
 */
export const DEFAULT_LANGUAGE = 'ko';
/**
 * The options to initialize i18next with.
 *
 * @type {Object}
 */
const options = {
    defaultNS: 'main',
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    load: 'languageOnly',
    ns: ['main', 'languages', 'countries', 'translation-languages'],
    react: {
        // re-render when a new resource bundle is added
        bindI18nStore: 'added',
        useSuspense: false,
    },
    returnEmptyString: false,
    returnNull: false,
    // LANGUAGES.
    whitelist: LANGUAGES.slice(),
};
i18next
    .use(
// @ts-ignore
navigator.product === 'ReactNative' ? initReactI18next : I18nextXHRBackend)
    .use(languageDetector)
    // @ts-ignore
    .init(options);
// Add default language which is preloaded from the source code.
i18next.addResourceBundle(DEFAULT_LANGUAGE, 'countries', COUNTRIES, 
/* deep */ true, 
/* overwrite */ true);
i18next.addResourceBundle(DEFAULT_LANGUAGE, 'languages', LANGUAGES_RESOURCES, 
/* deep */ true, 
/* overwrite */ true);
i18next.addResourceBundle(DEFAULT_LANGUAGE, 'translation-languages', TRANSLATION_LANGUAGES_RESOURCES, 
/* deep */ true, 
/* overwrite */ true);
i18next.addResourceBundle(DEFAULT_LANGUAGE, 'main', MAIN_RESOURCES, 
/* deep */ true, 
/* overwrite */ true);
// Add builtin languages.
// XXX: Note we are using require here, because we want the side-effects of the
// import, but imports can only be placed at the top, and it would be too early,
// since i18next is not yet initialized at that point.
require('./BuiltinLanguages');
// Label change through dynamic branding is available only for web
if (typeof APP !== 'undefined') {
    i18next.on('initialized', () => {
        APP.store.dispatch({ type: I18NEXT_INITIALIZED });
    });
    i18next.on('languageChanged', () => {
        APP.store.dispatch({ type: LANGUAGE_CHANGED });
    });
}
export default i18next;
