import React from 'react';
import { RegistButton } from '../RegistButton';
import { ReservationTable } from './component/DSTT001_List_Child';
import { ReviewClassList } from '../../DSS/DSST001/DSST001_List_Child';
import styled from 'styled-components';
const DSTT001_ListStyled = styled.div `
	width: 100%;
	display: flex;
	/* height: 100%; */
	flex: 1;
	.reservation-table {
		width: 100%;
		height: 100%;
	}

	${props => !props.$isTeacher &&
    `display: flex; 
		flex-direction: column; 
		gap: 40px;  
		.review_class_list {
			min-height: 342px;
		}`}
`;
export const DSTT001_List = React.memo(({ isClosed = false, isPrevious = false, isTeacher = false, }) => {
    const handlerButtonClick = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'regist',
            id: undefined,
        });
    };
    return (React.createElement(DSTT001_ListStyled, { "$isTeacher": isTeacher },
        React.createElement(ReservationTable, { isClosed: isClosed, isPrevious: isPrevious, isTeacher: isTeacher }),
        isTeacher ? (isPrevious ? null : (React.createElement(RegistButton, { handlerButtonClick: handlerButtonClick }))) : (React.createElement(ReviewClassList, null))));
});
