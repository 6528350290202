import React, { useMemo, useRef, useState } from 'react';
import { Colors } from '../../../../../Colors';
import { Button, DropDown } from '../../../../../common';
import FullScreenBtn from './FullScreenBtn';
const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
const isMobileDevice = () => {
    const mobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return (mobileDevice ||
        (window.matchMedia('(max-width: 767px)').matches &&
            window.matchMedia('(min-height: 450px)').matches));
};
let customTime;
const CustomControls = ({ videoRef, playing, onPlay, onPause, duration, currentTime, onSeek, volume, onVolumeChange, handlerFullScreen, handlerExitFullScreen, availableQualities, selectedQuality, handleQualityChange, }) => {
    const [isHovering, setIsHovering] = useState(true);
    const progressRef = useRef(null);
    const handlePlayPause = () => {
        if (playing) {
            onPause();
        }
        else {
            onPlay();
        }
    };
    const handleSeekChange = (e) => {
        const progressBarElement = progressRef.current;
        if (!progressBarElement)
            return;
        const rect = progressBarElement.getBoundingClientRect();
        const seekPosition = (e.clientX - rect.left) / rect.width;
        onSeek(seekPosition * duration);
    };
    const handleVolumeIconClick = () => {
        onVolumeChange(volume === 0 ? 1 : 0);
    };
    const progressPercentage = isNaN(currentTime / duration)
        ? 0
        : Math.max((currentTime / duration) * 100, 0);
    const handleMouseEnter = () => {
        if (customTime)
            clearTimeout(customTime);
        setIsHovering(true);
    };
    const handleMouseLeave = () => {
        customTime = setTimeout(() => setIsHovering(false), 3000);
    };
    const handleMouseMove = () => {
        if (customTime)
            clearTimeout(customTime);
        if (!isHovering)
            setIsHovering(true);
        customTime = setTimeout(() => setIsHovering(false), 5000);
    };
    const qualityOptions = useMemo(() => [
        { id: 'auto', title: 'Auto' },
        ...availableQualities.map(quality => ({
            id: quality.qualityIndex.toString(),
            title: `${quality.height}p (${quality.bitrate} kbps)`,
        })),
    ], [availableQualities]);
    return (React.createElement("div", { className: "custom-controls", onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onMouseMove: handleMouseMove, onTouchStart: handleMouseEnter, onTouchEnd: handleMouseLeave },
        React.createElement("div", { className: "controls-container", style: { display: isHovering ? 'flex' : 'none' } },
            React.createElement("div", { id: "progress-bar", className: "progress-bar-container", ref: progressRef, onClick: handleSeekChange },
                React.createElement("div", { className: "full-progress-bar", style: { width: `${progressPercentage}%` } }),
                React.createElement("div", { className: "progress-bar", style: { left: `${progressPercentage}%` } })),
            React.createElement("div", { className: "controls-row" },
                React.createElement("div", { className: "left_wrap" },
                    React.createElement(Button, { option: {
                            buttonBeforeIcon: {
                                show: true,
                                name: playing ? 'vodPause' : 'vodStart',
                                size: 25,
                                color: playing ? '#8bacff' : '#fff',
                                className: 'handlerIcon',
                            },
                        }, onClick: handlePlayPause }),
                    React.createElement("div", { className: "time-display" },
                        formatTime(currentTime),
                        " / ",
                        formatTime(duration)),
                    React.createElement("div", { className: "volume-control" },
                        React.createElement(Button, { option: {
                                buttonBeforeIcon: {
                                    show: true,
                                    name: volume === 0 ? 'vodVolumeOff' : 'vodVolumeOn',
                                    size: 25,
                                    color: volume === 0 ? Colors.accent : '#fff',
                                    className: 'handlerIcon',
                                },
                            }, onClick: handleVolumeIconClick }),
                        !isMobileDevice() && (React.createElement("input", { className: "volume-slider", type: "range", min: "0", max: "1", step: "0.1", value: volume, onChange: e => onVolumeChange(parseFloat(e.target.value)) })))),
                React.createElement("div", { className: "left_wrap" },
                    React.createElement("div", { className: "quality-selector" },
                        React.createElement(DropDown, { noShowButtonArea: true, className: "dropdown-live", value: qualityOptions.find(option => option.id === selectedQuality.toString()), list: qualityOptions, setDropValue: item => {
                                handleQualityChange(item.id === 'auto' ? 'auto' : parseInt(item.id, 10));
                            }, styles: {
                                dropWrap: {
                                    minWidth: 120,
                                    maxWidth: 200,
                                },
                                titleWrap: {
                                    background: Colors.transparent,
                                },
                                titleText: {
                                    color: Colors.white,
                                },
                            }, option: {
                                downIcon: {
                                    color: '#fff',
                                },
                            }, isDisable: false })),
                    React.createElement(FullScreenBtn, { autoPlay: false, className: "screen-full-btn", video: videoRef.current, handlerFullScreen: handlerFullScreen, handlerExitFullScreen: handlerExitFullScreen }))))));
};
export default CustomControls;
