import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckBoxElement, InputElement } from '../../../../../common';
import moment from 'moment';
import { useGlobalState } from '../../../../../GlobalStateProvider';
// 시작일 컴포넌트
export const StartDate = React.memo(({ startDate, startTime, setStartDate, setStartTime, updateStartNow, }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    const now = moment();
    const [startNow, setStartNow] = React.useState(false); // 바로 시작하기
    const handlerClick = () => {
        const changeDate = !startNow;
        updateStartNow(changeDate);
        setStartNow(changeDate);
    };
    return (React.createElement("div", { className: "field-global-001", style: { alignItems: isMobile && 'flex-start' } },
        React.createElement("div", { className: "task-label" }, t('assignment.startDate')),
        React.createElement("div", { className: `fext-field ${isMobile ? 'mobile-date-wrap' : ''}` },
            React.createElement(InputElement, { type: "date", value: startDate, onChange: setStartDate, min: now.format('YYYY-MM-DD') }),
            React.createElement(InputElement, { type: "time", value: startTime, onChange: setStartTime }),
            React.createElement(CheckBoxElement, { className: "assignment-checkbox-txt", style: { gap: 6 }, id: "1", title: "assignment.startNow", checked: startNow, handlerCheck: handlerClick }))));
});
export const EndDate = React.memo(({ endDate, endTime, setEndDate, setEndTime, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet } = useGlobalState();
    const now = moment();
    return (React.createElement("div", { className: "field-global-001", style: isMobile
            ? { width: '100%', alignItems: 'flex-start' }
            : { width: 'calc(100% - 120px)' } },
        React.createElement("div", { className: "task-label" }, t('assignment.endDate')),
        React.createElement("div", { className: `fext-field ${isMobile ? 'mobile-date-wrap' : ''}` },
            React.createElement(InputElement, { type: "date", value: endDate, onChange: setEndDate, min: now.format('YYYY-MM-DD'), style: { minWidth: isTablet ? '184px' : 'unset' } }),
            React.createElement(InputElement, { type: "time", value: endTime, onChange: setEndTime, style: { minWidth: isTablet ? '184px' : 'unset' } }))));
});
// 시작일 컴포넌트
export const ProgressDate = React.memo(({ startDate, startTime, setStartDate, setStartTime, }) => {
    const { t } = useTranslation();
    const now = moment();
    const updateDate = (date) => {
        setStartDate(date);
    };
    return (React.createElement("div", { className: "field-global-001" },
        React.createElement("div", { className: "task-label" }, t('assignment.startDate')),
        React.createElement("div", { className: "fext-field" },
            React.createElement(InputElement, { type: "date", value: startDate, onChange: updateDate, min: now.format('YYYY-MM-DD') }))));
});
