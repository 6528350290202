import _ from 'lodash';
import { throttle } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { Table } from '../../../../common';
import { useMemberInfo } from '../../../../server';
import { SendMemberHeaader } from './SendMemberHeader';
const SendMembersTableStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 14px;
	align-items: flex-start;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;

	.name {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.dropdown {
		width: 100%;
	}

	.body-item {
		position: relative;
	}

	.dropdown-list {
		top: 48px;
	}

	.sms-member-list {
		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			position: relative;
			overflow: auto;
		}
	}

	.paging {
		margin-top: 20px;
		gap: 10px;

		button {
			width: 18px;
			height: 18px;

			p {
				min-width: unset;
			}
		}
	}

	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileStyles = css `
	.table-item-wrap {
		.body-item {
			width: unset;
		}
	}

	.sms-member-list {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
		flex: 1;

		.item {
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px 0px 0px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: 12px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
			}

			.grey {
				color: var(--color-grey-l, #83868a);
				font-size: var(--b4-font-size, 12px);
			}
		}
	}

	.table-header-item {
		padding-bottom: 10px;
	}
	.all-checked {
		position: absolute;
		display: flex;
		top: 82px;
		left: 29px;
		font-size: 12px;
	}
`;
const tabletStyles = css `
	background: var(--color-white, #ffffff);
	display: flex;
	flex-direction: column;
	gap: 14px;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	min-width: 150px;
	flex: 1;

	.sms-member-list {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		align-self: stretch;
		flex: 1;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 6px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;

			position: relative;
			text-align: left;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: 10px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;

			.table-header-item {
				min-width: unset !important;
				svg {
					width: 18px;
					min-width: unset;
				}
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 16px 12px 16px;
			display: flex;
			flex-direction: row;
			gap: 6px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: 10px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;

			.body-item {
				min-width: unset !important;
				.checkbox-item {
					svg {
						width: 18px;
						min-width: unset;
					}
				}
			}
		}
	}
`;
const desktopStyles = css `
	background: var(--color-white, #ffffff);
	border-radius: 10px;
	border-style: solid;
	border-color: var(--color-grey-c, #e6e8ed);
	border-width: 1px;
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 14px;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	min-width: 215px;
	flex: 1;

	.sms-member-list {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		align-self: stretch;
		flex: 1;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;

			position: relative;
			text-align: left;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			letter-spacing: var(--b4-letter-spacing, -0.02em);
			font-weight: var(--b4-font-weight, 400);
			position: relative;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 16px 12px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}
		}
	}
`;
export const SendMembersTable = React.memo(({ isReceiver, isCheckBox = true, receiverMemberList, setMemberData, }) => {
    const { classesId } = useGlobalState();
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const [page, setPage] = useState(0);
    const [previousPage, setPreviousPage] = useState(0);
    const [count, setCount] = useState(10);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [checkList, setCheckList] = useState([]);
    const [selectedChecklistMembers, setSelectedChecklistMembers] = useState({});
    const [hasExecuted, setHasExecuted] = useState([]);
    const [defaultCheckMembers, setDefaultCheckMembers] = useState([]);
    const [searchOption, setSearchOption] = useState({
        type: undefined,
        status: undefined,
        name: undefined,
    });
    // 참가자 목록 가져오기
    const { data, refetch } = useMemberInfo({
        ...searchOption,
        class_id: classesId,
        status: 'created',
        level: ['03'],
        offset: page === 0 ? 0 : page * count,
        limit: count,
    });
    useEffect(() => {
        const resetCheck = props => {
            if (props?.no_phone_number?.length > 0) {
                const newTestData = props.testData
                    .filter(item => {
                    if (!props.no_phone_number.includes(item.id) &&
                        props.checkList.includes(item.id)) {
                        return item.id;
                    }
                })
                    .map(item => item.id);
                setSelectedChecklistMembers(prevState => {
                    return {
                        ...prevState,
                        [props.page]: newTestData,
                    };
                });
                setCheckList(newTestData);
                APP.eventManager.publish('UPDATE_TABLE_ITEM', {
                    className: 'sms-send-list',
                    defaultChekList: newTestData,
                });
            }
            else {
                setSelectedChecklistMembers({});
                setCheckList([]);
                APP.eventManager.publish('UPDATE_TABLE_ITEM', { className: 'sms-member-list' });
            }
        };
        APP.eventManager.subscribe('CLEAR_CHECK_LIST', resetCheck);
        return () => {
            APP.eventManager.unsubscribe('CLEAR_CHECK_LIST', resetCheck);
        };
    }, []);
    useEffect(() => {
        if (data) {
            const { total_count, found_count, items } = data;
            if (found_count === 0 && total_count > 0) {
                setPage(Math.max(page - 1, 0));
            }
            if (total_count > 0) {
                setTestData(items);
                setTotalCount(total_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    // (리스트 갱신
    const refetchList = throttle(refetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        setCount(10);
        refetchList();
    }, [searchOption, page, count]);
    //참여자 선택 시 페이지 이동을 해도 선택된 참여자가 유지되도록
    useEffect(() => {
        if (checkList.length > 0 && testData.length > 0) {
            const no_phone_number = testData.filter(item => checkList.includes(item.id) &&
                (!item.phone_number || item.phone_number === '-'));
            if (no_phone_number.length > 0) {
                alert('번호가 저장되어있지 않은 사용자가 있습니다.');
                APP.eventManager.publish('CLEAR_CHECK_LIST', {
                    no_phone_number: no_phone_number.map(item => item.id),
                    page,
                    testData,
                    checkList,
                });
            }
        }
        if (_.isEqual(hasExecuted, checkList) && page === previousPage) {
            if (checkList.length === 0) {
                setSelectedChecklistMembers(prevState => {
                    return {
                        ...prevState,
                        [page]: [],
                    };
                });
            }
            return;
        }
        if (previousPage === page && testData.length > 0) {
            setSelectedChecklistMembers(prevState => {
                return {
                    ...prevState,
                    [page]: checkList.length === 0
                        ? []
                        : testData.filter(item => checkList.includes(item.id)),
                };
            });
        }
        setPreviousPage(page);
    }, [checkList, page, testData, receiverMemberList]);
    useEffect(() => {
        if (selectedChecklistMembers) {
            const selectedMembers = Object.keys(selectedChecklistMembers)
                .map(index => selectedChecklistMembers[index])
                .flat();
            setMemberData(selectedMembers);
            setDefaultCheckMembers(selectedChecklistMembers[page]?.map(member => member.id));
        }
        else {
            setMemberData([]);
            setDefaultCheckMembers([]);
        }
    }, [selectedChecklistMembers]);
    const handlerMoreData = (page) => {
        setPage(page);
    };
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        const headTitleList = [
            ...(!isMobile ? [{ title: 'nameId', space: 2, sort: false }] : []),
        ];
        const tableReadDataKeys = [
            { title: 'nickname', space: 2, sort: false },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, []);
    // // 상단 레이아웃 검색 조건을 위한 action
    const handlerFilterList = (filter) => setSearchOption(filter);
    const renderTitle = useMemo(() => (React.createElement(SendMemberHeaader, { handlerFilterList: handlerFilterList, totalTableList: totalCount, isReceiver: isReceiver })), [searchOption, totalCount]);
    const renderItem = (item, styles) => {
        return tableReadDataKeys.map(headItem => (React.createElement("div", { key: headItem.title, className: "body-item", style: {
                flex: !isMobile && headItem.space,
                ...styles?.bodyItem,
            } },
            React.createElement("p", { className: "overText2" }, `${item[headItem.title]} (${item['identifier']})`))));
    };
    return (React.createElement(SendMembersTableStyled, { "$isMobile": isMobile, "$isTablet": isTablet, className: "user-list" },
        isMobile && React.createElement("div", { className: "all-checked" }, "\uC804\uCCB4\uC120\uD0DD"),
        React.createElement(Table, { className: "sms-member-list", forceShowheader: true, showCheckBox: isCheckBox, defaultCheckList: defaultCheckMembers ? defaultCheckMembers : [], disableIdList: receiverMemberList ? receiverMemberList.map(item => item.id) : [], isInifinite: false, data: testData, totalCount: totalCount, page: page, pagingCount: 3, showCount: count, sortDefault: "id", headTitleList: headTitleList, renderTitle: renderTitle, renderItem: renderItem, handlerCheckList: setCheckList, handlerMoreData: handlerMoreData, isKeepChecked: true })));
});
