import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDown } from '../../../../../common';
import { useGlobalState } from '../../../../../GlobalStateProvider';
// 출제 유형
const questionTypeList = [
    { id: 'select', title: 'common.select' },
    { id: 'OMR', title: 'assignment.OMR' },
    { id: 'OMR-free', title: 'assignment.OMR-free' },
    { id: 'attachment', title: 'assignment.attachment' },
    { id: 'survey', title: 'assignment.survey' },
];
/**
 * 출제 유형 선택 dropdown
 *  - 출제 유형 (OMR 기본형, OMR 자유형, 첨부파일, 설문) 선택
 */
export const QuestionType = React.memo(({ questionType, setQuestionType, isModify = false, }) => {
    const { t } = useTranslation();
    const { isDesktop, isMobile } = useGlobalState();
    return (React.createElement("div", { className: "field-global-001" },
        React.createElement("div", { className: "task-label" }, t('assignment.questionType')),
        React.createElement("div", { className: "fext-field" },
            React.createElement(DropDown, { className: isDesktop ? 'dropdown-popup-big' : 'dropdown-popup', value: questionType, styles: {
                    dropWrap: {
                        flex: 1,
                        maxWidth: isDesktop ? '272px' : isMobile ? undefined : '184px',
                    },
                    titleWrap: {
                        padding: isMobile && '9px',
                    },
                }, list: questionTypeList, setDropValue: setQuestionType }))));
});
