import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../../Colors';
import { Button, FileInput } from '../../../../../common';
import { useGlobalState } from '../../../../../GlobalStateProvider';
// 첨부 파일 컴포넌트
export const AttachFile = React.memo(({ attachFileList, setAttachFileList, isRegist, showRegist = false, isAnswer = false, isTeacher, isRequestion, isLive = false, }) => {
    const { t } = useTranslation();
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const fileRef = React.useRef(null);
    //이미지, pdf, 엑셀, 워드, PPT, 텍스트 파일
    const acceptedFileTypes = 'image/*,application/pdf,.xlsx, .xls, .csv, .ppt, .pptx, .pdf, .doc, .docx, .txt, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain';
    // (onClick) 첨부 파일 삭제
    const deleteAttachFile = (item) => {
        setAttachFileList(attachFileList.filter(file => file !== item));
    };
    const testLink = 'downloaded_file (1).png';
    const downloadFile = (url) => {
        console.log("attachFileList", attachFileList);
        console.log('url', url);
        const formattedUrl = url.replace(/\s/g, '%20');
        console.log('formattedUrl', formattedUrl);
        const a = document.createElement('a');
        a.href = formattedUrl; // 다운로드 URL 설정
        a.download = url.split('/').pop() || 'download'; // 다운로드 파일명 설정
        document.body.appendChild(a); // a 요소를 문서에 추가
        a.click();
        document.body.removeChild(a); // a 요소를 문서에서 제거
    };
    // (onChange) 첨부 파일 추가
    const onChangeFile = (e) => {
        const file = e.target.files[0];
        setAttachFileList([...attachFileList, file]);
        if (fileRef.current)
            fileRef.current.value = '';
    };
    //드래그 앤 드롭 파일 막기
    const handleDrop = e => {
        e.preventDefault();
        const dt = e.dataTransfer;
        // 드롭된 항목이 폴더인지 파일인지 확인
        for (let i = 0; i < dt.items.length; i++) {
            const item = dt.items[i];
            if (item.kind === 'file') {
                const file = item.getAsFile();
                // 파일 확장자 확인 (예시: mp4, mov 확장자만 허용)
                if (file.type.startsWith('video/') &&
                    (file.type.includes('mp4') || file.type.includes('quicktime'))) {
                    // 파일 처리 로직 추가
                }
                else {
                    alert('폴더를 등록할 수 없습니다.');
                    return;
                }
            }
            else {
                alert('폴더를 등록할 수 없습니다.');
                return;
            }
        }
    };
    const handleDragOver = e => {
        e.preventDefault();
    };
    const renderAttachFileButton = useMemo(() => (React.createElement(FileInput, { ref: fileRef, id: "dst_attach_file", onChange: onChangeFile, accept: acceptedFileTypes },
        React.createElement(Button, { className: `btn_tur ${isDesktop || isAnswer ? 'sm' : 'xxs'}`, text: isMobile
                ? 'assignment.reisgAttachFile'
                : isAnswer
                    ? 'supportRoom.regist'
                    : 'common.regist', styles: {
                buttonWrap: isAnswer
                    ? {
                        width: '60px',
                        height: '30px',
                    }
                    : isLive && isMobile
                        ? { width: '100%' }
                        : {
                            height: isTeacher ? undefined : '45px',
                            width: isMobile && '100%',
                        },
            } }))), [isDesktop, isTeacher, isAnswer, attachFileList]);
    const renderAttachFile = useMemo(() => (React.createElement(React.Fragment, null,
        isMobile && showRegist && renderAttachFileButton,
        React.createElement("div", { onDrop: handleDrop, onDragOver: handleDragOver, className: "box", style: {
                width: '100%',
                minHeight: isAnswer || (isTeacher && isTablet)
                    ? 50
                    : isTeacher && !isMobile
                        ? 100
                        : undefined,
                // maxHeight: isTeacher && !isTablet ? 100 : undefined,
            } },
            React.createElement("div", { style: {
                    width: isAnswer ? '85%' : '100%',
                    display: 'inline-flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    gap: '10px',
                } }, attachFileList.length > 0 ? (attachFileList.map((item, index) => (React.createElement(Button, { key: index, className: `btn_sliver md_round center body5 cursor attach_item ${isMobile ? 'mobile-xxs' : ''}`, text: typeof item === 'string'
                    ? item.split('/')[item.split('/').length - 1]
                    : item.name, styles: {
                    buttonWrap: {
                        height: 'fit-content',
                        maxWidth: isMobile ? 180 : 220,
                    },
                }, option: {
                    buttonBeforeIcon: {
                        name: 'file',
                        color: Colors.dark_tur,
                        size: isMobile ? 12 : 20,
                        show: true,
                    },
                    buttonAfterIcon: {
                        name: !showRegist &&
                            !isAnswer &&
                            typeof item === 'string'
                            ? 'file-down'
                            : 'cancel',
                        color: Colors.accent,
                        size: !showRegist &&
                            !isAnswer &&
                            typeof item === 'string'
                            ? 16
                            : 12,
                        show: showRegist || isAnswer
                            ? true
                            : typeof item === 'string',
                    },
                }, onClick: showRegist || isAnswer
                    ? () => deleteAttachFile(item)
                    : typeof item === 'string'
                        ? () => downloadFile(item)
                        : () => { } })))) : (React.createElement("p", { className: "body5", style: { color: Colors.light_grey, width: '85%' } }, isTeacher && isRegist
                ? t('assignment.attachFilePlaceholer')
                : t('assignment.noAttachFilePlaceholer')))),
            isAnswer && renderAttachFileButton),
        !isMobile && showRegist && renderAttachFileButton)), [showRegist, isAnswer, attachFileList, isTeacher, isRegist]);
    return (React.createElement("div", { className: "field-global-001", style: isRequestion ? { gap: '48px' } : { alignItems: 'flex-start' } }, isMobile && isTeacher && isRegist ? (React.createElement("div", { className: "attach-file-wrap" }, renderAttachFile)) : isLive && isMobile ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "mobile-file-wrap" },
            React.createElement("div", { className: "task-label" }, t('assignment.attachFile')),
            renderAttachFile))) : (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement("div", { className: "task-label" }, t('assignment.attachFile'))),
        renderAttachFile))));
});
