export const CONTENTS_TABS = [
    { key: 'document', icon: 'document', text: 'supportRoom.documentContents' },
    { key: 'video', icon: 'video', text: 'supportRoom.videoContents' },
    { key: 'background', icon: 'background', text: 'supportRoom.background' },
];
export const CONTENTS_DROP_LIST = [
    { id: 'all', title: 'common.all' },
    { id: 'document', title: 'supportRoom.documentContents' },
    { id: 'video', title: 'supportRoom.videoContents' },
    { id: 'background', title: 'supportRoom.background' },
];
export const CONTENTS_DROP_DEFAULT = { id: 'all', title: 'common.all' };
