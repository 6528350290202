import React from 'react';
import { Button } from '../../../../common';
import { TOGGLE_POPUP } from '../../../base/popup';
import { DeletePopUpLayout } from '../../../base/layout/DeletePopUpLayout';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { deleteContentsList } from '../../../../server';
export const DeleteContentItem = ({ menu, deleteList }) => {
    const { t } = useTranslation();
    const deleteMutation = useMutation(deleteContentsList);
    const onDelete = async () => {
        const successfulDeletes = [];
        const failedDeletes = [];
        const deleteHandler = async (id) => {
            try {
                await deleteMutation.mutateAsync(id);
                successfulDeletes.push(id);
            }
            catch (error) {
                failedDeletes.push(id);
            }
        };
        try {
            const deletePromises = deleteList.map(id => deleteHandler(id));
            await Promise.all(deletePromises);
        }
        catch (error) {
            alert(error);
        }
        finally {
            // Log successful and failed deletions
            // if (successfulDeletes.length) {
            // 	console.log('Successfully deleted ids:', successfulDeletes);
            // }
            // if (failedDeletes.length) {
            // 	console.error('Failed to delete ids:', failedDeletes);
            // }
            // Refresh the contents
            APP.eventManager.publish('REFRESH_CONTENTS');
        }
    };
    const handlerDel = () => {
        if (deleteList.length <= 0)
            return;
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DeletePopUpLayout,
            componentProps: {
                deleteContent: deleteList.length
                    ? t('assignment.delteInto', { count: deleteList?.length })
                    : t('common.notSelect'),
                onDelete: onDelete,
            },
            width: 639,
            title: t(`supportRoom.${menu}Delete`),
            isFit: true,
        });
    };
    return (React.createElement(Button, { option: {
            buttonAfterIcon: {
                show: true,
                name: 'delete',
                size: 20,
            },
        }, onClick: () => handlerDel() }));
};
