import React from 'react';
import { DropDown } from '../../../../../common';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { useSelector } from 'react-redux';
import { getIsTeacher } from '../../../../base/account';
const WEEKS_DROP_LIST = [
    {
        id: '0',
        title: 'common.select',
    },
    { id: '1', title: '1주차' },
    { id: '2', title: '2주차' },
    { id: '3', title: '3주차' },
    { id: '4', title: '4주차' },
    { id: '5', title: '5주차' },
];
export const WeeksDropDown = React.memo(({ isDisable = false, defaultWeek, handlerChange, isHome = false, }) => {
    const { isMobile } = useGlobalState();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const [weeks, setWeeks] = React.useState(isHome || !isTeacher ? { id: '1', title: '1주차' } : { id: '0', title: 'common.select' });
    const list = React.useMemo(() => (isHome || !isTeacher ? WEEKS_DROP_LIST.slice(1, 6) : WEEKS_DROP_LIST), [isHome, isTeacher]);
    React.useEffect(() => {
        setWeeks(isHome || !isTeacher
            ? { id: '1', title: '1주차' }
            : { id: '0', title: 'common.select' });
    }, [isHome, isTeacher]);
    React.useEffect(() => {
        if (defaultWeek) {
            setWeeks(list.find(item => item.id == defaultWeek));
        }
    }, [defaultWeek]);
    React.useEffect(() => {
        weeks?.id && handlerChange(weeks?.id);
    }, [weeks]);
    return (React.createElement(DropDown, { className: `${isMobile && isHome ? 'dropdown-mobile-small' : 'dropdown-live'} ${isMobile && 'weeks-dropdown'}`, isDisable: isDisable, list: list, value: weeks, styles: {
            dropWrap: {
                width: isMobile ? '100%' : '200px',
            },
            itemWrap: {
                top: isMobile && !isHome && '108px',
            },
        }, setDropValue: setWeeks }));
});
