export const SURVEY_TYPE = {
    OBJECTIVE: 'choice',
    SHORT_ANSWER: 'short',
    LONG_ANSWER: 'long',
    CHECKBOX: 'checkbox',
};
export const SURVEY_DROP_LIST = [
    { id: SURVEY_TYPE.OBJECTIVE, title: '객관식' },
    { id: SURVEY_TYPE.SHORT_ANSWER, title: '단답형' },
    { id: SURVEY_TYPE.LONG_ANSWER, title: '장문형' },
    { id: SURVEY_TYPE.CHECKBOX, title: '체크형' },
];
export const DEFAULT_SURVEY_DROP = { id: SURVEY_TYPE.CHECKBOX, title: '체크형' };
