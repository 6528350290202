import React, { useEffect } from 'react';
import { Icon } from '../../../common';
import { Colors } from '../../../Colors';
export const TabletPagetLayout = React.memo(({ pageList, setPage }) => {
    const [tabletPage, setTabletPage] = React.useState(0);
    const hadlePreviousPage = () => {
        if (tabletPage > 0) {
            setTabletPage(prevPage => prevPage - 1);
        }
    };
    const handleNextPage = () => {
        if (pageList.length >= tabletPage) {
            setTabletPage(prevPage => prevPage + 1);
        }
    };
    useEffect(() => {
        setPage(tabletPage);
    });
    return (React.createElement("div", { className: "field-global-001" },
        tabletPage - 1 >= 0 && (React.createElement("div", { className: "page", onClick: hadlePreviousPage, style: { cursor: 'pointer', justifyContent: 'flex-start' } },
            React.createElement(Icon, { className: "arrow-left", icon: "arrow", size: 22, fill: Colors.w_grey }),
            React.createElement("p", null, pageList[tabletPage - 1].title))),
        pageList.length > tabletPage + 1 && (React.createElement("div", { className: "page", onClick: handleNextPage, style: { cursor: 'pointer', justifyContent: 'flex-end' } },
            React.createElement("p", null, pageList[tabletPage + 1].title),
            React.createElement(Icon, { className: "arrow-right", icon: "arrow", size: 22, fill: Colors.w_grey })))));
});
