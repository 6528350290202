import React, { useEffect } from 'react';
import { DropDown } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
export const QnAStateDropdown = React.memo(({ type, searchCheckItem, handlerFilterList, }) => {
    const { isTablet, isMobile } = useGlobalState();
    const [dropdownList, setDropdownList] = React.useState([]);
    const [value, setValue] = React.useState({
        id: 'select',
        title: ['status', 'category'].includes(type)
            ? 'common.select'
            : 'assignment.searchCondition',
    });
    useEffect(() => {
        let list = [];
        if (type === 'status') {
            list = [
                { id: 'select', title: 'common.select' },
                { id: 'open', title: 'supportRoom.answer_wait' },
                { id: 'closed', title: 'supportRoom.answer_complete' },
            ];
        }
        else if (type === 'category') {
            list = [
                { id: 'select', title: 'common.select' },
                { id: 'lesson', title: 'supportRoom.lesson' },
                { id: 'subject', title: 'supportRoom.subject' },
                { id: 'textbook', title: 'supportRoom.textbook' },
                { id: 'attendance', title: 'supportRoom.attendance' },
                { id: 'etc', title: 'supportRoom.etc' },
                { id: 'preview', title: 'supportRoom.preview' },
                { id: 'system', title: 'supportRoom.system' },
            ];
        }
        else {
            list = [
                {
                    id: 'select',
                    title: 'assignment.searchCondition',
                },
                {
                    id: 'category',
                    title: 'supportRoom.category',
                },
                {
                    id: 'status',
                    title: 'supportRoom.answer_state',
                },
            ];
        }
        const checkSelect = list.find(item => item.id === searchCheckItem);
        let item = {
            id: 'searchCondition',
            title: ['status', 'category'].includes(type)
                ? 'common.searchCondition'
                : 'assignment.searchCondition',
        };
        if (searchCheckItem && checkSelect) {
            item = checkSelect;
        }
        setDropdownList(list);
        setValue(item);
    }, [type]);
    const handlerUpdateDrop = (item) => {
        if (dropdownList.length === 0)
            return;
        if (['status', 'category'].includes(type)) {
            handlerFilterList({ [type]: item.id === 'select' ? undefined : item.id });
        }
        else {
            handlerFilterList({ [item.id]: undefined });
        }
        setValue(item);
    };
    if (isMobile && !['status', 'category'].includes(type))
        return null;
    return (React.createElement(DropDown, { className: "dropdown-tablet-small", list: dropdownList, value: value, styles: {
            dropWrap: { width: isTablet ? 120 : 150 },
            itemWrap: {
                top: isTablet ? '50px' : '45px',
            },
        }, setDropValue: handlerUpdateDrop }));
});
