import { getAccessToken } from '../../features/base/account';
import { getClassesId } from '../../features/base/classes';
import { fetchData, objectToQueryString, useCustomQuery } from '../functions';
const getToken = () => getAccessToken(APP.store.getState);
const getSendSMS = async (queryString) => {
    const class_id = getClassesId();
    const url = `/api/mimac/v1/messages?${queryString}&class_id=${class_id}`; // API 엔드포인트 생성
    return fetchData(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
export const postModifySendSMS = async (data) => {
    const url = `/api/mimac/v1/messages/${data.id}/update`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data.props),
    });
};
export const postRegistSendSMS = async (data) => {
    const url = `/api/mimac/v1/messages`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ class_id: getClassesId(), ...data }),
    });
};
export const postDeleteSendSMS = async (id) => {
    const url = `/api/mimac/v1/messages/${id}/delete`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
//메시지 수신자 엑셀 업로드
export const postExcelUpload = async (file) => {
    //const queryString = objectToQueryString(data);
    const class_id = getClassesId();
    const url = `/api/mimac/v1/messages/upload-excel?&class_id=${class_id}`;
    const formData = new FormData();
    formData.append('file', file);
    return fetchData(url, {
        method: 'POST',
        body: formData,
    });
};
//SMS 발송 리스트 조회
export const useSendSMSList = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`send_sms_${data}`, data], () => getSendSMS(queryString));
};
// export const useExcelDownload = () => {
// 	//const queryString = objectToQueryString(data);
// 	return useCustomQuery([`excel_download`], () => getExcelDownload());
// };
