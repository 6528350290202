import _ from 'lodash';
import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { Colors } from '../../../../../Colors';
import { useGlobalState, useHistoryStore } from '../../../../../GlobalStateProvider';
import { Table } from '../../../../../common';
import { deleteConferenceList, useConferenceList, } from '../../../../../server';
import ReservationItem from './ReservationItem';
import { ReservationTitle } from './ReservationTitle';
const Container002Styled = styled.div `
	/* min-height: 100%; */
	gap: ${props => props.$isMobile && props.$isTeacher && '0px !important'};

	.qna-list-001 {
		/* padding: 0px 0px 36px 0px; */
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
		min-width: ${props => (props.$isDesktop ? '550px' : 'unset')};

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: ${props => (!props.$isDesktop ? '12px 20px 12px 20px' : '6px 28px 6px 28px')};
			display: flex;
			flex-direction: row;
			gap: ${props => (!props.$isDesktop ? '12px' : '24px')};
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				${props => props.$isMobile
    ? `  font-size: 12px;
						line-height: 150%;
						letter-spacing: -0.02em;`
    : props.$isDesktop
        ? `font-size: var(--b3-font-size, 16px);
				line-height: var(--b3-line-height, 32px);
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				`
        : `  font-size: 14px;
						line-height: 150%;
						letter-spacing: -0.02em;`}
				font-weight: var(--b3-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: ${props => (!props.$isDesktop ? '20px' : '24px 20px 24px 20px')};
			display: flex;
			flex-direction: row;
			gap: ${props => (!props.$isDesktop ? '12px' : '24px')};
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			${props => props.$isMobile
    ? `
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;`
    : props.$isDesktop
        ? ` font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			letter-spacing: var(--b3-letter-spacing, -0.02em);`
        : `
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;`}
			font-weight: var(--b3-font-weight, 400);

			&.selected {
				color: var(--tur-d, #479da6);
			}
		}

		.mobile_item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}
	}

	.live-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;

		${props => props.$isMobile
    ? `background: var(--color-white, #ffffff); 
			border-radius: 10px;
		padding: 0px;`
    : `padding: 0px 0px 36px 0px;`}

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 6px 28px 6px 28px;
			display: flex;
			flex-direction: row;
			gap: 28px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: left;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			${props => props.$isDesktop
    ? `
			font-size: var(--b3-font-size, 16px); 	
			line-height: var(--b3-line-height, 32px);`
    : `font-size: var(--b3-font-size, 14px); 	
			line-height: 150%;`}

			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			align-self: stretch;
			flex: 1;
			position: relative;
			box-sizing: border-box;
			height: unset;
		}

		.item {
			width: 100%;
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 20px 28px 20px 28px;
			display: flex;
			flex-direction: row;
			gap: 28px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			cursor: pointer;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);

			${props => props.$isDesktop
    ? `font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);`
    : `font-size: var(--b3-font-size, 14px);
			line-height: 150%;`}
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.mobile_item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.contents {
				color: var(--bule, #3c5295);
				text-align: left;
				font-family: 'NotoSansKr-Regular', sans-serif;
				font-size: 12px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
				position: relative;
				align-self: stretch;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}

			/* .title {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: 'NotoSansKr-SemiBold', sans-serif;
				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 600;
				position: relative;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			} */

			.desc {
				display: flex;
				flex-direction: row;
				gap: 10px;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: 'NotoSansKr-Regular', sans-serif;
				font-size: 12px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}
	.underline {
		color: var(--tur, #3ab2bf);

		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: ${props => (props.$isDesktop ? '16px' : '14px')};
		line-height: 32px;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		&_black {
			color: var(--color-black, #101010);
		}
	}

	${props => props.$isMobile &&
    `.status {
		position: absolute;
		right: 8px;
		top: 50px;
		z-index: 3;

		.underline {
			cursor: pointer;
		}

		p {
			font-size: 16px !important;
		}
	}`}

	.empty_data {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.mobile_item {
		.title {
			max-width: 80%;
		}
		.big {
			text-align: left;
			font-family: 'NotoSansKr-SemiBold', sans-serif;
			font-size: 14px;
			font-weight: 600;
		}
	}
`;
const count = 10;
export const ReservationTable = React.memo(({ isClosed = false, isPrevious = false, isTeacher = false, isAttendance = false, selectedId, setSelectedId, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const historyStore = useHistoryStore();
    const [previousPageState, setPreviousPageState] = useState(); // 이전 페이지의 상태를 저장
    const [previousSearchContent, setPreviousSearchContent] = useState(''); // 이전 검색어를 저장
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchOption, setSearchOption] = useState({});
    const [isClosedState, setIsClosedState] = useState(isClosed);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // [강의모드, 제목, 수업일자, 진행시간, 진행자, 참석인원, 질의/응답, 상태] : 강의 목록(강사)
        // [No, 강의 제목, 수업일자, 시작시간, 진행시간, 참석인원, 비고] : 강의 목록(학생)
        const headTitleList = isMobile
            ? []
            : isAttendance
                ? [
                    { title: 'class_name', space: 3, sort: false },
                    { title: 'classTime', space: 2, sort: false },
                    { title: 'participantMember', space: 1, sort: false },
                ]
                : isTablet
                    ? [
                        ...(isTeacher ? [{ title: 'live_class', space: 1, sort: false }] : []),
                        { title: 'live_title', space: 2, sort: false },
                        ...(isTeacher
                            ? [{ title: 'classDate', space: 2, sort: false }]
                            : [
                                { title: 'classDay', space: 1, sort: false },
                                { title: 'start_time', space: 1, sort: false },
                            ]),
                        { title: 'progress_time', space: 1 },
                        { title: 'live_status', space: 1, sort: false },
                        ...(!isClosed && isTeacher
                            ? [{ title: 'etc', space: 1, sort: false }]
                            : []),
                    ]
                    : [
                        { title: 'live_class', space: 1, sort: false },
                        { title: 'live_title', space: 3, sort: false },
                        { title: 'classDate', space: 2, sort: false },
                        { title: 'progress_time', space: 1, sort: false },
                        { title: 'progresser', space: 1, sort: false },
                        // { title: 'numberOfAttendees', space: 1, sort: false },
                        // { title: 'questionAndAnswer', space: 1, sort: false },
                        ...(isPrevious ? [] : [{ title: 'live_status', space: 1, sort: false }]),
                        ...(!isClosed && isTeacher
                            ? [{ title: 'etc', space: 1, sort: false }]
                            : []),
                    ];
        const tableReadDataKeys = isMobile
            ? isAttendance
                ? [
                    { title: 'title', space: 1 },
                    { title: 'dateAndParticipant', space: 1 },
                ]
                : [
                    ...(!isClosed && isTeacher ? [{ title: 'etc', space: 1 }] : []),
                    { title: 'status', space: 1 },
                    { title: 'title', space: 3 },
                    { title: 'dutaionTime', space: 2 },
                    { title: 'host_name', space: 1 },
                ]
            : isAttendance
                ? [
                    { title: 'title', space: 3 },
                    { title: 'start_time', space: 2 },
                    { title: 'participant', space: 1 },
                ]
                : isTablet
                    ? [
                        ...(isTeacher ? [{ title: 'type', space: 1 }] : []),
                        { title: 'title', space: 2 },
                        ...(isTeacher
                            ? [{ title: 'start_time', space: 2 }]
                            : [
                                { title: 'classDate', space: 1 },
                                { title: 'start_time', space: 1 },
                            ]),
                        { title: 'progress_duration', space: 1 },
                        ...(isPrevious ? [] : [{ title: 'status', space: 1, sort: false }]),
                        ...(!isClosed && isTeacher
                            ? [{ title: 'etc', space: 1, sort: false }] //[{ title: 'etc', space: 1, sort: false }]
                            : []),
                    ]
                    : [
                        { title: 'type', space: 1 },
                        { title: 'title', space: 3 },
                        { title: 'start_time', space: 2 },
                        { title: 'progress_duration', space: 1 },
                        { title: 'host_name', space: 1 },
                        // { title: 'members', space: 1 },
                        // { title: 'qna_managers_id', space: 1 },
                        ...(isPrevious ? [] : [{ title: 'status', space: 1, sort: false }]),
                        ...(!isClosed && isTeacher
                            ? [{ title: 'etc', space: 1, sort: false }] //[{ title: 'etc', space: 1, sort: false }]
                            : []),
                    ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, [isPrevious, isTeacher, isClosed, isMobile, isTablet]);
    useEffect(() => {
        // 이전 페이지의 상태를 가져와서 적용
        const previousPage = historyStore.getRecentPageHistory(isPrevious ? 'previousListPage' : 'reservationListPage');
        if (previousPage) {
            // 이전 페이지의 상태를 적용
            if (!isAttendance) {
                setIsClosedState(previousPage.props.isClosedState);
                setSearchOption(previousPage.searchOption);
                setPreviousSearchContent(previousPage.searchOption[Object.keys(previousPage.searchOption)[0]]);
                setPage(previousPage.prePage);
                setPreviousPageState(previousPage);
            }
        }
        else {
            setIsClosedState(undefined);
            setSearchOption({ title: undefined });
            setPreviousSearchContent(undefined);
            setPage(0);
            setPreviousPageState(undefined);
        }
        historyStore.clearHistoryForPage(isPrevious ? 'previousListPage' : 'reservationListPage');
    }, [isPrevious, isAttendance]);
    const { data, refetch } = useConferenceList({
        ...(previousPageState
            ? previousPageState.preItems
            : {
                ...searchOption,
                ...(isAttendance ? { with_more_info: true } : {}),
                status: isClosedState ? ['closed'] : ['booked', 'held'],
                offset: page === 0 ? 0 : page * count,
                limit: count,
                orderby: isAttendance || isPrevious ? ['startline DESC'] : ['startline ASC'],
            }),
    });
    const deleteMutation = useMutation(deleteConferenceList);
    const refetchList = throttle(() => {
        refetch();
    }, 1000, { leading: true, trailing: false });
    useEffect(() => {
        // Reset data when isClosed changes
        //setTestData([]);
        //setTotalCount(0);
        setIsClosedState(isClosed);
        //setPage(0);
    }, [isClosed, isPrevious, isAttendance]);
    useEffect(() => {
        APP.eventManager.subscribe('CONFERENCE_STATUS_CHANGED', refetchList);
        return () => {
            APP.eventManager.unsubscribe('CONFERENCE_STATUS_CHANGED', refetchList);
        };
    }, []);
    useEffect(() => {
        // Refetch data on page change, mobile change, or search option change
        refetchList();
    }, [page, isMobile, isClosedState, searchOption]);
    useEffect(() => {
        // Handle data response from API
        if (data) {
            const { total_count, items, found_count } = data;
            if (total_count > 0 && found_count === 0) {
                setPage(Math.max(page - 1, 0));
                return;
            }
            //이전 페이지 저장된 기록 초기화
            if (previousPageState) {
                setPreviousPageState(undefined);
            }
            if (total_count > 0) {
                const list = items.map(i => ({
                    ...i,
                    // members: i.members?.filter(m => m.entry_logs)?.length || 0,
                }));
                setTestData(isTeacher && !isMobile
                    ? list
                    : prevTestData => {
                        const prevDataById = _.keyBy(prevTestData, 'id');
                        const newDataById = _.keyBy(list, 'id');
                        const mergedData = _.merge({}, prevDataById, newDataById);
                        return _.values(mergedData);
                    });
                setTotalCount(total_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    const handlerFilterList = (filter) => {
        setSearchOption(filter);
    };
    const handlerMoreData = (page) => {
        if (isTeacher && !isMobile) {
            setPage(page);
        }
        else {
            setPage(prevPage => prevPage + 1);
        }
    };
    const handlerOneDel = async (id) => {
        await deleteMutation.mutateAsync(id);
        refetchList();
    };
    const handlerSelectItem = (item) => {
        setSelectedId(item.id);
    };
    const savePreviousPage = () => {
        const historyItem = {
            preItems: {
                ...searchOption,
                ...(isAttendance ? { with_more_info: true } : {}),
                status: isClosedState ? ['closed'] : ['booked', 'held'],
                offset: page === 0 ? 0 : page * count,
                limit: count,
                orderby: isAttendance || isPrevious ? ['startline DESC'] : ['startline ASC'],
            },
            props: {
                isAttendance: isAttendance,
                isClosedState: isClosedState,
                isPrevious: isPrevious,
            },
            prePage: page,
            searchOption: searchOption,
            pageName: isPrevious ? 'previousListPage' : 'reservationListPage',
        };
        historyStore.pushHistory(historyItem);
    };
    const renderItem = (item, styles, index, isMobile) => (React.createElement(ReservationItem, { item: item, styles: styles, isMobile: isMobile, isAttendance: isAttendance, isTeacher: isTeacher, isClosed: isClosedState, tableReadDataKeys: tableReadDataKeys, handlerOneDel: handlerOneDel, savePreviousPage: savePreviousPage }));
    const renderEmpty = useMemo(() => (React.createElement("div", { className: "empty_data", style: { color: Colors.light_grey } }, t('conference.noConference'))), []);
    return (React.createElement(Container002Styled, { className: `task-board-container ${!isTeacher ? 'liveRoom' : 'reservation-table'}`, "$isTeacher": isTeacher, "$isMobile": isMobile, "$isDesktop": isDesktop },
        React.createElement(Table, { className: isAttendance ? 'qna-list-001' : 'live-list-001', showCheckBox: false, showIndex: !isMobile, selectedId: selectedId, isInifinite: isTeacher && !isMobile ? false : true, data: testData, totalCount: totalCount, page: page, pagingCount: isAttendance && isTablet ? 3 : 5, showCount: count, sortDefault: "id", headTitleList: headTitleList, renderTitle: isAttendance ? null : (React.createElement(ReservationTitle, { isPrevious: isPrevious, handlerFilterList: handlerFilterList, previousSearchContent: previousSearchContent })), renderItem: renderItem, renderEmpty: totalCount <= 0 && renderEmpty, handlerMoreData: handlerMoreData, handlerSelectItem: setSelectedId && handlerSelectItem, forceShowheader: isMobile ? false : true, styles: {
                bodyWrapper: {
                    cursor: handlerMoreData || isAttendance ? 'pointer ' : 'default',
                },
            } })));
});
