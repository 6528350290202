import { SET_IS_TEACHER, SET_MANAGER, SET_TOKEN, SET_USER_INFO } from './actionTypes';
export const setToken = (token) => {
    // token.refresh_token && setCookie('refresh_token', token.refresh_token, 1);
    return {
        type: SET_TOKEN,
        token,
    };
};
// user 정보 업데이트
export const setUserInfo = (user, isTeacher) => {
    return {
        type: SET_USER_INFO,
        user,
        isTeacher,
    };
};
export const setIsTeacher = (isTeacher) => {
    return {
        type: SET_IS_TEACHER,
        isTeacher,
    };
};
export const setManager = (manager) => {
    return {
        type: SET_MANAGER,
        manager,
    };
};
