import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { useGlobalState } from '../../../GlobalStateProvider';
import '../../../assest/css/quill.scss';
import { Button, CheckBoxElement, InputElement } from '../../../common';
import { FileAttach } from '../../../common/option';
import { postFile, postUpdatePosts, registPosts, } from '../../../server';
import { TOGGLE_POPUP } from '../../base/popup';
import { RegistContent } from './DSTSL001_Regist_Child/RegistContent';
import { REFRESH_POSTS_LIST } from './constant';
const DSTL001Styled = styled.div `
	background: var(--color-white, #ffffff);
	border-radius: 0px 0px 8px 8px;
	display: flex;
	flex-direction: column;
	gap: ${props => (props.$isMobile ? '12px' : '20px')};
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	height: 100%;
	overflow: auto;

	.field-global-001 {
		flex-direction: column;
		align-items: center;

		.content-label {
			width: 100%;
		}

		.task-label {
			min-width: ${props => (props.$isMobile ? '24px' : '75px')};
			min-height: 24px;
		}
	}

	.must-read {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.content-label {
		display: flex;
		gap: 4px;
		justify-content: flex-start;
		align-items: flex-end;
	}
`;
export const DSTSL001_Regist = React.memo(({ item, isModify = false, isTeacher, isResource, isOpen = false, isQnA = false, isNotice = false, }) => {
    const { classesId, isMobile } = useGlobalState();
    const { t } = useTranslation();
    const containerRef = useRef(null);
    const [title, setTitle] = useState('');
    const [value, setValue] = useState('');
    const [fileList, setFileList] = useState();
    const [isImportance, setIsImportance] = useState(false);
    const [textLength, setTextLength] = useState(0);
    const fileMutation = useMutation(postFile);
    const updatePostsMutation = useMutation(postUpdatePosts);
    const registPostMutation = useMutation(registPosts);
    const handlerChecked = (id, checked) => {
        setIsImportance(checked);
    };
    useEffect(() => {
        if (item) {
            setTitle(item.title);
            setValue(item.content);
            setIsImportance(item.importance);
            setFileList(item.files || []);
        }
    }, [item]);
    const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
    //팝업창 바깥쪽 영역 클릭 시 팝업창 닫힘
    const popupRef = useRef(null);
    //게시글 등록, 수정
    const handlerSubmit = async () => {
        try {
            APP.eventManager.publish('SHOW_LOADING', true);
            if (!title || title.trim() === '') {
                alert(t('supportRoom.titleAlert'));
                return;
            }
            if (value.replace(/<[^>]*>/g, '').trim().length === 0 || value.trim() === '') {
                alert(t('supportRoom.contentAlert'));
                return;
            }
            let convertAttachFileList = [];
            // 첨부 파일이 있는 경우
            if (fileList && fileList.length > 0) {
                const alredyFile = fileList.filter(file => typeof file === 'string');
                const filterFile = fileList.filter(file => file instanceof File);
                // 첨부 파일 업로드를 비동기로 실행하고 완료될 때까지 기다립니다.
                if (filterFile && filterFile.length > 0) {
                    const data = await fileMutation.mutateAsync(filterFile);
                    convertAttachFileList = [...data.result.link, ...alredyFile];
                }
                else {
                    convertAttachFileList = alredyFile;
                }
            }
            if (isModify) {
                const data = {
                    content: value,
                    files: convertAttachFileList,
                    importance: isImportance,
                    title,
                };
                await updatePostsMutation.mutateAsync({
                    id: item.id,
                    props: data,
                });
                !isQnA && APP.eventManager.publish('REFRESH_POSTS_DETAIL_LIST');
            }
            else {
                const data = {
                    class_id: classesId,
                    content: value,
                    files: convertAttachFileList,
                    importance: isImportance,
                    title,
                    type: isResource ? 'study_data' : 'notice',
                };
                await registPostMutation.mutateAsync(data);
            }
            APP.eventManager.publish(REFRESH_POSTS_LIST);
            closePopup();
        }
        catch (error) {
            alert(error);
        }
        finally {
            APP.eventManager.publish('SHOW_LOADING', false);
        }
    };
    if (!isOpen)
        return null;
    return (React.createElement(DSTL001Styled, { "$isMobile": isMobile, ref: popupRef },
        React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "field-short", style: { alignItems: 'center' } },
                React.createElement("div", { className: "task-label", style: {
                        minWidth: '30px',
                    } }, t('supportRoom.title')),
                React.createElement("div", { className: "fext-field" },
                    React.createElement(InputElement, { type: "text", defaultValue: item && item.title, onChange: setTitle, placeholder: t('supportRoom.titlePlaceHolder') })),
                !isQnA && (React.createElement(CheckBoxElement, { id: "importance", title: "\uD544\uB3C5", checked: isImportance, handlerCheck: handlerChecked, className: "must-read" })))),
        React.createElement(RegistContent, { contentName: t('supportRoom.content'), defaultValue: item?.content, setValue: setValue, handlerTextLength: setTextLength, isTeacher: isTeacher, containerRef: popupRef }),
        React.createElement(FileAttach, { id: "content_regist_file", isRegist: true, isNotice: true, fileList: fileList, setAttachFileList: setFileList }),
        React.createElement("div", { className: "btn-container", style: { padding: '0px', width: isMobile ? '100%' : 'unset' } },
            React.createElement(Button, { className: `btn_white ${isMobile ? 'sm' : 'xmd'}`, text: "common.cancel", onClick: closePopup, styles: { buttonWrap: { width: isMobile ? '100%' : 'unset' } } }),
            React.createElement(Button, { className: `btn_default ${isMobile ? 'sm' : 'xmd'}`, text: "common.okay", onClick: handlerSubmit, styles: { buttonWrap: { width: isMobile ? '100%' : 'unset' } } }))));
});
