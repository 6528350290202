import React from 'react';
import { useTranslation } from 'react-i18next';
import { OpenTable } from '../../base/layout/OpenTable';
import { TOGGLE_POPUP } from '../../base/popup';
import { RegistButton } from '../RegistButton';
import { DSTSL001_Regist } from './DSTSL001_Regist';
export const DSTSL001_List = React.memo(({ defaultOpen, isResource, isTeacher, }) => {
    const { t } = useTranslation();
    const handlerButtonClick = () => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DSTSL001_Regist,
            componentProps: {
                isRegist: true,
                isTeacher: isTeacher,
                isResource: isResource,
                isOpen: true,
                isNotice: true,
            },
            width: 800,
            height: 884,
            title: isResource ? t('supportRoom.resourceRegist') : t('supportRoom.noticeRegist'),
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OpenTable, { isResource: isResource, isTeacher: isTeacher, defaultOpen: defaultOpen }),
        isTeacher && React.createElement(RegistButton, { handlerButtonClick: handlerButtonClick })));
});
