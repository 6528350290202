import { getAccessToken } from '../../features/base/account';
import { getClassesId } from '../../features/base/classes';
import { fetchData, objectToQueryString, useCustomQuery } from '../functions';
const getToken = () => getAccessToken(APP.store.getState);
// (Qna) qna 리스트 조회
const getQnaList = async (queryString) => {
    const class_id = getClassesId();
    const url = `/api/mimac/v1/qnas?${queryString}&class_id=${class_id}`; // API 엔드포인트 생성
    return fetchData(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
// (QnA) qna 등록
export const registQna = async (data) => {
    const url = `/api/mimac/v1/qnas`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
    });
};
// (QnA) qna 수정
export const modifyQna = async (data) => {
    const url = `/api/mimac/v1/qnas/${data.id}/update`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data.props),
    });
};
// (QnA) qna t삭제
export const deleteQna = async (id) => {
    const url = `/api/mimac/v1/qnas/${id}/delete`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
const getQnaDownLoad = async (queryString) => {
    const class_id = getClassesId();
    const url = `/api/mimac/v1/qnas?${queryString}&class_id=${class_id}`; // API 엔드포인트 생성
    return fetchData(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
// (Qna) qna 리스트 다운로드
export const useQnaDownload = (data) => {
    const queryString = objectToQueryString(data); // props를 쿼리 문자열로 변환
    return useCustomQuery(`qna_${queryString}`, () => getQnaDownLoad(queryString));
};
// (Qna) qna 리스트 조회 - useQnaLists
export const useQnaList = (data) => {
    const queryString = objectToQueryString(data); // props를 쿼리 문자열로 변환
    return useCustomQuery(`qna_${queryString}`, () => getQnaList(queryString));
};
