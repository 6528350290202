import { fetchData, objectToQueryString, useCustomQuery } from '../functions';
// (클래스) 클래스 리스트 조회
export const getClassesList = async (props) => {
    const queryString = objectToQueryString(props); // props를 쿼리 문자열로 변환
    const url = `/api/mimac/v1/classes?${queryString}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (클래스) 클래스 리스트 가져오기
export const useClassesList = (data) => {
    return useCustomQuery(data?.id ? `classes_${data?.id}` : `classes`, () => getClassesList(data));
};
