import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import styled, { css } from 'styled-components';
import { Colors } from '../../../Colors';
import { useGlobalState, useHistoryStore } from '../../../GlobalStateProvider';
import { Button } from '../../../common';
import { FileAttach } from '../../../common/option';
import { deleteQna, modifyQna, postFile, registQna, useQnaList, } from '../../../server';
import { getClassesId } from '../../base/classes';
import { DetailTitleInfo, MobileTabLayout } from '../../base/layout';
import { DeletePopUpLayout } from '../../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../../base/popup';
import { RegistContent } from '../DSTSL001/DSTSL001_Regist_Child';
import { QnADropDown, QnAInfo } from './DSTS004_Answer_Child';
import { QnAListTable } from './DTST004_List_Child';
import { QNA_TYPE } from './constant';
const DSTS004AnswerStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 28px;
	align-items: flex-start;
	justify-content: flex-start;
	flex: 1;
	position: relative;
	overflow: hidden;
	width: 100%;
	overflow: auto;

	.qna-contents {
		display: flex;
		flex-direction: row;
		gap: 28px;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		align-self: stretch;
		padding-bottom: 15px;
	}

	.wrap_content {
		background: var(--color-white, #ffffff);
		border-radius: 10px;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 1px;
		padding: 24px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		box-sizing: border-box;
		flex: 1;
		overflow: hidden;

		.field-global-001 {
			align-items: flex-start;
		}
	}

	.answer_wrap_content {
		border-radius: 10px;
		border-style: solid;
		border-color: var(--color-pink, #f46790);
		border-width: 1px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		box-sizing: border-box;
		flex: 1;
		height: 100%;
		overflow: hidden;

		.field-global-001 {
			align-items: flex-start;
		}
	}

	.btn_pink {
		box-shadow: none;
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.answer_wrap {
		min-height: 450px;
		overflow: hidden;
		height: 100%;
	}

	.answer-info-wrap {
		overflow: auto;
		display: flex;
		gap: 24px;
		flex-direction: column;
		width: 100%;
	}

	/* .task-label {
		min-width: 70px !important;
		max-width: 70px !important;
	} */

	.answer_wrap_content {
		padding: 10px;
	}

	.attach_file {
		width: 100%;
	}
`;
const tabletStyles = css `
	.answer_wrap {
		min-height: unset;
		overflow: unset;
		flex: 1;
	}

	.answer-info-wrap {
		overflow: auto;
		display: flex;
		gap: 24px;
		flex-direction: column;
		width: 100%;
		flex: 1;
	}

	.answer_wrap_content {
		padding: 20px;
	}

	.wrap_content {
		height: 100%;
	}
`;
const desktopStyles = css `
	.answer_wrap {
		min-height: unset;
		overflow: unset;
	}

	.answer-info-wrap {
		overflow: auto;
		display: flex;
		gap: 24px;
		flex-direction: column;
		width: 100%;
	}

	.task-label {
		min-width: 70px !important;
		max-width: 70px !important;
	}

	.answer_wrap_content {
		padding: 24px;
	}

	.wrap_content {
		height: 100%;
	}
`;
const count = 20;
export const DSTS004_Answer = React.memo(({ id, answer_number = 0, isModify = false, isTeacher, isPopUp = false, isReQuestion = false, previousData, }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = useGlobalState();
    const class_id = getClassesId();
    const containerRef = useRef(null);
    const [questionData, setQuestionDate] = useState();
    const [title, setTitle] = useState('');
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [answer_id, setAnswerId] = useState('');
    const [answerFiles, setAnswerFiles] = useState([]); // [파일1, 파일2, 파일3
    const [answerCotent, setAnswerContent] = useState('');
    const [textLength, setTextLength] = useState(0);
    const [isImportance, setIsImportance] = useState(false);
    const [importance, setImporance] = useState([]);
    const [isQuestionOpen, setIsQuestionOpen] = useState(false);
    const [isAnswerOpen, setIsAnswerOpen] = useState(false);
    const fileMutation = useMutation(postFile);
    const registQnaMutation = useMutation(registQna);
    const modifyQnaMutation = useMutation(modifyQna);
    const deleteQnaMutation = useMutation(deleteQna);
    const { data, refetch } = useQnaList({
        root_id: id,
        // ...(answer_number
        // 	? {
        // 			limit: 2,
        // 			offset: 2 * answer_number,
        // 	  }
        // 	: {}),
        orderby: ['creation_time'],
        with_content: true,
    });
    const { data: importance_data, refetch: importance_refetch } = useQnaList({
        type: 'question',
        orderby: ['creation_time'],
        with_content: true,
        importance: true,
    });
    const refetchList = throttle(() => refetch(), 1000, { leading: true, trailing: false });
    useEffect(() => {
        refetchList();
        importance_refetch();
    }, [id, answer_number]);
    useEffect(() => {
        setAnswerContent('');
        setAnswerFiles([]);
        setAnswerId('');
        if (data && importance_data) {
            const { total_count, items, found_count } = data;
            const { total_count: importance_total_count, items: importance_items } = importance_data;
            setImporance(importance_items);
            if (found_count > 0) {
                setTestData(items);
                setTotalCount(total_count);
                const question_item = isModify
                    ? items[total_count ? total_count - 2 : 0]
                    : items[total_count ? total_count - 1 : 0];
                setQuestionDate(question_item);
                setIsImportance(question_item?.importance);
                const answer_item = isModify ? items[total_count ? total_count - 1 : 1] : '';
                // : items.find(i => i.type === 'answer');
                if (answer_item) {
                    // setAnswerContent(answer_item.content);
                    setAnswerFiles(answer_item.files);
                    setAnswerContent(answer_item.content);
                    setAnswerId(answer_item.id);
                }
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
        setQuestionDate(undefined);
    }, [data, importance_data, answer_number, isModify, isReQuestion]);
    const historyStore = useHistoryStore();
    const [currentPage, setCurrentPage] = useState(null);
    useEffect(() => {
        // 컴포넌트가 마운트될 때 최근 페이지 이력을 가져와서 현재 페이지로 설정
        const recentPageHistory = historyStore.getRecentPageHistory('ExamplePage');
        if (recentPageHistory) {
            setCurrentPage(recentPageHistory);
        }
    }, [historyStore]);
    const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
    const deleteQuestion = async () => {
        try {
            APP.eventManager.publish('SHOW_LOADING', true);
            await deleteQnaMutation.mutateAsync(questionData.id);
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'list',
                id: undefined,
            });
        }
        catch (error) {
            alert(error);
        }
        finally {
            APP.eventManager.publish('SHOW_LOADING', false);
            closePopup();
        }
    };
    const previousPage = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'list',
            id: undefined,
            props: previousData,
        });
        closePopup();
    };
    //모바일에서 질문보기, 답변하기 버튼 클릭 시 동작
    const handlerOnClick = (type) => {
        if (type === 'question')
            setIsQuestionOpen(!isQuestionOpen);
        else if (type === 'answer')
            setIsAnswerOpen(!isAnswerOpen);
    };
    const handleAnswerOK = async () => {
        try {
            APP.eventManager.publish('SHOW_LOADING', true);
            // if (textLength > MAX_FONT_LENGTH) {
            // 	throw new Error(`글자수는 ${MAX_FONT_LENGTH}자 이하로 입력해주세요.`);
            // }
            let convertAttachFileList = [];
            // 첨부 파일이 있는 경우
            if (answerFiles && answerFiles.length > 0) {
                try {
                    const alredyFile = answerFiles.filter(file => typeof file === 'string');
                    const filterFile = answerFiles.filter(file => file instanceof File);
                    // 첨부 파일 업로드를 비동기로 실행하고 완료될 때까지 기다립니다.
                    if (filterFile && filterFile.length > 0) {
                        // @ts-ignore
                        const data = await fileMutation.mutateAsync(filterFile);
                        convertAttachFileList = [...data.result.link, ...alredyFile];
                    }
                    else {
                        convertAttachFileList = alredyFile;
                    }
                }
                catch (error) {
                    console.error('Failed to upload files:', error);
                    return; // 에러가 발생하면 함수를 종료합니다.
                }
            }
            if (isModify && answer_id) {
                const data = {
                    attachments: convertAttachFileList,
                    content: answerCotent,
                };
                try {
                    const responseData = await modifyQnaMutation.mutateAsync({
                        id: answer_id,
                        props: data,
                    });
                    if (responseData) {
                        const importanceData = await modifyQnaMutation.mutateAsync({
                            id: answer_id,
                            props: { importance: isImportance },
                        });
                    }
                }
                catch (error) {
                    console.error('Failed to post modifyPost:', error);
                    alert(error);
                }
            }
            else {
                const data = {
                    attachments: convertAttachFileList,
                    content: answerCotent,
                    root_id: id,
                    type: QNA_TYPE.ANSWER,
                    class_id,
                    title,
                    importance: isImportance,
                };
                const responseData = await registQnaMutation.mutateAsync(data);
            }
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'list',
                id: undefined,
            });
        }
        catch (error) {
            console.error('Failed to post modifyPost:', error);
            alert('삭제된 질문입니다.');
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'list',
                id: undefined,
            });
        }
        finally {
            APP.eventManager.publish('SHOW_LOADING', false);
            closePopup();
        }
    };
    //const navigate = useNavigate();
    //삭제, 취소, 확인 버튼 클릭 시 팝업창 생성
    const AnswerPopup = (type) => {
        if (type === 'regist' && answerCotent.replace(/<[^>]*>/g, '').trim().length === 0) {
            alert('Q&A 답변 내용을 입력해주세요.');
            return;
        }
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DeletePopUpLayout,
            componentProps: {
                isQnA: true,
                deleteContent: t(`supportRoom.answerPopUp${type}`),
                onDelete: type === 'delete' && deleteQuestion,
                previosPage: type === 'cancel' && previousPage,
                onRegist: type === 'regist' && handleAnswerOK,
            },
            width: 639,
            title: t(`supportRoom.${type}AnswerHeader`),
            isFit: true,
        });
    };
    const renderTitleInfo = useMemo(() => (React.createElement(DetailTitleInfo, { key: 'renderTitle', title: t('supportRoom.qna'), name: totalCount > 2
            ? isTeacher
                ? isModify
                    ? t('supportRoom.requestionModify')
                    : t('supportRoom.requestionAnswer')
                : t('supportRoom.requestionRegist')
            : isModify
                ? t('supportRoom.requestionModify')
                : t('supportRoom.answered'), previousPage: previousPage })), [totalCount, isTeacher, isModify]);
    const renderQnaInfo = useMemo(() => (React.createElement(QnAInfo, { key: 'qnaInfo', item: questionData, isTeacher: isTeacher, handlerTitle: setTitle, setIsImportance: setIsImportance, isImportance: isImportance, importance: importance })), [questionData, isTeacher, setTitle, isImportance, importance]);
    const renderQuestion = useMemo(() => questionData && (React.createElement("div", { key: 'questionContent', className: "wrap_content" },
        React.createElement(RegistContent, { isDisbaled: true, defaultValue: questionData.content, contentName: '내용', isTeacher: isTeacher }),
        React.createElement(FileAttach, { id: "attach_show_file", className: "default_file", isOnlyFile: true, forceTitle: true, isRegist: false, fileList: questionData.files }))), [questionData?.content, questionData?.files, testData]);
    const renderQuestionWrap = useMemo(() => isMobile ? (React.createElement("div", { key: 'questionListWrap', style: { gap: '12px', display: 'flex', flexDirection: 'column' } },
        React.createElement(QnADropDown, { buttonTitle: t('supportRoom.showQuestionContent'), icon: "question", isOpen: isQuestionOpen, handlerOnClick: handlerOnClick }),
        isQuestionOpen && renderQuestion)) : (renderQuestion), [questionData, isQuestionOpen]);
    const renderAnswer = useMemo(() => (React.createElement("div", { key: "answerContent", className: "answer_wrap_content" },
        React.createElement(RegistContent, { contentName: '답변', defaultValue: answerCotent, setValue: setAnswerContent, handlerTextLength: setTextLength, isAnswer: true, isTeacher: isTeacher, containerRef: containerRef }),
        React.createElement(FileAttach, { id: "attach_file", className: isMobile
                ? 'btn_tur mobile-xxs'
                : isTablet
                    ? 'btn_tur mobile-sm'
                    : 'btn_tur xmd', isRegist: true, fileList: answerFiles, setAttachFileList: setAnswerFiles, isQnA: true }))), [answerCotent, answerFiles, testData]);
    const renderAnswerWrap = useMemo(() => isMobile ? (React.createElement("div", { key: 'answerListWrap', style: { gap: '12px', display: 'flex', flexDirection: 'column' } },
        React.createElement(QnADropDown, { buttonTitle: "\uB2F5\uBCC0\uD558\uAE30", icon: "answer", isOpen: isAnswerOpen, handlerOnClick: handlerOnClick }),
        isAnswerOpen && renderAnswer)) : (renderAnswer), [isAnswerOpen, renderAnswer]);
    const questionListPage = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'question_list',
            id: testData ? testData[0].root_id : undefined,
            props: isModify,
        });
    };
    //모바일일때 질문이력 보기 버튼
    const renderQuestionList = useMemo(() => totalCount >= 2 && (React.createElement(Button, { key: 'renderQuestionList', className: "btn_line_tur mobile-xs", text: t('supportRoom.showQuestionList'), option: {
            buttonBeforeIcon: {
                show: true,
                name: 'question',
                size: 16,
                color: Colors.dark_tur,
            },
        }, styles: { buttonWrap: { width: '100%', padding: '8px 16px', gap: '4px' } }, onClick: questionListPage })), [totalCount]);
    const renderQnAListTable = useMemo(() => isPopUp ? (React.createElement(QnAListTable, { key: 'table', id: id, isPopUp: isPopUp })) : (React.createElement(QnAListTable, { key: 'table', items: testData, isRetry: true })), [id, isPopUp, testData]);
    //질문이력 보기 버튼 클릭 시 동작
    if (isPopUp) {
        return (React.createElement(DSTS004AnswerStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        component: [renderTitleInfo, renderQnAListTable],
                        styles: { gap: '12px', display: 'flex', flexDirection: 'column' },
                    },
                ], previousPageList: {
                    title: t('assignment.goBack'),
                    menuDetail: 're_regist',
                    props: isModify,
                }, test_id: id })));
    }
    if (!questionData)
        return null;
    if (isMobile) {
        return (React.createElement(DSTS004AnswerStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        component: [
                            renderTitleInfo,
                            renderQuestionList,
                            renderQnaInfo,
                            renderQuestionWrap,
                            renderAnswerWrap,
                        ],
                        styles: { gap: '12px', display: 'flex', flexDirection: 'column' },
                    },
                ], showNav: false, showPageButton: true, submitAssignment: handleAnswerOK, previousPageList: { menuDetail: undefined } })));
    }
    return (React.createElement(DSTS004AnswerStyled, { "$isTablet": isTablet, "$isMobile": isMobile },
        renderTitleInfo,
        React.createElement("div", { className: "answer-info-wrap", ref: containerRef },
            totalCount > 2 && (React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, t('supportRoom.questionList')),
                renderQnAListTable)),
            renderQnaInfo,
            React.createElement("div", { className: "field-short answer_wrap" },
                renderQuestion,
                renderAnswer),
            React.createElement("div", { className: "btn-container", style: { width: '100%' } },
                React.createElement(Button, { className: "btn_pink xmd", text: t('common.delete'), onClick: () => AnswerPopup('delete') }),
                React.createElement(Button, { className: "btn_white xmd", text: t('common.cancel'), onClick: () => AnswerPopup('cancel') }),
                React.createElement(Button, { className: "btn_default xmd", text: t('common.okay'), onClick: () => AnswerPopup('regist') })))));
});
