import React, { useEffect } from 'react';
import { DropDown } from '../../../../../common';
//참여 권한 선택
const liveModeList = [
    { id: 'host', title: 'conference.host' },
    { id: 'manager', title: 'conference.manager' },
    { id: 'participant', title: 'conference.participant' },
];
const default_mode = { id: 'participant', title: 'conference.participant' };
export const MemberRoleDropDown = React.memo(({ isDisable = false, defaultRole, handlerUpdateOption, }) => {
    const [dropValue, setDropValue] = React.useState();
    useEffect(() => {
        if (dropValue && defaultRole === dropValue?.id)
            return;
        const drop_item = liveModeList.find(item => item.id === defaultRole);
        setDropValue(drop_item || default_mode);
    }, [defaultRole]);
    useEffect(() => {
        defaultRole && handlerUpdateOption(dropValue);
    }, [dropValue]);
    return (React.createElement(DropDown, { isDisable: isDisable, className: `dropdown-live ${isDisable ? 'disable' : ''}`, value: dropValue, list: liveModeList, option: {
            downIcon: {
                show: !isDisable,
            },
        }, setDropValue: setDropValue }));
});
