import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './src/reportWebVitals';
import App from './src/App'; // App 컴포넌트를 import 해야합니다.
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(React.createElement(App, null));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
