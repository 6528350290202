import React, { useEffect, useState } from 'react';
import { useQnaList } from '../../../server';
import throttle from 'lodash/throttle';
import { Button } from '../../../common';
import { QnAContents } from './DSTS004_Detailed_Child';
import { QNA_TYPE } from './constant';
import { Colors } from '../../../Colors';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../GlobalStateProvider';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const DSTL004ViewStyled = styled.div `
	${props => props.$isPopup &&
    `display: flex;
    flex-direction: column;
	justify-content: space-between;`}

	.qna_contents {
		border-radius: ${props => (props.$isPopup ? '12px' : '0px 0px 12px 12px')};
		width: 100%;
		background: var(--color-silver, #f3f4f8);
		display: flex;
		flex-direction: column;

		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		${props => props.$isPopup && `flex: 1;`}
	}
	.btn_groups {
		padding: 0px 0px 24px 0px;
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.item {
		display: flex;
		gap: 6px;
	}

	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileStyles = css `
	.qna_contents {
		padding: 10px;
		gap: 12px;
	}

	.qna_contents_popup {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.answer {
			padding: 0px 30px 30px 30px;
			border-radius: 0px 0px 12px 12px;
			margin-bottom: 24px;
		}

		.question {
			border-radius: 12px 12px 0px 0px;
		}
	}

	.question-list-popup {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
		padding: 0px 16px 16px 16px;
	}

	.item {
	}
`;
const tabletStyles = css `
	.qna_contents {
		padding: 20px;
		gap: 24px;
	}

	.qna_contents_popup {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.answer {
			padding: 0px 20px 20px 20px;
			border-radius: 0px 0px 12px 12px;
			margin-bottom: 24px;
		}

		.question {
			border-radius: 12px 12px 0px 0px;
		}
	}

	.question-list-popup {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
		padding: 0px 16px 16px 16px;
	}

	.item {
		font-size: var(--b3-font-size, 14px);
		line-height: 150%;
		letter-spacing: var(--b3-letter-spacing, -0.02em);
		font-weight: var(--b3-font-weight, 400);
	}
`;
const desktopStyles = css `
	.qna_contents {
		padding: 30px;
		gap: 24px;
	}

	.qna_contents_popup {
		display: flex;
		flex-direction: column;
		gap: 16px;

		.answer {
			padding: 0px 30px 30px 30px;
			border-radius: 0px 0px 12px 12px;
			margin-bottom: 24px;
		}

		.question {
			border-radius: 12px 12px 0px 0px;
		}
	}

	.question-list-popup {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
		padding: 0px 16px 16px 16px;
	}

	.item {
		font-size: var(--b3-font-size, 16px);
		line-height: var(--b3-line-height, 32px);
		letter-spacing: var(--b3-letter-spacing, -0.02em);
		font-weight: var(--b3-font-weight, 400);
	}
`;
export const DSTS004_Detail = ({ root_id, depths = 0, isPopUp = false, isHidePaging = false, isContent = false, isSearchAnswer = false, importance, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet } = useGlobalState();
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [number, setNumber] = useState(0);
    const { data, refetch } = useQnaList({
        root_id,
        limit: 2,
        offset: isContent && number !== 0 && number % 2 !== 0 ? number - 1 : number,
        orderby: ['creation_time'],
        with_content: true,
    });
    const refetchList = throttle(() => refetch(), 1000, { leading: true, trailing: false });
    useEffect(() => {
        setNumber(depths);
    }, [depths]);
    useEffect(() => {
        refetchList();
    }, [root_id, number]);
    useEffect(() => {
        if (data) {
            const { total_count, items, found_count } = data;
            if (found_count > 0) {
                setTestData(items);
                setTotalCount(total_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    if (testData && testData.length > 0) {
        return (React.createElement(DSTL004ViewStyled, { "$isPopup": isPopUp, "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement("div", null, isPopUp && !isMobile ? (testData.map((item) => (React.createElement("div", { className: "qna_contents_popup", key: item.id },
                item.type === QNA_TYPE.QUESTION && (React.createElement("div", { className: "field-global-001 question-list-popup" },
                    React.createElement("div", { className: "item", style: { flex: 1, maxWidth: '40px' } }, item.index),
                    React.createElement("div", { className: "item", style: { flex: 2 } }, moment(item.creation_time).format('YYYY-MM-DD, HH:mm')),
                    React.createElement("div", { className: "item", style: { flex: 1 } }, t(`supportRoom.${item.category}`)),
                    React.createElement("div", { className: "item", style: { flex: 3 } },
                        item.id !== item.root_id && (React.createElement(Button, { className: "btn_tur mobile-xxs", text: 'supportRoom.req' })),
                        React.createElement("div", null, item.title)),
                    React.createElement("div", { className: "item", style: { flex: 1 } }, item.author_name),
                    React.createElement("div", { className: "item", style: { flex: 1 } }, t(`supportRoom.${item.status}`)))),
                React.createElement("div", { className: `qna_contents ${testData.length === 2
                        ? item.type === QNA_TYPE.ANSWER
                            ? 'answer'
                            : 'question'
                        : ''}`, style: { marginBottom: testData.length === 1 && '24px' } },
                    React.createElement(QnAContents, { key: item.id, number: number, totalCount: totalCount, data: item, questionData: {
                            id: testData[0].id,
                            status: testData[0].status,
                        }, isQuestion: item.type === QNA_TYPE.QUESTION, isPopUP: isPopUp, isSearchAnswer: isSearchAnswer, importanceData: importance })))))) : (React.createElement("div", { className: "qna_contents" }, testData.map((item) => (React.createElement(QnAContents, { key: item.id, number: number, totalCount: totalCount, data: item, questionData: {
                    id: testData[0].id,
                    status: testData[0].status,
                }, isQuestion: item.type === QNA_TYPE.QUESTION, isPopUP: isPopUp, isSearchAnswer: isSearchAnswer, importanceData: importance })))))),
            totalCount > 2 && !isHidePaging && (React.createElement("div", { className: "btn_groups" },
                number !== 0 && (React.createElement(Button, { className: "btn btn_default xmd", text: "supportRoom.previousQuestion", option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: 'arrow',
                            size: 22,
                            color: Colors.w_grey,
                        },
                    }, onClick: () => setNumber(number - 2) })),
                totalCount > number * 2 && (React.createElement(Button, { className: "btn btn_default xmd", text: "supportRoom.nextQuestion", option: {
                        buttonAfterIcon: {
                            show: true,
                            className: 'arrow-right',
                            name: 'arrow',
                            size: 22,
                            color: Colors.w_grey,
                        },
                    }, onClick: () => setNumber(number + 2) }))))));
    }
};
