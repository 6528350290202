import _ from 'lodash';
import throttle from 'lodash/throttle';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Colors } from '../../../../../Colors';
import { useGlobalState, useHistoryStore } from '../../../../../GlobalStateProvider';
import { Button, Table } from '../../../../../common';
import { useAssignmentList } from '../../../../../server';
import { getClasses } from '../../../../base/classes';
import { TOGGLE_POPUP } from '../../../../base/popup';
import { updateMenu } from '../../../../menu/action';
import { DSTP001_Regist } from '../../DSTP001_Regist';
import { REFRESH_ASSIGNMENT_LIST, TEST_STATUS } from '../../constant';
import { AssignmentTitle } from './AssignmentTitle';
import { Container002Styled } from './DSTL001ListStyled';
import { DeleteListBtn } from './DeleteListBtn';
export const AssignmentTable = React.memo(({ isTeacher = false, isTest = false, isReserve = false, isReserveRegist = false, isHome = false, defaultCheckList = [], setUpdateCheckList, selectItem, handlerSelectItem, conference_id, }) => {
    const historyStore = useHistoryStore();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const [previousPageState, setPreviousPageState] = useState(); // 이전 페이지의 상태를 저장
    const [initLoad, setInitLoad] = useState(false);
    const classes_data = useSelector((state) => getClasses(state));
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [count, setCount] = useState(isHome ? 5 : 10);
    const [checkList, setCheckList] = useState([]);
    const [searchOption, setSearchOption] = useState({});
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = isMobile && isTeacher
            ? []
            : [
                ...(isReserve
                    ? [
                        { title: 'type', space: 1, sort: false },
                        {
                            title: isTest ? 'test_name' : 'title',
                            space: 2,
                            sort: false,
                        },
                        ...(isTeacher
                            ? [{ title: 'author_name', space: 1, sort: false }]
                            : []),
                    ]
                    : isHome
                        ? [
                            { title: 'type', space: 1, sort: false },
                            {
                                title: isTest ? 'test_name' : 'title',
                                space: 2,
                                sort: false,
                            },
                            {
                                title: isTest ? 'status' : 'submitDate',
                                space: 1,
                                sort: false,
                            },
                            ...(isTest
                                ? [{ title: 'etc', space: 1, sort: false }]
                                : []),
                        ]
                        : [
                            { title: 'creation_time', space: 1, sort: false },
                            ...(isTeacher && isTablet
                                ? [
                                    {
                                        title: `type_and_${isTest ? 'test' : 'subject'}_name`,
                                        space: 2,
                                        sort: false,
                                    },
                                ]
                                : isTablet && !isTeacher && isTest
                                    ? [
                                        { title: 'type', space: 1, sort: false },
                                        {
                                            title: 'class_name',
                                            space: 2,
                                            sort: false,
                                        },
                                        {
                                            title: 'test_name',
                                            space: 2,
                                            sort: false,
                                        },
                                    ]
                                    : [
                                        { title: 'type', space: 1, sort: false },
                                        {
                                            title: isTest ? 'class_name' : 'title',
                                            space: 2,
                                            sort: false,
                                        },
                                    ]),
                            ...(isTest
                                ? [
                                    ...(isTablet
                                        ? []
                                        : [
                                            {
                                                title: 'test_name',
                                                space: 2,
                                                sort: false,
                                            },
                                        ]),
                                    {
                                        title: 'progressdate',
                                        space: 2,
                                        sort: false,
                                    },
                                    { title: 'duration', space: 1, sort: false },
                                ]
                                : [
                                    { title: 'startline', space: 2, sort: false },
                                    { title: 'deadline', space: 2, sort: false },
                                ]),
                            { title: 'status', space: 1, sort: false },
                            ...(isTeacher
                                ? [
                                    { title: 'author_name', space: 1, sort: false },
                                    { title: 'etc', space: 1, sort: false },
                                ]
                                : []),
                        ]),
            ];
        const tableReadDataKeys = [
            ...(isReserve
                ? [
                    { title: 'type', space: 1, sort: false },
                    { title: 'title', space: 2, sort: false },
                    ...(isTeacher ? [{ title: 'author_name', space: 1, sort: false }] : []),
                ]
                : isHome
                    ? [
                        { title: 'type', space: 1, sort: false },
                        { title: 'title', space: 2, sort: false },
                        { title: isTest ? 'status' : 'submitDate', space: 1, sort: false },
                        ...(isTest ? [{ title: 'etc', space: 1 }] : []),
                    ]
                    : [
                        ...(isMobile
                            ? []
                            : [{ title: 'creation_time', space: 1, sort: false }]),
                        ...(isTeacher && isTablet && !isHome
                            ? [
                                { title: 'type', space: 2, sort: false },
                                {
                                    title: isTest ? 'conference_name' : 'title',
                                    space: 0,
                                    sort: false,
                                },
                            ]
                            : [
                                { title: 'type', space: 1, sort: false },
                                {
                                    title: isTest ? 'conference_name' : 'title',
                                    space: 2,
                                    sort: false,
                                },
                            ]),
                        ...(isTest
                            ? [
                                { title: 'title', space: 2, sort: false },
                                { title: 'progressdate', space: 2, sort: false },
                                { title: 'duration', space: 1, sort: false },
                            ]
                            : [
                                { title: 'startline', space: 2, sort: false },
                                { title: 'deadline', space: 2, sort: false },
                            ]),
                        { title: 'status', space: 1, sort: false },
                        ...(isTeacher
                            ? [
                                { title: 'author_name', space: 1, sort: false },
                                { title: 'etc', space: 1, sort: false },
                            ]
                            : []),
                    ]),
        ];
        return { headTitleList, tableReadDataKeys };
    }, [isTest, isTeacher]);
    useEffect(() => {
        const initializeState = () => {
            const previousPage = historyStore.getRecentPageHistory(isTest ? 'testListPage' : 'assignmentListPage');
            setPreviousPageState(previousPage);
            setPage(previousPage?.prePage || 0);
            setSearchOption(previousPage?.searchOption);
            setInitLoad(true);
        };
        initializeState();
        return () => {
            setInitLoad(false);
        };
    }, [isTest]);
    const { data, refetch } = useAssignmentList({
        ...(isReserve
            ? isReserveRegist
                ? { conference_id: '' }
                : { conference_id: ['', conference_id] }
            : {}),
        assignment: !isTest,
        offset: page === 0 ? 0 : page * count,
        limit: count,
        orderby: ['creation_time DESC'],
        ...(isHome && !isTest
            ? { status: [TEST_STATUS.OPEN, TEST_STATUS.CREATED] }
            : isReserve
                ? { status: TEST_STATUS.CREATED }
                : {}),
        ...searchOption,
    });
    // (리스트 갱신
    const refetchList = throttle(() => {
        refetch();
    }, 1000, { leading: true, trailing: false });
    const delSuccessList = (list) => {
        setCheckList(prev => prev.filter(id => !list.includes(id)));
    };
    // 이벤트 등록 - 리스트 갱신
    useEffect(() => {
        APP.eventManager.subscribe('DEL_SUCCESS_LIST', delSuccessList);
        return () => {
            APP.eventManager.unsubscribe('DEL_SUCCESS_LIST', delSuccessList);
        };
    }, [delSuccessList]);
    // 이벤트 등록 - 리스트 갱신
    useEffect(() => {
        APP.eventManager.subscribe(REFRESH_ASSIGNMENT_LIST, refetchList);
        APP.eventManager.subscribe('DEL_SUCCESS_LIST', delSuccessList);
        return () => {
            APP.eventManager.unsubscribe(REFRESH_ASSIGNMENT_LIST, refetchList);
            APP.eventManager.unsubscribe('DEL_SUCCESS_LIST', delSuccessList);
        };
    }, []);
    //홈화면 리스트 갱신
    useEffect(() => {
        APP.eventManager.publish(REFRESH_ASSIGNMENT_LIST);
    }, [classes_data.id]);
    useEffect(() => {
        if (data) {
            //이전 페이지 저장된 기록 초기화
            const { total_count, found_count, items } = data;
            if (total_count > 0 && found_count === 0) {
                setPage(Math.max(page - 1, 0));
                return;
            }
            //이전 페이지 저장된 기록 초기화
            if (previousPageState) {
                setPreviousPageState(undefined);
            }
            setTotalCount(isHome ? Math.min(total_count, 5) : total_count);
            // setTestData(prevData => (page === 0 ? items : [...prevData, ...items]));
            if (total_count > 0) {
                const list = items.map(i => ({
                    ...i,
                }));
                if (checkList.length > 0 && isReserve) {
                    setTestData(prevTestData => {
                        const prevDataById = _.keyBy(prevTestData, 'id');
                        const newDataById = _.keyBy(list, 'id');
                        const mergedData = _.merge({}, prevDataById, newDataById);
                        const allItems = _.values(mergedData);
                        const checkedItems = allItems.filter(item => checkList.includes(item.id));
                        const notCheckedItems = allItems.filter(item => !checkList.includes(item.id));
                        return [...checkedItems, ...notCheckedItems];
                    });
                }
                else {
                    if (isReserve)
                        setTestData(prevTestData => {
                            const prevDataById = _.keyBy(prevTestData, 'id');
                            const newDataById = _.keyBy(list, 'id');
                            const mergedData = _.merge({}, prevDataById, newDataById);
                            return _.values(mergedData);
                        });
                    else
                        setTestData(items);
                }
                setTotalCount(total_count);
                return;
            }
            // setTestData(items);
            if (handlerSelectItem && total_count >= 1) {
                handlerSelectItem(items[0].id, items[0].title);
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data, checkList]);
    useEffect(() => {
        setCount(isHome ? 5 : 10);
        refetchList();
    }, [isHome, isTest, count, page, searchOption]);
    // 상단 레이아웃 검색 조건을 위한 action
    const handlerFilterList = (filter) => {
        setSearchOption(filter);
    };
    // 상세 페이지로 이동
    const goToDetailPage = (id) => {
        // 새로운 페이지로 이동할 때 현재 상태를 기록
        const historyItem = {
            preItems: {
                ...(isReserve
                    ? isReserveRegist
                        ? { conference_id: '' }
                        : { conference_id: ['', conference_id] }
                    : {}),
                assignment: !isTest,
                offset: page === 0 ? 0 : page * count,
                limit: count,
                orderby: ['creation_time DESC'],
                ...(isHome && !isTest
                    ? { status: [TEST_STATUS.OPEN, TEST_STATUS.CREATED] }
                    : isReserve
                        ? { status: TEST_STATUS.CREATED }
                        : {}),
                ...searchOption,
            },
            props: {
                isHome: isHome,
                isTest: isTest,
                isReserve: isReserve,
                isReserveRegist: isReserveRegist,
                conference_id: conference_id,
            },
            prePage: page,
            searchOption: searchOption,
            pageName: isTest ? 'testListPage' : 'assignmentListPage',
        };
        historyStore.pushHistory(historyItem);
        // setMenu('assignmentDetail', id);
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'detail',
            id,
        });
    };
    // 수정 페이지로 이동
    const handlerEdit = (id) => {
        isMobile
            ? APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'modify',
                id: id,
            })
            : APP.eventManager.publish(TOGGLE_POPUP, {
                component: DSTP001_Regist,
                componentProps: { id, isTest, isTeacher: isTeacher, isModify: true },
                width: 800,
                title: t(`assignment.${isTest ? 'testModify' : 'modify'}`),
            });
    };
    const renderTitle = useMemo(() => initLoad && (React.createElement(AssignmentTitle, { isTest: isTest, isTeacher: isTeacher, checkList: checkList, searchOption: searchOption, handlerFilterList: handlerFilterList })), [initLoad, isTest, isTeacher, checkList, searchOption, handlerFilterList]);
    // list 페이지 업데이트 시
    const handlerMoreData = (page) => {
        if (isReserve) {
            setPage(prevPage => prevPage + 1);
        }
        else {
            setPage(page);
        }
    };
    const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
    const submitAssignment = () => {
        setUpdateCheckList && setUpdateCheckList(checkList);
        closePopup();
    };
    const renderItem = (item, styles) => {
        if (!item)
            return;
        // 날짜 포맷을 변수로 선언
        const dateFormat = 'YYYY-MM-DD';
        const dateTimeFormat = 'YYYY-MM-DD, HH:mm';
        const diffTime = isHome ? moment(item.deadline).diff(moment(), 'days') : 0;
        //모바일 화면에서 사용할 시작일, 상태 값
        const startline = moment(item.startline).format(dateTimeFormat);
        const status = t(`assignment.${item.status}`);
        // 공통적으로 사용될 스타일 선언
        const commonTextStyle = styles?.bodyText;
        const getStatusColor = status => {
            if (status === 'closed')
                return Colors.black;
            if (status === 'open')
                return Colors.tur;
            return Colors.pink;
        };
        const renderContent = headItem => {
            switch (headItem.title) {
                case 'etc':
                    if (isHome) {
                        return (React.createElement("p", { className: selectItem === item.id
                                ? 'underline_blue bold'
                                : 'underline_blue ', onClick: () => handlerSelectItem(item.id, item.title) }, "\uC694\uC57D\uBCF4\uAE30"));
                    }
                    return (React.createElement("div", { className: "flex row", style: { gap: 10 } },
                        item.status == TEST_STATUS.CREATED && (React.createElement(Button, { option: {
                                buttonAfterIcon: {
                                    show: true,
                                    name: 'edit',
                                    color: Colors.tur,
                                    size: 20,
                                },
                            }, onClick: () => handlerEdit(item.id) })),
                        React.createElement(DeleteListBtn, { isTest: isTest, isOneDel: true, deleteList: [item.id] })));
                case 'submitDate':
                    return (React.createElement("p", { className: "overText2", style: {
                            color: diffTime > 0
                                ? Colors.black
                                : diffTime === 0
                                    ? Colors.tur
                                    : Colors.pink,
                        } }, diffTime > 0
                        ? `D-${diffTime}`
                        : diffTime === 0
                            ? 'D-Day'
                            : t('assignment.end')));
                case 'status':
                    return (React.createElement("p", { className: "overText2", style: { color: getStatusColor(item[headItem.title]) } }, t(`assignment.${item[headItem.title]}${isHome ? 'Home' : ''}`)));
                case 'startline':
                    return (!isMobile && (React.createElement("p", { className: "overText2", style: commonTextStyle }, moment(item[headItem.title]).format(dateTimeFormat))));
                case 'deadline':
                    return isMobile ? (isTeacher ? (React.createElement("div", { className: "date-wrap-mobile" },
                        React.createElement("p", null,
                            startline,
                            " ~"),
                        React.createElement("p", null, moment(item[headItem.title]).format(dateTimeFormat)))) : (React.createElement("div", { className: "date-wrap-mobile" },
                        React.createElement("p", null,
                            t('assignment.startDate'),
                            " : ",
                            startline),
                        React.createElement("p", null,
                            t('assignment.endDate'),
                            " :",
                            ' ',
                            moment(item[headItem.title]).format(dateTimeFormat))))) : (React.createElement("p", { className: "overText2", style: commonTextStyle }, moment(item[headItem.title]).format(dateTimeFormat)));
                case 'duration':
                    return isMobile ? (React.createElement("div", { className: "status-wrap-mobile" },
                        React.createElement("div", { className: "status-text" },
                            t('assignment.startDate'),
                            " :",
                            moment(item.startline).format(dateFormat),
                            ","),
                        React.createElement("div", { className: "status-text" }, t('assignment.minutes', { minute: item[headItem.title] })))) : (React.createElement("p", { className: "overText2", style: commonTextStyle }, t('assignment.minutes', { minute: item[headItem.title] })));
                case 'author_name':
                    return isMobile ? (isTeacher ? (React.createElement("div", { className: "status-wrap-mobile" },
                        React.createElement("p", { className: "status-text", style: commonTextStyle }, moment(item['headItem.title']).format(dateFormat)),
                        React.createElement("p", { className: "status-text" }, item[headItem.title]))) : (React.createElement("div", { className: "status-wrap-mobile" },
                        React.createElement("p", { className: "overText2", style: commonTextStyle }, status),
                        React.createElement("p", { className: "status-text" }, item[headItem.title])))) : (React.createElement("p", { className: "overText2", style: commonTextStyle }, item[headItem.title]));
                case 'title':
                    return isMobile ? (React.createElement("div", { className: "status-wrap-mobile" },
                        !isHome && React.createElement(Button, { className: "btn_tur mobile-xxs", text: status }),
                        React.createElement("p", { className: "title-text-mobile overText2", style: commonTextStyle }, item[headItem.title]))) : (React.createElement("p", { className: "overText2", style: commonTextStyle }, item[headItem.title]));
                case 'type':
                    return !isHome && isTeacher && isTablet ? (React.createElement(React.Fragment, null,
                        React.createElement("p", { className: "typeText", style: commonTextStyle }, item[headItem.title]),
                        React.createElement("p", { className: "overText2", style: commonTextStyle }, item['title']))) : !isHome && !isTeacher && isMobile ? (React.createElement("div", { className: "status-wrap-mobile" },
                        React.createElement("p", { className: "typeText", style: commonTextStyle }, item[headItem.title]))) : (React.createElement("p", { className: "overText2", style: commonTextStyle }, item[headItem.title]));
                default:
                    const content = headItem.title === 'creation_time'
                        ? moment(item[headItem.title]).format(dateFormat)
                        : headItem.title === 'progressdate'
                            ? moment(item.startline).format(dateFormat)
                            : headItem.title === 'duration'
                                ? t('assignment.minutes', { minute: item[headItem.title] })
                                : item[headItem.title];
                    return (React.createElement("p", { className: "overText2", style: commonTextStyle }, content));
            }
        };
        return tableReadDataKeys.map(headItem => {
            const isMobileVisible = isMobile &&
                (isHome
                    ? headItem.title === 'startline'
                    : ['startline', 'progressdate', 'status', 'conference_name'].includes(headItem.title));
            const isTabletVisible = isTablet &&
                isTeacher &&
                !isHome &&
                ['conference_name', !(isReserve && isTest) && 'title'].includes(headItem.title);
            if (isMobileVisible || isTabletVisible) {
                return null;
            }
            return (React.createElement("div", { key: headItem.title, style: {
                    flex: !isMobile && headItem.space,
                    ...styles?.bodyItem,
                    overflow: 'hidden',
                    cursor: (isHome && !isTest) || headItem.title === 'etc'
                        ? 'default'
                        : 'pointer',
                    ...(isMobile &&
                        headItem.title === 'etc' && {
                        position: 'absolute',
                        top: '12px',
                        right: isHome ? '10px' : '0',
                    }),
                    width: isMobile && headItem.title === 'title' ? '80%' : 'unset',
                }, onClick: isReserve || headItem.title === 'etc'
                    ? undefined
                    : e => {
                        e.preventDefault();
                        if (isHome) {
                            dispatch(updateMenu(isTeacher ? 'learningManagement' : 'myRoom', {
                                menu: isTest ? 'test' : 'assignment',
                                detail: 'detail',
                            }, item.id));
                        }
                        else
                            goToDetailPage(item.id);
                    } }, renderContent(headItem)));
        });
    };
    const renderEmpty = useMemo(() => {
        return (React.createElement("div", { className: "empty_data", style: { color: Colors.light_grey } }, t(`assignment.${isTest ? 'test' : 'assignment'}emptyData`)));
    }, []);
    const renderMobileDeleteButton = useMemo(() => {
        return (React.createElement(React.Fragment, null,
            !isReserve && (React.createElement(DeleteListBtn, { isTest: isTest, isOneDel: false, deleteList: checkList, styles: { position: 'absolute', top: 115, right: 0, zIndex: 2 } })),
            React.createElement("p", { style: {
                    position: 'absolute',
                    top: isReserve && isTest ? 25 : 125,
                    left: isReserve && isTest ? 50 : 27,
                    fontSize: '12px',
                    fontWeight: 400,
                } }, t('assignment.allSelect'))));
    }, [checkList]);
    return (React.createElement(Container002Styled, { className: `task-board-container assignment-table ${isHome && 'home'} ${isReserve && isTest && 'liveRoom'}`, "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile, "$isHome": isHome, "$isTeacher": isTeacher, "$isTest": isTest, "$isReserve": isReserve },
        isTeacher && isMobile && !isHome && renderMobileDeleteButton,
        React.createElement(Table, { className: isHome ? 'home-list-003' : isReserve ? 'popup-list-006' : 'task-list-001', showCheckBox: !isHome && (isTeacher || isReserve) ? true : false, showIndex: isHome ? false : isMobile ? false : true, isInifinite: isReserve ? true : false, data: testData, defaultCheckList: defaultCheckList, totalCount: totalCount, page: page, showCount: count, sortDefault: "id", headTitleList: headTitleList, renderTitle: !isReserve && !isHome && renderTitle, renderEmpty: totalCount <= 0 && renderEmpty, renderItem: renderItem, handlerMoreData: handlerMoreData, handlerCheckList: setCheckList, forceShowheader: !isTeacher && isMobile ? false : true, selectedId: selectItem }),
        isReserve && (React.createElement("div", { className: "btn-container " },
            React.createElement(Button, { text: "common.cancel", className: "btn_white xmd", onClick: closePopup }),
            React.createElement(Button, { text: "common.okay", className: "btn_default xmd", onClick: submitAssignment })))));
});
