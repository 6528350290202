import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../GlobalStateProvider';
import { SubmitResult } from '../../DSS/DSSL001/DSSL001_Detail_Child';
import { getUserId } from '../../base/account';
import { SubmissionContainer } from './HomeSubmission';
import { AssignmentTable } from './component/DSTL001_List_Child';
const HomeAssignmentStyled = styled.div `
	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	&.home_assignment {
		height: 100%;
	}
	.wrap_submission {
		text-align: center;
		display: flex;
		flex-direction: column;
		gap: 8px;
		justify-content: center;
		align-items: center;
	}
`;
const desktopStyles = css `
	&.home_assignment {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		gap: 20px;
		flex: 1;
		overflow: hidden;

		.task-board-container {
			overflow: hidden;
		}

		.assignment-table {
			width: 100%;
		}
	}

	.wrap_submission {
		text-align: center;
		display: flex;
		flex-direction: column;
		gap: 8px;
		justify-content: center;
		align-items: center;
	}

	.title {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b2-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b2-font-size, 18px);
		line-height: var(--b2-line-height, 36px);
		letter-spacing: var(--b2-letter-spacing, -0.02em);
		font-weight: var(--b2-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
`;
export const HomeAssignment = React.memo(({ isTeacher, isTest }) => {
    const { isMobile } = useGlobalState();
    const [selectItem, setSelectItem] = useState();
    const [selectName, setSelectName] = useState();
    const member_id = useSelector((state) => getUserId(state));
    const handlerSelectItem = (id, name) => {
        setSelectItem(id);
        setSelectName(name);
    };
    return (React.createElement(HomeAssignmentStyled, { "$isMobile": isMobile, className: "home_assignment" },
        selectItem && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "line" }),
            React.createElement("div", { className: "wrap_submission" }, isTeacher ? (React.createElement(SubmissionContainer, { test_id: selectItem })) : (React.createElement(SubmitResult, { selectName: selectName, isHome: true, test_id: selectItem, member_id: member_id }))))),
        React.createElement(AssignmentTable, { isTeacher: isTeacher, isTest: isTest, isHome: true, selectItem: selectItem, handlerSelectItem: handlerSelectItem })));
});
