import { toState } from '../../../redux/regist';
export const getClasses = (statful) => {
    const state = toState(statful);
    return state['base/classes'].classes;
};
export const getClassesTitle = (statful) => {
    const classes = getClasses(statful);
    return classes?.title;
};
export const getClassesId = () => {
    // 정규표현식을 사용하여 URL에서 UUID 추출
    const uuidMatch = location.pathname.match(/\/class\/([0-9a-f-]+)$/i);
    // 추출된 UUID 확인
    const roomUuid = uuidMatch ? uuidMatch[1] : null;
    return roomUuid;
};
/**
 * 클래스에 속하는 그룹 멤버 아이디 가져온다
 * @param statful
 * @returns
 */
export const getClassesMember = (statful) => {
    const classes = getClasses(statful);
    return [...classes?.members, ...classes?.managers, classes?.host] || [];
};
export const getClassesSliceMember = (statful, count) => {
    return getClassesMember(statful).splice(0, count);
};
//학생 id만 가져옴
export const getClassesStudnet = (statful) => {
    const classes = getClasses(statful);
    return [...classes?.members] || [];
};
export const getClassesMemberCount = (statful) => {
    const members = getClassesMember(statful);
    return members.length || 0;
};
