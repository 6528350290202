import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistoryStore } from '../../GlobalStateProvider';
import { DSSL001_Detail } from '../DSS';
import { DSSL007_List } from '../DSS/DSSL007/DSSL007_List';
import { DSTL001_Detail, DSTL001_List, DSTP001_Regist } from '../DST/DSTL001';
import { DSTL001_Submit_Detail } from '../DST/DSTL001/DSTL001_Submit_Detail';
import { DSTL001_Submit_View } from '../DST/DSTL001/DSTL001_Submit_View';
import { DSTL006_List } from '../DST/DSTL006/DSTL006_List';
import { DSTL007_Send } from '../DST/DSTL007';
import { DSTS004_Answer } from '../DST/DSTS004/DSTS004_Answer';
import { DSTS004_List } from '../DST/DSTS004/DSTS004_List';
import { DSTS004_Regist } from '../DST/DSTS004/DSTS004_Regist';
import { DSTS006_List } from '../DST/DSTS006/DSTS006_List';
import { DSTSL001_List } from '../DST/DSTSL001';
import { DSTT001_List, DSTT001_Regist } from '../DST/DSTT001';
import { DSTT007_Detail } from '../DST/DSTT007';
import { DSTT007_List } from '../DST/DSTT007/DSTT007_List';
import { MobileTabLayout } from '../base/layout';
import { getMenuData } from './function';
const MenuBody = ({ isTeacher }) => {
    const { depths: name, menu, id, props, } = useSelector((state) => getMenuData(state), shallowEqual);
    const { t } = useTranslation();
    const historyStore = useHistoryStore();
    useEffect(() => {
        return () => {
            if (menu.menu === 'assignment' || menu.menu === 'test') {
                const isTest = menu.menu === 'test';
                historyStore.clearHistoryForPage(!isTest ? 'testListPage' : 'assignmentListPage');
            }
            if (menu.menu === 'qna') {
                console.log('clear history = 2', menu);
                historyStore.clearHistoryForPage('qnaListPage');
            }
        };
    }, [menu?.menu]);
    if (!menu?.menu)
        return null;
    if (name === 'home')
        return null;
    if (name === 'myRoom' || name === 'learningManagement') {
        if (menu.menu === 'assignment' || menu.menu === 'test') {
            const isTest = menu.menu === 'test';
            switch (menu.detail) {
                case 'list':
                    return React.createElement(DSTL001_List, { isTeacher: isTeacher, isTest: isTest });
                case 'regist':
                case 'modify':
                    return (React.createElement(DSTP001_Regist, { id: id, isTest: isTest, isTeacher: isTeacher, isModify: menu.detail === 'regist' ? false : true }));
                case 'detail':
                    if (isTeacher) {
                        return React.createElement(DSTL001_Detail, { isTest: isTest, test_id: id, props: props });
                    }
                    else {
                        return React.createElement(DSSL001_Detail, { isTest: isTest, test_id: id });
                    }
                case 'submit_view':
                    return (React.createElement(MobileTabLayout, { tabList: [
                            {
                                id: 1,
                                component: [
                                    React.createElement(DSTL001_Submit_View, { key: id, isTeacher: isTeacher, test_id: id, member: props }),
                                ],
                            },
                        ], showPageButton: false, isSubmit: true, previousPageList: {
                            title: t('assignment.goBack'),
                            menuDetail: 'detail',
                            props: { mobilePage: 1 },
                        }, test_id: id }));
                case 'submit_detail':
                    return (React.createElement(DSTL001_Submit_Detail, { test_id: id, isTest: isTest, isTeacher: isTeacher }));
            }
        }
        else if (menu.menu === 'attendanceStatus') {
            return React.createElement(DSTL006_List, { isTeacher: isTeacher });
        }
        else if (menu.menu === 'gradeCard') {
            return React.createElement(DSSL007_List, null);
            // return <DSTL001_List isTeacher={isTeacher} isGradeCard={true} />;
        }
        else if (menu.menu === 'sendSMS') {
            return React.createElement(DSTL007_Send, null);
        }
    }
    else if (name === 'liveRoom') {
        if (menu.menu === 'reservation' || menu.menu === 'previous-conference') {
            const isClosed = menu.menu === 'reservation' ? false : true;
            switch (menu.detail) {
                case 'list':
                    return (React.createElement(DSTT001_List, { isTeacher: isTeacher, isPrevious: menu.menu === 'previous-conference', isClosed: isClosed }));
                case 'detail':
                    return (React.createElement(DSTT001_Regist, { id: id, isPrevious: menu.menu === 'previous-conference', isModify: false, isDetail: true, isTeacher: isTeacher }));
                case 'regist':
                    return React.createElement(DSTT001_Regist, { isModify: false });
                case 'modify':
                    return React.createElement(DSTT001_Regist, { isModify: true, id: id });
            }
        }
        else if (menu.menu === 'reply') {
            switch (menu.detail) {
                case 'list':
                    return React.createElement(DSTT007_List, { isTeacher: isTeacher });
                case 'modify':
                    return React.createElement(DSTT007_Detail, { id: id, isRegist: true, isModify: true });
                case 'regist':
                    return React.createElement(DSTT007_Detail, { id: id, isRegist: true });
                case 'detail':
                    return React.createElement(DSTT007_Detail, { id: id, isRegist: false });
            }
        }
    }
    else if (name === 'learningSupportRoom') {
        if (menu.menu === 'notice') {
            switch (menu.detail) {
                case 'list':
                    return React.createElement(DSTSL001_List, { isResource: false, ...props, isTeacher: isTeacher });
            }
        }
        else if (menu.menu === 'qna') {
            switch (menu.detail) {
                case 'list':
                    return (React.createElement(DSTS004_List, { ...props, isTeacher: isTeacher, id: id, previousData: props }));
                case 'answer':
                    return React.createElement(DSTS004_Answer, { id: id, isTeacher: isTeacher, previousData: props });
                case 'answer_modify':
                    return (React.createElement(DSTS004_Answer, { id: id, ...props, isModify: true, isTeacher: isTeacher }));
                case 'regist':
                    return React.createElement(DSTS004_Regist, { id: id, isTeacher: isTeacher });
                case 're_regist':
                    return isTeacher ? (React.createElement(DSTS004_Answer, { id: id, isTeacher: isTeacher, isModify: props })) : (React.createElement(DSTS004_Regist, { id: id, isRetry: true, isTeacher: isTeacher }));
                case 'question_list':
                    return isTeacher ? (React.createElement(DSTS004_Answer, { id: id, isTeacher: isTeacher, isPopUp: true, isModify: props })) : (React.createElement(DSTS004_Regist, { id: id, isTeacher: isTeacher, isPopUp: true }));
            }
        }
        else if (menu.menu === 'resource') {
            switch (menu.detail) {
                case 'list':
                    return React.createElement(DSTSL001_List, { isResource: true, ...props, isTeacher: isTeacher });
            }
        }
        else if (menu.menu === 'content') {
            switch (menu.detail) {
                case 'list':
                    return React.createElement(DSTS006_List, null);
            }
        }
    }
    return React.createElement("div", null, menu?.menu);
};
export default MenuBody;
