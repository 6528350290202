import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { DropDown } from '../../../../../common';
import { getClasses } from '../../../../base/classes';
export const ReplayDropDown = React.memo(({ setDropValue, setSelectYear, setSelectMonth, selectYear, selcetMonth, isHome = false, }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    const startline = useSelector((state) => getClasses(state)?.startline);
    const [dropList, setDropList] = React.useState([]);
    const [value, setValue] = React.useState();
    useEffect(() => {
        const today = moment();
        const months = [];
        let currentDate = moment(startline);
        while (currentDate.isSameOrBefore(today, 'month')) {
            const title = `${currentDate.year()}년 ${currentDate.month() + 1}월 `;
            months.push({ id: currentDate.unix(), title });
            // Move to the next month
            currentDate.add(1, 'month');
        }
        setDropList(months.reverse()); // 최신 달부터 과거로 정렬
        setValue(months[0]); // 최신 달로 초기화
    }, [startline]);
    useEffect(() => {
        setSelectYear(value?.title.split('년')[0].trim());
        setSelectMonth(value?.title.split('년')[1].split('월')[0].trim());
        setDropValue(value?.id);
    }, [value]);
    return (React.createElement(DropDown, { className: isMobile && isHome ? 'dropdown-mobile-small' : 'dropdown-live', list: dropList, value: value, setDropValue: setValue, styles: {
            dropWrap: {
                ...(isMobile ? { width: '100%' } : { minWidth: 200, maxWidth: 200 }),
            },
        } }));
});
