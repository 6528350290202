import { CHANGE_MENU_FROM_NAME, CHANGE_MENU_PROPS, SET_MENU } from './actionType';
export const updateMenu = (depths, menu, id, props) => {
    return {
        type: SET_MENU,
        depths,
        menu,
        id,
        props,
    };
};
export const changeMenuFromName = (name, isMain = false) => {
    return {
        type: CHANGE_MENU_FROM_NAME,
        name,
        isMain,
    };
};
export const changeMemuProps = (props) => {
    return {
        type: CHANGE_MENU_PROPS,
        props,
    };
};
