import React, { useEffect } from 'react';
import { useStatistics } from '../../../../server';
import { ErrorRateGraph, ErrorRateTable, } from '../../../DST/DSTL001/component/DSTL001_Detail_Child';
import { Icon } from '../../../../common';
import { Colors } from '../../../../Colors';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { getIsTeacher } from '../../../base/account';
import { useGlobalState } from '../../../../GlobalStateProvider';
const ErrorContainerStyled = styled.div `
	&.no-rate-wrap {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b1-font-family, 'NotoSansKr-Regular', sans-serif);

				font-weight: var(--b1-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.task-label {
			p {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: 'NotoSansKr-Bold', sans-serif;
				font-size: 18px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 700;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}

	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	&.no-rate-wrap {
		.fext-field {
			height: 90%;
			p {
				font-size: var(--b1-font-size, 16px);
				line-height: 150%;
			}
		}
	}
`;
const desktopStyles = css `
	&.no-rate-wrap {
		min-width: '695px';
		.fext-field {
			height: 90%;
			p {
				font-size: var(--b1-font-size, 20px);
				line-height: var(--b1-line-height, 42px);
			}
		}
	}
`;
export const ErrorContainer = React.memo(({ isClosed, isTest, isSubmit, test_id, setIsErrorGraph, }) => {
    const { t } = useTranslation();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const [statisticItem, setStatisticItem] = React.useState(null);
    const { data: statisticData, refetch: statisticRefetch } = useStatistics({ test_id });
    const { isMobile, isDesktop } = useGlobalState();
    useEffect(() => {
        statisticRefetch();
    }, [test_id]);
    useEffect(() => {
        if (!statisticData)
            return;
        setStatisticItem(statisticData);
    }, [statisticData]);
    useEffect(() => {
        setIsErrorGraph(isClosed && statisticItem?.hardest_rank && statisticItem?.hardest_rank.length > 0);
    }, [isClosed, statisticItem]);
    return isClosed && statisticItem?.hardest_rank && statisticItem?.hardest_rank.length > 0 ? (React.createElement("div", { className: "error-rate-box" },
        React.createElement(ErrorRateTable, { hardest: statisticItem.hardest_rank }),
        React.createElement(ErrorRateGraph, { isVertical: false, hardest: statisticItem.hardest_rank, width: isMobile ? 243 : 340, height: 280 }))) : (React.createElement(ErrorContainerStyled, { "$isMobile": isMobile, className: "field-global-001 no-rate-wrap" },
        React.createElement("div", { className: "task-label", style: { width: '100%', alignItems: 'space-between', minHeight: '35px' } },
            React.createElement("p", null, t('assignment.errorRate'))),
        React.createElement("div", { className: "fext-field" },
            React.createElement(Icon, { icon: "detail-delete", size: 35, fill: Colors.w_grey }),
            (!isTeacher && isSubmit) || statisticItem?.hardest_rank.length > 0 ? (React.createElement("p", null, t(`assignment.${isTest ? 'testCheck' : 'assignmentCheck'}`))) : (React.createElement("p", null, t('assignment.noSubmitInfo'))))));
});
