import { Colors } from '../../Colors';
export const ButtonOption = {
    buttonAfterIcon: {
        className: undefined,
        show: false,
        name: undefined,
        size: 10,
        color: Colors.grey,
        stroke: Colors.transparent,
    },
    buttonBeforeIcon: {
        className: undefined,
        show: false,
        name: undefined,
        size: 10,
        color: Colors.grey,
        stroke: Colors.transparent,
    },
};
