import { getAccessToken } from '../../features/base/account';
import { getClassesId } from '../../features/base/classes';
import { fetchData, objectToQueryString, useCustomQuery } from '../functions';
const getToken = () => getAccessToken(APP.store.getState);
// (회의실) conference 리스트 조회
const getConferenceList = async (queryString) => {
    const class_id = getClassesId();
    const url = `/api/mimac/v1/conferences?${queryString}&class_id=${class_id}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (회의실) 1:1라이브  리스트 조회
const getEnquiriesList = async (queryString) => {
    const class_id = getClassesId();
    const url = `/api/mimac/v1/enquiries?${queryString}&class_id=${class_id}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (회의실) 1:1 라이브 대기열 등록
export const postEnquiriesList = async (data) => {
    const url = `/api/mimac/v1/enquiries/${data.id}/queue-in`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
    });
};
// (회의실) 1:1 라이브 대기열 해제
export const postoutEnquiriesList = async (id) => {
    const url = `/api/mimac/v1/enquiries/${id}/queue-out`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
// 문의 퇴장
export const quitEnquiriesList = async (id) => {
    const url = `/api/mimac/v1/enquiries/${id}/quit`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
// (회의실) 강의실 등록
export const postConferenceList = async (data) => {
    const url = `/api/mimac/v1/conferences`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
    });
};
// (회의실) 강의실 수정
export const updateConferenceList = async ({ id, props }) => {
    const url = `/api/mimac/v1/conferences/${id}/update`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(props),
    });
};
// (회의실) 강의실 삭제
export const deleteConferenceList = async (id) => {
    const url = `/api/mimac/v1/conferences/${id}/delete`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
    });
};
// (회의실) r강의실 회의 연결
export const connectionConferenceList = async ({ id, props }) => {
    const url = `/api/mimac/v1/conferences/${id}/connect`;
    return fetchData(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(props),
    });
};
// (회의실) conference 리스트 조회 -useConferenceList
export const useConferenceList = (data) => {
    const queryString = objectToQueryString(data); // props를 쿼리 문자열로 변환
    return useCustomQuery(`conferences_${queryString}`, () => getConferenceList(queryString));
};
// (회의실) conference 리스트 조회 -useConferenceList
export const useEnquiriesList = (data) => {
    const queryString = objectToQueryString(data); // props를 쿼리 문자열로 변환
    return useCustomQuery(`enquires_${queryString}`, () => getEnquiriesList(queryString));
};
// (회의 출결 현황 조회)
export const getConferenceReport = async (id, queryString) => {
    const class_id = getClassesId();
    const url = `/api/mimac/v1/members/${id}/conference-report?${queryString}&class_id=${class_id}`; // API 엔드포인트 생성`; // API 엔드포인트 생성
    return fetchData(url);
};
export const useConferenceReport = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`conferenceReport_${data}`, data], () => getConferenceReport(data.id, queryString));
};
// (회의 출결 현황 조회)
export const getConferenceAttendance = async (id, queryString) => {
    const url = `/api/mimac/v1/conferences/${id}/attendance?${queryString}`; // API 엔드포인트 생성`; // API 엔드포인트 생성
    return fetchData(url);
};
export const useConferenceAttendance = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`conferenceAttendance_${data}`, data], () => getConferenceAttendance(data.id, queryString));
};
// (회의실) entry_log 리스트 조회
export const getEntryData = async (data) => {
    const class_id = getClassesId();
    const url = `/api/mimac/v1/conferences/${data.id}/entry-logs`; // API 엔드포인트 생성
    return fetchData(url);
};
// (회의실) entry_log 리스트 조회
export const useEntryData = (data) => {
    const queryString = objectToQueryString(data);
    return useCustomQuery([`conferenceAttendance_${data}`, data], () => getEntryData(data));
};
