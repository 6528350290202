import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useGlobalState, useHistoryStore } from '../../../GlobalStateProvider';
import { MobileTabLayout } from '../../base/layout';
import { OpenMemeberList, SendSMSListTable } from './DSTL007_Send_Child';
import { SendSMSContent } from './DSTL007_Send_Child/SendSMSContent';
import { useDispatch } from 'react-redux';
import { changeMemuProps } from '../../menu/action';
const DSTL007SendStyled = styled.div `
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: row;
	gap: ${props => (props.$isMobile ? '0px' : props.$isTablet ? '24px' : '40px')};
	position: relative;

	${props => props.$isDesktop &&
    `
		
		.right_box {position: relative;
			flex: 2;
			display: flex;
			flex-direction: row;
			position: relative;
			width: 65%;
			min-width: 820px;
		}
	`}

	${props => props.$isMobile &&
    `
		.tab-item {
			display:flex;
		}
	`}

	${props => props.$isTablet &&
    `
		.mobile-tab-layout {
			width: unset;
			flex: 1;
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px;
			border-radius: 10px;
			padding:20px
		}
	`}
`;
export const DSTL007_Send = React.memo(() => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const historyStore = useHistoryStore();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [isOpen, setIsOpen] = useState(false);
    const [openMemberList, setOpenMemberList] = useState(false);
    const [sendMemberSetting, setSendMemberSetting] = useState('detail');
    const [receiverMemberList, setReceiverMemberList] = useState([]);
    const [editId, setEditId] = useState('');
    const [mobilePage, setMobilePage] = useState('');
    const [prevPage, setPrevPage] = useState('');
    const [historyItem, setHistoryItem] = useState({
        props: {},
        pageName: '',
    });
    const updateEditId = (id) => {
        setEditId(id);
        setIsOpen(true);
        setOpenMemberList(false);
    };
    useEffect(() => {
        //새로고침시 데이터 유지
        const value = localStorage.getItem('learningManagement_props');
        if (value !== 'undefined') {
            const parsedValue = JSON.parse(value).historyItem?.props;
            const parsedPageName = JSON.parse(value).historyItem?.pageName;
            if (!parsedPageName)
                return;
            setEditId(parsedValue.editId);
            setIsOpen(parsedValue.isOpen);
            setMobilePage(parsedValue.mobilePage);
            setOpenMemberList(parsedValue.openMemberList);
            setReceiverMemberList(parsedValue.receiverMemberList);
            setSendMemberSetting(parsedValue.sendMemberSetting);
            setPrevPage(parsedValue.mobilePage);
        }
        APP.eventManager.subscribe('MODIFY_SMS', updateEditId);
        return () => {
            APP.eventManager.unsubscribe('MODIFY_SMS', updateEditId);
        };
    }, []);
    useEffect(() => {
        if (isDesktop)
            return;
        if (openMemberList) {
            if (sendMemberSetting === 'add' || sendMemberSetting === 'delete') {
                setMobilePage('memberAdd');
            }
            else if (sendMemberSetting === 'detail') {
                setMobilePage('memberLeft');
            }
        }
        else if (isOpen) {
            setMobilePage('send');
        }
        else {
            setMobilePage('list');
        }
    }, [isOpen, openMemberList, sendMemberSetting]);
    useEffect(() => {
        return () => {
            setIsOpen(false);
        };
    }, [isMobile]);
    const renderMobileView = useMemo(() => {
        let content = null;
        switch (mobilePage) {
            case 'send':
                content = (React.createElement(SendSMSContent, { receiverMemberList: receiverMemberList, setIsOpen: setIsOpen, editId: editId, setEditId: setEditId, openMemberList: openMemberList, setOpenMemberList: setOpenMemberList, setReceiverMemberList: setReceiverMemberList, setMobilePage: setMobilePage }));
                break;
            case 'memberLeft':
                content = (React.createElement(OpenMemeberList, { receiverMemberList: receiverMemberList, setReceiverMemberList: setReceiverMemberList, setSendMemberSetting: setSendMemberSetting, sendMemberSetting: sendMemberSetting }));
                break;
            case 'memberAdd':
                content = (React.createElement(OpenMemeberList, { receiverMemberList: receiverMemberList, setReceiverMemberList: setReceiverMemberList, setSendMemberSetting: setSendMemberSetting, sendMemberSetting: sendMemberSetting }));
                break;
            case 'list':
            default:
                content = (React.createElement(SendSMSListTable, { setIsOpen: setIsOpen, setEditId: setEditId, setReceiverMemberList: setReceiverMemberList }));
                break;
        }
        if (isMobile) {
            //새로고침시 유지할 데이터 저장
            setHistoryItem({
                props: {
                    mobilePage,
                    isOpen,
                    editId,
                    openMemberList,
                    sendMemberSetting,
                    receiverMemberList,
                },
                pageName: 'SenedSMS',
            });
            historyStore.pushHistory(historyItem);
        }
        return React.createElement(Fragment, { key: mobilePage },
            content,
            " ");
    }, [mobilePage, isOpen, editId, openMemberList, sendMemberSetting, receiverMemberList]);
    useEffect(() => {
        if (historyItem) {
            dispatch(changeMemuProps({ historyItem }));
        }
    }, [historyItem]);
    const renderTabletView = () => (React.createElement(React.Fragment, null,
        React.createElement(SendSMSContent, { receiverMemberList: receiverMemberList, editId: editId, setEditId: setEditId, setIsOpen: setIsOpen, openMemberList: openMemberList, setOpenMemberList: setOpenMemberList, setReceiverMemberList: setReceiverMemberList }),
        openMemberList ? (React.createElement(MobileTabLayout, { tabList: [
                {
                    id: 1,
                    component: [renderMobileView],
                },
            ], showNav: sendMemberSetting === 'detail' ? false : true, previousPageList: {
                title: t('assignment.goBack'),
            }, handlerPreviousPage: handlerPreviousPage })) : (React.createElement(SendSMSListTable, { setIsOpen: setIsOpen, setEditId: updateEditId }))));
    const renderDesktopView = () => (React.createElement(React.Fragment, null,
        React.createElement(SendSMSContent, { receiverMemberList: receiverMemberList, editId: editId, setEditId: setEditId, setIsOpen: setIsOpen, openMemberList: openMemberList, setOpenMemberList: setOpenMemberList, setReceiverMemberList: setReceiverMemberList }),
        React.createElement("div", { className: "right_box" },
            openMemberList && (React.createElement(OpenMemeberList, { receiverMemberList: receiverMemberList, setReceiverMemberList: setReceiverMemberList })),
            React.createElement(SendSMSListTable, { setIsOpen: setIsOpen, setEditId: updateEditId }))));
    const handlerPreviousPage = () => {
        if (sendMemberSetting === 'add' || sendMemberSetting === 'delete') {
            setSendMemberSetting('detail');
            setMobilePage('member');
            setOpenMemberList(true);
        }
        else if (openMemberList) {
            setOpenMemberList(false);
        }
        else if (isOpen) {
            setIsOpen(false);
        }
    };
    return (React.createElement(DSTL007SendStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop, className: "dtsl-007-send" }, isMobile ? (React.createElement(MobileTabLayout, { tabList: [
            {
                id: 1,
                component: [renderMobileView],
            },
        ], showNav: mobilePage !== 'list', previousPageList: {
            title: t('assignment.goBack'),
        }, handlerPreviousPage: handlerPreviousPage })) : isTablet ? (renderTabletView()) : (renderDesktopView())));
});
