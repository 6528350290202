import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { CheckBox, SearchBar } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
const AttandancesTitleStyled = styled.div `
	${props => (props.$isMobile ? `width: 100%;` : ``)}

	.attandances-title {
		display: flex;
		flex-direction: row;
		gap: 14px;
		align-items: center;
		justify-content: ${props => (props.$isMobile ? 'flex-start' : 'flex-end')};
		flex-shrink: 0;
		position: relative;
		flex-wrap: wrap;

		${props => (props.$isMobile ? `width: 100%;` : ``)}
	}
`;
const STATE_CHECKBOX_LIST = [
    { id: 'all', title: 'common.all', checked: true },
    { id: 'attendance', title: 'conference.attendance', checked: false },
    { id: 'late', title: 'conference.late', checked: false },
    { id: 'nonAttendance', title: 'conference.nonAttendance', checked: false },
];
export const AttandancesTitle = React.memo(({ defaultStatus, handlerFilterList, }) => {
    const { isMobile } = useGlobalState();
    const [list, setList] = React.useState(STATE_CHECKBOX_LIST);
    useEffect(() => {
        if (defaultStatus) {
            const updatedList = list.map(item => item.id === defaultStatus
                ? { ...item, checked: true }
                : { ...item, checked: false });
            setList(updatedList);
        }
    }, [defaultStatus]);
    useEffect(() => {
        const checkedItem = list.find(item => item.checked);
        if (checkedItem) {
            handlerFilterList(checkedItem.id === 'all'
                ? { done: null, late: null }
                : {
                    done: checkedItem.id === 'nonAttendance' ? false : true,
                    late: checkedItem.id === 'late' ? true : false,
                });
        }
    }, [list]);
    const handlerCheckRadio = useCallback((id) => {
        setList(prevList => prevList.map(item => item.id === id ? { ...item, checked: true } : { ...item, checked: false }));
    }, []);
    return (React.createElement(AttandancesTitleStyled, { "$isMobile": isMobile },
        React.createElement("div", { className: "attandances-title" },
            React.createElement(CheckBox, { isRadioButton: true, list: list, handlerCheck: handlerCheckRadio }),
            React.createElement(SearchBar, { className: "search-bar-tablet", filterData: "member", handlerFilterList: handlerFilterList }))));
});
