import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Colors } from '../../../../Colors';
import { Button } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
const QnAAnswerStyled = styled.div `
	display: flex;
	flex-direction: row;
	gap: 28px;
	align-items: flex-start;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	width: 48%;

	.question-wrapper {
		background: var(--color-white, #ffffff);
		border-radius: 10px;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 1px;
		padding: 24px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}
	.attach-wrapper {
		background: var(--color-white, #ffffff);
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 10px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		width: 100%;
	}

	.attach-files {
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		position: relative;
	}

	.question-content {
		display: flex;
		flex-direction: column;
		gap: 10px;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		min-height: 280px;
		flex: 1;

		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 11px;
		flex: 1;
		width: 100%;
		box-sizing: border-box;
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		.img_wrap {
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			padding-top: 10px;
			position: relative;
			.img {
				border-radius: 4px;
				flex-shrink: 0;
				width: 100px;
				height: 100px;
				position: relative;
				object-fit: cover;
				background: #000;
			}
		}
	}
`;
export const QnAAnswer = React.memo(({ item }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    return (React.createElement(QnAAnswerStyled, null,
        React.createElement("div", { className: "question-wrapper", style: { height: '100%' } },
            React.createElement("div", { className: "field-global-001", style: { height: '100%', alignItems: 'start' } },
                React.createElement("div", { className: "task-label" }, t('supportRoom.content')),
                React.createElement("div", { className: "question-content" },
                    React.createElement("div", null,
                        item ? item[0].content : '',
                        " "),
                    React.createElement("div", { className: "img_wrap" }, item &&
                        item[0].files.map((file, index) => (React.createElement("div", { key: index, className: "img" })))))),
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, t('supportRoom.attachFile')),
                React.createElement("div", { className: "attach-wrapper" }, item &&
                    item[0].files.map((attach, index) => (React.createElement("div", { className: "attach-files", key: index },
                        React.createElement(Button, { key: index, className: "btn_sliver md_round center body5 cursor attach_item", text: attach, styles: {
                                buttonWrap: {
                                    height: 'fit-content',
                                    maxWidth: isMobile ? 180 : 220,
                                },
                            }, option: {
                                buttonBeforeIcon: {
                                    name: 'file',
                                    color: Colors.dark_tur,
                                    size: 12,
                                    show: true,
                                },
                                buttonAfterIcon: {
                                    name: 'file-down',
                                    color: Colors.accent,
                                    size: 12,
                                    show: true,
                                },
                            } })))))))));
});
