import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { postAttachment, postSubmitAnswer, useAnswerList, useFeedbackList, useQuestionList } from '../../../../server';
import { Button, } from '../../../../common';
import throttle from 'lodash/throttle';
import { useMutation } from 'react-query';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { useSelector } from 'react-redux';
import { getIsTeacher } from '../../../base/account';
import { TOGGLE_POPUP } from '../../../base/popup';
import { AttachFileAnswer } from './AttachFileAnswer';
const DetailQuestionStyled = styled.div `
	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;
	height: 100%;

	/* .fext-field {
		height: 100%;
		overflow: hidden;
	} */

	.task-label {
		width: 100%;
	}

	.no-question-wrap {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b1-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b1-font-size, 20px);
				line-height: var(--b1-line-height, 42px);
				font-weight: var(--b1-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}

	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 6px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.input_answer_wrapper {
				background: #ffffff;
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 4px 10px 4px 10px;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			.body-item {
				width: 100%;
			}

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 12px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}
	}
	.input_point_wrapper {
		background: #ffffff;
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 10px;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: center;
		position: relative;
		max-width: 80px;
		height: 38px;

		input {
			width: 100%;
			outline: none !important;
			border: none !important;
			padding: 0 !important;
			background: transparent;
		}
	}

	.detail_txt {
		color: var(--color-grey, #666666);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 16px;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
	}

	.subject-item-wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.subject-item-title {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'NotoSansKr-SemiBold', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 600;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.omr-point-wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}

	.submission-header {
		gap: 24px;
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.attachment-explain {
		width: 100%;
    	display: flex;
    	justify-content: flex-start;
    	flex-direction: row;
	}

	.attachment-wrapper {
    	flex: 1;
    	width: 100%;
    	box-sizing: border-box;
    	gap: 12px;
	}

	.attachment-display {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.attachment-question-wrap {
		min-width: 695px;
		width: 695px;
		height: 100%;
		overflow: hidden;

		/* .fext-field {
			height: 100%;
			overflow: hidden;
		} */
	}
`;
const desktopStyles = css `
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 21px;
	align-items: flex-end;
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	position: relative;

	/* .fext-field {
		height: 100%;
		overflow: hidden;
	} */

	.task-label {
		width: 100%;
	}

	.no-question-wrap {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b1-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b1-font-size, 20px);
				line-height: var(--b1-line-height, 42px);
				font-weight: var(--b1-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}

	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.input_answer_wrapper {
				background: #ffffff;
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 4px 10px 4px 10px;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}
	}
	.input_point_wrapper {
		background: #ffffff;
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 4px 10px 4px 10px;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;

		input {
			width: 100%;
			outline: none !important;
			border: none !important;
			padding: 0 !important;
			background: transparent;
		}
	}

	.detail_txt {
		color: var(--color-grey, #666666);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 16px;
		// line-height: 32px;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
	}

	.attachment-explain {
		width: 100%;
    	display: flex;
    	justify-content: flex-start;
    	flex-direction: row;
	}

	.attachment-wrapper {
    	flex: 1;
    	width: 100%;
    	box-sizing: border-box;
    	gap: 12px;
	}

	.attachment-display {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.attach-file-box-render {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
	}

	.attach-file-answer {
		width: 100%;
	}
	
	.attach-file-column {
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
	}

	.attach-file-box {
		border-radius: 4px;
    	padding: 10px;
    	display: inline-flex;
    	flex-wrap: wrap;
    	gap: 10px;
    	border: 1px solid #b6bac0;
		height: 50px;
	}

	.attachment-question-wrap {
		min-width: 695px;
		width: 695px;
		height: 100%;
		overflow: hidden;

		/* .fext-field {
			height: 100%;
			overflow: hidden;
		} */
	}
`;
const count = 100;
export const AttachmentQuestionTable = React.memo(({ item, isTest, isSubmit, isOpen, test_id, }) => {
    const { t } = useTranslation();
    const { isDesktop, isMobile } = useGlobalState();
    const [questionId, setQuestionId] = useState('');
    const [submitted, setSubmitted] = useState(isSubmit);
    const [testData, setTestData] = useState([]);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [submittedFileList, setSubmittedFileList] = useState([]);
    const [attachFileList, setAttachFileList] = useState([]);
    // const [feedbackFileList, setFeedbackFileList] = useState<string[]>([]);
    // const [feedbackComplete, setFeedbackComplete] = useState<boolean>(false);
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const member_id = useSelector((state) => state['base/account'].user.id);
    const submitAnswerMutation = useMutation(postSubmitAnswer);
    const { data, refetch } = useQuestionList({
        test_id,
        offset: page === 0 ? 0 : page * 100,
        limit: 100,
        orderby: ['number'],
    });
    const { data: answerData, refetch: answerRefetch } = useAnswerList({
        question_id: questionId,
        from: member_id,
        orderby: "value",
    });
    const { data: feedbackData, refetch: feedbackRefetch } = useFeedbackList({
        test_id,
        to: member_id,
    });
    const throttledRefetch = throttle(refetch, 1000, { leading: true, trailing: false });
    const throttledAnswerRefetch = throttle(answerRefetch, 1000, { leading: true, trailing: false });
    // const throttledFeedbackRefetch = throttle(feedbackRefetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        // console.log("attachment:", item?.attachments);
        // console.log("length",item?.attachments?.length);
        // console.log("item2", item);
        // console.log("answerData", answerData);
        throttledRefetch();
        // throttledFeedbackRefetch();
    }, [page, test_id]);
    useEffect(() => {
        if (data) {
            const { total_count, items } = data;
            console.log("items", items);
            setTestData(items);
            setTotalCount(total_count);
            const questionId = items[0]?.id;
            setQuestionId(questionId);
        }
    }, [data]);
    useEffect(() => {
        throttledAnswerRefetch();
    }, [questionId]);
    // useEffect(() => {
    // 	if (!feedbackData) return;
    // 	const { items, total_count } = feedbackData;
    // 	if (total_count >= 1) {
    // 		setFeedbackComplete(true);
    // 		setFeedbackFileList(items.map(item => item.value));
    // 	}
    // }, [feedbackData]);
    useEffect(() => {
        if (!questionId)
            return;
        if (!answerData)
            return;
        const { items } = answerData;
        console.log("answerData.items", items);
        const valuesArray = items.map(item => item?.value);
        // console.log("valuesArray", valuesArray);
        setSubmittedFileList(valuesArray);
    }, [answerData]);
    useEffect(() => {
        if (item.type === '파일첨부형') {
            console.log("파일첨부형 item.type", item.type);
        }
        console.log("attachFileList:", attachFileList);
        // console.log("attachFileList.length:", attachFileList.length, index);
        // console.log("hasEmptyArray", hasEmptyArray);
        console.log("attachFileList[0]", attachFileList[0]);
        // console.log("submittedFileList", submittedFileList);
    }, [attachFileList, submittedFileList]);
    const onSubmit = async () => {
        try {
            let values = [];
            if (attachFileList.length === 0) {
                alert('답변 파일을 업로드해주세요.');
                return;
            }
            for (let i = 0; i < attachFileList.length; i++) {
                const file = attachFileList[i];
                if (!file || !file.name) {
                    continue;
                }
                const filepath = `${testData[0].id}/answer/${i + 1}`;
                const value = `/api/mimac/file/${filepath}/${file.name}`;
                await postAttachment(file, filepath);
                values.push(value);
            }
            console.log("testData", testData);
            console.log('values1', values);
            await submitAnswerMutation.mutateAsync({
                id: test_id,
                props: [{
                        question_id: testData[0].id,
                        values: values,
                    }],
            });
            APP.eventManager.publish('ANSWER_REFETCH', throttledRefetch);
            setSubmitted(true);
            alert('답변이 제출되었습니다.');
            APP.eventManager.publish(TOGGLE_POPUP);
        }
        catch (error) {
            alert(error);
        }
    };
    return (React.createElement(DetailQuestionStyled, { "$isDesktop": isDesktop, "$isMobile": isMobile, className: "field-global-001 attachment-question-wrap" },
        isMobile && (React.createElement("div", { className: "submission-header" },
            isOpen && !submitted && (React.createElement(Button, { className: "btn_grey full", text: "common.submit", onClick: onSubmit })),
            !isMobile && (React.createElement("div", { className: "submission-title" }, t('assignment.detailQuestions'))))),
        React.createElement("div", { className: "flex column attachment-wrapper" }, submitted ? (React.createElement("div", { className: 'attachment-display' },
            React.createElement(AttachFileAnswer, { isTeacher: isTeacher, showRegist: !submitted, isRegist: !submitted, attachFileList: submittedFileList }),
            React.createElement("p", null, "\uC81C\uCD9C\uC774 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4."))) : (React.createElement("div", { className: 'attachment-display' },
            React.createElement(AttachFileAnswer, { isTeacher: isTeacher, showRegist: !submitted, isRegist: !submitted, attachFileList: attachFileList, setAttachFileList: setAttachFileList })))),
        isTest
            ? null
            : !isMobile &&
                isOpen && !submitted && (React.createElement(Button, { className: "btn_default xmd", text: "common.submit", onClick: onSubmit }))));
});
