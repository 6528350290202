import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { CheckBox, DropDown } from '../../../../common';
export const QnAFilterType = React.memo(({ isTeacher, type, searchCheckItem, setType, handlerFilterList, }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const checkBoxRef = useRef(null);
    const checkboxList = useMemo(() => {
        const baseList = [
            ...(isMobile
                ? [
                    {
                        id: 'select',
                        title: 'common.select',
                        ...(isMobile ? { checked: false } : {}),
                    },
                ]
                : []),
            { id: 'category', title: 'supportRoom.category', checked: false },
            { id: 'title', title: 'supportRoom.title', checked: false },
            { id: 'content', title: 'supportRoom.content', checked: false },
            { id: 'register_author', title: 'supportRoom.register', checked: false },
            ...(isTeacher
                ? [{ id: 'answerer_author', title: 'supportRoom.answerer', checked: false }]
                : []),
            { id: 'status', title: 'supportRoom.answer_state', checked: false },
        ];
        return baseList;
    }, [isMobile, isTeacher]);
    const [list, setList] = React.useState(checkboxList);
    const [value, setValue] = React.useState({
        id: 'select',
        title: 'common.select',
    });
    useEffect(() => {
        if (type === '') {
            if (checkBoxRef.current) {
                checkBoxRef.current.reset();
            }
        }
        setList(checkboxList.map(item => ({
            ...item,
            checked: item.id === (type || searchCheckItem),
        })));
        const item = checkboxList.find(item => item.id === (type || searchCheckItem));
        item && setValue(item);
    }, [checkboxList, type, searchCheckItem]);
    const handlerCheck = useCallback((id) => {
        setType(id);
        const checkedItem = list.find(item => item.id === id);
        if (checkedItem) {
            if (type)
                handlerFilterList({ [type]: searchCheckItem });
        }
    }, [setType]);
    const handlerDrop = useCallback((value) => {
        setType(value?.id);
        setValue(value);
        handlerFilterList({ [value?.id]: undefined });
    }, [type]);
    if (isDesktop) {
        return (React.createElement("div", null,
            React.createElement(CheckBox, { className: "qna-check", isRadioButton: true, list: list, defaultCheckId: searchCheckItem, handlerCheck: handlerCheck })));
    }
    else {
        return (React.createElement(DropDown, { className: "dropdown-mobile-small", value: value, list: list, setDropValue: handlerDrop, styles: { dropWrap: { width: isTablet && '120px' } } }));
    }
});
