import React, { useEffect } from 'react';
import { AverageExamAttendance, SubmissionStatusGraph } from './component/DSTL001_Detail_Child';
import styled, { css } from 'styled-components';
import { useStatistics } from '../../../server';
import { useGlobalState } from '../../../GlobalStateProvider';
const SubmissionContainerStyled = styled.div `
	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
`;
const desktopStyles = css `
	display: flex;
	flex-direction: row;
	gap: 20px;
`;
export const SubmissionContainer = React.memo(({ test_id }) => {
    const { isMobile } = useGlobalState();
    const [statisticItem, setStatisticItem] = React.useState({
        average: {
            '100%': {
                score: 0,
                corrects: 0,
            },
            '50%': {
                score: 0,
                corrects: 0,
            },
            '10%': {
                score: 0,
                corrects: 0,
            },
        },
        submitted: 0,
        not_submitted: 0,
        question_count: 0,
        test_id: '',
        subject_item: '',
        hardest_rank: [],
    });
    const { data: statisticData, refetch: statisticRefetch } = useStatistics({
        test_id,
    });
    useEffect(() => {
        statisticRefetch();
    }, [test_id]);
    useEffect(() => {
        if (!statisticData)
            return;
        setStatisticItem(statisticData);
    }, [statisticData]);
    return (React.createElement(SubmissionContainerStyled, { "$isMobile": isMobile, className: "_001" },
        React.createElement(SubmissionStatusGraph, { isHome: true, test_id: test_id }),
        React.createElement(AverageExamAttendance, { isHome: true, question_count: statisticItem.question_count, average: statisticItem.average['100%'] })));
});
