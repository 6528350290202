import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../../Colors';
import { useSubmitStatus } from '../../../../../server';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { DoughnutChart } from '../../../../../common';
const SubmissionStyled = styled.div `
	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileStyles = css `
	border-style: solid;
	border-color: var(--color-silver, #f3f4f8);
	border-width: ${props => (props.$isHome ? '0px' : '0px 1px 0px 0px;')};
	display: flex;
	flex-direction: column;
	gap: ${props => (props.$isHome ? '6px' : '32px')};
	align-items: center;
	justify-content: ${props => (props.$isHome ? 'flex-start' : 'center')};
	flex-shrink: 0;
	position: relative;

	.submission_status_container {
		display: flex;
		flex-direction: column;
		gap: 28px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}

	${props => props.$isHome
    ? css `
					.number-people {
						border-radius: 10px;
						border-style: solid;
						border-color: var(--color-grey-c, #e6e8ed);
						border-width: 1px;
						padding: 12px 20px 12px 20px;
						display: flex;
						flex-direction: row;
						gap: 12px;
						align-items: flex-start;
						justify-content: space-around;
						align-self: stretch;
						flex-shrink: 0;
						position: relative;

						.submission-txt-wrap {
							display: flex;
							flex-direction: column;
							gap: 4px;
							align-items: center;
							justify-content: center;
							flex: 1;
							position: relative;
						}

						.submission_status_wrap {
							display: flex;
							flex-direction: row;
							gap: 6px;
							align-items: center;
							justify-content: flex-start;
							flex-shrink: 0;
							position: relative;
						}
					}

					.submission-headline,
					.submission-headline2 {
						text-align: left;
						font-family: 'NotoSansKr-Regular', sans-serif;
						font-size: 14px;
						line-height: 150%;
						letter-spacing: -0.02em;
						font-weight: 400;
						position: relative;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.submission_status_container {
						gap: 0px;
					}
			  `
    : css `
					.number-people {
						background: var(--color-white, #ffffff);
						border-radius: 10px;
						padding: 20px;
						display: flex;
						flex-direction: column;
						gap: 8px;
						align-items: flex-start;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
						box-shadow: var(
							--shadow-bg-box-shadow,
							0px 2px 6px 0px rgba(224, 226, 235, 0.4)
						);
						width: 100%;

						.submission_txt_wrap {
							display: flex;
							flex-direction: row;
							gap: 6px;
							align-items: center;
							justify-content: space-between;
							flex-shrink: 0;
							height: 42px;
							position: relative;
							width: 100%;
						}

					}

					.submission-headline,
					.submission-headline2 {
						text-align: left;
						font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
						font-size: var(--h4-font-size, 18px);
						line-height: 150%
						letter-spacing: var(--h4-letter-spacing, -0.02em);
						font-weight: var(--h4-font-weight, 700);
						position: relative;
						display: flex;
						align-items: center;
						justify-content: flex-start;
					}

					.submission_status_container {
						gap: 28px;
					}
			  `};
`;
const tabletStyles = css `
	border-style: solid;
	border-color: var(--color-silver, #f3f4f8);
	border-width: ${props => (props.$isHome ? '0px' : '0px 1px 0px 0px;')};
	padding: ${props => (props.$isHome ? '6px 12px 6px 12px;' : ' 0px 20px 0px 0px;')};
	display: flex;
	flex-direction: column;
	gap: ${props => (props.$isHome ? '12px' : '32px')};
	align-items: center;
	justify-content: ${props => (props.$isHome ? 'flex-start' : 'center')};
	flex-shrink: 0;
	position: relative;

	.submission_status_container {
		display: flex;
		flex-direction: ${props => (props.$isHome ? 'column' : 'row')};
		gap: ${props => (props.$isHome ? '14px' : '28px')};
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.number-people {
		.submission_txt_wrap {
			display: flex;
			flex-direction: row;
			gap: 6px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			height: ${props => (props.$isHome ? '32px' : '42px')};
			position: relative;
		}

		.submission_status_wrap {
			display: flex;
			flex-direction: row;
			gap: 6px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.divider {
				background: #d9d9d9;
				flex-shrink: 0;
				width: 1px;
				height: 16px;
				position: relative;
			}
		}
	}

	${props => props.$isHome
    ? css `
					.number-people {
						border-radius: 10px;
						border-style: solid;
						border-color: var(--color-grey-c, #e6e8ed);
						border-width: 1px;
						padding: 6px 12px 6px 12px;
						display: flex;
						flex-direction: column;
						gap: 0px;
						align-items: center;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
					}

					.submission-headline,
					.submission-headline2 {
						text-align: left;
						font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
						font-size: var(--b4-font-size, 14px);
						line-height: var(--b4-line-height, 28px);
						letter-spacing: var(--b4-letter-spacing, -0.02em);
						font-weight: var(--b4-font-weight, 400);
						position: relative;
						display: flex;
						align-items: center;
						justify-content: flex-start;
					}
			  `
    : css `
					.number-people {
						background: var(--color-white, #ffffff);
						border-radius: 10px;
						padding: 20px;
						display: flex;
						flex-direction: column;
						gap: 8px;
						align-items: flex-start;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
						box-shadow: var(
							--shadow-bg-box-shadow,
							0px 2px 6px 0px rgba(224, 226, 235, 0.4)
						);
						min-width: 218px;
						.submission_txt_wrap {
							width: 100%;
						}
					}

					.submission-headline,
					.submission-headline2 {
						text-align: left;
						font-family: 'NotoSansKr-Bold', sans-serif;
						font-size: 18px;
						line-height: 150%;
						letter-spacing: -0.02em;
						font-weight: 700;
						position: relative;
						display: flex;
						align-items: center;
						flex: 1;
					}

					.submission-headline {
						justify-content: flex-start;
						min-width: 90px;
					}

					.submission-headline2 {
						justify-content: flex-end;
					}
			  `};
`;
const desktopStyles = css `
	border-style: solid;
	border-color: var(--color-silver, #f3f4f8);
	border-width: ${props => (props.$isHome ? '0px' : '0px 1px 0px 0px;')};
	padding: ${props => (props.$isHome ? '6px 12px 6px 12px;' : ' 0px 20px 0px 0px;')};
	display: flex;
	flex-direction: column;
	gap: ${props => (props.$isHome ? '14px' : '32px')};
	align-items: center;
	justify-content: ${props => (props.$isHome ? 'flex-start' : 'center')};
	flex-shrink: 0;
	position: relative;

	.submission_status_container {
		display: flex;
		flex-direction: ${props => (props.$isHome ? 'column' : 'row')};
		gap: ${props => (props.$isHome ? '12px' : '28px')};
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}

	${props => props.$isHome
    ? css `
					.number-people {
						border-radius: 10px;
						border-style: solid;
						border-color: var(--color-grey-c, #e6e8ed);
						border-width: 1px;
						padding: 6px 12px 6px 12px;
						display: flex;
						flex-direction: column;
						gap: 0px;
						align-items: center;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
					}

					.submission-headline,
					.submission-headline2 {
						text-align: left;
						font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
						font-size: var(--b4-font-size, 14px);
						line-height: var(--b4-line-height, 28px);
						letter-spacing: var(--b4-letter-spacing, -0.02em);
						font-weight: var(--b4-font-weight, 400);
						position: relative;
						display: flex;
						align-items: center;
						justify-content: flex-start;
					}
			  `
    : css `
					.number-people {
						background: var(--color-white, #ffffff);
						border-radius: 10px;
						padding: 20px;
						display: flex;
						flex-direction: column;
						gap: 8px;
						align-items: flex-start;
						justify-content: flex-start;
						flex-shrink: 0;
						position: relative;
						box-shadow: var(
							--shadow-bg-box-shadow,
							0px 2px 6px 0px rgba(224, 226, 235, 0.4)
						);
					}

					.submission-headline,
					.submission-headline2 {
						text-align: left;
						font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
						font-size: var(--h4-font-size, 20px);
						line-height: var(--h4-line-height, 32px);
						letter-spacing: var(--h4-letter-spacing, -0.02em);
						font-weight: var(--h4-font-weight, 700);
						position: relative;
						display: flex;
						align-items: center;
						justify-content: flex-start;
					}
			  `};

	.number-people {
		.submission_txt_wrap {
			display: flex;
			flex-direction: row;
			gap: 6px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			height: ${props => (props.$isHome ? '32px' : '42px')};
			position: relative;
		}

		.submission_status_wrap {
			display: flex;
			flex-direction: row;
			gap: 6px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.divider {
				background: #d9d9d9;
				flex-shrink: 0;
				width: 1px;
				height: 16px;
				position: relative;
			}
		}
	}
`;
export const SubmissionStatusGraph = React.memo(({ isHome = false, test_id, subject_item, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet } = useGlobalState();
    const [submitted, setSubmitted] = React.useState(0);
    const [notSubmitted, setNotSubmitted] = React.useState(0);
    const { data, refetch } = useSubmitStatus({
        id: test_id,
        subject_item,
    });
    useEffect(() => {
        test_id && refetch();
    }, [test_id, subject_item]);
    useEffect(() => {
        if (data) {
            const { submitted, not_submitted } = data;
            setSubmitted(submitted);
            setNotSubmitted(not_submitted);
        }
    }, [data]);
    const renderPerson = useMemo(() => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "submission_txt_wrap" },
            React.createElement("p", { className: "submission-headline", style: { color: Colors.light_tur } }, t(`assignment.${isHome ? 'submitted' : 'submitPerson'}`)),
            React.createElement("p", { className: "submission-headline2" }, t('assignment.person', { count: submitted }))),
        isHome && !isMobile && React.createElement("div", { className: "divider" }),
        React.createElement("div", { className: "submission_txt_wrap" },
            React.createElement("p", { className: "submission-headline", style: { color: '#F46790' } }, t(`assignment.${isHome ? 'notSubmitted' : 'noSubmitPerson'}`)),
            React.createElement("p", { className: "submission-headline2" }, t('assignment.person', { count: notSubmitted }))))), [submitted, notSubmitted]);
    return (React.createElement(SubmissionStyled, { "$isHome": isHome, "$isMobile": isMobile, "$isTablet": isTablet, className: "submission_status_graph" },
        React.createElement("div", { className: "dst001_detail_title" },
            t('assignment.submitStatus'),
            " "),
        React.createElement("div", { className: "submission_status_container" },
            React.createElement(DoughnutChart, { size: isHome ? 160 : 200, percentage: Math.round((submitted / (submitted + notSubmitted)) * 100) }),
            React.createElement("div", { className: "number-people" },
                React.createElement("div", { className: "submission_txt_wrap" },
                    React.createElement("p", { className: "submission-headline" }, "\uCD1D \uC778\uC6D0"),
                    React.createElement("p", { className: "submission-headline2", style: { color: Colors.p_ture } }, t('assignment.person', { count: submitted + notSubmitted }))),
                isHome && !isMobile ? (React.createElement("div", { className: "submission_status_wrap" }, renderPerson)) : (renderPerson)))));
});
