import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../GlobalStateProvider';
const AnswerStyled = styled.div `
	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	width: 100%;
	gap: 12px;
	align-items: center;
	padding: 0px 4px 0px 4px;

	.submit {
		position: relative;
		cursor: pointer;

		.frame {
			border-radius: 50px;
			border-style: solid;
			border-color: var(--color-black, #101010);
			border-width: 1px;
			padding: 4px 10px 4px 10px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			width: 26px;
			height: 26px;
			position: relative;
		}
		.value {
			color: var(--color-black, #101010);
			text-align: center;
			font-family: 'NotoSansKr-Regular', sans-serif;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
		}

		&.active {
			.frame {
				border-color: var(--color-pink, #f46790);
			}

			.value {
				color: var(--color-pink, #f46790);
			}
		}
	}
`;
const desktopStyles = css `
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	width: 100%;
	gap: 10px;

	.submit {
		position: relative;
		cursor: pointer;

		.frame {
			border-radius: 50px;
			border-style: solid;
			border-color: var(--color-black, #101010);
			border-width: 1px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			width: 28px;
			height: 28px;
		}
		.value {
			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
			position: relative;
		}

		&.active {
			.frame {
				border-color: var(--color-pink, #f46790);
			}

			.value {
				color: var(--color-pink, #f46790);
			}
		}
	}
`;
export const AnswerContainer = ({ defaultValue = 0, isOnlyAnswer = false, maxCount, onChange, }) => {
    const { isMobile } = useGlobalState();
    const [number, setNumber] = useState(defaultValue);
    const containers = useMemo(() => {
        if (isOnlyAnswer) {
            return (React.createElement("div", { className: "submit" },
                React.createElement("div", { className: "frame" },
                    React.createElement("div", { className: "value" }, defaultValue))));
        }
        const arr = [];
        for (let i = 0; i < maxCount; i++) {
            arr.push(React.createElement("div", { className: `submit ${number === i + 1 ? 'active' : ''}`, onClick: () => {
                    onChange(i + 1);
                    setNumber(i + 1);
                }, key: i },
                React.createElement("div", { className: "frame" },
                    React.createElement("div", { className: "value" }, i + 1))));
        }
        return arr;
    }, [onChange]);
    return React.createElement(AnswerStyled, { "$isMobile": isMobile }, containers);
};
