import { getClassesId } from '../../features/base/classes';
import { fetchData, objectToQueryString, useCustomQuery } from '../functions';
// (파일) 파일 업로드
export const postFile = async (files) => {
    const url = `/api/mimac/file`; // API 엔드포인트 생성
    const formData = new FormData();
    files.map(file => formData.append('file', file));
    const options = {
        method: 'POST',
        body: formData,
    };
    return fetchData(url, options);
};
// (파일) 파일 업로드
export const postAttachment = async (file, filepath) => {
    const url = `/api/mimac/file`; // API 엔드포인트 생성
    const formData = new FormData();
    formData.append('file', file); // Add the file
    formData.append('filepath', filepath); // Add the filepath
    const options = {
        method: 'POST',
        body: formData,
    };
    return fetchData(url, options);
};
// (사용자) 멤버 정보 가져오기
const getMemberList = async (queryString) => {
    const class_id = getClassesId();
    const url = `/api/mimac/v1/members?${queryString}&class_id=${class_id}`; // API 엔드포인트 생성
    return fetchData(url);
};
// (사용자) 멤버 정보 가져오기 -useMemberInfo
export const useMemberInfo = (data) => {
    const queryString = objectToQueryString(data); // data를 쿼리 문자열로 변환
    return useCustomQuery(`user_${queryString}`, () => getMemberList(queryString));
};
