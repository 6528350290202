import React, { Suspense, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { GlobalProvider } from './GlobalStateProvider';
import LoadingPage from './Loading';
import './assest/css/all.scss';
import { default as eventManager } from './eventManager';
import { StreamingContainer } from './features/DST/DSTT007/component/DSTT007_List_Child/StreamingContainer';
import { DupliactionErrorPage, Login } from './features/base/account';
import { Login2 } from './features/base/account/component/Login2';
import { appWillLoad } from './features/base/app';
import { MainClasses } from './features/base/layout';
import i18next from './lang/i18next';
import './redux/app/middleware.any';
import './redux/app/reducers.any';
import createAppStore from './redux/store';
window.APP =
    {
        eventManager: eventManager,
        deviceType: 'desktop',
    } || {};
const App = React.memo(() => {
    const queryClient = new QueryClient();
    const store = createAppStore();
    return (React.createElement(I18nextProvider, { i18n: i18next },
        React.createElement(QueryClientProvider, { client: queryClient },
            React.createElement(Provider, { store: store },
                React.createElement(RouterApp, null)))));
});
export default App;
const RouterApp = React.memo(() => {
    const dispatch = useDispatch();
    const load = useSelector((state) => state['base/app'].isLoad);
    // Function to clear cache
    const clearCache = () => {
        if ('caches' in window) {
            caches.keys().then(names => {
                names.forEach(name => {
                    caches.delete(name);
                });
            });
        }
    };
    useEffect(() => {
        dispatch(appWillLoad());
        clearCache();
        const setVh = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVh();
        window.addEventListener('resize', setVh);
        return () => {
            window.removeEventListener('resize', setVh);
        };
    }, []);
    if (!load)
        return React.createElement(LoadingPage, { defaultLoading: true });
    return (React.createElement(Suspense, { fallback: React.createElement(LoadingPage, { defaultLoading: true }) },
        React.createElement(Router, null,
            React.createElement(GlobalProvider, null,
                React.createElement(Routes, null,
                    React.createElement(Route, { path: "/", element: React.createElement("iframe", { src: "https://klassmate.dev.meetmate.co.kr", style: {
                                width: '100%',
                                height: '100%',
                                outline: 'none',
                            } }) }),
                    React.createElement(Route, { path: "/login", element: React.createElement(Login, null) }),
                    React.createElement(Route, { path: "/dev", element: React.createElement(Login2, null) }),
                    React.createElement(Route, { path: "/class/*", element: React.createElement(MainClasses, null) }),
                    React.createElement(Route, { path: "/error", element: React.createElement(DupliactionErrorPage, null) }),
                    React.createElement(Route, { path: "/player/*", element: React.createElement(StreamingContainer, null) })),
                React.createElement(LoadingPage, null)))));
});
