import React, { useEffect } from 'react';
import { CheckBox } from '../../../../../common';
import { getIsManager } from '../../../../base/account';
import { useSelector } from 'react-redux';
export const SetPublicRadio = React.memo(({ isDisable = false, defaultPublic, handlerChange, }) => {
    const isManager = useSelector((state) => getIsManager(state));
    const PUBLIC_RADIO_LIST = [
        { id: 'open', title: 'conference.public', checked: false },
        { id: 'hide', title: 'conference.private', checked: true },
        ...(isManager
            ? [{ id: 'open_partial', title: 'conference.part', checked: false }]
            : []),
    ];
    const [list, setList] = React.useState(PUBLIC_RADIO_LIST);
    const [selectedId, setSelectedId] = React.useState(null);
    const handlerCheck = (id, checked) => {
        !isDisable && setSelectedId(checked ? id : undefined);
    };
    useEffect(() => {
        if (defaultPublic) {
            setSelectedId(defaultPublic);
            setList(PUBLIC_RADIO_LIST.map(item => {
                return { ...item, checked: item.id === defaultPublic };
            }));
        }
        else {
            setSelectedId('hide');
        }
    }, [defaultPublic]);
    useEffect(() => {
        handlerChange(selectedId);
    }, [selectedId]);
    return (React.createElement(CheckBox, { isDisable: isDisable, isRadioButton: true, list: list, handlerCheck: handlerCheck }));
});
