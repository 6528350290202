import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
class HistoryStore {
    historyList;
    constructor() {
        this.historyList = [];
    }
    getRecentPageHistory(pageName) {
        const matchPageNameHistoryList = this.historyList.filter(history => history.pageName === pageName);
        if (matchPageNameHistoryList.length > 0) {
            return matchPageNameHistoryList[matchPageNameHistoryList.length - 1];
        }
        return null;
    }
    pushHistory(historyItem) {
        this.historyList.push(historyItem);
    }
    clearHistoryForPage(pageName) {
        this.historyList = this.historyList.filter(history => history.pageName !== pageName);
    }
}
const CombinedContext = createContext(null);
export const GlobalProvider = ({ children }) => {
    const location = useLocation();
    const [historyStore] = useState(() => new HistoryStore());
    const [globalState, setGlobalState] = useState({
        isMobile: window.innerWidth <= 767,
        isTablet: window.innerWidth > 767 && window.innerWidth < 1400,
        isDesktop: window.innerWidth >= 1400,
        classesId: '',
    });
    const updateGlobalState = () => {
        setGlobalState(prevState => ({
            ...prevState,
            isMobile: window.innerWidth <= 767,
            isTablet: window.innerWidth > 767 && window.innerWidth < 1400,
            isDesktop: window.innerWidth >= 1400,
        }));
    };
    useEffect(() => {
        window.addEventListener('resize', updateGlobalState);
        return () => window.removeEventListener('resize', updateGlobalState);
    }, []);
    const updateClassesId = () => {
        const uuidMatch = location.pathname.match(/\/class\/([0-9a-f-]+)$/i);
        const roomUuid = uuidMatch ? uuidMatch[1] : null;
        setGlobalState(prevState => ({
            ...prevState,
            classesId: roomUuid || '',
        }));
    };
    useEffect(() => {
        updateClassesId();
    }, [location]);
    const contextValue = {
        globalState,
        updateGlobalState,
        historyStore,
    };
    return React.createElement(CombinedContext.Provider, { value: contextValue }, children);
};
export const useGlobalContext = () => {
    const context = useContext(CombinedContext);
    if (!context) {
        throw new Error('useGlobalContext must be used within a GlobalProvider');
    }
    return context;
};
export const useGlobalState = () => {
    const { globalState } = useGlobalContext();
    return globalState;
};
export const useHistoryStore = () => {
    const { historyStore } = useGlobalContext();
    return historyStore;
};
