import throttle from 'lodash/throttle';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { useGlobalState, useHistoryStore } from '../../../../GlobalStateProvider';
import { Button, Table } from '../../../../common';
import { useSendSMSList } from '../../../../server/sms';
import { changeMemuProps } from '../../../menu/action';
import { DSTL007_Detail } from '../DSTL007_Detail';
import { SendSMSTitle } from './SendSMSTitle';
const SendSMSTableStyles = styled.div `
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-start;
	align-self: stretch;
	position: relative;
	width: 100%;
	overflow: hidden;

	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.body-item {
		.pink {
			color: var(--color-pink, #f46790);
		}

		.tur {
			color: var(--tur-d, #479da6);
		}
	}

	.bold-tur {
		p {
			color: var(--tur-d, #479da6);
			font-weight: 700;
		}
	}

	.sms-list-001 {
		width: 100%;
	}

	.locked-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.title-wrap {
		width: 100%;
	}
	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	gap: 24px;

	.title-wrap {
		display: flex;
		flex-direction: column;
		gap: 12px;

		.title-button-wrap {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			.title {
				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
			}
		}
	}

	.sms-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		overflow: hidden;

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b3-font-size, 12px);
				line-height: 150%;
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
			}

			.button {
				p {
					font-size: var(--b3-font-size, 10px);
					color: var(--color-white, #ffffff);
					width: 30px;
					height: 17px;
					display: flex;
					justify-content: center;
				}
			}

			.bold {
				font-size: 14px;
				font-weight: 700;
			}

			.grey {
				color: var(--color-grey-l, #83868a);
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 0px 0px;
			padding: 10px 0px 10px 0px;
			gap: 4px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}

	.qna-list-wrap {
		display: flex;
		flex-direction: row;
		gap: 6px;
	}

	.locked-wrap {
		gap: 4px;
		svg {
			min-width: 14px;
		}
	}
	.search-bar-big {
		height: 41px;
	}
`;
const tabletStyles = css `
	flex: 1;
	gap: 12px;
	min-width: 530px;

	.sms-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 12px 20px 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: 12px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: var(--b3-font-weight, 400);
			}

			.button {
				p {
					font-size: var(--b3-font-size, 12px);
					color: var(--color-white, #ffffff);
					width: 45px;
					height: 21px;
					display: flex;
					justify-content: center;
				}
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;

			padding: 12px 20px 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}

	.search-bar-big {
		height: 41px;
	}

	.paging {
		gap: 8px;
		button {
			width: 25px;
			height: 25px;
			p {
				min-width: unset;
			}
		}
	}
`;
const desktopStyles = css `
	flex: 2;
	gap: 24px;

	.sms-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 6px 28px 6px 28px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b3-font-size, 16px);
				line-height: var(--b3-line-height, 32px);
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
			}

			.file {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				overflow: hidden;
				p {
					color: var(--color-grey-w, #b6bac0);
				}
			}

			.button {
				p {
					font-size: var(--b3-font-size, 14px);
					color: var(--color-white, #ffffff);
					width: 49px;
					height: 30px;
					display: flex;
					justify-content: center;
				}
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;

			padding: 20px 28px 20px 28px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}
`;
export const SendSMSListTable = React.memo(({ setIsOpen, setEditId, setReceiverMemberList, }) => {
    const { t } = useTranslation();
    const historyStore = useHistoryStore();
    const { isTablet, isMobile, isDesktop } = useGlobalState();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [count, setCount] = useState(10);
    const [searchOption, setSearchOption] = useState({});
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    // detail ID
    const [selectedId, setSelectedId] = useState();
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            { title: 'dataType', space: 1 },
            { title: 'content', space: 3 },
            ...(isMobile ? [] : [{ title: 'sender', space: 1 }]),
            { title: 'sendDate', space: 2 },
        ];
        const tableReadDataKeys = [
            { title: 'status', space: 1 },
            { title: 'content', space: 3 },
            ...(isMobile ? [] : [{ title: 'sender_name', space: 1 }]),
            { title: 'send_time', space: 2 },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, [isMobile]);
    useEffect(() => {
        if (isMobile) {
            //history에 저장된 항목 초기화 (리스트 페이지일 때만) - 발송 -> 수정으로 이동 시 초기화
            const value = localStorage.getItem('learningManagement_props');
            if (value !== 'undefined') {
                const parsedValue = JSON.parse(value).historyItem?.props;
                if (parsedValue.mobilePage === 'list' || parsedValue.mobilePage === 'send') {
                    const historyItem = { props: undefined, pageName: 'mobileSend' };
                    historyStore.pushHistory(historyItem);
                }
            }
        }
        APP.eventManager.subscribe('UPDATE_SEND_SMS', refetchList);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_SEND_SMS', refetchList);
        };
    }, []);
    const { data, refetch } = useSendSMSList({
        ...searchOption,
        offset: page === 0 ? 0 : page * count,
        limit: count,
        orderby: 'send_time DESC',
    });
    const refetchList = throttle(() => refetch(), 1000, { leading: true, trailing: false });
    useEffect(() => {
        setSelectedId(undefined);
        refetchList();
    }, [searchOption, page]);
    useEffect(() => {
        if (data) {
            const { total_count, items, found_count } = data;
            if (total_count > 0 && found_count === 0) {
                setPage(Math.max(page - 1, 0));
                return;
            }
            if (found_count > 0) {
                setTestData(items);
                setTotalCount(total_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    const handlerMoreData = (page) => setPage(page);
    // 상단 레이아웃 검색 조건을 위한 action
    const handlerFilterList = (filter) => {
        setSearchOption({ ...filter[0], ...filter[1] });
    };
    const handlerSendMessage = () => {
        setIsOpen(true);
        //수정 -> 발송으로 올 경우 초기화
        setEditId('');
        setReceiverMemberList([]);
    };
    const renderTitle = useMemo(() => {
        return (React.createElement("div", { className: "title-wrap" },
            isMobile && (React.createElement("div", { className: "title-button-wrap" },
                React.createElement("div", { className: "title" }, t('sendSMS.sendSMS')),
                React.createElement(Button, { className: "btn_light_tur md_round", text: t('sendSMS.submitSMS'), onClick: handlerSendMessage }))),
            React.createElement(SendSMSTitle, { setStartDate: setStartDate, startDate: startDate, setEndDate: setEndDate, endDate: endDate, handlerFilterList: handlerFilterList })));
    }, [handlerFilterList]);
    const renderItem = (item, styles, index) => {
        const isSelcted = item.id === selectedId;
        return tableReadDataKeys.map(headItem => (React.createElement("div", { key: `${item.id} ${headItem.title}`, className: `body-item ${isSelcted ? 'bold-tur' : ''}`, style: {
                flex: headItem.space,
                maxWidth: headItem?.maxWidth ? headItem.maxWidth : 'auto',
                ...styles?.bodyItem,
                overflow: 'hidden',
            } }, headItem.title === 'status' ? (React.createElement("p", { className: "overText2" }, t(`sendSMS.${item[headItem.title]}`))) : headItem.title === 'content' ? (React.createElement("p", { className: `overText2 ${isMobile ? 'bold' : ''}` },
            item.content,
            " ")) : headItem.title === 'send_time' ? (React.createElement(React.Fragment, null,
            isMobile && (React.createElement("p", { className: `overText2 ${selectedId ? 'bold-tur' : 'grey'}` }, item['sender'])),
            React.createElement("p", { className: `overText2 ${isMobile && (selectedId ? 'bold-tur' : 'grey')}` }, moment(item[headItem.title]).format('YYYY-MM-DD, HH:mm')))) : (React.createElement("p", { className: "overText2" },
            " ",
            item[headItem.title])))));
    };
    const renderMoreItem = (item, styles) => {
        return (React.createElement(DSTL007_Detail, { selectedId: selectedId, setSelectedId: setEditId, setReceiverMemberList: setReceiverMemberList }));
    };
    const handlerSelectItem = (item) => {
        const newId = selectedId === item.id ? undefined : item.id;
        setSelectedId(newId);
        dispatch(changeMemuProps({ defaultOpen: newId }));
    };
    return (React.createElement(SendSMSTableStyles, { "$isTablet": isTablet, "$isMobile": isMobile, className: "sms-list-table" },
        React.createElement(Table, { className: 'sms-list-001', showIndex: false, showCheckBox: false, isInifinite: false, selectedId: selectedId, page: page, showCount: count, data: testData, totalCount: totalCount, headTitleList: headTitleList, renderTitle: renderTitle, renderItem: renderItem, renderMoreItem: renderMoreItem, handlerSelectItem: handlerSelectItem, handlerMoreData: handlerMoreData, forceShowheader: isMobile ? false : true })));
});
