import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InputElement } from '../../../../../common';
import { useGlobalState } from '../../../../../GlobalStateProvider';
export const NameTagContainer = React.memo(({ test_type, member_name }) => {
    const { t } = useTranslation();
    const { isMobile, isDesktop } = useGlobalState();
    const [memberName, setMemberName] = React.useState(member_name);
    const [subjectName, setSubjectName] = React.useState('');
    useEffect(() => {
        setSubjectName(test_type);
    }, [test_type]);
    useEffect(() => {
        setMemberName(member_name);
    }, [member_name]);
    return (React.createElement("div", { className: `${isMobile ? 'name-tag-info' : 'field-short'}`, style: { gap: isDesktop && '24px' } },
        React.createElement("div", { className: "field-global-001", style: { width: isMobile ? '100%' : '80%' } },
            React.createElement("div", { className: "task-label" }, t('assignment.subjectType')),
            React.createElement("div", { className: "fext-field", style: { flexDirection: 'column', alignItems: 'flex-start' } },
                React.createElement(InputElement, { type: "text", isDisable: true, value: subjectName }))),
        memberName && (React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "task-label", style: { minWidth: '55px' } }, t('assignment.submittedBy')),
            React.createElement("div", { className: "fext-field", style: { flexDirection: 'column', alignItems: 'flex-start' } },
                React.createElement(InputElement, { type: "text", isDisable: true, value: memberName }))))));
});
